import React from "react";
import Accordion, { AccordionProps } from "@material-ui/core/Accordion";
import AccordionSummary, {
  AccordionSummaryProps,
} from "@material-ui/core/AccordionSummary";
import { styled } from "@material-ui/core/styles";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { ReactComponent as ArrowDownIcon } from "../../resources/icons/arrowDown.svg";
import theme from "../../themes/Theme";

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion elevation={0} {...props} />
))(({ theme }) => ({
  "&.MuiAccordion-root": {
    width: "100%",
    background: "#FFFFFF",
    //boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.1)",
    //borderRadius: 20,
  },
  //border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ArrowDownIcon style={{ height: 9, width: 17 }} />}
    //expandIcon={<KeyboardArrowDownRoundedIcon style={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  //backgroundColor: 'rgba(0, 0, 0, .03)',
  //flexDirection: 'row-reverse',
  backgroundColor: "transparent",
  lineHeight: "30px",
  "&.MuiAccordionSummary-root.Mui-expanded": {
    height: 30,
    marginBottom: 0,
    marginTop: 20,
    minHeight: 30,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    //transform: 'rotate(90deg)',
  },
  "& .MuiAccordionSummary-content": {},
  "&.MuiAccordionSummary-root": {
    height: 30,
    marginBottom: 20,
    marginTop: 20,
    minHeight: 30,
    padding: "0px 30px",
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  "&.MuiAccordionDetails-root": {
    display: "block",
    textAlign: "left",
    padding: "0px 30px",
    //maxHeight:"350px",
    minHeight: 200,
    overflow: "scroll",
  },

  //borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export type ExpandablePanelProps = {
  expanded: boolean;
  switchExpanded: () => void;
  expandedByDefault: boolean;
  content: any;
  name: string;
  number?: number;
};

export default function ExpandablePanel(props: ExpandablePanelProps) {
  const handleChange = () => {
    props.switchExpanded();
  };

  return (
    <StyledAccordion
      TransitionProps={{
        timeout: { exit: 20, enter: 0 },
      }}
      expanded={props.expanded}
      onChange={() => handleChange()}
    >
      <StyledAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            fontSize: 18,
            textAlign: "left",
            fontFamily: "RedHatDisplay-SemiBold",
            margin: "auto 0px",
            color: theme.palette.primary.main,
          }}
        >
          {props.name}
        </div>
        {props.number && (
          <div
            style={{
              background: "#38B9C2",
              textAlign: "center",
              borderRadius: 8,
              width: 28,
              height: 28,
              fontFamily: "RedHatDisplay-SemiBold",
              fontSize: 14,
              color: "white",
            }}
          >
            {props.number}
          </div>
        )}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{props.content}</StyledAccordionDetails>
    </StyledAccordion>
  );
}
