import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import EstimateQuoteCard from "./GetEstimationCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "rgba(14,127,138,0.2)",
      fontFamily: "SuperaGothic",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        margin: "0%",
        marginBottom: "0%",
        padding: "8%",
      },
      [theme.breakpoints.up("md")]: {
        margin: "0% 8%",
        //marginBottom: '0%',
        padding: 80,
      },

      borderRadius: "50px",
    },
    text: {
      //padding: '10%',
      paddingBottom: "5px",
    },
    image: {
      [theme.breakpoints.down("md")]: {
        //display: 'block',
        //marginBottom: '60px',
        //marginLeft: '20px',
        //width: '80%',
      },
      [theme.breakpoints.up("md")]: {
        //paddingRight: '60px',
        //marginRight: '60px',
        //width: '70%',
        //display: 'flex',
      },
    },
    spacing: {
      "@media (min-width:800px)": {
        marginTop: "25px",
        marginBottom: "15px",
      },
      "@media (max-width:800px)": {
        marginTop: "5vw",
        marginBottom: "0px",
      },
    },
    titleSection: {
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
    cardWrapper: {
      [theme.breakpoints.up("md")]: {
        padding: "5rem",
        paddingBottom: 5,
      },
      [theme.breakpoints.down("md")]: {
        padding: 0,
        paddingTop: "5rem",
      },
    },
  })
);

function EstimateQuoteSection() {
  const [oneTimeReward, setOneTimeReward] = useState<number | null>(null);
  //const [annualRevenue,  setAnnualRevenue] = useState<number | null>(null);
  const styles = useStyles();

  return (
    <div className={`${styles.root}`}>
      <div className={styles.text}>
        <Typography
          variant="h3"
          style={{ fontWeight: "bold" }}
          className={styles.titleSection}
        >
          How Much Can I Earn?
        </Typography>
        <Typography
          style={{ textAlign: "left" }}
          variant="body1"
          className={`roboto-condensed ${styles.spacing}`}
        >
          We are committed to pay{" "}
          <span style={{ fontWeight: "bold" }}>$50-$150</span> for every
          profile. Get a quick assessment below.
        </Typography>
      </div>
      <div className={`${styles.image}`}>
        <EstimateQuoteCard setOneTimeReward={setOneTimeReward} />
        {oneTimeReward && (
          <div
            className={`card-wrapper ${styles.cardWrapper}`}
            style={{
              paddingTop: 30,
              display: "flex",
              justifyContent: "space-around",
              textAlign: "center",
              maxWidth: 800,
              margin: " auto",
              color: "#0E7F8A",
            }}
          >
            <div>
              <Typography variant="h3" style={{ fontWeight: "bold" }}>
                ${oneTimeReward}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EstimateQuoteSection;
