import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { logEvent } from "../../helpers/GoogleAnalyticsLogger";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    generalButton: {
      color: "white",
      fontSize: "20px",
      fontFamily: "SuperaGothic",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
      "&:disabled": {
        backgroundColor: "#BFBFBF",
        color: "white",
      },
      textTransform: "none",
      alignItems: "center",
      padding: "0.3rem 1.5rem",
    },
    disabled: {
      fontSize: "20px",
      fontFamily: "SuperaGothic",
      backgroundColor: "#BFBFBF",
      color: "white",
      "&:disabled": {
        backgroundColor: "#BFBFBF",
        color: "white",
      },
      textTransform: "none",
      alignItems: "center",
      padding: "0.3rem 1.5rem",
    },
    unfilledButton: {
      backgroundColor: "rgba(0,0,0,0)",
      fontSize: "20px",
      fontFamily: "SuperaGothic",
      "&:hover": {
        backgroundColor: "white",
        color: theme.palette.primary.dark,
      },
      "&:disabled": {
        backgroundColor: "#BFBFBF",
        color: "white",
      },
      color: theme.palette.primary.main,
      textTransform: "none",
      alignItems: "center",
      padding: "0.3rem 1.5rem",
    },
    grayButton: {
      backgroundColor: theme.palette.secondary.dark,
      fontSize: "20px",
      fontFamily: "SuperaGothic",
      "&:hover": {
        backgroundColor: "#878585",
        color: "white",
      },
      "&:disabled": {
        backgroundColor: "#BFBFBF",
        color: "white",
      },
      textTransform: "none",
      alignItems: "center",
      padding: "0.3rem 1.5rem",
    },
    highlightButton: {
      backgroundColor: "#FCB24F",
      fontSize: "20px",
      fontFamily: "SuperaGothic",
      "&:hover": {
        backgroundColor: "#E89424",
        color: "white",
      },
      "&:disabled": {
        backgroundColor: "#BFBFBF",
        color: "white",
      },
      color: "white",
      textTransform: "none",
      alignItems: "center",
      padding: "0.3rem 1.5rem",
    },
  })
);

type ButtonStyle = "primary" | "unfilled" | "secondary" | "highlighted";

interface Props {
  label?: string;
  value?: number;
  link?: string;
  buttonStyle?: ButtonStyle;
  openInNewTab?: boolean;
}

function CustomizedButton(props: ButtonProps & Props) {
  const classes = useStyles();
  const { buttonStyle, label, value, link, openInNewTab, ...otherProps } =
    props;

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //const label = props.label ? history.location.pathname + "." + convertStringToLoggingFormat(props.label) : history.location.pathname;
    logEvent({
      category: "Button",
      action: "Click",
      value: props.value,
      label: props.label,
    });
    if (props.onClick) {
      props.onClick(event);
    }
  };

  let buttonStyleClass = classes.generalButton;
  switch (buttonStyle || "primary") {
    case "unfilled":
      buttonStyleClass = classes.unfilledButton;
      break;
    case "secondary":
      buttonStyleClass = classes.grayButton;
      break;
    case "highlighted":
      buttonStyleClass = classes.highlightButton;
  }

  if (props.disabled) {
    buttonStyleClass = classes.disabled;
  }

  if (props.link) {
    return (
      <Link to={props.link} target={openInNewTab ? "_blank" : undefined}>
        <Button className={buttonStyleClass} {...otherProps} onClick={onClick}>
          {props.children}
        </Button>
      </Link>
    );
  }

  return (
    <Button className={buttonStyleClass} {...otherProps} onClick={onClick}>
      {props.children}
    </Button>
  );
}

export default CustomizedButton;
