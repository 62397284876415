import React, { useState } from "react";
import ProfileSection from "./ProfileSection";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import RowLeft from "./RowLeft";
import RowRight from "./RowRight";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import PrimaryButton from "../../general/PrimaryButton";
import ChangePasswordPanel from "./ChangePasswordPanel";
import { Helmet } from "react-helmet";
import { User } from "../../../types/types";
import { useStore } from "react-redux";
import ChangeUserNamePanel from "./ChangeUserNamePanel";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import SnackBar from "../../general/SnackBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moneyInput: {
      width: "80%",
      maxWidth: "200px",
    },
    sideMargin: {
      margin: "10px 0px",
      width: "100%",
    },
    smallText: {
      fontFamily: "SuperaGothic",
      fontSize: "18px",
    },
    text: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    normalText: {
      fontFamily: "SuperaGothic",
      //marginBottom: '10px',
      fontSize: "16px",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
    },
    root: {
      [theme.breakpoints.down("sm")]: {
        margin: "0px 15px",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0rem 3rem",
      },
      //minHeight: "70%",
    },
  })
);

function LoginAndSecurityPage() {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [openUserNamePanel, setOpenUserNamePanel] = useState<boolean>(false);
  const store = useStore();
  const [user, setUser] = useState<User>(store.getState().user);
  const [message, setMessage] = useState<string | undefined>(undefined);
  //const user: User = store.getState().user;

  const callBack = () => {
    setMessage(undefined);
  };

  const refreshUser = () => {
    axios("api/v1/auto_login", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        data = data.data;
        if (data.user) {
          setUser(data.user);
          store.dispatch({ type: "UPDATE_USER_ACCTION", user: data.user });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const proceedToChangePassword = () => {
    if (openUserNamePanel) {
      return;
    }
    setOpen(true);
  };

  const proceedToChangeUserName = () => {
    if (open) {
      return;
    }
    setOpenUserNamePanel(true);
  };

  const onCloseUserNamePanel = () => {
    setOpenUserNamePanel(false);
  };

  const onSubmitUserNamePanel = () => {
    setOpenUserNamePanel(false);
    setMessage("Display name successfully updated!");
    refreshUser();
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setOpen(false);
    setMessage("Password successfuly updated!");
  };
  return (
    <div className={`${classes.root} page-container`}>
      <Helmet>
        <title>Log in and security - CollegeSharing</title>
      </Helmet>
      <SnackBar message={message} callBack={callBack} />
      <ChangeUserNamePanel
        open={openUserNamePanel}
        onClose={onCloseUserNamePanel}
        onSubmit={onSubmitUserNamePanel}
      />
      <ChangePasswordPanel open={open} onClose={onClose} onSubmit={onSubmit} />
      <ProfileSection title="Login and security">
        <Grid container spacing={3} className={classes.sideMargin}>
          <RowLeft>
            <Typography variant="h6" className={classes.text}>
              Email
            </Typography>
            <Typography variant="body2" className={classes.smallText}>
              {user.email}
            </Typography>
          </RowLeft>
        </Grid>
        <Grid container spacing={3} className={classes.sideMargin}>
          <RowLeft>
            <Typography variant="h6" className={classes.text}>
              Display Name
            </Typography>
            <Typography variant="body2" className={classes.smallText}>
              {user.userName}
            </Typography>
          </RowLeft>
          <RowRight>
            <PrimaryButton
              onClick={proceedToChangeUserName}
              label="proceed_to_change_user_name"
            >
              <Typography variant="h6" className={classes.buttonText}>
                Update
              </Typography>
            </PrimaryButton>
          </RowRight>
        </Grid>
        <Grid container spacing={3} className={classes.sideMargin}>
          <RowLeft>
            <Typography variant="h6" className={classes.text}>
              Password
            </Typography>
          </RowLeft>
          <RowRight>
            <PrimaryButton
              onClick={proceedToChangePassword}
              label="proceed_to_change_password"
            >
              <Typography variant="h6" className={classes.buttonText}>
                Change
              </Typography>
            </PrimaryButton>
          </RowRight>
        </Grid>
        <Divider />
      </ProfileSection>
    </div>
  );
}

export default LoginAndSecurityPage;
