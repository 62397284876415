import React, { useState } from "react";
import { School, Essay } from "../../../types/admitProfileTypes";
import { useStyles } from "./ProfileStyles";
import CutsomCarousel from "./CutsomCarousel";
import { ReactComponent as ApplicationRoundIcon } from "../../../resources/icons/application_round.svg";
import { ReactComponent as ScholarshipIcon } from "../../../resources/icons/scholarship.svg";
import { ReactComponent as LegacyIcon } from "../../../resources/icons/legacy.svg";
import { ReactComponent as SupplementIcon } from "../../../resources/icons/supplement.svg";
import { ReactComponent as BlueSchoolIcon } from "../../../resources/icons/blue_school.svg";
import { RowLabel } from "./LeftPanel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Card from "@material-ui/core/Card";
import "react-lazy-load-image-component/src/effects/blur.css";

export type SchoolSectionProps = {
  schools: School[];
};

export function SmallSchoolSection(props: SchoolSectionProps) {
  const classes = useStyles();
  const defaultSchoolId = props.schools[0].id;
  const [schoolId, setSchoolId] = useState<string>(defaultSchoolId);
  const currentSchool = props.schools.find((school) => school.id === schoolId);

  const changeSchool = (value: string) => {
    setSchoolId(value);
  };

  const logos = props.schools
    .concat(props.schools)
    .concat(props.schools)
    .concat(props.schools)
    .concat(props.schools)
    .concat(props.schools)
    .map((school, index) => {
      return (
        <div key={index} onClick={() => changeSchool(school.id)} style={{}}>
          <Card
            elevation={schoolId === school.id ? 5 : 0}
            style={{
              cursor: schoolId === school.id ? null : "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              //height: "100%",
              margin: "0px 10px",
              marginLeft: index === 0 ? 20 : 10,
              borderRadius: "50%",
              width: 100,
              height: 100,
              //height:"100%",
              paddingTop: 20,
              //paddingLeft: 20,
              backgroundColor: "rgba(229, 245, 246, 0.5)",
            }}
          >
            <SchoolAvatar
              school={school}
              key={index}
              selected={schoolId === school.id}
            />
          </Card>
        </div>
      );
    });

  return (
    <div style={{ marginBottom: 0 }}>
      <div style={{ width: "100%" }}>
        <div
          style={{
            paddingTop: 20,
            overflow: "scroll",
            display: "flex",
            paddingBottom: 20,
          }}
        >
          {logos}
        </div>

        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <div
            className={classes.accordionRoot}
            style={{ textAlign: "left", marginBottom: 20 }}
          >
            <RowLabel
              name="School"
              value={
                props.schools.find((school) => school.id === schoolId)
                  .shortSchoolName
              }
              icon={<BlueSchoolIcon />}
              shouldShowBottonLine
            />
            <RowLabel
              name="App. Round"
              value={currentSchool.applicationRound}
              icon={<ApplicationRoundIcon />}
              shouldShowBottonLine
            />
            <RowLabel
              name="Scholarship"
              value={currentSchool.scholarship}
              icon={<ScholarshipIcon />}
              shouldShowBottonLine
            />
            <RowLabel
              name="Legacy"
              value={currentSchool.legacy}
              icon={<LegacyIcon />}
              shouldShowBottonLine={false}
            />
          </div>
          {currentSchool.essays.length !== 0 && (
            <div
              className={classes.accordionRoot}
              style={{ textAlign: "left" }}
            >
              <div style={{ padding: 15, marginBottom: 20 }}>
                <EssaySection school={currentSchool} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function SchoolSection(props: SchoolSectionProps) {
  const classes = useStyles();
  const defaultSchoolId = props.schools[0].id;
  const [schoolId, setSchoolId] = useState<string>(defaultSchoolId);

  const currentSchool = props.schools.find((school) => school.id === schoolId);

  const changeSchool = (value: string) => {
    setSchoolId(value);
  };
  const logos = props.schools
    .concat(props.schools)
    .concat(props.schools)
    .concat(props.schools)
    .concat(props.schools)
    .concat(props.schools)
    .map((school, index) => {
      return (
        <div
          key={index}
          onClick={() => changeSchool(school.id)}
          style={{
            display: "flex",
            flexDirection: "column",
            cursor: schoolId === school.id ? null : "pointer",
            margin: "10px auto",
            height: 100,
            width: 100,
            backgroundColor: "white",
            borderRadius: 100,
          }}
        >
          <Card
            elevation={schoolId === school.id ? 5 : 0}
            style={{
              borderRadius: "50%",
              cursor: schoolId === school.id ? null : "pointer",
              //height:"100%",
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <SchoolAvatar
              school={school}
              key={index}
              selected={schoolId === school.id}
            />
          </Card>
        </div>
      );
    });

  return (
    <div
      className={classes.schoolSection}
      style={{ marginBottom: 20, padding: 15 }}
    >
      <div style={{ width: "100%" }}>
        <div style={{ marginBottom: 10 }}>
          <CutsomCarousel showDots={true}>{logos}</CutsomCarousel>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Badge
            name="Round"
            value={currentSchool.applicationRound}
            icon={<ApplicationRoundIcon style={{ marginRight: 8 }} />}
          />
          <Badge
            name="Scholarship"
            value={currentSchool.scholarship}
            icon={<ScholarshipIcon style={{ marginRight: 8 }} />}
          />
          <Badge
            name="Legacy"
            value={currentSchool.legacy}
            icon={<LegacyIcon style={{ marginRight: 8 }} />}
          />
        </div>
        {currentSchool.essays.length !== 0 && (
          <div
            style={{
              borderRadius: 15,
              backgroundColor: "white",
              marginTop: 15,
              padding: "25px 15px",
              textAlign: "left",
            }}
          >
            <EssaySection school={currentSchool} />
          </div>
        )}
      </div>
    </div>
  );
}

type SchoolAvatarProps = {
  school: School;
  selected: boolean;
};

function SchoolAvatar(props: SchoolAvatarProps) {
  return (
    <div>
      <LazyLoadImage
        src={props.school.logoUrl}
        height={60}
        effect="blur"
        visibleByDefault={true}
        style={{ margin: "auto", height: 60 }}
      />
    </div>
  );
}

type BadgeProps = {
  icon: any;
  name: string;
  value: string;
};

function Badge(props: BadgeProps) {
  return (
    <div
      style={{
        borderRadius: 15,
        height: 105,
        backgroundColor: "white",
        width: "calc(33% - 7.5px)",
        fontFamily: "RedHatDisplay-SemiBold",
        fontSize: 16,
      }}
    >
      <div
        style={{
          backgroundColor: "rgba(56, 185, 194, 0.15)",
          borderRadius: 12,
          margin: 5,
          display: "flex",
          height: 50,
          color: "#0E7F8A",
          overflowWrap: "break-word",
        }}
      >
        <div
          style={{
            margin: "auto",
            padding: "0px 5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 16,
          }}
        >
          {props.icon}
          <div style={{ marginRight: 5 }}>{props.value}</div>
        </div>
      </div>
      <div style={{ marginTop: 10, fontSize: 16 }}>{props.name}</div>
    </div>
  );
}

type EssaySectionProps = {
  school: School;
};

function EssaySection(props: EssaySectionProps) {
  const supplements = props.school.essays.map((essay, index) => {
    return (
      <SupplementSection
        essay={essay}
        key={index}
        showDivider={index !== props.school.essays.length - 1}
      />
    );
  });
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SupplementIcon style={{ marginRight: 10 }} />
        <div
          style={{
            fontSize: 16,
            fontFamily: "RedHatDisplay",
            fontWeight: "bold",
          }}
        >
          {props.school.shortSchoolName} Supplements
        </div>
      </div>
      {supplements}
    </div>
  );
}

type SupplementSectionProps = {
  essay: Essay;
  showDivider?: boolean;
};

function SupplementSection(props: SupplementSectionProps) {
  const profileClasses = useStyles();
  return (
    <div
      style={{
        fontSize: 14,
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
      }}
    >
      <div
        style={{
          color: "#0E7F8A",
          fontFamily: "RedHatDisplay-SemiBold",
          marginTop: 18,
        }}
      >
        {props.essay.title || props.essay.essayTopic}
      </div>
      <div style={{ fontFamily: "RedHatDisplay", marginTop: 15 }}>
        {props.essay.value}
      </div>
      {props.showDivider && (
        <div
          className={profileClasses.supplementLine}
          style={{ marginTop: 20, marginBottom: 20 }}
        ></div>
      )}
    </div>
  );
}

export default SchoolSection;
