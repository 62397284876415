import React from "react";

import Carousel from "react-multi-carousel";
import "./CustomCarouselStyles.css";

const responsive = {
  extralarge: {
    breakpoint: { max: 10000, min: 2000 },
    items: 5,
    slidesToSlide: 5, // optional, default to 1.
    //partialVisibilityGutter: 30
  },
  large: {
    breakpoint: { max: 2000, min: 1400 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
    //partialVisibilityGutter: 30
  },
  desktop: {
    breakpoint: { max: 1400, min: 1280 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
    //partialVisibilityGutter: 30
  },
  tabletUp: {
    breakpoint: { max: 1280, min: 1050 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
    //partialVisibilityGutter: 30
  },
  tablet: {
    breakpoint: { max: 1050, min: 960 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
    //partialVisibilityGutter: 30
  },
  middleUp: {
    breakpoint: { max: 960, min: 880 },
    items: 7,
    slidesToSlide: 7, // optional, default to 1.
    //partialVisibilityGutter: 30
  },
  middleUpStatic: {
    breakpoint: { max: 880, min: 780 },
    items: 6,
    slidesToSlide: 6, // optional, default to 1.
    //partialVisibilityGutter: 30
  },
  middle: {
    breakpoint: { max: 780, min: 650 },
    items: 5,
    slidesToSlide: 5, // optional, default to 1.
    //partialVisibilityGutter: 30
  },
  mobileUp: {
    breakpoint: { max: 650, min: 550 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
    // partialVisibilityGutter: 15,
  },
  mobile: {
    breakpoint: { max: 550, min: 350 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
    // partialVisibilityGutter: 15,
  },
  small: {
    breakpoint: { max: 350, min: 0 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
    //partialVisibilityGutter: 15,
  },
};

class CarouselWithCustomDots extends React.PureComponent {
  render() {
    const CustomDot = ({ onClick, active }) => {
      return (
        <button
          style={{ flex: "1 1 auto", height: 5, borderRadius: 15 }}
          className={active ? "active" : "inactive"}
          onClick={() => onClick()}
        />
      );
    };

    return (
      <Carousel
        responsive={responsive}
        arrows={false}
        renderButtonGroupOutside={true}
        renderDotsOutside={true}
        deviceType={this.props.deviceType}
        draggable={false}
        swipeable={this.props.swipeble || false}
        autoPlay={false}
        shouldResetAutoplay={false}
        dotListClass={"dot-list"}
        customDot={<CustomDot />}
        customTransition="transform 500ms ease-in-out"
        transitionDuration={500}
        showDots={this.props.showDots || false}
        partialVisible={this.props.partialVisible || false}
        minimumTouchDrag={30}
      >
        {this.props.children}
      </Carousel>
    );
  }
}

export default CarouselWithCustomDots;
