import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import SelecPagesPage, { Page } from "../pages/SelectPagesPage";
import UploadPdfPage from "../pages/UploadPdfPage";
import BlackOffPage from "../pages/BlackOffPage";
import UploadSuccessPage from "../pages/SaveSuccessPage";
import UploadingCasePage from "../pages/UploadingCasePage";
import { logEvent, logModalView } from "../../helpers/GoogleAnalyticsLogger";
import axios from "axios";
import Banner from "./Banner";
import * as Sentry from "@sentry/browser";
import ScrollToTop from "../../helpers/ScrollToTop";
import { GeneralApplicationInfo } from "../../types/uploadTypes";
import EnterGeneralInfoPage from "../pages/EnterGeneralApplicationPage";
import { UploadType } from "../pages/UploadPdfPage";
import SelectTypesPage from "../pages/SelectTypesPage";
import SubmissionListPage from "../pages/SubmissionListPage";
import { useHistory } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    labelRoot: {
      padding: "10px 0px",
    },
    container: {
      margin: "40px 20px",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    navigationButton: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    stepper: {
      position: "sticky",
      top: "65px",
      //borderTop: "1px solid #e5e5e5",
      borderBottom: "1px solid #e5e5e5",
      zIndex: 40,
      //paddingTop: "10px",
      //paddingBottom: "10px",
      paddingLeft: 0,
      paddingRight: 0,
    },
    arrow: {
      color: theme.palette.primary.main,
      fontSize: 32,
      "&:hover": {
        color: "#0A5961",
      },
    },
    stepLabel: {
      fontFamily: "SuperaGothic",
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 16,
      },
    },
    labelContainer: {
      "& $alternativeLabel": {
        marginTop: 4,
      },
    },
    alternativeLabel: {},
  })
);

function getStepContent(
  stepIndex: number,
  nodes: Array<() => React.ReactNode>
): React.ReactNode {
  if (stepIndex < nodes.length) {
    return nodes[stepIndex]();
  }
  return <div></div>;
}

interface Props {
  files: Array<FileWithPages>;
  title: string | null;
  caseInfo?: GeneralApplicationInfo;
  //generalInfo?: GeneralApplicationInfo,
  profileId?: string;
  mode?: string;
  //shouldNotDisplayGeneralInfoPage?: boolean,
}

export interface FileWithPages {
  file: File;
  pages: Array<Page>;
}

function getFinishPage(isLoading: boolean, mode?: string): React.ReactNode {
  if (isLoading) {
    return <UploadingCasePage />;
  }
  if (mode === "review") {
    return <SubmissionListPage />;
  }
  return <UploadSuccessPage />;
}

interface BannerStatus {
  subtitle: string;
  title: string;
}

function convertGeneralProfile(
  generalInfo: GeneralApplicationInfo,
  files: Array<FileWithPages>
) {
  let formData = new FormData();
  formData.append("school_id", generalInfo.schoolId);
  formData.append("year_id", generalInfo.yearId);
  formData.append("major_id", generalInfo.majorId);
  if (generalInfo.ethnicityId) {
    formData.append("ethnicity_id", generalInfo.ethnicityId);
  }
  if (generalInfo.usStateId) {
    formData.append("us_state_id", generalInfo.usStateId);
  }
  if (generalInfo.genderId) {
    formData.append("gender_id", generalInfo.genderId);
  }
  formData.append("country_id", generalInfo.countryId);
  formData.append("title", generalInfo.title);
  if (generalInfo.city) {
    formData.append("city", generalInfo.city);
  }
  formData.append("unweighted_gpa", generalInfo.unweightedGpa);
  if (generalInfo.weightedGpa) {
    formData.append("weighted_gpa", generalInfo.weightedGpa);
  }
  if (generalInfo.classRank) {
    formData.append("class_rank", String(generalInfo.classRank));
  }
  if (generalInfo.additionalInformation) {
    formData.append(
      "additional_information",
      generalInfo.additionalInformation
    );
  }
  for (let i = 0; i < generalInfo.essays.length; i++) {
    let essayTopicId = generalInfo.essays[i].essayTopicId;
    let title = generalInfo.essays[i].title;
    let value = generalInfo.essays[i].value;
    if (essayTopicId) {
      formData.append(`essays[${i}][essay_topic_id]`, essayTopicId);
    }
    if (title) {
      formData.append(`essays[${i}][title]`, title);
    }
    if (value) {
      formData.append(`essays[${i}][value]`, value);
    }
  }
  for (let i = 0; i < generalInfo.activities.length; i++) {
    let name = generalInfo.activities[i].name;
    let years_involved = generalInfo.activities[i].yearsInvolved;
    let description = generalInfo.activities[i].description;
    let position = generalInfo.activities[i].position;
    if (name) {
      formData.append(`activities[${i}][name]`, name);
    }
    if (years_involved) {
      formData.append(
        `activities[${i}][years_involved]`,
        String(years_involved)
      );
    }
    if (description) {
      formData.append(`activities[${i}][description]`, description);
    }
    if (position) {
      formData.append(`activities[${i}][position]`, position);
    }
  }
  for (let i = 0; i < generalInfo.satSubjectTests.length; i++) {
    let sat_subject_test_name_id =
      generalInfo.satSubjectTests[i].satSubjectTestNameId;
    let score = generalInfo.satSubjectTests[i].score;
    let year_id = generalInfo.satSubjectTests[i].yearId;
    if (sat_subject_test_name_id) {
      formData.append(
        `sat_subject_tests[${i}][sat_subject_test_name_id]`,
        sat_subject_test_name_id
      );
    }
    if (score) {
      formData.append(`sat_subject_tests[${i}][score]`, String(score));
    }
    if (year_id) {
      formData.append(`sat_subject_tests[${i}][year_id]`, year_id);
    }
  }
  for (let i = 0; i < generalInfo.honors.length; i++) {
    let honor_level_id = generalInfo.honors[i].honorLevelId;
    let description = generalInfo.honors[i].description;
    if (honor_level_id) {
      formData.append(`honors[${i}][honor_level_id]`, honor_level_id);
    }
    if (description) {
      formData.append(`honors[${i}][description]`, description);
    }
  }
  for (let i = 0; i < generalInfo.collegeLevelTests.length; i++) {
    let college_level_test_name_id =
      generalInfo.collegeLevelTests[i].collegeLevelTestNameId;
    let score = generalInfo.collegeLevelTests[i].collegeLevelTestScoreId;
    let year_id = generalInfo.collegeLevelTests[i].yearId;
    if (college_level_test_name_id) {
      formData.append(
        `college_level_tests[${i}][college_level_test_name_id]`,
        college_level_test_name_id
      );
    }
    if (score) {
      formData.append(
        `college_level_tests[${i}][college_level_test_score_id]`,
        score
      );
    }
    if (year_id) {
      formData.append(`college_level_tests[${i}][year_id]`, year_id);
    }
  }
  for (let i = 0; i < generalInfo.aLevelTests.length; i++) {
    let a_level_test_name_id = generalInfo.aLevelTests[i].aLevelTestNameId;
    let score = generalInfo.aLevelTests[i].aLevelTestScoreId;
    let year_id = generalInfo.aLevelTests[i].yearId;
    if (a_level_test_name_id) {
      formData.append(
        `a_level_tests[${i}][a_level_test_name_id]`,
        a_level_test_name_id
      );
    }
    if (score) {
      formData.append(`a_level_tests[${i}][a_level_test_score_id]`, score);
    }
    if (year_id) {
      formData.append(`a_level_tests[${i}][year_id]`, year_id);
    }
  }
  if (generalInfo.act?.math) {
    formData.append(`acts[math]`, String(generalInfo.act?.math));
  }
  if (generalInfo.act?.english) {
    formData.append(`acts[english]`, String(generalInfo.act?.english));
  }
  if (generalInfo.act?.reading) {
    formData.append(`acts[reading]`, String(generalInfo.act?.reading));
  }
  if (generalInfo.act?.science) {
    formData.append(`acts[science]`, String(generalInfo.act?.science));
  }
  if (generalInfo.sat?.math) {
    formData.append(`sats[math]`, String(generalInfo.sat?.math));
  }
  if (generalInfo.sat?.verbal) {
    formData.append(`sats[verbal]`, String(generalInfo.sat?.verbal));
  }
  if (generalInfo.sat?.writing) {
    formData.append(`sats[writing]`, String(generalInfo.sat?.writing));
  }
  for (let i = 0; i < files.length; i++) {
    formData.append(`files[${i}][file]`, files[i].file);
    for (let j = 0; j < files[i].pages.length; j++) {
      formData.append(
        `files[${i}][pages][${j}]`,
        JSON.stringify(files[i].pages[j])
      );
    }
  }
  return formData;
}

export default function HorizontalLabelPositionBelowStepper(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [bannerStatus, setBannerStatus] = useState<BannerStatus | undefined>(
    undefined
  );
  const [shouldProceedToNext, setShouldProceedToNext] =
    useState<boolean>(false);
  const [shouldProceedToPrevious, setShouldProceedToPrevious] =
    useState<boolean>(false);
  const handleNext = () => {
    logEvent({
      category: "Stepper",
      action: "Click",
      value: activeStep,
      label: "next_button",
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setBannerStatus(undefined);
  };

  const handleBack = () => {
    logEvent({
      category: "Stepper",
      action: "Click",
      value: activeStep,
      label: "back_button",
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setBannerStatus(undefined);
  };

  const [files, setFiles] = useState<Array<FileWithPages>>(props.files);

  const [caseInfo, setCaseInfo] = useState<GeneralApplicationInfo | undefined>(
    props.caseInfo
  );
  //const [generalApplicationInfo, setGeneralApplicationInfo] = useState<GeneralApplicationInfo | undefined> (props.generalInfo);
  const [loading, setLoading] = useState<boolean>(false);
  //const [uploadingGeneralApplication, setUploadingGeneralApplication] = useState<boolean>(false);
  //const [successfullyUploadedGeneralApplication, setSuccessfullyUploadedGeneralApplication] = useState<boolean>(false);
  //const [successfullyUploadedCase, setSuccessfulllyUploadedCase] = useState<boolean>(false);

  const onEnterInfo = (caseInfo: GeneralApplicationInfo) => {
    setCaseInfo(caseInfo);
    handleNext();
  };

  const onSaveRectsAndBack = (selectedFiles: Array<FileWithPages>) => {
    setFiles(selectedFiles);
    handleBack();
    //setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmitFiles = (uploadedFiles: Array<File>) => {
    var newFiles: Array<FileWithPages> = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      var existingFile = files.find((file) => file.file === uploadedFiles[i]);
      if (existingFile) {
        newFiles.push(existingFile);
      } else {
        newFiles.push({ file: uploadedFiles[i], pages: [] });
      }
    }
    setFiles(newFiles);
    handleNext();
  };

  const postDraftCaseToBackend = () => {
    logEvent({ category: "Button", action: "Click", label: "submit_button" });
    handleNext();
    //setProofFiles(proofFiles);
    //setTitle(title);
    setLoading(true);

    if (caseInfo) {
      let path = "general_applications";
      if (props.mode === "edit") {
        path = "update_general_application_profile";
      } else if (props.mode === "review") {
        path = `update_general_application_profile_as_admin/${props.profileId}`;
      }
      renderPages(files, props.mode === "review")
        .then((newFiles) => {
          axios(`api/v1/${path}`, {
            method: "POST",
            withCredentials: true,
            data: convertGeneralProfile(caseInfo, newFiles),
            timeout: 0,
          })
            .then((response) => {
              setLoading(false);
              //setSuccessfullyUploadedGeneralApplication(true);
              setBannerStatus(undefined);
            })
            .catch((error) => {
              //setSuccessfullyUploadedGeneralApplication(false);
              if (error.response && error.response.data) {
                error = error.response.data;
                const subtitle =
                  (error.error && error.error.message) ||
                  "We had technical difficulties to upload the case you submitted.";
                setBannerStatus({
                  subtitle: subtitle,
                  title: "Failed to upload your case",
                });
              } else {
                setBannerStatus({
                  subtitle:
                    "We had technical difficulties to upload the case you submitted.",
                  title: "Failed to upload your case",
                });
              }
              // TODO: add error logger
              Sentry.captureException(error);
              setActiveStep((prevActiveStep) => prevActiveStep - 1);
              setLoading(false);
            });
        })
        .catch((error) => {
          setBannerStatus({
            subtitle:
              "We had technical difficulties to upload the case you submitted.",
            title: "Failed to upload your case",
          });
          // TODO: add error logger
          Sentry.captureException(error);
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          setLoading(false);
        });
    }
  };

  const onSelectPages = (selectedFiles: Array<FileWithPages>) => {
    //const actualPages = selectedPages.filter(page => page.selected);
    setFiles(selectedFiles);
    handleNext();
  };

  const onSubmit = (selectedFiles: Array<FileWithPages>) => {
    setFiles(selectedFiles);
    postDraftCaseToBackend();
  };

  const enterInfoPage = () => {
    logModalView("enter_info_modal");
    return (
      <EnterGeneralInfoPage
        disableBack={true}
        handleNext={onEnterInfo}
        nextButtonText={"Next"}
        caseInfo={caseInfo}
        shouldProceedToNext={shouldProceedToNext}
        setShouldProceedToNext={setShouldProceedToNext}
      />
    );
  };

  const uploadPdfPage = () => {
    logModalView("upload_pdf_modal");
    return (
      <UploadPdfPage
        uploadType={UploadType.GENERAL_APPLICATION_PROFILE}
        disableBack={false}
        handleBack={handleBack}
        handleNext={onSubmitFiles}
        nextButtonText={"Next"}
        files={files.map((file) => {
          return file.file;
        })}
        shouldProceedToNext={shouldProceedToNext}
        setShouldProceedToNext={setShouldProceedToNext}
        shouldProceedToPrevious={shouldProceedToPrevious}
        setShouldProceedToPrevious={setShouldProceedToPrevious}
      />
    );
  };

  const pageSelector = () => {
    logModalView("select_page_modal");
    return (
      <SelecPagesPage
        disableBack={false}
        handleBack={handleBack}
        handleNext={onSelectPages}
        nextButtonText={"Next"}
        files={files}
        shouldProceedToNext={shouldProceedToNext}
        setShouldProceedToNext={setShouldProceedToNext}
        shouldProceedToPrevious={shouldProceedToPrevious}
        setShouldProceedToPrevious={setShouldProceedToPrevious}
      />
    );
  };

  const blackOffPage = () => {
    logModalView("black_off_modal");
    return (
      files.length !== 0 && (
        <BlackOffPage
          disableBack={false}
          handleBack={onSaveRectsAndBack}
          handleNext={props.mode === "review" ? onSelectPages : onSubmit}
          nextButtonText={props.mode === "review" ? "Next" : "Finish"}
          files={files}
          shouldProceedToNext={
            props.mode === "review" ? shouldProceedToNext : undefined
          }
          setShouldProceedToNext={
            props.mode === "review" ? setShouldProceedToNext : undefined
          }
          shouldProceedToPrevious={shouldProceedToPrevious}
          setShouldProceedToPrevious={setShouldProceedToPrevious}
        />
      )
    );
  };

  const selectTypesPage = () => (
    <SelectTypesPage
      disableBack={false}
      handleBack={handleBack}
      handleNext={onSubmit}
      nextButtonText={"Finish"}
      files={files}
      shouldProceedToPrevious={shouldProceedToPrevious}
      setShouldProceedToPrevious={setShouldProceedToPrevious}
    />
  );

  const steps = ["Start", "Upload", "Select", "Blackoff"];
  const nodes: Array<() => React.ReactNode> = [
    enterInfoPage,
    uploadPdfPage,
    pageSelector,
    blackOffPage,
  ];

  if (props.mode === "review") {
    steps.push("Finish");
    nodes.push(selectTypesPage);
  }

  if (props.mode === "review" && activeStep === steps.length && !loading) {
    history.push({ pathname: "/submissions" });
    //history.push({pathname:"/upload_status", search: "?showBanner=true"})
    return null;
  }

  return (
    <div className={`${classes.root} page-container`}>
      <div
        className={classes.stepper}
        style={{
          display: "flex",
          backgroundColor: "white",
          overflow: "scroll",
        }}
      >
        <Button
          className={classes.navigationButton}
          style={{
            flexGrow: 0,
            width: 40,
            minWidth: 40,
            margin: "auto 0px auto 20px",
          }}
          disabled={activeStep === 0}
          onClick={() => setShouldProceedToPrevious(true)}
        >
          {activeStep !== 0 && (
            <ChevronLeftIcon
              className={classes.arrow}
              style={{ margin: "auto" }}
            />
          )}
        </Button>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          classes={{ root: classes.labelRoot }}
          style={{ flexGrow: 1 }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                classes={{
                  alternativeLabel: classes.alternativeLabel,
                  labelContainer: classes.labelContainer,
                }}
              >
                <Typography className={classes.stepLabel} variant="body1">
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Button
          className={classes.navigationButton}
          style={{
            flexGrow: 0,
            width: 40,
            minWidth: 40,
            margin: "auto 20px auto 0px",
          }}
          disabled={activeStep >= steps.length - 1}
          onClick={() => setShouldProceedToNext(true)}
        >
          {activeStep < steps.length - 1 && (
            <ChevronRightIcon
              className={classes.arrow}
              style={{ margin: "auto" }}
            />
          )}
        </Button>
      </div>
      <div
        className="page-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {activeStep === steps.length ? (
          <div
            className="page-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <ScrollToTop />
            {getFinishPage(loading, props.mode)}
          </div>
        ) : (
          <div
            className="page-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div>
              <ScrollToTop />
              {bannerStatus && (
                <div className={classes.container}>
                  <Banner
                    title={bannerStatus.title}
                    subtitle={bannerStatus.subtitle}
                    severity={"failed"}
                  />
                </div>
              )}
            </div>
            {getStepContent(activeStep, nodes)}
          </div>
        )}
      </div>
    </div>
  );
}

function renderPages(
  files: Array<FileWithPages>,
  shouldRenderImages: Boolean
): Promise<Array<FileWithPages>> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const promises: Array<Array<Promise<Page>>> = [];
      for (let file of files) {
        promises.push(loadRenderableImage(file, shouldRenderImages));
      }
      let newFiles: Array<FileWithPages> = Array.from(files);

      promises.forEach((promise, index) => {
        Promise.all(promise)
          .then((newPages) => {
            newFiles[index].pages = newPages;
            if (index === files.length - 1) {
              resolve(newFiles);
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
      /*Promise.all(promises.map(promise => Promise.all(promise))).then(newPages => {
          newPages.forEach((value, index) => {
              files[index].pages = value
              //newFiles.push({file: files[index].file, pages: value})
          })
          //setFiles(newFiles)
          resolve(newFiles)
      })*/
    }, 500);
  });
}

function loadRenderableImage(
  file: FileWithPages,
  shouldRenderImages: Boolean
): Array<Promise<Page>> {
  const promises: Array<Promise<Page>> = [];
  for (let page of file.pages) {
    if (shouldRenderImages) {
      promises.push(renderPageWithRects(page));
    } else {
      promises.push(renderEmptyPageWithRects(page));
    }
  }
  return promises;
}

function renderEmptyPageWithRects(page: Page): Promise<Page> {
  return new Promise((resolve, reject) => {
    //setTimeout(() => {
    if (page.purePage) {
      resolve({
        selected: page.selected,
        rects: page.rects,
        types: page.types,
        purePage: undefined,
        renderablePage: undefined,
      });
    }
    //},300)
  });
}

function renderPageWithRects(page: Page): Promise<Page> {
  return new Promise((resolve, reject) => {
    //setTimeout(() => {
    if (page.purePage) {
      var image = new Image();
      image.onload = (event) => {
        const canvas = document.createElement("canvas");
        const canvasContext =
          canvas.getContext("2d") || new CanvasRenderingContext2D();
        canvas.height = image.height;
        canvas.width = image.width;
        canvas.style.height = "100%";
        canvas.style.width = "100%";
        // first paint the entire background to white
        canvasContext.fillStyle = "#FFF";
        canvasContext.fillRect(0, 0, canvas.width, canvas.height);
        canvasContext.drawImage(image, 0, 0);

        canvasContext.fillStyle = "black";
        for (let rect of page.rects) {
          canvasContext.fillRect(rect.x, rect.y, rect.width, rect.height);
        }
        resolve({
          selected: page.selected,
          rects: page.rects,
          types: page.types,
          purePage: page.purePage,
          renderablePage: canvas.toDataURL("image/jpeg"),
        });
      };
      image.onerror = (event) => {
        reject("Failed to drat the renderable image.");
      };
      image.src = page.purePage;
    } else {
      reject("The page doesn't have purePage.");
    }
    //},300)
  });
}
