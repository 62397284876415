import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "flex-start",
      overflow: "hidden",
    },
    paperEdge: {
      width: "10px",
      backgroundColor: theme.palette.primary.main,
      //marginRight: '20px',
    },
    icon: {
      fontSize: theme.typography.h6.fontSize,
    },
    container: {
      margin: "20px",
    },
    title: {
      //color: theme.palette.primary.main,
      //fontWeight: 'bold',
      textAlign: "left",
      fontFamily: "SuperaGothic",
    },
    titleFlex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",

      margin: "10px 0px",
      //borderBottom: '1px solid #e5e5e5',
      //maxWidth: '500px',
    },
  })
);

interface TimelineItemNode {
  title: string;
  icon: JSX.Element;
  primary?: boolean;
}

interface Props {
  timelineItems: Array<TimelineItemNode>;
}

function VerticalTimeline(props: Props) {
  const classes = useStyles();
  const items = props.timelineItems.map((item, index) => {
    return (
      <div className={classes.titleFlex} key={index}>
        <div style={{ marginRight: "15px" }}>{item.icon}</div>
        <Typography variant="body1" className={classes.title}>
          {item.title}
        </Typography>
      </div>
    );
  });
  return <div>{items}</div>;
}

export default VerticalTimeline;
