import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SearchBar from "./SearchBar";
import CheckBoxList from "./SideSearchCheckBoxList";
import { CheckBoxItem } from "../../types/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleButton: {},
    popover: {},
    filterButtonGroup: {
      position: "sticky",
      width: "100%",
      left: "0",
      bottom: "0",
    },
    section: {
      //padding: "10px 30px",
      paddingTop: 0,
    },
    sticky: {
      [theme.breakpoints.down("md")]: {
        position: "sticky",
        top: 25,
      },
      [theme.breakpoints.up("lg")]: {
        position: "relative",
      },
    },
  })
);

interface Props {
  title: string;
  list: Array<CheckBoxItem>;
  selected: Array<string>;
  updateSearchFilter: (newList: Array<string>) => void;
  hideSearchBar?: boolean;
}

const placeholderTextMap: { [name: string]: string } = {
  Schools: "Stanford University",
  Majors: "Computer Science",
  Categories: "Engineering",
  Years: "Class of 2019",
  Rankings: "Top 10",
};

function FilterPopoverContent(props: Props) {
  const classes = useStyles();
  const [name, setName] = React.useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  //const [state, dispatch] = useReducer(updateCheckBoxItemReducer, props.list);
  //const [list, setList] = useState<Array<CheckBoxItem>>(props.list);

  const handleCheckBoxItemChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updated: Array<string> = [...props.selected];
    if (!props.selected.includes(event.target.id)) {
      updated.push(event.target.id);
    } else {
      const index = updated.indexOf(event.target.id, 0);
      updated.splice(index, 1);
    }
    props.updateSearchFilter(updated);
  };

  const paperStyle = {
    height: "100%",
    width: "100%",
  };

  return (
    <div style={paperStyle}>
      <div className={classes.section} style={{}}>
        <div
          style={{
            height: 25,
            position: "sticky",
            top: 0,
            zIndex: 200,
            width: "100%",
            backgroundColor: "white",
          }}
        ></div>
        <div className={classes.sticky} style={{ zIndex: 200 }}>
          {!props.hideSearchBar && (
            <div style={{ zIndex: 200 }}>
              <SearchBar
                onChange={handleChange}
                placeholderText={placeholderTextMap[props.title] || ""}
              />
              <div
                style={{
                  height: 20,
                  zIndex: 200,
                  width: "100%",
                  background:
                    "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
                }}
              ></div>
            </div>
          )}
        </div>
        <div>
          <CheckBoxList
            title={props.title}
            input={name}
            list={props.list.map((item) => {
              return {
                filterItem: item.filterItem,
                selected: props.selected.includes(item.filterItem.id),
              };
            })}
            toggleCheckBoxItem={handleCheckBoxItemChange}
          />
        </div>
      </div>
    </div>
  );
}

/*
function mapStateToProps(state: StoreState, ownProps: ContainerProps) {
    const {searchPageFilter} = state;
    const list = searchPageFilter?.categoryStatuses.find(categoryStatus => 
        categoryStatus.title === ownProps.title)?.listStatus || [];
    return {list, ...ownProps};

}*/

//export default connect(mapStateToProps)(FilterPopoverContent);
export default FilterPopoverContent;
