import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Spacing from "../../general/Spacing";
import { Paper } from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router";
import Alert from "./PanelAlert";
import isValidPassword, {
  passwordAlertMessage,
  isValidEmail,
  emailAlertMessage,
  isValidUserName,
  userNameAlertMessage,
} from "../../../helpers/AccountInfoChecker";
import ColoredTextField from "./ColoredTextField";
import SubmitButton from "./SubmitButton";
import CardHeader from "./CardHeader";
import LoadingContent from "./LoadingContent";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/browser";
import { useStore } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
//import Cookies from 'js-cookie';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: "0px 15px",
    },
    paper: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    card: {
      alignItems: "center",
      minWidth: "320px",
      maxWidth: "450px",
      width: "80%",
      overflow: "hidden",
      margin: "0px auto",
    },
    header: {
      width: "100%",
      height: "120px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerText: {
      fontFamily: "Righteous",
      fontSize: "32px",
      color: "white",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
      fontSize: "18px",
    },
    root: {
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      paddingBottom: "80px",
      paddingTop: "80px",
    },
    labelRoot: {
      fontFamily: "SuperaGothic",
    },
    inputRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleText: {
      fontSize: "16px",
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      width: "120px",
      textAlign: "left",
    },
    textField: {
      width: "90%",
      maxWidth: "280px",
    },
    textFieldContainer: {
      width: "90%",
      margin: "auto",
    },
    rowWrapper: {
      marginBottom: "12px",
    },
    cardContent: {
      margin: "30px",
    },
    formLabelControl: {
      fontSize: 12,
      fontFamily: "SuperaGothic",
    },
    formLabelControlRoot: {
      display: "flex",
      alignItems: "flex-start",
    },
  })
);

interface LocationState {
  from?: Location;
}

function SignUpPage() {
  const classes = useStyles();
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );
  const [passwordConfirmation, setPasswordConfirmation] = useState<
    string | undefined
  >(undefined);
  const [checked, setChecked] = useState<boolean>(false);
  const [, setIsLoggedIn] = useState<boolean | undefined>(undefined);

  const store = useStore();

  const history = useHistory<LocationState>();

  useEffect(() => {
    axios("api/v1/auto_login", {
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        data = data.data;
        if (data.user) {
          setIsLoggedIn(true);
          store.dispatch({ type: "UPDATE_USER_ACCTION", user: data.user });
          history.push({ pathname: "/search" });
        } else {
          setIsLoggedIn(false);
          store.dispatch({ type: "UPDATE_USER_ACCTION", user: null });
        }
      })
      .catch((error) => {
        setIsLoggedIn(false);
        store.dispatch({ type: "UPDATE_USER_ACCTION", user: null });
      });
  }, [history, store]);

  /*if (isLoggedIn === undefined) {
        return null;
    }*/

  const handleUserAgreementChecked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChecked(event.target.checked);
  };

  const onRedirectWithSearchParams = () => {
    const from = history.location.state;
    history.push({
      pathname: "/log_in",
      state: from,
      search: "?showBanner=true",
    });
  };

  const onRedirect = () => {
    const from = history.location.state;
    history.push({ pathname: "/log_in", state: from });
  };

  const onSignUp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!checked) {
      setAlertMessage("You must agree to the terms of use and privacy policy");
      return;
    }
    if (!isValidEmail(email)) {
      setAlertMessage(emailAlertMessage);
      return;
    }
    if (!isValidPassword(password)) {
      setAlertMessage(passwordAlertMessage);
      return;
    }
    if (!isValidUserName(userName)) {
      setAlertMessage(userNameAlertMessage);
      return;
    }
    setIsLoading(true);
    axios("api/v1/users", {
      method: "POST",
      withCredentials: true,
      data: {
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
        user_name: userName,
      },
    })
      .then((response) => {
        onRedirectWithSearchParams();
        /*const locationState = history.location.state;
            if (locationState && locationState.from) {
                history.push(locationState.from);
            } else {
                history.push({pathname: "/search", state: {}});
            }*/
        //Cookies.set("token", data.data.token, { secure: true, sameSite: 'strict'})
      })
      .catch((error) => {
        // TODO: log error
        Sentry.captureException(error);
        setIsLoading(false);
        if (error.response && error.response.data) {
          error = error.response.data;
          const message = error.error || "Failed to sign up. Please try again.";
          setAlertMessage(message);
        } else {
          setAlertMessage("Failed to sign up. Please try again.");
        }
      });
  };

  const onSubmitEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onSubmitPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onSubmitPasswordConfirmation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirmation(event.target.value);
  };

  const onSubmitUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  var cardContent = null;
  if (!isLoading) {
    cardContent = (
      <SignUpCardContent
        setEmail={onSubmitEmail}
        setPassword={onSubmitPassword}
        setPasswordConfirmation={onSubmitPasswordConfirmation}
        setUserName={onSubmitUserName}
        onSignUp={onSignUp}
        onRedirect={onRedirect}
        userAgreementChecked={checked}
        handleUserAgreementChange={handleUserAgreementChecked}
        alertMessage={alertMessage}
      />
    );
  } else {
    cardContent = <LoadingContent title="Sign Up" />;
  }

  return (
    <div className={`page-container ${classes.root}`}>
      <Helmet>
        <title>Sign up - CollegeSharing</title>
      </Helmet>
      <Paper className={classes.card} elevation={8}>
        {cardContent}
      </Paper>
    </div>
  );
}

function SignUpCardContent({
  setEmail,
  setPassword,
  setPasswordConfirmation,
  setUserName,
  onSignUp,
  onRedirect,
  alertMessage,
  userAgreementChecked,
  handleUserAgreementChange,
}: {
  setEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setPasswordConfirmation: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setUserName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSignUp: (event: React.FormEvent<HTMLFormElement>) => void;
  onRedirect: () => void;
  userAgreementChecked: boolean;
  handleUserAgreementChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  alertMessage?: string;
}) {
  const classes = useStyles();
  return (
    <div>
      <CardHeader title="Sign Up" />
      <div className="card-wrapper">
        {alertMessage && (
          <div>
            <Alert severity="error">{alertMessage}</Alert>
            <Spacing spacing={24} />
          </div>
        )}
        <form onSubmit={onSignUp}>
          <div className="row-container">
            <div className={classes.rowWrapper}>
              <ColoredTextField
                title="Email"
                type="text"
                onChange={setEmail}
                placeholder="anna@gmail.com"
              />
            </div>
            <div className={classes.rowWrapper}>
              <ColoredTextField
                title="Password"
                type="password"
                onChange={setPassword}
                placeholder="password"
              />
            </div>
            <div className={classes.rowWrapper}>
              <ColoredTextField
                title="Confirm Password"
                type="password"
                onChange={setPasswordConfirmation}
                placeholder="password"
              />
            </div>
            <div className={classes.rowWrapper}>
              <ColoredTextField
                title="Display Name"
                type="text"
                text="6-20 characters including only letters, underscores or numbers"
                onChange={setUserName}
                placeholder="David_Smith"
              />
            </div>
            <div style={{ paddingTop: 8, paddingBottom: 8 }}>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userAgreementChecked}
                        style={{ paddingTop: 0 }}
                        onChange={handleUserAgreementChange}
                        name="gilad"
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        style={{ textAlign: "left", fontSize: 16 }}
                      >
                        I agree to the{" "}
                        <Link
                          to="/privacy_policy"
                          target="_blank"
                          style={{ color: "#0E7F8A" }}
                        >
                          privacy policy
                        </Link>{" "}
                        and{" "}
                        <Link
                          to="/terms_of_use"
                          target="_blank"
                          style={{ color: "#0E7F8A" }}
                        >
                          terms of use
                        </Link>
                      </Typography>
                    }
                    classes={{
                      label: classes.formLabelControl,
                      root: classes.formLabelControlRoot,
                    }}
                  />
                </FormGroup>
              </FormControl>
            </div>
          </div>
          <SubmitButton
            type="submit"
            disabled={!userAgreementChecked}
            text="SIGN UP"
            filled={true}
          />
        </form>
        <Spacing spacing={8} />
        <SubmitButton text="LOG IN" onSubmit={onRedirect} filled={false} />
        <Spacing spacing={24} />
      </div>
    </div>
  );
}

export default SignUpPage;
