import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "60px",
      marginBottom: "30px",
      textAlign: "left",
      //width: '70%',
      maxWidth: "1000px",
      minWidth: "300px",
    },
    content: {
      marginTop: "15px",
    },
    title: {
      fontSize: "SuperaGothic-ExtraBold",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
  })
);

interface Props {
  children?: React.ReactNode;
  title: string;
}

function ProfileSection(props: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        {props.title}
      </Typography>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
}

export default ProfileSection;
