import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import RoundedButton from "./PrimaryButton";
import Typography from "@material-ui/core/Typography";
import { SimpleDraftCase } from "../../types/serverSideTypes";

interface Props {
  cases: Array<SimpleDraftCase>;
  mode: string;
  buttonText: string;
  showHeader?: boolean;
  onlyShowActionForActiveCase?: boolean;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    body: {
      fontSize: 16,
      fontFamily: "SuperaGothic",
      fontWeight: "normal",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(2n+1)": {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.action.hover,
      },
      "& > *": {
        borderBottom: "unset",
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    //minWidth: 500,
  },
});

interface RowProps {
  case: SimpleDraftCase;
  mode: string;
  onlyShowActionForActiveCase?: boolean;
  buttonText: string;
}

function Row(props: RowProps) {
  const shouldShowButton =
    !props.onlyShowActionForActiveCase ||
    props.case.dbStatus === "active" ||
    props.case.dbStatus === "pending_confirmation";
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell component="th" scope="row">
          {props.case.schoolName}
        </StyledTableCell>
        <StyledTableCell align="right">{props.case.status}</StyledTableCell>
        <StyledTableCell align="right">
          {shouldShowButton && (
            <RoundedButton
              variant="outlined"
              style={{ borderRadius: "30px", color: "white", border: "none" }}
              buttonStyle="secondary"
              link={`/${props.mode}/${props.case.id}`}
            >
              <Typography
                variant="body2"
                style={{
                  fontSize: "16px",
                  fontFamily: "SuperaGothic",
                  lineHeight: "normal",
                }}
              >
                {props.buttonText}
              </Typography>
            </RoundedButton>
          )}
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export interface CaseIdentification {
  caseId: number;
  status: string;
}

export default function CustomizedTables(props: Props) {
  const classes = useStyles();

  //const [loadedCaseId, setLoadedCaseId] = useState<CaseIdentification | undefined>(undefined);
  //const [isLoadingCaseId, setIsLoadingCaseId] = useState<CaseIdentification | undefined>(undefined);

  /*const caseLoaded = (caseId: number, status: string) => {
      setLoadedCaseId({caseId: caseId, status: status});
      setIsLoadingCaseId(undefined);
  }

  const loadCase = (caseId: number, status: string) => {
      setIsLoadingCaseId({caseId: caseId, status: status});
      props.loadCaseById(caseId, status, caseLoaded);
  }*/

  if (props.cases.length === 0) {
    return null;
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        {props.showHeader && (
          <TableHead>
            <TableRow>
              <StyledTableCell>School</StyledTableCell>
              <StyledTableCell align="right">Status</StyledTableCell>
              <StyledTableCell align="right" />
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {props.cases.map((previewCase, index) => (
            <Row
              case={previewCase}
              mode={props.mode}
              buttonText={props.buttonText}
              onlyShowActionForActiveCase={props.onlyShowActionForActiveCase}
              key={index}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
