import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStore } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import theme from "../../themes/Theme";
import MenuIcon from "@material-ui/icons/Menu";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import ImageSearchOutlinedIcon from "@material-ui/icons/ImageSearchOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import PaymentIcon from "@material-ui/icons/Payment";
import { useHistory } from "react-router";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { User } from "../../types/types";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    //width: 150,
  },
  fullList: {
    width: "auto",
  },
  menuIcon: {
    color: theme.palette.primary.main,
  },
  listItemText: {
    fontFamily: "SuperaGothic",
    marginRight: "30px",
    //marginLeft: '20px',
    fontSize: "16px",
  },
  backdrop: {
    zIndex: 1500,
    color: "#fff",
  },
});

interface MenuDrawerProps {
  setOpenBackdrop: (open: boolean) => void;
}

function MenuDrawer(props: MenuDrawerProps) {
  const classes = useStyles();
  const store = useStore();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(store.getState().user);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setIsOpen(open);
    };

  store.subscribe(() => {
    setUser(store.getState().user);
  });

  /*useEffect(() => {
        fetch('http://localhost:3001/api/v1/auto_login', {
          method: 'GET',
          credentials: 'include',
        })
        .then(res => res.json())
        .then(data => {
          data = data.data
          if (data.user) {
            setIsLoggedIn(true);
            store.dispatch({type: "UPDATE_IS_LOGGINED_IN_ACCTION", status: true})
          } else {
            setIsLoggedIn(false);
            store.dispatch({type: "UPDATE_IS_LOGGINED_IN_ACCTION", status: false})
          }
        })
        .catch(error => {
          setIsLoggedIn(false)
          store.dispatch({type: "UPDATE_IS_LOGGINED_IN_ACCTION", status: false})
        })
      },[loggedIn])*/

  const logout = () => {
    props.setOpenBackdrop(true);
    axios("api/v1/logout", {
      method: "POST",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        props.setOpenBackdrop(false);
        store.dispatch({ type: "UPDATE_USER_ACCTION", user: null });
        history.push({ pathname: "/" });
      })
      .catch((error) => {
        // TODO: add error logger
        props.setOpenBackdrop(false);
        store.dispatch({ type: "UPDATE_USER_ACCTION", user: null });
        history.push({ pathname: "/" });
        Sentry.captureException(error);
      });

    /*fetch('http://localhost:3001/api/v1/logout', {
            method: 'POST',
            credentials: 'include',
        })
        .then(res => res.json())
        .then(data => {
            props.setOpenBackdrop(false);
            store.dispatch({type: "UPDATE_IS_LOGGINED_IN_ACCTION", status: false})
            store.dispatch({type: "UPDATE_USER_ACCTION", user: null});
            history.push({pathname: "/"})
        })
        .catch(error => {
            props.setOpenBackdrop(false);
          })*/
  };

  var sideBarList = [...menuList];
  if (!user) {
    sideBarList.push(logInOption);
    sideBarList.push(signUpOption);
  } else {
    sideBarList.push(accountOption);
    sideBarList.push(savedOption);
    if (!user.subscribed) {
      sideBarList.push(subscribeOption);
    }
  }
  const menuContent = sideBarList.map((item, index) => {
    return (
      <Link to={item.link} key={index}>
        <ListItem button>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText
            primary={item.title}
            classes={{ primary: classes.listItemText }}
          />
        </ListItem>
      </Link>
    );
  });

  if (user) {
    const logOutOption = (
      <ListItem button onClick={logout} key={sideBarList.length} component="a">
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText
          primary="Log Out"
          classes={{ primary: classes.listItemText }}
        />
      </ListItem>
    );
    menuContent.push(logOutOption);
  }
  const list = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className={classes.list}
    >
      <List>{menuContent}</List>
    </div>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)} className={classes.menuIcon}>
        <MenuIcon />
      </Button>
      <Drawer open={isOpen} anchor="right" onClose={toggleDrawer(false)}>
        {list}
      </Drawer>
    </div>
  );
}

const menuList = [
  {
    title: "Home",
    link: "/",
    icon: <HomeOutlinedIcon />,
  },
  {
    title: "Browse",
    link: "/search",
    icon: <ImageSearchOutlinedIcon />,
  },
  {
    title: "Upload",
    link: "/host_landing",
    icon: <BackupOutlinedIcon />,
  },
  {
    title: "FAQ",
    link: "/faq",
    icon: <HelpOutlineIcon />,
  },
];

const logInOption = {
  title: "Log In",
  link: "/log_in",
  icon: <ExitToAppIcon />,
};

const signUpOption = {
  title: "Sign Up",
  link: "/sign_up",
  icon: <AccountCircleOutlinedIcon />,
};

const accountOption = {
  title: "Account",
  link: "/account",
  icon: <AccountCircleOutlinedIcon />,
};

const savedOption = {
  title: "Saved",
  link: "/saved_cases",
  icon: <FavoriteBorderIcon />,
};

const subscribeOption = {
  title: "Subscribe",
  link: "/payment_page",
  icon: <PaymentIcon />,
};

export default MenuDrawer;
