import React, { useState, useEffect } from "react";
const {
  ShapeEditor,
  ImageLayer,
  DrawLayer,
  wrapShape,
} = require("react-shape-editor");

interface Shape {
  width: number;
  height: number;
}

interface Rect {
  width: number;
  height: number;
  x: number;
  y: number;
}

export interface RectWithId {
  id: string;
  width: number;
  height: number;
  x: number;
  y: number;
}

interface NaturalShape {
  naturalWidth: number;
  naturalHeight: number;
}

function arrayReplace(
  arr: Array<RectWithId>,
  index: number,
  item: RectWithId | Array<RectWithId>
) {
  return [
    ...arr.slice(0, index),
    ...(Array.isArray(item) ? item : [item]),
    ...arr.slice(index + 1),
  ];
}

const RectShape = wrapShape(({ width, height }: Shape) => (
  <rect width={width} height={height} fill="rgba(0,0,0,1)" />
));

let idIterator = 1;

interface Props {
  dataUrl: string;
  initialItems: Array<RectWithId>;
  onUpdate: (items: Array<RectWithId>) => void;
}

function Editor(props: Props) {
  const [items, setItems] = useState<Array<RectWithId>>(props.initialItems);

  const [scale, setScale] = useState<number>(1);

  const [{ vectorHeight, vectorWidth }, setVectorDimensions] = useState({
    vectorHeight: 0,
    vectorWidth: 0,
  });

  useEffect(() => {
    props.onUpdate(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <div>
      <ShapeEditor
        vectorWidth={vectorWidth}
        vectorHeight={vectorHeight}
        scale={scale}
      >
        <ImageLayer
          // Photo by Sarah Gualtieri on Unsplash
          src={props.dataUrl}
          onLoad={({ naturalWidth, naturalHeight }: NaturalShape) => {
            let size = 0.8 * window.innerWidth;
            if (size > 1000) {
              size = 1000;
            }
            setScale(size / naturalWidth);
            setVectorDimensions({
              vectorWidth: naturalWidth,
              vectorHeight: naturalHeight,
            });
          }}
        />
        <DrawLayer
          onAddShape={({ x, y, width, height }: Rect) => {
            idIterator = items.length + 1;
            setItems((currentItems) => [
              ...currentItems,
              { id: `id${idIterator}`, x, y, width, height },
            ]);
          }}
        />
        {items.map((item, index) => {
          const { id, height, width, x, y } = item;
          return (
            <RectShape
              key={id}
              shapeId={id}
              height={height}
              width={width}
              x={x}
              y={y}
              onChange={(newRect: RectWithId) => {
                setItems((currentItems) =>
                  arrayReplace(currentItems, index, {
                    ...item,
                    ...newRect,
                  })
                );
              }}
              onDelete={() => {
                setItems((currentItems) =>
                  arrayReplace(currentItems, index, [])
                );
              }}
            />
          );
        })}
      </ShapeEditor>
    </div>
  );
}

export default Editor;
