import React from "react";
import { Route, RouteProps } from "react-router";
import ErrorBoundary from "./ErrorBoundary";

const PrivateRoute: React.SFC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  if (!Component) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      )}
    />
  );
};

export default PrivateRoute;
