import React, { useState, useEffect } from "react";
import ImageEditor, { RectWithId } from "../upload/ImageEditor";
import { Page } from "./SelectPagesPage";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import PageTitle from "../general/PageTitle";
import { FileWithPages } from "../general/Stepper";
import { Paper } from "@material-ui/core";
import * as Sentry from "@sentry/browser";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light,
      //paddingBottom: '5%',
    },
    floatingButtons: {
      //position: 'fixed',
      //bottom: '0px',

      padding: "20px 0px",
      zIndex: 30,
      boxShadow: "0px -5px 20px 3px #888",
      position: "relative",
      //paddingBottom: '5%',
    },
    paragraph: {
      margin: "auto auto",
      //width: "90%",
      //minWidth: "320px",
      textAlign: "left",
    },
    questionBlock: {
      textAlign: "left",
      [theme.breakpoints.up("md")]: {
        padding: "20px 40px",
        paddingTop: "0px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px 30px",
        paddingTop: "0px",
      },
    },
    floatingHeader: {
      boxShadow: "0px 5px 20px 3px #888",
      zIndex: 200,
      position: "relative",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    imageEditorContainer: {
      //overflow: "scroll",
      backgroundColor: "#c4c4c4",
      overflow: "hidden",
    },
    note: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
  })
);

interface Props {
  files: Array<FileWithPages>;
  disableBack: boolean;
  handleBack: (selectedFiles: Array<FileWithPages>) => void;
  handleNext: (selectedFiles: Array<FileWithPages>) => void;
  nextButtonText: string;
  shouldProceedToNext?: boolean;
  setShouldProceedToNext?: (value: boolean) => void;
  shouldProceedToPrevious?: boolean;
  setShouldProceedToPrevious?: (value: boolean) => void;
}

function BlackOffPage(props: Props) {
  const classes = useStyles();
  //const [pages, setPages] = useState<Array<Page>>(props.pages);
  const [files, setFiles] = useState<Array<FileWithPages>>(props.files);
  const [imageEditor, setImageEditor] = useState<Array<JSX.Element | null>>([]);
  const [shouldDisableStepChange, setShouldDisableStepChange] =
    useState<boolean>(true);

  var concatedPages: Array<Page> = [];
  props.files.forEach((file) => {
    concatedPages = concatedPages.concat(
      file.pages.filter((page) => page.purePage)
    );
  });

  const onUpdate = (index: number, newRects: Array<RectWithId>) => {
    let newFiles = Array.from(files);
    var count = 0;
    for (let i = 0; i < newFiles.length; i++) {
      if (count + newFiles[i].pages.length > index) {
        newFiles[i].pages[index - count].rects = newRects;
        break;
      } else {
        count += newFiles[i].pages.length;
      }
    }
    setFiles(newFiles);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setShouldDisableStepChange(true);
    loadImageEditor(concatedPages, onUpdate)
      .then((response) => {
        setShouldDisableStepChange(false);
        setImageEditor(response);
      })
      .catch((error) => {
        setShouldDisableStepChange(false);
        Sentry.captureException(error, {
          extra: {
            //'concated_pages': concatedPages
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.files]);

  useEffect(() => {
    if (props.shouldProceedToNext === true && props.setShouldProceedToNext) {
      props.setShouldProceedToNext(false);
      if (shouldDisableStepChange) {
        return;
      }
      props.handleNext(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToNext]);

  useEffect(() => {
    if (
      props.shouldProceedToPrevious === true &&
      props.setShouldProceedToPrevious
    ) {
      props.setShouldProceedToPrevious(false);
      if (shouldDisableStepChange) {
        return;
      }
      props.handleBack(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToPrevious]);

  return (
    <div>
      <div>
        <PageTitle title="Hide Sensitive Information" />
      </div>
      <div className={classes.questionBlock}>
        <div
          className={classes.paragraph}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <Typography
            variant="body1"
            style={{ fontFamily: "SuperaGothic", fontWeight: "bold" }}
          >
            {
              "Please drag and drop on the page to hide any sensitive information you would like to hide."
            }
          </Typography>
          <Typography
            variant="body2"
            className={classes.note}
            style={{
              fontSize: 16,
              fontFamily: "SuperaGothic",
              margin: "20px 0px",
              fontWeight: "bold",
            }}
          >
            Note: This feature{" "}
            <span style={{ fontFamily: "SuperaGothic-ExtraBold" }}>
              doesn't work on mobile devices
            </span>
            . Please use a laptop or a desktop if you need to redact any
            information on your application.
          </Typography>
        </div>
      </div>
      <div className={classes.imageEditorContainer}>
        {imageEditor.length > 0 ? (
          imageEditor
        ) : (
          <Paper
            elevation={10}
            square={true}
            style={{ display: "inline-block" }}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
              alt=""
            ></img>
          </Paper>
        )}
      </div>
      <div className={classes.floatingButtons}>
        <Button
          disabled={props.disableBack || shouldDisableStepChange}
          onClick={() => props.handleBack(files)}
          className={classes.backButton}
        >
          Back
        </Button>
        <Button
          disabled={shouldDisableStepChange}
          variant="contained"
          color="primary"
          onClick={() => props.handleNext(files)}
        >
          {props.nextButtonText}
        </Button>
      </div>
    </div>
  );
}

function loadImageEditor(
  concatedPages: Array<Page>,
  onUpdate: (index: number, newRects: Array<RectWithId>) => void
): Promise<Array<JSX.Element | null>> {
  return new Promise(function (resolve, reject) {
    //setTimeout(() => {
    const imageEditors = concatedPages.map((page, index) => {
      if (!page.selected || !page.purePage) {
        return null;
      }
      return (
        <div key={index} style={{ overflow: "hidden" }}>
          <Paper
            elevation={5}
            square={true}
            style={{ display: "inline-block" }}
          >
            <ImageEditor
              dataUrl={page.purePage}
              initialItems={page.rects}
              onUpdate={(items: Array<RectWithId>) => onUpdate(index, items)}
            />
          </Paper>
        </div>
      );
    });
    resolve(imageEditors);
    //}, 500)
  });
}

export default BlackOffPage;
