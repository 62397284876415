import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, ButtonProps } from "@material-ui/core";
import PrimaryButton from "../../general/PrimaryButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonText: {
      fontFamily: "SuperaGothic",
      fontSize: "18px",
    },
  })
);

interface Props {
  text: string;
  onSubmit?: () => void;
  filled?: boolean;
  disabled?: boolean;
}

function SubmitButton(props: Props & ButtonProps) {
  const { onSubmit, filled, text, disabled, type } = props;
  const classes = useStyles();
  const buttonStyle = {
    backgroundColor: "white",
    color: "#0A5961",
  };

  return (
    <div>
      <PrimaryButton
        style={filled === false ? buttonStyle : undefined}
        onClick={onSubmit}
        disabled={disabled}
        label={text}
        type={type}
      >
        <Typography className={classes.buttonText}>{text}</Typography>
      </PrimaryButton>
    </div>
  );
}

export default SubmitButton;
