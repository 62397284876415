/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import theme from "../../themes/Theme";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  noOption: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  autoComplete: {
    //width: '30%',
    //minWidth: '240px',
    //maxWidth: '320px',
    width: "100%",
  },
  clearIndicator: {
    marginRight: 15,
  },
  input: {
    padding: "0px",
    //paddingLeft: 15,
    fontFamily: "SuperaGothic",
    ...theme.typography.body2,
  },
  customizedTextField: {
    appearance: "none",
    fontFamily: "SuperaGothic",
    width: "100%",
    backgroundColor: "#edf2f7",
    borderWidth: "1px",
    borderRadius: "0.375rem",
    color: "#4a5568",
    padding: "0.75rem 0.5rem",
    marginBottom: "0.75rem",
    lineHeight: "1.25",
  },
});

export interface Option {
  key: string;
  value: string;
}

interface Props {
  options: Array<Option>;
  value: string | null;
  placeholder?: string;
  handleSelect: (key: string | null) => void;
  whiteBackground?: boolean;
}

export default function CountrySelect(props: Props) {
  const classes = useStyles();

  return (
    <Autocomplete
      forcePopupIcon={false}
      className={classes.autoComplete}
      options={props.options}
      classes={{
        option: classes.option,
        noOptions: classes.noOption,
        clearIndicator: classes.clearIndicator,
      }}
      value={props.options.find((option) => option.key === props.value) || null}
      autoHighlight
      getOptionSelected={(option: Option, value: Option) => {
        return option.value === value.value;
      }}
      getOptionLabel={(option) => option.value}
      renderOption={(option) => (
        <Typography variant="subtitle1">{option.value}</Typography>
      )}
      onChange={(event: any, value: Option | null) => {
        props.handleSelect(value ? value.key : null);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={true}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            type: "text",
            disableUnderline: true,
            style: {
              appearance: "none",
              //color:'black',
              backgroundColor: props.whiteBackground ? "white" : "#edf2f7",
              color: "#4a5568",
              borderRadius: "10px",
              border: `1px solid #e2e8f0`,
            },
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              padding: "0.75rem 1rem",
              fontSize: "16px",
              fontFamily: "SuperaGothic",
              //ackgroundColor: '#edf2f7',
              border: "none",
            },
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
