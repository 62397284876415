import { useRef, useEffect, useState } from "react";
import throttle from "lodash.throttle";

/**
 * Check if an element is in viewport

 * @param {number} offset - Number of pixels up to the observable element from the top
 * @param {number} throttleMilliseconds - Throttle observable listener, in ms
 */
export default function useVisibility<Element extends HTMLElement>(
  offset = 0,
  throttleMilliseconds = 100,
  defaultVisible = false,
  lastElement = false
): [boolean, React.RefObject<Element>] {
  const [isVisible, setIsVisible] = useState(defaultVisible);
  const currentElement = useRef<Element>();

  const onScroll = throttle(() => {
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }
    const top = currentElement.current.getBoundingClientRect().top;
    const bottom = currentElement.current.getBoundingClientRect().bottom;
    const line = (window.innerHeight - offset) / 3.0 + offset;
    if (lastElement) {
      setIsVisible(top <= line);
    } else {
      setIsVisible(top <= line && bottom >= line);
    }
  }, throttleMilliseconds);

  useEffect(() => {
    document.addEventListener("scroll", onScroll, true);
    return () => document.removeEventListener("scroll", onScroll, true);
  });

  return [isVisible, currentElement];
}
