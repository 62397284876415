import React, { useState, useEffect } from "react";
import { useStore } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Spacing from "../../general/Spacing";
import { Paper } from "@material-ui/core";
import axios from "axios";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import Alert from "./PanelAlert";
import LoadingContent from "./LoadingContent";
import ColoredTextField from "./ColoredTextField";
import SubmitButton from "./SubmitButton";
import CardHeader from "./CardHeader";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/browser";
import Banner from "../../general/Banner";
const queryString = require("query-string");
//import Cookies from 'js-cookie';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: "0px 15px",
    },
    paper: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    card: {
      marginTop: "80px",
      alignItems: "center",
      minWidth: "320px",
      maxWidth: "450px",
      width: "80%",
      overflow: "hidden",
      margin: "0px auto",
    },
    header: {
      width: "100%",
      height: "120px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerText: {
      fontFamily: "Righteous",
      fontSize: "32px",
      color: "white",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
      fontSize: "18px",
    },
    root: {
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      paddingBottom: "80px",
      paddingTop: "0px",
    },
    labelRoot: {
      fontFamily: "SuperaGothic",
    },
    inputRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleText: {
      fontSize: "16px",
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      width: "120px",
      textAlign: "left",
    },
    textField: {
      width: "90%",
      maxWidth: "280px",
    },
    textFieldContainer: {
      width: "90%",
      margin: "auto",
    },
    alert: {
      fontFamily: "SuperaGothic",
      "& .MuiAlert-icon": {
        marginTop: "auto",
        marginBottom: "auto",
      },
    },
    cardContent: {
      margin: "30px",
    },
    link: {
      fontFamily: "SuperaGothic",
      color: theme.palette.primary.main,
    },
    container: {
      padding: "40px 20px",
      paddingBottom: "0px",
      backgroundColor: theme.palette.primary.main,
      //marginTop: '40px',
    },
  })
);

interface LocationState {
  from?: Location;
}

function LogInPage() {
  const classes = useStyles();
  const store = useStore();
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );
  //const [loggedIn, setLoggedIn] = useState<boolean>(store.getState().loggedIn);
  const [, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const user = store.getState().user;

  const history = useHistory<LocationState>();

  useEffect(() => {
    axios("api/v1/auto_login", {
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        data = data.data;
        if (data.user) {
          setIsLoggedIn(true);
          store.dispatch({ type: "UPDATE_USER_ACCTION", user: data.user });
          history.push({ pathname: "/search" });
        } else {
          setIsLoggedIn(false);
          store.dispatch({ type: "UPDATE_USER_ACCTION", user: null });
        }
      })
      .catch((error) => {
        setIsLoggedIn(false);
        store.dispatch({ type: "UPDATE_USER_ACCTION", user: null });
      });
  }, [history, store]);

  const location = useLocation();

  /*if (isLoggedIn === undefined) {
        return (
        <div className={`page-container ${classes.root}`}>
            <Helmet>
                <title>Sign in - CollegeSharing</title>
            </Helmet>
            <CircularProgress style={{color: "white", margin:"auto", marginTop: 100, marginBottom:100}} />
        </div>)
    }*/

  const parsed = queryString.parse(location.search);
  const showBanner = parsed["showBanner"];

  const goToNextPage = () => {
    const locationState = history.location.state;
    if (locationState && locationState.from) {
      history.push(locationState.from);
    } else {
      history.push({ pathname: "/search", state: {} });
    }
  };
  const onRedirect = () => {
    const from = history.location.state;
    history.push({ pathname: "/sign_up", state: from });
  };

  const onSignIn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (user) {
      goToNextPage();
    }
    setIsLoading(true);
    axios("api/v1/login", {
      method: "POST",
      withCredentials: true,
      data: {
        email: email,
        password: password,
      },
    })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        data = data.data;
        if (data.user) {
          store.dispatch({
            type: "UPDATE_IS_LOGGINED_IN_ACCTION",
            status: true,
          });
          store.dispatch({ type: "UPDATE_USER_ACCTION", user: data.user });
          goToNextPage();
        } else if (data.error) {
          setAlertMessage(data.error);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data) {
          if (error.response.status !== 401) {
            Sentry.captureException(error);
          }
          error = error.response.data;
          // TODO: add error logger
          const message =
            (error.error && error.error.message) ||
            "We are faced with technical issues.";
          setAlertMessage(message);
        } else {
          // TODO: add error logger
          Sentry.captureException(error);
          setAlertMessage("We are faced with technical issues.");
        }
      });
  };

  const onSubmitEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onSubmitPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  var cardContent = null;

  if (!isLoading) {
    cardContent = (
      <SignInCardContent
        setEmail={onSubmitEmail}
        setPassword={onSubmitPassword}
        onSignIn={onSignIn}
        onRedirect={onRedirect}
        alertMessage={alertMessage}
        isLoading={isLoading}
      />
    );
  } else {
    cardContent = <LoadingContent title="Sign In" />;
  }

  return (
    <div className={`page-container ${classes.root}`}>
      <Helmet>
        <title>Sign in - CollegeSharing</title>
      </Helmet>
      {showBanner && (
        <div className={classes.container}>
          <Banner
            title="Successfully signed up"
            subtitle="Please confirm your email address before logging in"
          />
        </div>
      )}
      <Paper className={classes.card} elevation={8}>
        {cardContent}
      </Paper>
    </div>
  );
}

function SignInCardContent({
  setEmail,
  setPassword,
  onSignIn,
  onRedirect,
  alertMessage,
}: {
  setEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSignIn: (event: React.FormEvent<HTMLFormElement>) => void;
  onRedirect: () => void;
  alertMessage?: string;
  isLoading: boolean;
}) {
  const classes = useStyles();
  return (
    <div className="page-container">
      <CardHeader title="Sign In" />
      <div className="card-wrapper">
        {alertMessage && (
          <div>
            <Alert severity="error">{alertMessage}</Alert>
            <Spacing spacing={24} />
          </div>
        )}
        <form onSubmit={onSignIn}>
          <div className="row-container">
            <div className="row-wrapper">
              <ColoredTextField
                title="Email"
                type="text"
                onChange={setEmail}
                placeholder="anna@gmail.com"
              />
            </div>
            <div className="row-wrapper">
              <ColoredTextField
                title="Password"
                type="password"
                onChange={setPassword}
                placeholder="password"
              />
            </div>
          </div>
          <SubmitButton text="SIGN IN" type="submit" filled={true} />
        </form>
        <Spacing spacing={8} />
        <SubmitButton text="SIGN UP" onSubmit={onRedirect} filled={false} />
        <Spacing spacing={8} />
        <Link to="/reset_password" className={classes.link}>
          Forgot your password?
        </Link>
        <Spacing spacing={24} />
      </div>
    </div>
  );
}

export default LogInPage;
