import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Spacing from "../general/Spacing";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { FilterLineItem, Filter } from "../../types/serverSideTypes";
import { FilterList, OriginalFilterItem } from "../../types/types";
import SearchableSelector, { Option } from "../general/SearchableSelector";
import PrimaryButton from "../general/PrimaryButton";
import { logEvent } from "../../helpers/GoogleAnalyticsLogger";
import * as Sentry from "@sentry/browser";
import InputTextField from "../pages/profile/InputTextField";
//import Cookies from 'js-cookie';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: "0px 15px",
    },
    paper: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    card: {
      alignItems: "center",
      minWidth: "256px",
      margin: "auto",
      [theme.breakpoints.up("md")]: {
        maxWidth: "800px",
      },
      [theme.breakpoints.down("md")]: {
        margin: "0px auto",
      },
      //maxWidth: '480px',
      width: "100%",
      overflow: "hidden",
    },
    header: {
      width: "100%",
      height: "120px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerText: {
      fontFamily: "Righteous",
      fontSize: "32px",
      color: "white",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
      fontSize: "18px",
    },
    root: {
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      paddingBottom: "80px",
      paddingTop: "80px",
    },
    labelRoot: {
      fontFamily: "SuperaGothic",
    },
    inputRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleText: {
      fontSize: "16px",
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      width: "120px",
      textAlign: "left",
    },
    textField: {
      width: "90%",
      maxWidth: "280px",
    },
    textFieldContainer: {
      width: "90%",
      margin: "auto",
    },
    rowWrapperRight: {
      marginBottom: "12px",
      [theme.breakpoints.up("md")]: {
        width: "40%",
        marginLeft: "30px",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    rowWrapperLeft: {
      marginBottom: "12px",
      [theme.breakpoints.up("md")]: {
        width: "60%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    cardContent: {
      margin: "30px",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "1rem",
      //fontFamily: 'SuperaGothic',
      color: "#4a5568",
      letterSpacing: "0.025em",
      marginBottom: "0.5rem",
    },
    smallFonts: {
      fontSize: "16px",
    },
    rowContainer: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      [theme.breakpoints.down("md")]: {
        display: "inherent",
      },
    },
    cardWrapper: {
      [theme.breakpoints.up("md")]: {
        padding: "0rem 5rem",
        paddingTop: 0,
      },
      [theme.breakpoints.down("md")]: {
        padding: 0,
      },
    },
  })
);

interface Props {
  setOneTimeReward: (oneTimeReward: number) => void;
  //setAnnualRevenue: (annualRevenue: number) => void,
}

function SignUpPage(props: Props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //const [isLoadingAnnualAmount, setIsLoadingAnnualAmount] = useState<boolean>(false);
  const [schoolId, setSchoolId] = useState<string | null>(null);
  const [filter, setFilter] = useState<Filter | null>(null);
  const [numSchools, setNumSchools] = useState<number | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );
  //const [oneTimeReward, setOneTimeReward] = useState<number | null>(15);
  //const [annualRevenue,  setAnnualRevenue] = useState<number | null>(27.5);

  useEffect(() => {
    axios("api/v1/filters", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        var downloadedFilter = data.data as Array<FilterList>;
        if (downloadedFilter) {
          downloadedFilter = downloadedFilter.map((item) => {
            if (item.title === "Schools" || item.title === "Majors") {
              return {
                title: item.title,
                listStatus: addOtherOption(item.listStatus),
              };
            }
            return item;
          });
          const transformedFilter = {
            schools: convertFilterFormat(downloadedFilter, "Schools"),
            majors: convertFilterFormat(downloadedFilter, "Majors"),
            years: convertFilterFormat(downloadedFilter, "Years"),
            categories: convertFilterFormat(downloadedFilter, "Categories"),
            rankings: convertFilterFormat(downloadedFilter, "Rankings"),
          };
          setFilter(transformedFilter);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        // TODO: add logger
        // TODO: possibly add banner to indicate the error
      });
  }, []);

  const handleSelectSchool = (key: string | null) => {
    setSchoolId(key);
  };

  const handleSelectNumSchools = (key: string | null) => {
    if (key === "") {
      setNumSchools(null);
    } else if (!isNaN(Number(key))) {
      setNumSchools(Number(key));
    }
  };

  const onSubmit = () => {
    if (!schoolId || !numSchools) {
      return;
    }
    setIsLoading(true);
    //setIsLoadingAnnualAmount(true);
    logEvent({
      category: "Card",
      action: "Click",
      label: "estimate_quote_button",
    });
    axios("api/v1/get_reward_estimation", {
      method: "POST",
      withCredentials: true,
      data: {
        school_id: schoolId,
        number_of_schools: numSchools,
        number_of_pages: 5,
        number_of_blackouts: 10,
      },
    })
      .then((response) => {
        props.setOneTimeReward(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        // TODO: add logger
        setIsLoading(false);
        setAlertMessage("Failed to get estimated price. Please try again");
        Sentry.captureException(error);
      });

    /*axios(`api/v1/get_annual_revenue_estimation`, {
            method: 'POST',
            withCredentials: true,
            data: {
                school_id: schoolId,
            }
        })
        .then(response => {
            props.setAnnualRevenue(response.data.data);
            setIsLoadingAnnualAmount(false);
        })
        .catch(error => {
            setIsLoadingAnnualAmount(false)
            setAlertMessage("Failed to get estimated price. Please try again")
            // TODO: add logger
            Sentry.captureException(error)
        });*/
  };

  var cardContent = (
    <SignUpCardContent
      setSchoolId={handleSelectSchool}
      setYearId={handleSelectNumSchools}
      schoolId={schoolId}
      yearId={numSchools}
      onSubmit={onSubmit}
      filter={filter}
      alertMessage={alertMessage}
      isLoading={isLoading}
    />
  );

  return <div className={classes.card}>{cardContent}</div>;
}

function SignUpCardContent({
  setSchoolId,
  setYearId,
  schoolId,
  yearId,
  filter,
  onSubmit,
  isLoading,
  alertMessage,
}: {
  setSchoolId: (value: string | null) => void;
  setYearId: (value: string | null) => void;
  schoolId: string | null;
  yearId: number | null;
  filter: Filter | null;
  onSubmit: () => void;
  alertMessage?: string;
  isLoading: boolean;
}) {
  const classes = useStyles();
  const buttonStyle = {
    width: "100%",
    color: "white",
    //backgroundColor: 'white',
    //color: '#0A5961',
    //marginTop: '40px',
    //marginBottom: '10vw'
  };
  return (
    <div className={`card-wrapper ${classes.cardWrapper}`}>
      <div style={{ paddingTop: "20px" }}>
        <Typography
          variant="h4"
          style={{
            fontWeight: "bold",
            textAlign: "left",
            color: "#0E7F8A",
            borderBottom: `solid 1px #F5F5F5`,
          }}
        >
          Tell us more about your application
        </Typography>
        {alertMessage && (
          <div>
            <Spacing spacing={24} />
          </div>
        )}
        <Spacing spacing={24} />
        <div className={`row-wrapper ${classes.rowContainer}`}>
          <div className={classes.rowWrapperLeft}>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ textAlign: "left" }}
            >
              Top-Ranking School Admitted Into
            </Typography>
            {DropdownList(
              getFilter(filter !== null ? filter?.schools : []),
              setSchoolId,
              schoolId,
              "Columbia University"
            )}
          </div>
          <div className={classes.rowWrapperRight}>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ textAlign: "left" }}
            >
              Number of Accepted Schools
            </Typography>
            {TextField(setYearId, yearId ? String(yearId) : "", "3")}
          </div>
        </div>
        <Spacing spacing={24} />
        <PrimaryButton
          style={buttonStyle}
          onClick={onSubmit}
          disabled={isLoading || !schoolId || !yearId}
          label="get_estimation_card.get_an_estimate"
        >
          <Typography variant="h5">Get an Estimate</Typography>
        </PrimaryButton>
      </div>
    </div>
  );
}

function TextField(
  handleChange: (key: string | null) => void,
  value: string,
  placeholder?: string
) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value);
  };
  return (
    <InputTextField
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      backgroundcolor={"white"}
    />
  );
}

function DropdownList(
  options: Array<Option>,
  handleSelect: (key: string | null) => void,
  value: string | null,
  placeholder?: string
) {
  return (
    <SearchableSelector
      whiteBackground
      options={options}
      handleSelect={handleSelect}
      value={value}
      placeholder={placeholder}
    />
  );
}

function addOtherOption(
  options: Array<OriginalFilterItem>
): Array<OriginalFilterItem> {
  if (!options) {
    return options;
  }
  options.splice(0, 0, { id: "-1", name: "Other" });
  return options;
}

function convertFilterFormat(filter: Array<FilterList>, title: string) {
  return filter.find((item) => item.title === title)?.listStatus || [];
}

function getFilter(items: Array<FilterLineItem>): Array<Option> {
  const options = items.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });
  return options;
}

export default SignUpPage;
