import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    generalButton: {
      color: "white",
      fontSize: "20px",
      fontFamily: "SuperaGothic",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "#0A5961",
      },
      textTransform: "none",
      alignItems: "center",
      padding: "3px 15px",
      borderRadius: "50% 50% 50% 50%",
      height: "52px",
      width: "52px",
      minWidth: "52px",
    },
  })
);

function CustomizedButton(props: ButtonProps) {
  const classes = useStyles();
  return (
    <Button className={classes.generalButton} {...props}>
      {props.children}
    </Button>
  );
}

export default CustomizedButton;
