/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NavButton from "./NavButton";
import { ServerCategoryStatus } from "../../types/serverSideTypes";
import { FilterItem } from "../../types/types";
import TextField from "@material-ui/core/TextField";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import theme from "../../themes/Theme";
import { useHistory } from "react-router";
import { logEvent } from "../../helpers/GoogleAnalyticsLogger";
import axios from "axios";
import * as Sentry from "@sentry/browser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: "100%",
      maxWidth: "280px",
    },
    paper: {
      //backgroundColor: "blue",
      fontFamily: "SuperaGothic",
      fontSize: "16px",
    },
    typeBar: {
      flexGrow: 1,
      //minWidth: '250px',
      textAlign: "right",
      height: "100%",
    },
    root: {
      display: "flex",
      minWidth: "240px",
      maxWidth: "360px",
      flexGrow: 1,
      paddingLeft: "20px",
      //paddingRight: '20px',
      alignItems: "center",
      height: "45px",
    },
    button: {
      height: "100%",
    },
    zeroPadding: {
      padding: "0px",
      margin: "0px",
      height: "45px",
    },
    rightPadding: {
      marginRight: "10px",
    },
    options: {
      fontFamily: "RobotoCondensed",
    },
  })
);

export default function SearchBar() {
  const classes = useStyles();
  const [schools, setSchools] = useState<Array<FilterItem>>([]);
  const [selectedSchool, setSelectedSchool] = useState<FilterItem | undefined>(
    undefined
  );
  const history = useHistory();

  const getOptionLabel = (school: FilterItem) => {
    return school.name;
  };

  const onChange = (
    event: React.ChangeEvent<{}>,
    value: FilterItem | string | null,
    reason: any,
    details?: any
  ) => {
    if (!value || typeof value === "string") {
      setSelectedSchool(undefined);
      return;
    }
    if (typeof value !== "string") {
      setSelectedSchool(value);
    }
  };

  const onInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: any,
    details?: any
  ) => {
    setSelectedSchool(undefined);
  };

  const onSubmit = () => {
    logEvent({
      category: "Search",
      action: "Click",
      label: "nav.search_bar",
      value: Number(selectedSchool?.id),
    });
    if (selectedSchool) {
      history.push({
        pathname: `/search`,
        search: `Schools=${selectedSchool.id}`,
      });
    } else {
      history.push({ pathname: "/search" });
    }
  };

  useEffect(() => {
    axios("api/v1/filters", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.data) {
          const filter = data.data as Array<ServerCategoryStatus>;
          const schoolList: Array<FilterItem> =
            filter.find((item) => item.title === "Schools")?.listStatus || [];
          setSchools(schoolList);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        // TODO: error logger
        // TODO: add some search mechanism
      });
    /*fetch('http://localhost:3001/api/v1/filters', {
        method: 'GET',
        credentials: 'include',
    })
    .then(res => res.json())
    .then(data => {
        if (data.data) {
          const filter = data.data as Array<ServerCategoryStatus>;
          const schoolList: Array<FilterItem> = filter.find(item => item.title === "Schools")?.listStatus || []
          setSchools(schoolList);
        }
    }); */
  }, []); //initial load filter

  return (
    <div className={classes.root}>
      <Autocomplete
        id="search-bar-autocomplete"
        className={classes.typeBar}
        freeSolo
        onChange={onChange}
        onInputChange={onInputChange}
        classes={{
          paper: classes.paper,
          clearIndicator: classes.rightPadding,
          option: classes.options,
        }}
        options={schools}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <form noValidate>
            <TextField
              {...params}
              autoComplete="new-password"
              margin="normal"
              placeholder={"Stanford"}
              className={classes.textField}
              classes={{
                root: classes.zeroPadding,
              }}
              InputProps={{
                ...params.InputProps,
                type: "text",
                disableUnderline: true,
                style: {
                  height: "100%",
                  padding: "0px",
                  borderRadius: "3px 0px 0px 3px",
                  border: `1px solid ${theme.palette.primary.main}`,
                },
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
                style: {
                  color: theme.palette.primary.main,
                  fontSize: "18px",
                  padding: "10px",
                  paddingRight: "35px",
                  fontFamily: "SuperaGothic",
                  fontWeight: "bold",
                  border: "none",
                },
              }}
            />
          </form>
          //<InputBar {...params} inputRef={params.InputProps.ref} margin="none" />
        )}
      />
      <NavButton onClick={onSubmit} />
    </div>
  );
}
