import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { Payout } from "../../types/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      paddingTop: "4rem",
      paddingBottom: "6rem",
    },
    title: {
      //textTransform: 'uppercase',
      //fontWeight:'bold',
      fontSize: "1rem",
      color: "#4a5568",
      //letterSpacing:'0.025em',
      marginBottom: "0.5rem",
    },
    rowContainer: {
      display: "flex",
      flexWrap: "wrap",
      //marginLeft: '-0.75rem',
      //marginRight: '-0.75rem',
      marginBottom: "0.5rem",
    },
    innerRowContainer: {
      width: "100%",
      //paddingLeft: '0.75rem',
      //paddingRight: '0.75rem',
    },
  })
);

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  payout: Payout;
}

function ChangePasswordPanel(props: Props) {
  const classes = useStyles();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      open={props.open}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Typography
          style={{ fontFamily: "SuperaGothic-ExtraBold", fontWeight: "bold" }}
        >
          Confirm Payout
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <div className="w-full">
          <div className={classes.rowContainer}>
            <div className={classes.innerRowContainer}>
              <Typography variant="body1" className={classes.title}>
                <span style={{ fontWeight: "bold" }}>Amount:</span>{" "}
                {props.payout.amount}
              </Typography>
              <Typography variant="body1" className={classes.title}>
                <span style={{ fontWeight: "bold" }}>Account:</span>{" "}
                {props.payout.receiver}
              </Typography>
              <Typography variant="body1" className={classes.title}>
                <span style={{ fontWeight: "bold" }}>Payment Platform:</span>{" "}
                {props.payout.accountType}
              </Typography>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => props.onClose()} color="primary">
          <Typography
            variant="body1"
            style={{
              fontFamily: "SuperaGothic",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Cancel
          </Typography>
        </Button>
        <Button onClick={() => props.onSubmit()} color="primary">
          <Typography
            variant="body1"
            style={{
              fontFamily: "SuperaGothic",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Confirm
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangePasswordPanel;
