import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Spacing from "../../general/Spacing";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //width: '10%',
      textAlign: "left",
      color: "white",
      paddingLeft: "5%",
      paddingRight: "5%",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "40px",
      },
      [theme.breakpoints.up("md")]: {
        paddingBottom: "30px",
      },
    },
  })
);

interface FooterLink {
  name: string;
  link?: string;
  external?: boolean;
}

interface Props {
  title: string;
  contentList: Array<FooterLink>;
}

function FooterColumn(props: Props) {
  const styles = useStyles();
  const list = props.contentList.map((content) => {
    return (
      <div key={content.name}>
        <div className="roboto-condensed footer-font-size-1200">
          {content.link && !content.external && (
            <Link to={content.link}>{content.name}</Link>
          )}
          {content.external && content.link && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => window.open(content.link, "_blank")}
            >
              {content.name}
            </div>
          )}
          {!content.link && content.name}
        </div>
        <Spacing spacing={8} />
      </div>
    );
  });
  return (
    <div className={styles.root}>
      <div
        className={`red-hat-display-bold paragraph-font-size-1200`}
        style={{ fontFamily: "SuperaGothic-ExtraBold" }}
      >
        {props.title}
      </div>
      <Spacing spacing={20} />
      {list}
    </div>
  );
}

export default FooterColumn;
