import React from "react";
import { Typography } from "@material-ui/core";
import licenses from "../../licenses.json";

interface License {
  name?: string;
  license?: string;
  url?: string;
}

export default function EndUserLicenseAgreementPage() {
  let licenseList: Array<License> = [];
  Object.entries(licenses).forEach(([key, val]) => {
    licenseList.push({
      name: key,
      license: val["licenses"],
      url: val["licenseUrl"],
    });
  });
  const content = licenseList.map((license, index) => {
    return (
      <div style={{ marginTop: 10 }} key={index}>
        <Typography variant="body2" style={{ fontWeight: "bold" }}>
          {license.name}
        </Typography>
        <Typography variant="body2">{license.url}</Typography>
      </div>
    );
  });
  return (
    <div style={{ margin: "5%", textAlign: "left" }}>
      <Typography variant="h3" style={{ marginTop: 20, fontWeight: "bold" }}>
        End-User License Agreement (EULA) of CollegeSharing
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        This End-User License Agreement ("EULA") is a legal agreement between
        you and CollegeSharing Inc.. Our EULA was created by EULA Template for
        CollegeSharing.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        This EULA agreement governs your acquisition and use of our
        CollegeSharing software ("Software") directly from CollegeSharing Inc.
        or indirectly through a CollegeSharing Inc. authorized reseller or
        distributor (a "Reseller"). Our Privacy Policy was created by the
        Privacy Policy Generator.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        Please read this EULA agreement carefully before completing the
        installation process and using the CollegeSharing software. It provides
        a license to use the CollegeSharing software and contains warranty
        information and liability disclaimers.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        If you register for a free trial of the CollegeSharing software, this
        EULA agreement will also govern that trial. By clicking "accept" or
        installing and/or using the CollegeSharing software, you are confirming
        your acceptance of the Software and agreeing to become bound by the
        terms of this EULA agreement.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        If you are entering into this EULA agreement on behalf of a company or
        other legal entity, you represent that you have the authority to bind
        such entity and its affiliates to these terms and conditions. If you do
        not have such authority or if you do not agree with the terms and
        conditions of this EULA agreement, do not install or use the Software,
        and you must not accept this EULA agreement.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        This EULA agreement shall apply only to the Software supplied by
        CollegeSharing Inc. herewith regardless of whether other software is
        referred to or described herein. The terms also apply to any
        CollegeSharing Inc. updates, supplements, Internet-based services, and
        support services for the Software, unless other terms accompany those
        items on delivery. If so, those terms apply.
      </Typography>
      <Typography variant="h4" style={{ marginTop: 20, fontWeight: "bold" }}>
        License Grant
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        CollegeSharing Inc. hereby grants you a personal, non-transferable,
        non-exclusive licence to use the CollegeSharing software on your devices
        in accordance with the terms of this EULA agreement.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        You are permitted to load the CollegeSharing software (for example a PC,
        laptop, mobile or tablet) under your control. You are responsible for
        ensuring your device meets the minimum requirements of the
        CollegeSharing software.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        You are not permitted to:
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <li>
          Edit, alter, modify, adapt, translate or otherwise change the whole or
          any part of the Software nor permit the whole or any part of the
          Software to be combined with or become incorporated in any other
          software, nor decompile, disassemble or reverse engineer the Software
          or attempt to do any such things
        </li>
        <li>
          Reproduce, copy, distribute, resell or otherwise use the Software for
          any commercial purpose
        </li>
        <li>
          Allow any third party to use the Software on behalf of or for the
          benefit of any third party
        </li>
        <li>
          Use the Software in any way which breaches any applicable local,
          national or international law
        </li>
        <li>
          use the Software for any purpose that CollegeSharing Inc. considers is
          a breach of this EULA agreement
        </li>
      </Typography>
      <Typography variant="h4" style={{ marginTop: 20, fontWeight: "bold" }}>
        Intellectual Property and Ownership
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        CollegeSharing Inc. shall at all times retain ownership of the Software
        as originally downloaded by you and all subsequent downloads of the
        Software by you. The Software (and the copyright, and other intellectual
        property rights of whatever nature in the Software, including any
        modifications made thereto) are and shall remain the property of
        CollegeSharing Inc..
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        CollegeSharing Inc. reserves the right to grant licences to use the
        Software to third parties.
      </Typography>
      <Typography variant="h4" style={{ marginTop: 20, fontWeight: "bold" }}>
        Termination
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        This EULA agreement is effective from the date you first use the
        Software and shall continue until terminated. You may terminate it at
        any time upon written notice to CollegeSharing Inc..
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        It will also terminate immediately if you fail to comply with any term
        of this EULA agreement. Upon such termination, the licenses granted by
        this EULA agreement will immediately terminate and you agree to stop all
        access and use of the Software. The provisions that by their nature
        continue and survive will survive any termination of this EULA
        agreement.
      </Typography>
      <Typography variant="h4" style={{ marginTop: 20, fontWeight: "bold" }}>
        Governing Law
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        This EULA agreement, and any dispute arising out of or in connection
        with this EULA agreement, shall be governed by and construed in
        accordance with the laws of us.
      </Typography>
      <Typography variant="h4" style={{ marginTop: 20, fontWeight: "bold" }}>
        Third Party Software Licenses
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        CollegeSharing Inc. makes use of open source software. Attributions as
        required by licensing are listed below:
      </Typography>
      {content}
    </div>
  );
}
