import React from "react";
import Banner from "../general/Banner";
import VerticalTimeline from "../general/VerticalTimeline";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EmailIcon from "@material-ui/icons/Email";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import theme from "../../themes/Theme";
import { Typography } from "@material-ui/core";
import PrimaryButton from "../general/PrimaryButton";
import Spacing from "../general/Spacing";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "flex-start",
      overflow: "hidden",
    },
    paperEdge: {
      width: "10px",
      backgroundColor: theme.palette.primary.main,
      //marginRight: '20px',
    },
    titleFlex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      //borderBottom: '1px solid #e5e5e5',
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: "2rem",
      marginRight: "10px",
    },
    container: {
      margin: "40px 20px",
      //marginTop: '40px',
    },
    buttonStyle: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      marginTop: "40px",
      marginBottom: "10vw",
    },
  })
);

function UploadSuccessPage() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container}>
        <Banner
          title="Application Successfully Submitted"
          subtitle="Your application has been successfully submitted!"
        />
        <div className={classes.container}>
          <Typography
            variant="h4"
            style={{ textAlign: "left", fontWeight: "bold" }}
          >
            What happens next?
          </Typography>
          <VerticalTimeline timelineItems={timelineItems} />
          <Spacing spacing={24} />
          <PrimaryButton
            style={{ float: "left", maxWidth: "280px" }}
            href="/upload_status"
            label="upload_more_applications"
            buttonStyle="primary"
          >
            <Typography variant="body1">Upload More Applications</Typography>
          </PrimaryButton>
        </div>
      </div>
      <Spacing spacing={100} />
    </div>
  );
}

const timelineItems = [
  {
    title: "We will review your application in the next 48 hours.",
    icon: (
      <VisibilityIcon style={{ fontSize: theme.typography.body1.fontSize }} />
    ),
    primary: true,
  },
  {
    title: "You will receive an offer price from us through email.",
    icon: <EmailIcon style={{ fontSize: theme.typography.body1.fontSize }} />,
  },
  {
    title: "You can accept our offer and take the reward!",
    icon: (
      <MonetizationOnIcon
        style={{ fontSize: theme.typography.body1.fontSize }}
      />
    ),
  },
];

export default UploadSuccessPage;
