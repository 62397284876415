import "react-multi-carousel/lib/styles.css";
import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import Spacing from "./Spacing";
import theme from "../../themes/Theme";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "flex-start",
      overflow: "hidden",
    },
    paperEdge: {
      width: "10px",
      backgroundColor: theme.palette.primary.main,
      //marginRight: '20px',
    },
    titleFlex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.h4.fontSize,
      marginRight: "10px",
    },
    container: {
      margin: "10px 20px",
    },
    title: {
      color: theme.palette.primary.main,
      fontFamily: "SuperaGothic-ExtraBold",
      fontWeight: "bold",
      textAlign: "left",
    },
    subtitle: {
      fontFamily: "SuperaGothic",
      textAlign: "left",
      color: "#334a5f",
    },
    internalIcon: {
      color: theme.palette.primary.main,
      fontSize: "1.5rem",
      marginRight: "10px",
    },
  })
);

type Severity = "confirmed" | "failed" | "warning";

interface Props {
  title: string;
  subtitle: string;
  //color?: string,
  severity?: Severity;
}

function Banner(props: Props) {
  const classes = useStyles();
  const color = severityToColorMap[props.severity || "confirmed"];
  const icon = <Icon severity={props.severity || "confirmed"} />;
  return (
    <Paper elevation={3} className={classes.flex}>
      <div
        className={classes.paperEdge}
        style={{ backgroundColor: color }}
      ></div>
      <div className={classes.container}>
        <div className={classes.titleFlex}>
          {icon}
          <Typography
            variant="h6"
            className={classes.title}
            style={{ color: color }}
          >
            {props.title}
          </Typography>
        </div>
        <Spacing spacing={4} />
        <div>
          <Typography variant="body2" className={classes.subtitle}>
            {props.subtitle}
          </Typography>
        </div>
      </div>
    </Paper>
  );
}

const severityToColorMap = {
  confirmed: theme.palette.primary.main,
  failed: theme.palette.error.main,
  warning: theme.palette.warning.main,
};

function Icon({ severity }: { severity: Severity }) {
  const classes = useStyles();
  let icon;
  switch (severity) {
    case "confirmed":
      icon = (
        <CheckCircleRoundedIcon
          className={classes.internalIcon}
          style={{ color: severityToColorMap["confirmed"] }}
        />
      );
      break;
    case "failed":
      icon = (
        <CancelRoundedIcon
          className={classes.internalIcon}
          style={{ color: severityToColorMap["failed"] }}
        />
      );
      break;
    case "warning":
      icon = (
        <ErrorRoundedIcon
          className={classes.internalIcon}
          style={{ color: severityToColorMap["warning"] }}
        />
      );
      break;
  }
  return icon;
}

export default Banner;
