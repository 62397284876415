import React from "react";
import Accordion, { AccordionProps } from "@material-ui/core/Accordion";
import AccordionSummary, {
  AccordionSummaryProps,
} from "@material-ui/core/AccordionSummary";
import { styled } from "@material-ui/core/styles";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { ReactComponent as ArrowDownIcon } from "../../resources/icons/arrowDown.svg";
import { OrderByField } from "../../types/serverSideTypes";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion elevation={0} {...props} />
))(({ theme }) => ({
  "&.MuiAccordion-root": {
    background: "#FFFFFF",
    boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.1)",
    borderRadius: 15,
    padding: 0,
    zIndex: 50,
  },
  "&:not(:last-child)": {},
  "&:before": {
    display: "none",
  },
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ArrowDownIcon style={{ height: 9, width: 17 }} />}
    //expandIcon={<KeyboardArrowDownRoundedIcon style={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {},
  "& .MuiAccordionSummary-content": {
    margin: "10px 0px",
  },
  "&.MuiAccordionSummary-root": {
    marginLeft: "18px",
    marginRight: 18,
    padding: 0,
    width: 140,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: "0px 0px",
  "&.MuiAccordionDetails-root": {
    display: "block",
    textAlign: "left",
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      fontFamily: "RedHatDisplay-Medium",
      fontSize: 18,
      backgroundColor: "rgba(1,1,1,0)",
      color: "#0E7F8A",
      paddingTop: 12,
      paddingBottom: 13,
      cursor: "pointer",
    },
    gradient: {
      height: 1,
      width: "100%",
      top: 0,
      background:
        "linear-gradient(90deg, rgba(56, 185, 194, 0) 0%, rgba(56, 185, 194, 0.5) 48.96%, rgba(255, 255, 255, 0) 100%, rgba(56, 185, 194, 0) 100%)",
    },
  })
);

interface Option {
  name: string;
  type: OrderByField;
}

export type ExpandablePanelProps = {
  expandedByDefault: boolean;
  options: Option[];
  name: string;
  handleClick: (option: Option) => void;
};

export default function ExpandablePanel(props: ExpandablePanelProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<boolean>(
    props.expandedByDefault
  );

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const optionChanged = (option: Option) => {
    props.handleClick(option);
    setExpanded(false);
  };

  const items = props.options.map((option, index) => {
    return (
      <div key={option.type} onClick={() => optionChanged(option)}>
        {<div className={classes.gradient} />}
        <div className={classes.option} style={{ marginLeft: 18 }}>
          {option.name}
        </div>
      </div>
    );
  });

  return (
    <StyledAccordion expanded={expanded} onChange={() => handleChange()}>
      <StyledAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            fontSize: 18,
            fontFamily: "RedHatDisplay-Bold",
            margin: "auto 0px",
            color: "#0E7F8A",
            textTransform: "none",
          }}
        >
          {props.name}
        </div>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{items}</StyledAccordionDetails>
    </StyledAccordion>
  );
}
