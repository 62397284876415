import React, { useState, useEffect } from "react";
import axios from "axios";
import { PreviewCase } from "../../types/types";
import { useHistory } from "react-router";
import AccordianTable from "../general/ExternalCaseTable";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import ProfileSection from "./profile/ProfileSection";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import * as Sentry from "@sentry/browser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moneyInput: {
      width: "80%",
      maxWidth: "200px",
    },
    sideMargin: {
      margin: "10px 0px",
      width: "100%",
    },
    text: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    normalText: {
      fontFamily: "SuperaGothic",
      //marginBottom: '10px',
      fontSize: "16px",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
    },
    root: {
      [theme.breakpoints.down("sm")]: {
        margin: "0px 15px",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0rem 3rem",
      },
      //minHeight: "70%",
    },
  })
);

export default function EarningsPage() {
  const classes = useStyles();
  const [pendingReviewCases, setPendingReviewCases] = useState<
    Array<PreviewCase>
  >([]);
  const [pendingRevisionCases, setPendingRevisionCases] = useState<
    Array<PreviewCase>
  >([]);
  const [pendingConfirmationCases, setPendingConfirmationCases] = useState<
    Array<PreviewCase>
  >([]);
  const history = useHistory();
  useEffect(() => {
    axios(`api/v1/get_draft_cases_by_status`, {
      method: "POST",
      withCredentials: true,
      data: {
        status: "pending_review",
      },
    })
      .then((response) => response.data)
      .then((data) => {
        var embededData = data.data;
        if (embededData) {
          var cases = embededData.cases as Array<PreviewCase>;
          if (cases) {
            setPendingReviewCases(cases);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        } else {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        }
        Sentry.captureException(error);
      });

    axios(`api/v1/get_draft_cases_by_status`, {
      method: "POST",
      withCredentials: true,
      data: {
        status: "needs_revision",
      },
    })
      .then((response) => response.data)
      .then((data) => {
        var embededData = data.data;
        if (embededData) {
          var cases = embededData.cases as Array<PreviewCase>;
          if (cases) {
            setPendingRevisionCases(cases);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        } else {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        }
        Sentry.captureException(error);
      });

    axios(`api/v1/get_cases_by_status`, {
      method: "POST",
      withCredentials: true,
      data: {
        status: "pending_confirmation",
      },
    })
      .then((response) => response.data)
      .then((data) => {
        var embededData = data.data;
        if (embededData) {
          var cases = embededData.cases as Array<PreviewCase>;
          if (cases) {
            setPendingConfirmationCases(cases);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        } else {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        }
        Sentry.captureException(error);
      });
  }, [history]);

  return (
    <div className={`${classes.root} page-container`}>
      <Helmet>
        <title>Submitted applications - CollegeSharing</title>
      </Helmet>
      {pendingReviewCases && (
        <ProfileSection title="Key Statistics">
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
              <Typography variant="h3">{pendingReviewCases.length}</Typography>
              <Typography variant="h6">Pending Review</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
              <Typography variant="h3">
                {pendingRevisionCases.length}
              </Typography>
              <Typography variant="h6">Pending Revision</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
              <Typography variant="h3">
                {pendingConfirmationCases.length}
              </Typography>
              <Typography variant="h6">Pending Confirmation</Typography>
            </Grid>
          </Grid>
        </ProfileSection>
      )}
      {pendingReviewCases && (
        <ProfileSection title="Pending Review">
          <AccordianTable cases={pendingReviewCases} prefix="/review/" />
        </ProfileSection>
      )}
      {pendingRevisionCases && (
        <ProfileSection title="Pending Revision">
          <AccordianTable cases={pendingRevisionCases} prefix="/review/" />
        </ProfileSection>
      )}
      {pendingConfirmationCases && (
        <ProfileSection title="Pending Confirmation">
          <AccordianTable cases={pendingConfirmationCases} prefix="/profile/" />
        </ProfileSection>
      )}
    </div>
  );
}
