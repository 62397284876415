import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import AboutUs from "../../resources/aboutUs.svg";
import Background from "../../resources/AboutUsBack.svg";
import PrimaryButton from "../general/PrimaryButton";
import textBackground from "../../resources/whoAreWeText.svg";
import Circle from "../../resources/circle.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      [theme.breakpoints.up("md")]: {
        //borderLeft: `3px ${theme.palette.primary.main} solid`,
        //borderRight: `3px ${theme.palette.primary.main} solid`,
        margin: "0px 3%",
        marginRight: "0%",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "3%",
        border: `2px rgba(57, 125, 136, 0.2) solid`,
        //backgroundColor: "rgba(57, 125, 136, 0.1)"
      },
    },
    root: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        margin: "0px 7%",
        marginRight: "0%",
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
        //padding: "7% 0",
        margin: "7%",
      },
    },
    imageContainer: {
      [theme.breakpoints.up("md")]: {
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundImage: `url(${Background})`,
        maxWidth: 800,

        maxHeight: 500,
        display: "flex",
        padding: "2%",
        width: "60%",
        paddingLeft: "80px",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
        marginTop: 40,
        maxWidth: 500,

        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundImage: `url(${Circle})`,
      },
    },
    shape: {
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
        //bottom: "430px",
        //left: "-100px",
      },
    },
    text: {
      textAlign: "left",
      margin: "auto",
      marginLeft: 0,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundImage: `url(${textBackground})`,
      [theme.breakpoints.up("md")]: {
        width: "50%",
        paddingRight: "40px",
        paddingTop: 50,
        paddingBottom: 50,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    image: {
      margin: "auto",
      zIndex: 50,
      [theme.breakpoints.up("md")]: {
        maxWidth: 600,
        width: "100%",
        paddingRight: "10vw",
      },
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
  })
);

export default function AboutUsSection() {
  const classes = useStyles();
  return (
    <div className={classes.border}>
      <div className={classes.root}>
        <div className={classes.text}>
          <Typography
            variant="h4"
            style={{ fontFamily: "SuperaGothic-ExtraBold" }}
          >
            What Is CollegeSharing?
          </Typography>
          <Typography variant="body1" style={{ marginTop: 20 }}>
            Founded by a group of Stanford students, CollegeSharing is a
            data-powered community of accepted college applications. We connect
            real insights with applicants in need, so knowing how to get into a
            specific school isn’t exclusive to those who "know someone."{" "}
          </Typography>
          <div
            style={{
              margin: "40px auto",
              marginBottom: 0,
              textAlign: "center",
            }}
          >
            <PrimaryButton
              onClick={() => window.open("https://m.me/collegesharing")}
              label="about_us.chat_with_us"
            >
              <Typography variant="h5">Chat with Us</Typography>
            </PrimaryButton>
          </div>
        </div>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={AboutUs} alt="" />
        </div>
      </div>
    </div>
  );
}
