import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../general/PrimaryButton";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import SideFilterContent from "./SideFilterContent";
import { CheckBoxItem } from "../../types/types";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleButton: {
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        margin: "0.3rem 0.1rem",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0.3rem 0.5rem",
      },
    },
    popoverBackground: {
      //zIndex: -1,
      backgroundColor: "rgba(0,0,0,0.3)",
    },
  })
);

export interface Props {
  title: string;
  list: Array<CheckBoxItem>;
  updateSearchFilter?: (title: string, newList: Array<CheckBoxItem>) => void;
}

function FilterPopover(props: Props) {
  const buttonColor =
    props.list.filter((checkbox) => checkbox.selected).length === 0
      ? "rgba(14, 127, 138, 0.08)"
      : "rgba(56, 185, 194, 1)";
  const textColor =
    props.list.filter((checkbox) => checkbox.selected).length === 0
      ? "#0E7F8A"
      : "white";
  const buttonStyle = {
    background: buttonColor,
    color: textColor,
    borderRadius: 8,
    boxShadow: "inset 2px 2px 6px rgba(255, 255, 255, 0.3)",
    padding: "0.3rem 0.9rem",
    //boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.1)"
  };
  const paperStyle = {
    maxHeight: "70%",
    minWidth: "300px",
    borderRadius: 12,
  };
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  useEffect(() => {
    setSelected(
      props.list
        .filter((item) => item.selected)
        .map((item) => item.filterItem.id)
    );
  }, [props.list]);

  let defaultSelected: Array<string> = props.list
    .filter((item) => item.selected)
    .map((item) => item.filterItem.id);
  const [selected, setSelected] = useState<Array<string>>(defaultSelected);

  const [backgroundColor, setBackgroundColor] =
    React.useState<string>("rgba(0,0,0,0.1)");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setBackgroundColor("rgba(0,0,0,0.3)");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setBackgroundColor("rgba(0,0,0,0)");
    setAnchorEl(null);
  };

  const updateLocalSelected = (newList: Array<string>) => {
    setSelected(newList);
  };

  const handleApply = () => {
    const updated = props.list.map((item) => {
      if (selected.includes(item.filterItem.id)) {
        return { filterItem: item.filterItem, selected: true };
      } else {
        return { filterItem: item.filterItem, selected: false };
      }
    });
    if (props.updateSearchFilter) {
      props.updateSearchFilter(props.title, updated);
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{}}>
      <PrimaryButton
        disableTouchRipple
        className={classes.titleButton}
        style={buttonStyle}
        aria-describedby={id}
        color="secondary"
        onClick={handleClick}
        label={props.title}
      >
        <Typography
          variant="subtitle1"
          style={{ fontFamily: "RedHatDisplay-SemiBold" }}
        >
          {props.title}
        </Typography>
        <ExpandMoreOutlinedIcon />
      </PrimaryButton>
      <Popover
        id={id}
        //className={classes.popoverBackground}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={5}
        transitionDuration={80}
        style={{ backgroundColor: backgroundColor }}
        PaperProps={{ style: paperStyle }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: "0px 30px" }}>
          <SideFilterContent
            title={props.title}
            updateSearchFilter={updateLocalSelected}
            list={props.list}
            selected={selected}
          />
        </div>
        <div
          style={{
            padding: "0px 30px",
            position: "sticky",
            bottom: 0,
          }}
        >
          <div
            style={{
              height: 25,
              zIndex: 200,
              width: "100%",
              background:
                "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
            }}
          ></div>
          <Button
            onClick={handleApply}
            variant="contained"
            color="primary"
            disableElevation
            style={{
              borderRadius: 12,
              color: "white",
              width: "100%",
              height: 44,
              fontFamily: "RedHatDisplay-Bold",
              fontSize: 18,
              textTransform: "none",
            }}
          >
            Apply
          </Button>
          <div
            style={{
              height: 25,
              zIndex: 200,
              width: "100%",
              backgroundColor: "white",
            }}
          ></div>
        </div>
      </Popover>
    </div>
  );
}

export default FilterPopover;
