import React from "react";
import { Product } from "../../types/types";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Card, Typography } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import { logEvent } from "../../helpers/GoogleAnalyticsLogger";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    generalButton: {
      color: "white",
      fontSize: "20px",
      fontFamily: "SuperaGothic",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
      textTransform: "none",
      alignItems: "center",
      padding: "0.3rem 1.5rem",
    },
    card: {
      width: "50%",
      padding: "0.5rem",
      margin: "0.5rem",
      textAlign: "left",
      "&:hover": {
        backgroundColor: "rgba(244,244,244,1)",
      },
    },
    contentContainer: {
      padding: "0.5rem",
      display: "flex",
    },
    productName: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      color: theme.palette.secondary.dark,
      marginBottom: "0.5rem",
    },
    priceName: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      color: theme.palette.primary.light,
      marginBottom: "0.1rem",
    },
    interval: {
      //textTransform: 'uppercase',
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      fontSize: "12px",
      color: theme.palette.secondary.dark,
      //marginBottom: '0.2rem',
    },
    billed: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      fontSize: "12px",
      color: theme.palette.secondary.dark,
    },
    title: {
      fontFamily: "SuperaGothic-ExtraBold",
      fontWeight: "bold",
      //textTransform: 'uppercase',
      //fontWeight:'bold',
      //fontSize:'0.75rem',
      color: theme.palette.secondary.dark,
      //letterSpacing:'0.025em',
      marginBottom: "0.5rem",
    },
    radio: {
      top: 0,
      paddingRight: "15px",
      paddingLeft: "15px",
    },
    radioRoot: {
      paddingRight: "15px",
      paddingLeft: "5px",
      color: theme.palette.secondary.dark,
      "&$checked": {
        color: "#4bc6bc",
      },
    },
    checked: {},
  })
);

export interface Props {
  product: Product;
  currentProductSelected: boolean;
  handleClick: (key: string) => void;
  subscribed?: boolean;
  fullWidth?: boolean;
  shouldDisplayRadio?: boolean;
}
function ProductCard(props: Props) {
  const classes = useStyles();
  const borderColor = props.currentProductSelected
    ? `solid 3px #4bc6bc`
    : "solid 3px transparent";
  //const margin = props.currentProductSelected ? `calc(0.5rem - 3px)` : '0.5rem';
  //const backgroundColor = props.currentProductSelected ? "#a7ebf2" : "white";
  //const titleColor = props.currentProductSelected ? theme.palette.primary.main : theme.palette.secondary.dark

  const onClick = () => {
    if (props.subscribed) {
      return;
    }
    logEvent({
      category: "Card",
      action: "Click",
      value: props.product.id,
      label: "product_card",
    });
    props.handleClick(props.product.price_id);
  };
  return (
    <Card
      className={classes.card}
      elevation={4}
      style={{
        cursor: props.currentProductSelected ? "auto" : "pointer",
        position: "relative",
        border: borderColor,
        margin: "0.5rem",
        width: props.fullWidth ? "auto" : "50%",
      }}
      onClick={onClick}
    >
      <div className={classes.contentContainer}>
        {props.shouldDisplayRadio && (
          <Radio
            checked={props.currentProductSelected}
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
            //color="primary"
            classes={{
              root: classes.radioRoot,
              checked: classes.checked,
              //colorPrimary: classes.colorSecondary,
            }}
          />
        )}
        <div>
          <Typography variant="h6" className={classes.title}>
            {props.product.name}
          </Typography>
          <Typography
            variant="h5"
            className={classes.priceName}
            style={{ fontFamily: "SuperaGothic-ExtraBold" }}
          >
            {props.product.price_name}
          </Typography>
          <div className="flex-wrap">
            <Typography className={classes.interval}>
              {props.product.interval}
            </Typography>
            <Typography className={classes.billed}>
              {props.product.billed}
            </Typography>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default ProductCard;
