import React, { useEffect, useState } from "react";
import PageTitle from "../general/PageTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import SearchableSelector, { Option } from "../general/SearchableSelector";
import {
  FilterLineItem,
  GeneralApplicationFilter,
  AlternativeFilter,
} from "../../types/serverSideTypes";
import Spacing from "../general/Spacing";
import Button from "@material-ui/core/Button";
import SnackBar from "../general/SnackBar";
import { FilterList } from "../../types/types";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { Essay } from "../../types/uploadTypes";
import EssaysTable from "../general/EssaysTable";
import RadioOptions from "../general/RadioOptions";

function convertFilterFormat(filter: Array<FilterList>, title: string) {
  return filter.find((item) => item.title === title)?.listStatus || [];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light,
    },
    question: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      padding: "0px 5px",
    },
    questionBlock: {
      textAlign: "left",
      [theme.breakpoints.up("sm")]: {
        padding: "20px 40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px 30px",
      },
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
  })
);

export interface CaseInfo {
  userId: string;
  schoolId: string;
  yearId: string;
  majorId: string;
  newlyAddedSchoolName: string | null;
  newlyAddedMajorName: string | null;
  title?: string;
  applicationRoundId: string;
  scholarshipId: string;
  essays: Array<Essay>;
  legacy?: boolean;
}

interface Props {
  caseInfo: CaseInfo;
  disableBack: boolean;
  handleBack: () => void;
  handleNext: () => void;
  handleEnterInfo: (caseInfo: CaseInfo) => void;
  nextButtonText: string;
  shouldProceedToNext?: boolean;
  setShouldProceedToNext?: (value: boolean) => void;
}

function EnterInformationPage(props: Props) {
  const classes = useStyles();

  const caseInfo = props.caseInfo;
  const [filter, setFilter] = useState<GeneralApplicationFilter | null>(null);
  const [alternativeFilter, setAlternativeFilter] =
    useState<AlternativeFilter | null>(null);
  const [schoolId, setSchoolId] = useState<string | null>(
    caseInfo.schoolId || null
  );
  const [yearId, setYearId] = useState<string | null>(caseInfo.yearId || null);
  const [majorId, setMajorId] = useState<string | null>(
    caseInfo.majorId || null
  );
  //const [title, setTitle] = useState<string | undefined>(caseInfo.title);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [applicationRoundId, setApplicationRoundId] = useState<string | null>(
    caseInfo?.applicationRoundId || null
  );
  const [scholarshipId, setScholarshipId] = useState<string | null>(
    caseInfo?.scholarshipId || null
  );
  const [essays, setEssays] = useState<Array<Essay>>(caseInfo.essays);
  const [legacy, setLegacy] = useState<boolean | null>(
    caseInfo?.legacy || false
  );

  useEffect(() => {
    axios("api/v1/get_general_profile_filters", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        var downloadedFilter = data.data as Array<FilterList>;
        if (downloadedFilter) {
          downloadedFilter = downloadedFilter.map((item) => {
            if (item.title === "Schools" || item.title === "Majors") {
              return {
                title: item.title,
                listStatus: addOtherOption(item.listStatus),
              };
            }
            return item;
          });
          const transformedFilter = {
            schools: convertFilterFormat(downloadedFilter, "Schools"),
            majors: convertFilterFormat(downloadedFilter, "Majors"),
            years: convertFilterFormat(downloadedFilter, "Years"),
            ethnicities: convertFilterFormat(downloadedFilter, "Ethnicities"),
            genders: convertFilterFormat(downloadedFilter, "Genders"),
            countries: convertFilterFormat(downloadedFilter, "Countries"),
            essay_topics: convertFilterFormat(downloadedFilter, "Essay Topics"),
            honor_levels: convertFilterFormat(downloadedFilter, "Honor Levels"),
            sat_subject_test_names: convertFilterFormat(
              downloadedFilter,
              "SAT Subject Test Names"
            ),
            us_states: convertFilterFormat(downloadedFilter, "US States"),
            a_level_test_names: convertFilterFormat(
              downloadedFilter,
              "A Level Test Names"
            ),
            a_level_test_scores: convertFilterFormat(
              downloadedFilter,
              "A Level Test Scores"
            ),
            college_level_test_names: convertFilterFormat(
              downloadedFilter,
              "College Level Test Names"
            ),
            college_level_test_scores: convertFilterFormat(
              downloadedFilter,
              "College Level Test Scores"
            ),
          };
          setFilter(transformedFilter);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        // TODO: add error logger
        // TODO: add banner or history.push
      });

    axios("api/v1/get_alternative_filters", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        var downloadedFilter = data.data as Array<FilterList>;
        if (downloadedFilter) {
          const transformedFilter = {
            application_rounds: convertFilterFormat(
              downloadedFilter,
              "Application Rounds"
            ),
            scholarships: convertFilterFormat(downloadedFilter, "Scholarships"),
          };
          setAlternativeFilter(transformedFilter);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        // TODO: add error logger
        // TODO: add banner
      });
  }, []);

  const handleSelectSchool = (key: string | null) => {
    setSchoolId(key);
  };

  const handleSelectYear = (key: string | null) => {
    setYearId(key);
  };

  const handleSelectMajor = (key: string | null) => {
    setMajorId(key);
  };

  const handleLegacy = (value: string) => {
    setLegacy(value === "Yes" ? true : false);
  };

  const handleNext = () => {
    if (
      !schoolId ||
      !majorId ||
      !yearId ||
      !applicationRoundId ||
      !scholarshipId
    ) {
      setMessage(
        getNotificationMessage(
          schoolId,
          majorId,
          yearId,
          applicationRoundId,
          scholarshipId,
          legacy
        )
      );
      return;
    }
    if (schoolId === "-1") {
      setMessage("School cannot be other");
      return;
    }
    if (majorId === "-1") {
      setMessage("Major cannot be other");
      return;
    }
    props.handleEnterInfo({
      schoolId,
      yearId,
      majorId,
      newlyAddedMajorName: null,
      newlyAddedSchoolName: null,
      userId: props.caseInfo.userId,
      applicationRoundId,
      scholarshipId,
      essays,
      legacy,
    });
    props.handleNext();
  };

  useEffect(() => {
    if (props.shouldProceedToNext === true && props.setShouldProceedToNext) {
      handleNext();
      props.setShouldProceedToNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToNext]);

  const callBack = () => {
    setMessage(undefined);
  };

  const radioOptions = [
    {
      key: "yes",
      value: "Yes",
    },
    {
      key: "no",
      value: "No",
    },
  ];

  const legacyQuestion = (
    <RadioOptions
      options={radioOptions}
      handleSelect={handleLegacy}
      value={legacy ? "Yes" : legacy === false ? "No" : null}
    />
  );

  return (
    <div className={classes.root}>
      <PageTitle title="Review Application Information" />
      <div className={classes.questionBlock}>
        {GetQuestionTitle("1. Which college is this application for?")}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(filter !== null ? filter?.schools : []),
          handleSelectSchool,
          schoolId,
          "Stanford University"
        )}
        {caseInfo?.newlyAddedSchoolName && (
          <div>
            <Spacing spacing={12} />
            {GetQuestionTitle("User added new school name:")}
            <Spacing spacing={8} />
            {GetQuestionTitle(caseInfo?.newlyAddedSchoolName)}
          </div>
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle(
          "2. Which year is this application for? (Class of 2019 means that you graducated from college in 2019.)"
        )}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(filter !== null ? filter?.years : []),
          handleSelectYear,
          yearId,
          "Class of 2020"
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle(
          "3. What is your prospective major as indicated in this application?"
        )}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(filter !== null ? filter?.majors : []),
          handleSelectMajor,
          majorId,
          "Psychology"
        )}
        {caseInfo?.newlyAddedMajorName && (
          <div>
            <Spacing spacing={12} />
            {GetQuestionTitle("User added new major name:")}
            <Spacing spacing={8} />
            {GetQuestionTitle(caseInfo?.newlyAddedMajorName)}
          </div>
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("4. Which round was this application for?")}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(
            alternativeFilter !== null
              ? alternativeFilter?.application_rounds
              : []
          ),
          setApplicationRoundId,
          applicationRoundId,
          "Regular Round"
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("5. Did you get any scholarship from this school?")}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(
            alternativeFilter !== null ? alternativeFilter?.scholarships : []
          ),
          setScholarshipId,
          scholarshipId,
          "$5000"
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("*6. Are you a legacy student for this college?")}
        <Spacing spacing={8} />
        {legacyQuestion}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("7. Please paste your supplements below.")}
        <Spacing spacing={8} />
        <EssaysTable
          schoolId={schoolId || undefined}
          shouldFilterBySchoolId={true}
          activities={essays}
          setActivities={setEssays}
          essayTopics={filter?.essay_topics || []}
        />
      </div>
      <SnackBar message={message} callBack={callBack} />
      <div style={{ padding: "20px 0px" }}>
        <Button
          disabled={props.disableBack}
          onClick={props.handleBack}
          className={classes.backButton}
        >
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          {props.nextButtonText}
        </Button>
      </div>
    </div>
  );
}

function getNotificationMessage(
  schoolId: string | null,
  majorId: string | null,
  yearId: string | null,
  applicationRoundId: string | null,
  scholarshipId: string | null,
  legacy: boolean | null
): string {
  var missingFields = [];
  if (!schoolId) {
    missingFields.push(" Q1");
  }
  if (!yearId) {
    missingFields.push(" Q2");
  }
  if (!majorId) {
    missingFields.push(" Q3");
  }
  if (!applicationRoundId) {
    missingFields.push(" Q4");
  }
  if (!scholarshipId) {
    missingFields.push(" Q5");
  }
  if (legacy === null) {
    missingFields.push(" Q6");
  }
  if (missingFields.length > 1) {
    return "These questions are required:" + missingFields.toString();
  } else {
    return "You need to answer" + missingFields.toString();
  }
}

/*
function getFilterList(name: string, filter:Array<ServerCategoryStatus> ): Array<Option> {
    const options = filter.find(serverCategoryStatus => 
        serverCategoryStatus.title.toLocaleLowerCase() === name)?.listStatus.map(filterItem => {
            return {
                key: filterItem.id,
                value: filterItem.name,
            }
        }) || [];
    return options;
}*/

function getFilter(items: Array<FilterLineItem>): Array<Option> {
  const options = items.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });
  return options;
}

function addOtherOption(options: Array<FilterLineItem>): Array<FilterLineItem> {
  if (!options) {
    return options;
  }
  options.splice(0, 0, { id: "-1", name: "Other" });
  return options;
}

function GetDropdownList(
  options: Array<Option>,
  handleSelect: (key: string | null) => void,
  value: string | null,
  placeholder?: string
) {
  return (
    <div style={{ minWidth: "240px", maxWidth: "320px", width: "30%" }}>
      <SearchableSelector
        options={options}
        handleSelect={handleSelect}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
}

function GetQuestionTitle(question: string) {
  //const classes = useStyles();
  return (
    <Typography
      variant="body1"
      style={{
        fontFamily: "SuperaGothic",
        fontWeight: "bold",
        padding: "0px 5px",
      }}
    >
      {question}
    </Typography>
  );
}

export default EnterInformationPage;
