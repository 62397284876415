import React, { useEffect, useState } from "react";
import Banner from "../../general/Banner";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import LogInPage from "./LogInPage";
import { useHistory } from "react-router";
import axios from "axios";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/browser";

const queryString = require("query-string");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "flex-start",
      overflow: "hidden",
    },
    paperEdge: {
      width: "10px",
      backgroundColor: theme.palette.primary.main,
      //marginRight: '20px',
    },
    titleFlex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      //borderBottom: '1px solid #e5e5e5',
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: "2rem",
      marginRight: "10px",
    },
    container: {
      padding: "40px 20px",
      paddingBottom: "5px",
      backgroundColor: theme.palette.primary.main,
      //marginTop: '40px',
    },
    buttonStyle: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      marginTop: "40px",
      marginBottom: "10vw",
    },
    darkBackground: {
      backgroundColor: theme.palette.primary.main,
    },
  })
);

function EmailConfirmationPage() {
  const classes = useStyles();
  const history = useHistory();
  const [hasConfirmed, setHasConfirmed] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    const parsed = queryString.parse(history.location.search);
    const token = parsed["confirmation_token"];
    if (!token) {
      return;
    }
    axios(`users/confirmations`, {
      method: "POST",
      withCredentials: true,
      data: {
        confirmation_token: token,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        setHasConfirmed(true);
      })
      .catch((error) => {
        // TODO: add banner to indicate failure
        setHasConfirmed(false);
        // TODO: add error logger
        Sentry.captureException(error);
      });

    /*fetch(`http://localhost:3001/users/confirmation?confirmation_token=${token}`, {
            method: 'GET',
            credentials: 'include',
        })
        .then(response => {
            if (response.status === 200) {
                setHasConfirmed(true)
            } else {
                setHasConfirmed(false);
            }
        })
        .catch(error => {
            setHasConfirmed(false);
        });*/
  }, [history.location.search]);

  const parsed = queryString.parse(history.location.search);
  if (!parsed["confirmation_token"]) {
    return (
      <div
        className={`page-container ${classes.darkBackground}`}
        style={{ paddingBottom: "30%" }}
      >
        <Helmet>
          <title>Failed to confirm your email - CollegeSharing</title>
        </Helmet>
        <div className={classes.container}>
          <Banner
            title="Failed to confirm your email"
            subtitle="Sorry, we have experienced some technical issues."
            severity="failed"
          />
        </div>
      </div>
    );
  }

  if (hasConfirmed === undefined) {
    return null;
  }

  if (hasConfirmed) {
    return (
      <div className={`page-container ${classes.darkBackground}`}>
        <Helmet>
          <title>Email successfully confirmed - CollegeSharing</title>
        </Helmet>
        <div className={classes.container}>
          <Banner
            title="Successfully confirmed your email"
            subtitle="Your email has been successfully confirmed. Please log in."
          />
        </div>
        <LogInPage />
      </div>
    );
  } else {
    return (
      <div className={`page-container ${classes.darkBackground}`}>
        <Helmet>
          <title>Failed to confirm your email - CollegeSharing</title>
        </Helmet>
        <div className={classes.container}>
          <Banner
            title="Email was already confirmed"
            subtitle="It seems that your email has already been confirmed. Please log in."
            severity="failed"
          />
        </div>
        <LogInPage />
      </div>
    );
  }
}

export default EmailConfirmationPage;
