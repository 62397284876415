import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { OrderByField } from "../../types/serverSideTypes";
import { ReactComponent as SortByIcon } from "../../resources/icons/sort_by.svg";
import ExpandedAccordion from "./SelectAccordion";
import { useMediaQuery } from "@material-ui/core";
import theme from "../../themes/Theme";
import { ReactComponent as FilterByIcon } from "../../resources/icons/filter_by.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputSelector: {
      marginRight: 20,
    },
    option: {
      fontFamily: "RedHatDisplay-Medium",
      fontSize: 18,
      backgroundColor: "white",
      color: "#0E7F8A",
      paddingTop: 12,
      marginBottom: 13,
    },
    gradient: {
      height: 1,
      width: "100%",
      top: 0,
      background:
        "linear-gradient(90deg, rgba(56, 185, 194, 0) 0%, rgba(56, 185, 194, 0.5) 48.96%, rgba(255, 255, 255, 0) 100%, rgba(56, 185, 194, 0) 100%)",
    },
    container: {
      justifyContent: "space-between",
    },
  })
);

interface Option {
  name: string;
  type: OrderByField;
}

interface Props {
  defaultValue: OrderByField;
  options: Array<Option>;
  handleSelect: (value: unknown) => void;
  openFilter?: () => void;
}

export default function CustomizedSelects(props: Props) {
  const classes = useStyles();
  const [value, setValue] = useState<Option>(
    props.options.find((option) => option.type === props.defaultValue)
  );
  const handleApply = (option: Option) => {
    setValue(option);
    props.handleSelect(option.type);
  };
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div
      style={{
        //position: "absolute",
        width: "100%",
        height: 48,
        top: 125,
        zIndex: 50,
        marginTop: 30,
        display: "flex",
        alignItems: "flex-start",
      }}
      className={classes.container}
    >
      {!smallScreen && (
        <div
          style={{
            marginLeft: 20,
            fontFamily: "RedHatDisplay-SemiBold",
            fontSize: 20,
            lineHeight: "48px",
          }}
        >
          Profile Search
        </div>
      )}
      {smallScreen && props.openFilter && (
        <div
          onClick={props.openFilter}
          style={{
            marginLeft: "20px",
            fontFamily: "RedHatDisplay-SemiBold",
            fontSize: 20,
            lineHeight: "48px",
            marginTop: "auto",
            marginBottom: "auto",
            display: "flex",
            alignItems: "center",
            color: theme.palette.primary.main,
            cursor: "pointer",
          }}
        >
          <FilterByIcon style={{ marginRight: 5 }} />
          Search
        </div>
      )}
      <div
        style={{ display: "flex", alignItems: "flex-start" }}
        className={classes.inputSelector}
      >
        {!smallScreen && <SortByIcon style={{ height: 48, marginRight: 10 }} />}
        {!smallScreen && (
          <div
            style={{
              fontSize: 16,
              lineHeight: "48px",
              fontFamily: "RedHatDisplay-Medium",
              marginRight: 12,
            }}
          >
            Sort By:
          </div>
        )}
        <div>
          <ExpandedAccordion
            name={value.name}
            expandedByDefault={false}
            handleClick={(option) => handleApply(option)}
            options={props.options.filter(
              (option) => option.type !== value.type
            )}
          />
        </div>
      </div>
    </div>
  );
}
