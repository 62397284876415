import React, { useEffect, useState, useReducer } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SideFilter from "../general/SideFilter";
import {
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import TextButton from "../general/TextButton";
import { CheckBoxItem, SearchPageFilter } from "../../types/types";
import SideFilterBar from "../general/SideFilterBar";
import { Props as SideFilterProps } from "../general/SideFilter";
import {
  ServerCategoryStatus,
  OrderByField,
} from "../../types/serverSideTypes";
import { CategoryStatus } from "../../types/types";
import { orderByMap } from "../../types/serverSideTypes";

import {
  LOAD_INITIAL_FILTER,
  UPDATE_ORDER_BY,
  UPDATE_PAGE_NUMBER,
  UPDATE_FILTER,
  CLEAR_ALL_FILTERS,
  UPDATE_FILTERS,
} from "../../types/actions";
import searchPageFilterReducer from "../../reducers/searchPageFilter";
import Pagination from "@material-ui/lab/Pagination";
import InputSelector from "../general/OrderBySelector";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/browser";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GeneralProfilePreview } from "../../types/admitProfileTypes";
import AdmitProfileCard from "../general/AdmitProfileCard";
import CloseIcon from "@material-ui/icons/Close";
import theme from "../../themes/Theme";
import { TransitionProps } from "@material-ui/core/transitions";

const queryString = require("query-string");

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clearAllText: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "30px",
      },
      fontFamily: "RedHatDisplay-Bold",
      whiteSpace: "nowrap",
      textDecoration: "underline",
    },
    sideFilterBar: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
    },
    sideFilterContainer: {
      flexGrow: 1,
      width: 280,
      minHeight: "100vh",
      padding: "37px 0px",
      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.12)",
      backgroundColor: "white",
    },
    upperBorderRadius: {
      //borderRadius: "20px 20px 0px 0px",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    paginationContainer: {
      textAlign: "center",
      width: "100%",
    },
    pagination: {
      fontFamily: "SuperaGothic",
      fontSize: "16px",
      display: "inline-block",
      paddingTop: "30px",
      paddingBottom: "60px",
    },

    dialogContainer: {
      width: "100%",
      //borderRadius: "20px 20px 0px 0px",
      maxWidth: 960,
      margin: "auto",
    },
    dialogWidth: {
      minHeight: "calc(100% - 80px)",
      margin: "40px 15px",
      //borderRadius: "20px 20px 0px 0px",
      width: "100%",
      maxWidth: 960,
    },
    flexContainer: {
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
    },
    savedCasesTitle: {
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
    filterContainer: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
      [theme.breakpoints.only("md")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
      display: "flex",
      zIndex: 60,
      backgroundColor: "white",
      borderBottom: "1px solid #e5e5e5",
      overflow: "scroll",
      position: "sticky",
      width: "100%",
      left: "0",
      top: "65px",
    },
  })
);

interface SearchPageProps {
  onlyShowSaveCases?: boolean;
}

function SearchPage(props: SearchPageProps) {
  let location = useLocation();
  let history = useHistory();
  const styles = useStyles();
  const [filter, dispatch] = useReducer(searchPageFilterReducer, {
    pageNumber: 1,
    categoryStatuses: [],
    orderBy: OrderByField.MOST_VIEWED,
  });
  const [categoryStatuses, setCategoryStatuses] =
    useState<Array<ServerCategoryStatus> | null>(null);
  const [pageContent, setPageContent] = useState<Array<GeneralProfilePreview>>(
    []
  );
  const [totalPageNumbers, setTotalPageNumbers] = useState(1);
  const [, setTotalResultsCount] = useState<number | null>(null);
  const [isLoadingCases, setIsLoadingCases] = useState<boolean>(true);
  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(true);
  const [shouldReload, setShouldReload] = useState<boolean>(true);
  const [openPdf, setOpenPdf] = useState<boolean>(false);

  const smallScreen = useMediaQuery("(max-width:900px)");
  const middleScreen = useMediaQuery("(min-width:1200px)");

  const largeScreen = useMediaQuery("(min-width:1450px)");
  const xLargeScreen = useMediaQuery("(min-width:2020px)");

  const hideFilter = useMediaQuery(theme.breakpoints.down("xs"));

  const callBack = (searchPageFilter: SearchPageFilter) => {
    setShouldReload(false);
    const locationSearch = convertFilterToLocationSearch(searchPageFilter);
    history.replace({
      pathname: props.onlyShowSaveCases ? "/saved_profiles" : "/profile_search",
      search: locationSearch,
    });
  };

  const handleClear = () => {
    dispatch({ type: CLEAR_ALL_FILTERS, callBack: callBack });
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch({ type: UPDATE_PAGE_NUMBER, pageNumber: value, callBack });
  };

  const handleApply = (title: string, newList: Array<CheckBoxItem>) => {
    dispatch({
      type: UPDATE_FILTER,
      name: title,
      newFilter: newList,
      callBack,
    });
  };

  const handleApplyNewFilter = (
    updatedFilter: Map<string, Array<CheckBoxItem>>
  ) => {
    dispatch({
      type: UPDATE_FILTERS,
      updatedFilter: updatedFilter,
      callBack,
    });
  };
  const handleSelect = (value: unknown) => {
    dispatch({
      type: UPDATE_ORDER_BY,
      orderBy: value as OrderByField,
      callBack,
    });
  };

  useEffect(() => {
    /*if (filter.categoryStatuses.length !== 0) {
            return;
        }*/
    const parsed = queryString.parse(location.search);

    if (!shouldReload) {
      setShouldReload(true);
      return;
    }

    if (categoryStatuses) {
      const urlFilter = Object.keys(parsed).map((name) => {
        return {
          name,
          value: parsed[name].split("|"),
        };
      });
      let pageNumber: number = parsed["pageNumber"];
      pageNumber = pageNumber && !isNaN(pageNumber) ? Number(pageNumber) : 1;

      let orderBy = parsed["orderBy"]
        ? parsed["orderBy"]
        : OrderByField.MOST_VIEWED;
      if (!(orderBy in OrderByField)) {
        orderBy = OrderByField.MOST_VIEWED;
      }

      dispatch({
        type: LOAD_INITIAL_FILTER,
        initialFilter: categoryStatuses,
        urlFilter: { selected: urlFilter, pageNumber, orderBy },
      });
      setIsLoadingFilter(false);
    } else {
      axios("api/v1/filters", {
        method: "GET",
        withCredentials: true,
      })
        .then((response) => response.data)
        .then((data) => {
          const dataFilter = data.data as Array<ServerCategoryStatus>;
          const urlFilter = Object.keys(parsed).map((name) => {
            return {
              name,
              value: parsed[name].split("|"),
            };
          });
          let pageNumber: number = parsed["pageNumber"];
          pageNumber =
            pageNumber && !isNaN(pageNumber) ? Number(pageNumber) : 1;

          let orderBy = parsed["orderBy"]
            ? parsed["orderBy"]
            : OrderByField.MOST_VIEWED;
          if (!(orderBy in OrderByField)) {
            orderBy = OrderByField.MOST_VIEWED;
          }
          setCategoryStatuses(dataFilter);
          setIsLoadingFilter(false);
          dispatch({
            type: LOAD_INITIAL_FILTER,
            initialFilter: dataFilter,
            urlFilter: { selected: urlFilter, pageNumber, orderBy },
          });
        })
        .catch((error) => {
          setIsLoadingFilter(false);
          Sentry.captureException(error);
          // TODO: add log error
          // TODO: add banner
        });
    }
  }, [location.search, categoryStatuses, shouldReload]); //initial load filter

  useEffect(() => {
    if (!categoryStatuses || categoryStatuses.length === 0 || isLoadingFilter) {
      return;
    }
    setIsLoadingCases(true);
    setPageContent([]);
    axios("api/v1/get_filtered_profiles", {
      method: "POST",
      withCredentials: true,
      data: {
        saved: props.onlyShowSaveCases,
        order_by: filter.orderBy,
        page: {
          offset: filter.pageNumber - 1,
          size: 24,
        },
        filters: filter.categoryStatuses.map((categoryStatus) => {
          return {
            title: categoryStatus.title,
            ids: categoryStatus.listStatus
              .filter((item) => item.selected)
              .map((item) => item.filterItem.id),
          };
        }),
      },
    })
      .then((response) => {
        const data = response.data.data;
        setPageContent(
          data.profiles.map((profile) => {
            return { ...profile.profile, saved: profile.metadata.saved };
          })
        );
        setTotalPageNumbers(data.totalPageNumbers);
        setTotalResultsCount(data.totalResultsCount);
        setIsLoadingCases(false);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        Sentry.captureException(error, { extra: { filter: filter } });
        setIsLoadingCases(false);
        // TODO: add log error
        // TODO: add banner
      });
  }, [filter, props.onlyShowSaveCases, categoryStatuses, isLoadingFilter]);

  if (isLoadingFilter) {
    return (
      <div className={`page-container ${styles.paginationContainer}`}>
        <Helmet>
          <title>
            {props.onlyShowSaveCases
              ? "Your saved applications - CollegeSharing"
              : "Browse real accepted college applications - CollegeSharing"}
          </title>
        </Helmet>
        <CircularProgress
          style={{ marginTop: "100px", marginBottom: "50px" }}
        />
      </div>
    );
  }

  const multipleChoices = (
    filter.categoryStatuses.length !== 0
      ? filter.categoryStatuses
      : defaultCategoryStatuses
  ).map((categoryStatus) => {
    return (
      <SideFilter
        title={categoryStatus.title}
        key={categoryStatus.title}
        list={categoryStatus.listStatus}
        updateSearchFilter={handleApply}
      />
    );
  });
  const inputSelector = (
    <div>
      <InputSelector
        defaultValue={filter.orderBy}
        options={orderByMap}
        handleSelect={handleSelect}
        openFilter={() => setOpenPdf(true)}
      />
    </div>
  );

  /*if (!filter || filter.categoryStatuses.length === 0) {
        return <div></div>;
    }*/

  let content = null;
  if (pageContent != null && pageContent.length > 0) {
    const cards = pageContent.map((card) => {
      return (
        <Grid
          key={card.id}
          item
          xs={12}
          sm={smallScreen ? 6 : 4}
          md={middleScreen ? 3 : 4}
          lg={largeScreen ? 3 : 4}
          xl={xLargeScreen ? 2 : 3}
        >
          <div style={{ padding: "10px", height: "100%" }}>
            <AdmitProfileCard profile={card} shouldShowAction />
          </div>
        </Grid>
      );
    });

    content = (
      <div className={styles.paginationContainer}>
        {props.onlyShowSaveCases === true && (
          <div
            style={{ marginTop: "1%", fontSize: 24, fontWeight: "bold" }}
            className={styles.savedCasesTitle}
          >
            Saved Applications
          </div>
        )}

        {inputSelector}
        <div style={{ margin: "0px 10px", marginTop: 20 }}>
          <Grid container spacing={0} alignItems={"stretch"}>
            {cards}
          </Grid>
        </div>
        <Pagination
          className={styles.pagination}
          count={totalPageNumbers}
          page={filter.pageNumber}
          onChange={handlePaginationChange}
          color="primary"
        />
      </div>
    );
  } else {
    content = (
      <div className={styles.paginationContainer}>
        {props.onlyShowSaveCases === true && (
          <div
            style={{ marginTop: "1%", fontSize: 24, fontWeight: "bold" }}
            className={styles.savedCasesTitle}
          >
            Saved Applications
          </div>
        )}
        {inputSelector}
        <div
          style={{
            //margin: "0px 10px",
            paddingTop: "5vh",
            paddingBottom: "10vh",
          }}
        >
          {!isLoadingCases && (
            <Typography
              style={{ fontSize: "1.5rem", fontFamily: "RedHatDisplay" }}
            >
              No matching results.
            </Typography>
          )}
          {isLoadingCases && (
            <CircularProgress
              style={{ marginTop: "100px", marginBottom: "50px" }}
            />
          )}
        </div>
      </div>
    );
  }

  const sideBarFilter: Array<SideFilterProps> = (
    filter.categoryStatuses.length !== 0
      ? filter.categoryStatuses
      : defaultCategoryStatuses
  ).map((categoryStatus) => {
    return { title: categoryStatus.title, list: categoryStatus.listStatus };
  });

  const popoverFilter = hideFilter && (
    <Dialog
      scroll="body"
      classes={{
        container: styles.dialogContainer,
        paper: styles.dialogWidth,
      }}
      style={{
        zIndex: 2000,
        display: "block",
        margin: "auto",
      }}
      open={openPdf}
      fullScreen={true}
      TransitionComponent={SlideTransition}
    >
      <div style={{}}>
        <DialogTitle
          className={styles.flexColumn}
          classes={{ root: styles.upperBorderRadius }}
          style={{
            position: "sticky",
            top: 0,
            width: "100%",
            backgroundColor: "white",
            zIndex: 300,

            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          <CloseIcon
            onClick={() => setOpenPdf(false)}
            style={{ cursor: "pointer" }}
          />
        </DialogTitle>

        <div style={{}}>
          <SideFilterBar
            sideFilters={sideBarFilter}
            clearAll={handleClear}
            handleApply={handleApplyNewFilter}
            callBack={() => setOpenPdf(false)}
          />
        </div>
      </div>
    </Dialog>
  );

  return (
    <div className={`page-container ${styles.paginationContainer}`}>
      <Helmet>
        <title>
          {props.onlyShowSaveCases
            ? "Your saved applications - CollegeSharing"
            : "Browse real accepted college applications - CollegeSharing"}
        </title>
      </Helmet>
      {popoverFilter}
      <div className={styles.flexContainer} style={{ alignItems: "stretch" }}>
        <div
          className={styles.sideFilterBar}
          style={{ flexShrink: 0, flexDirection: "column" }}
        >
          <div className={styles.sideFilterContainer}>
            <SideFilterBar
              sideFilters={sideBarFilter}
              clearAll={handleClear}
              handleApply={handleApplyNewFilter}
            />
          </div>
        </div>
        <div style={{ flexGrow: 1 }}>
          <div className={styles.filterContainer}>
            {multipleChoices}

            {
              <TextButton disableTouchRipple onClick={handleClear}>
                <Typography variant="subtitle1" className={styles.clearAllText}>
                  Clear All
                </Typography>
              </TextButton>
            }
          </div>
          {content}
        </div>
      </div>
    </div>
  );
}

function convertFilterToLocationSearch(
  searchPageFilter: SearchPageFilter
): string {
  let result: { [key: string]: string } = {};
  for (let categoryStatus of searchPageFilter.categoryStatuses) {
    const list = categoryStatus.listStatus
      .filter((checkboxItem) => checkboxItem.selected)
      .map((checkboxItem) => checkboxItem.filterItem.id);
    if (list.length === 0) {
      continue;
    }
    const value = list.join("|");
    result[categoryStatus.title] = value;
  }
  result["pageNumber"] = String(searchPageFilter.pageNumber);
  result["orderBy"] = String(searchPageFilter.orderBy);
  return queryString.stringify(result);
}

const defaultCategoryStatuses: Array<CategoryStatus> = [
  {
    title: "Schools",
    listStatus: [],
  },
  {
    title: "Majors",
    listStatus: [],
  },
  {
    title: "Categories",
    listStatus: [],
  },
  {
    title: "Years",
    listStatus: [],
  },
  {
    title: "Rankings",
    listStatus: [],
  },
];

export default SearchPage;
