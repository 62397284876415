import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PrimaryButton from "../general/PrimaryButton";
import CategorySection from "../landing/CategorySection";
import VisionSection from "../landing/VisionSection";
import PreviewSection from "../landing/PreviewSection";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";
import LandingImage from "../../resources/landing.svg";
import FullLogo from "../../resources/fullLogo.svg";
import VerificationSection from "../landing/VerifiedSection";
import CallToActionSection from "../landing/CallToActionSection";
import AboutUsSection from "../landing/AboutUsSection";
import RealitySection from "../landing/TheRealitySeciton";
import WeNeedYourHelpSection from "../landing/WeNeedYourHelpSection";
import { User } from "../../types/types";
import { useStore } from "react-redux";
import SearchPage from "../pages/AdmitProfileSearchPage";
import Spacing from "../general/Spacing";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    landingSection: {
      fontFamily: "SuperaGothic",
      textTransform: "none",
      width: "100%",
      justifyContent: "center",
      minWidth: "320px",
      backgroundColor: "#D3E8EB",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        display: "block",
        //backgroundColor: "rgba(76, 175, 80, 0.0)",
      },
      [theme.breakpoints.up("md")]: {
        backgroundColor: "#D3E8EB",
        height: "550px",
        display: "flex",
      },
    },
    imageWrapper: {
      [theme.breakpoints.down("lg")]: {
        height: "100%",
        width: "100%",
      },
      [theme.breakpoints.up("lg")]: {
        height: "100%",
        width: "100%",
      },
    },
    titleSection: {
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      fontWeight: "bold",
    },
    image: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "auto",
      maxHeight: "100%",
    },
    landingImage: {
      height: "100%",
      marginLeft: "50px",
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
    },
    landingTextSection: {
      backgroundColor: "#D3E8EB",
      [theme.breakpoints.down("sm")]: {
        padding: "10%",
        paddingBottom: "10%",
      },
      [theme.breakpoints.only("md")]: {
        //paddingTop: '80px',
        paddingLeft: "10%",
      },
      [theme.breakpoints.up("lg")]: {
        //paddingTop: '100px',
        paddingLeft: "10%",
      },
      margin: "auto 0",
      paddingRight: "0vw",
      paddingBottom: "3vw",
      textAlign: "left",
    },
    descriptionTextSection: {
      marginTop: "20px",
      display: "block",
    },
    startBrowsingButton: {
      backgroundColor: "#0A5961",
    },
    categorySection: {
      width: "100%",
      display: "flex",
    },
    imageHeight: {
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        //height:'auto'
      },
      [theme.breakpoints.up("md")]: {
        height: "100%",
      },
    },
    conditionalLogo: {
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    spacingAcrossSection: {
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    wrapper: {
      height: "100%",
      paddingLeft: "25px",
      paddingRight: "25px",
      flexShrink: 0,
      backgroundColor: "#D3E8EB",
      maxWidth: 1000,
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        width: "80%",
        maxWidth: 550,
        margin: "auto",
        //backgroundColor: "rgba(76, 175, 80, 0.0)",
        display: "block",
        //position:"relative",
        //bottom:"18vw",
        paddingBottom: "10vw",
        //marginBottom:'-14vw',
        //left:"150px",
      },
      [theme.breakpoints.between("md", "lg")]: {
        display: "block",
        width: "45%",
        minWidth: "400",
        margin: "auto",
        bottom: "auto",
        marginRight: "20px",
      },
      [theme.breakpoints.up("lg")]: {
        display: "block",
        width: "45%",
        minWidth: "400px",
        maxWidth: "620px",
        marginRight: "20px",
      },
    },
  })
);

function GuestLandingPage() {
  const styles = useStyles();
  const buttonStyle = {
    color: "white",
    margin: "auto",
    //marginBottom: '10vw',
  };

  const store = useStore();
  const user: User = store.getState().user;
  if (user) {
    return <SearchPage />;
  }

  return (
    <div>
      <Helmet>
        <title>
          Browse real and accepted college applications - CollegeSharing
        </title>
      </Helmet>
      <div className={`${styles.landingSection}`}>
        <div className={`${styles.landingTextSection}`}>
          <img
            alt=""
            className={styles.conditionalLogo}
            style={{ marginBottom: 30, width: 200 }}
            src={FullLogo}
          ></img>
          <Typography variant="h3" className={`${styles.titleSection}`}>
            Get Accepted Into Your Dream College.
          </Typography>
          <Typography
            variant="body1"
            className={`${styles.descriptionTextSection}`}
          >
            Read thousands of accepted college applications, essays, test
            results and extracurriculars from students who fought the good fight
            for you.
          </Typography>
          <Typography
            variant="body1"
            className={`${styles.descriptionTextSection}`}
          >
            And find out exactly what your dream school’s dream applicant looks
            like.
          </Typography>
          <div
            style={{ margin: "auto", textAlign: "center", marginTop: "40px" }}
          >
            <PrimaryButton
              style={buttonStyle}
              link="/search"
              label="header_section.start_browsing"
            >
              <Typography variant="h5">Start Browsing</Typography>
            </PrimaryButton>
          </div>
        </div>
        <div className={styles.wrapper}>
          <img
            alt=""
            className={styles.imageHeight}
            style={{ margin: "auto" }}
            src={LandingImage}
          />
        </div>
      </div>
      <CategorySection />
      <VisionSection />
      <PreviewSection />
      <div className={styles.spacingAcrossSection}>
        <Spacing spacing={50} />
      </div>
      <AboutUsSection />
      <div className={styles.spacingAcrossSection}>
        <Spacing spacing={30} />
      </div>
      <RealitySection />
      <div className={styles.spacingAcrossSection}>
        <Spacing spacing={30} />
      </div>
      <WeNeedYourHelpSection />
      <div className={styles.spacingAcrossSection}>
        <Spacing spacing={50} />
      </div>
      <VerificationSection />
      <CallToActionSection />
    </div>
  );
}

export default GuestLandingPage;
