import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import RoundButton from "./RoundButton";
import { User } from "../../types/types";
import { useHistory } from "react-router";
import { useStore } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      fontFamily: "SuperaGothic",
      fontSize: "16px",
      width: "100%",
      "&:focus": {
        backgroundColor: theme.palette.primary,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.common.black,
        },
      },
    },
    paper: {
      zIndex: 1300,
    },
    backdrop: {
      zIndex: 1500,
      color: "#fff",
    },
    listItemText: {
      fontFamily: "SuperaGothic",
      //marginRight: '30px',
      //marginLeft: '20px',
      fontSize: "18px",
    },
    menu: {
      border: "1px solid #d3d4d5",
      zIndex: 1300,
      width: 200,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },
  })
);

function BottomMenu(props: MenuProps) {
  const classes = useStyles();
  return (
    <Menu
      classes={{
        paper: classes.menu,
      }}
      elevation={3}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 58.5,
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  );
}

interface Props {
  user: User;
  setOpenBackdrop: (open: boolean) => void;
}

export default function CustomizedMenus(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const store = useStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

  const logOut = () => {
    props.setOpenBackdrop(true);
    setAnchorEl(null);
    axios("api/v1/logout", {
      method: "POST",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        props.setOpenBackdrop(false);
        store.dispatch({ type: "UPDATE_USER_ACCTION", user: null });
        history.push({ pathname: "/" });
      })
      .catch((error) => {
        props.setOpenBackdrop(false);
        store.dispatch({ type: "UPDATE_USER_ACCTION", user: null });
        history.push({ pathname: "/" });
        Sentry.captureException(error);
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <RoundButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        {props.user &&
          props.user.userName &&
          props.user.userName.substring(0, 1).toUpperCase()}
      </RoundButton>
      <BottomMenu
        style={{ zIndex: 1300 }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to="/account" style={{ width: "100%" }}>
          <MenuItem classes={{ root: classes.menuItem }} onClick={handleClose}>
            <ListItemText
              primary="Account"
              classes={{ primary: classes.listItemText }}
            />
          </MenuItem>
        </Link>
        <Link to="/saved_cases" style={{ width: "100%" }}>
          <MenuItem classes={{ root: classes.menuItem }} onClick={handleClose}>
            <ListItemText
              primary="Saved"
              classes={{ primary: classes.listItemText }}
            />
          </MenuItem>
        </Link>
        <Divider />
        <MenuItem classes={{ root: classes.menuItem }} onClick={logOut}>
          <ListItemText
            primary="Log out"
            classes={{ primary: classes.listItemText }}
          />
        </MenuItem>
      </BottomMenu>
    </div>
  );
}
