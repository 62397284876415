import { User } from "../types/types";
export interface UpdateUserAction {
  type: "UPDATE_USER_ACCTION";
  user: User | null;
}
const isLoggedInReducer = (
  state: User | null = null,
  action: UpdateUserAction
) => {
  switch (action.type) {
    case "UPDATE_USER_ACCTION":
      return action.user;
    default:
      return state;
  }
};

export default isLoggedInReducer;
