import React, { useState } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: "0px 15px",
    },
    formLabelControl: {
      fontSize: 16,
    },
    checkBox: {
      color: theme.palette.primary.main,
    },
  })
);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export interface Props {
  open: boolean;
  checked: boolean;
  ageChecked: boolean;
  handleClose: () => void;
  handleConfirm: (checked: boolean, ageChecked: boolean) => void;
}

export default function CustomizedDialogs(props: Props) {
  const [checked, setChecked] = useState<boolean>(props.checked);
  const [ageChecked, setAgeChecked] = useState<boolean>(props.ageChecked);

  const handleClose = () => {
    setChecked(props.checked);
    props.handleClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgeChecked(event.target.checked);
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => handleClose()}>
          <Typography
            variant="h5"
            style={{ fontFamily: "SuperaGothic-ExtraBold" }}
          >
            User Agreement and Privacy Policy
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontWeight: "bold", textAlign: "center" }}
          >
            Privacy Policy
          </Typography>
          <PrivacyPolicy fontSize={1} />
          <Divider style={{ marginTop: 50, marginBottom: 50 }} />
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontWeight: "bold", textAlign: "center" }}
          >
            Terms of Use
          </Typography>
          <TermsOfUse fontSize={1} />
        </DialogContent>
        <div style={{ padding: 16 }}>
          <FormControl component="fieldset" style={{ margin: 0 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    style={{ padding: 4 }}
                    name="gilad"
                    color="primary"
                  />
                }
                label="I agree to the user agreement and privacy policy."
                classes={{ label: classes.formLabelControl }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ageChecked}
                    onChange={handleAgeChange}
                    style={{ padding: 4 }}
                    name="gilad"
                    color="primary"
                  />
                }
                label="I confirm that I am 18 years of age or older."
                classes={{ label: classes.formLabelControl }}
              />
            </FormGroup>
          </FormControl>
        </div>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => props.handleConfirm(checked, ageChecked)}
            color="primary"
            style={{ fontFamily: "SuperaGothic", fontWeight: "bold" }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
