import React, { useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Grid from "@material-ui/core/Grid";
import { Product, Subscription, Invoice } from "../../types/types";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import { Typography } from "@material-ui/core";
//import Tabs from '@material-ui/core/Tabs';
import Button from "@material-ui/core/Button";
//import {PaymentMethod} from '../../types/types';
//import Radio from '@material-ui/core/Radio';
//import Tab from '@material-ui/core/Tab';
import { useHistory } from "react-router";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStore } from "react-redux";
import CountryCodes from "../../helpers/CountryCode";

const fallBackErrorMessage =
  "We encountered some issues processing your payment.";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentForm: {
      display: "flex",
      justifyContent: "center",
    },
    card: {
      width: "100%",
      margin: "0.5rem",
      //display: 'inline-block',
      padding: "2rem",
    },
    rowContainer: {
      display: "flex",
      flexWrap: "wrap",
      //marginLeft: '-0.75rem',
      //marginRight: '-0.75rem',
      marginBottom: "0.5rem",
    },
    innerRowContainer: {
      width: "100%",
      //paddingLeft: '0.75rem',
      //paddingRight: '0.75rem',
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "0.75rem",
      color: "#4a5568",
      letterSpacing: "0.025em",
      marginBottom: "0.5rem",
    },
    customizedTextField: {
      appearance: "none",
      fontFamily: "SuperaGothic",
      width: "100%",
      backgroundColor: "#edf2f7",
      borderWidth: "1px",
      borderRadius: "0.375rem",
      color: "#4a5568",
      padding: "0.75rem 0.5rem",
      marginBottom: "0.75rem",
      lineHeight: "1.25",
    },
    generalButton: {
      color: "white",
      fontSize: "20px",
      fontFamily: "SuperaGothic",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
      "&:disabled": {
        backgroundColor: "#BFBFBF",
        color: "white",
      },
      marginTop: 20,
      textTransform: "none",
      alignItems: "center",
      width: "100%",
    },
    radio: {
      top: 0,
      paddingRight: "15px",
      paddingLeft: "15px",
    },
    radioRoot: {
      paddingRight: "15px",
      paddingLeft: "5px",
      color: theme.palette.secondary.dark,
      "&$checked": {
        color: "#4bc6bc",
      },
    },
    checked: {},
    tabContainer: {
      paddingTop: "20px",
    },
    text: {
      fontFamily: "SuperaGothic",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    paymentMethodRow: {
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        display: "block",
        marginBottom: "5px",
        marginTop: "5px",
      },
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    titleSection: {
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
    smallText: {
      fontFamily: "SuperaGothic",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
      },
    },
  })
);

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
var stripePromise: Promise<Stripe | null> = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
  { locale: "en" }
);
/*if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  console.error('**Stripe publishable key environment variable not set**');
  console.error(
    '**Add an environemnt variable REACT_APP_STRIPE_PUBLISHABLE_KEY**'
  );
  console.error('**Replace .env.example with .env and **');
} else {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
}*/

export interface Props {
  productSelected: Product;
}

interface Result {
  subscription: Subscription | SubscriptionId;
  invoice?: Invoice;
}

interface SubscriptionId {
  id: string | undefined;
}

/*interface NewPaymentMethodTabProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}*/

interface CardComponentProps {
  handleSubmit: (event: any, address?: Address) => void;
  errorToDisplay?: string;
  disabled: boolean;
  subscribing: boolean;
}

/*interface PaymentMethodProps {
    handleSubmit: (event: any, address?: Address, paymentMethodId?: string) => void,
    paymentMethods: Array<PaymentMethod>,
    subscribing: boolean,
    errorToDisplay?: string,
}*/

interface LocationState {
  from?: Location;
}

interface Address {
  city?: string;
  line1?: string;
  line2?: string;
  state?: string;
  postal_code?: string;
  country: string;
  name: string;
}

function CardComponent(props: CardComponentProps) {
  const classes = useStyles();
  const { handleSubmit, disabled, subscribing, errorToDisplay } = props;
  const [line1, setLine1] = useState<string | undefined>(undefined);
  const [line2, setLine2] = useState<string | undefined>(undefined);
  const [city, setCity] = useState<string | undefined>(undefined);
  const [country, setCountry] = useState<string | undefined>(undefined);
  const [cardState, setCardState] = useState<string | undefined>(undefined);
  const [zipCode, setZipCode] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);

  const allAddressFieldsFilled =
    country &&
    name &&
    ((country.toUpperCase() !== "US" &&
      country.toUpperCase() !== "UNITED STATES") ||
      (cardState && zipCode));

  const submitForm = () => {
    if (
      !name ||
      !country ||
      (country.toUpperCase() !== "US" &&
        country.toUpperCase() !== "UNITED STATES" &&
        (!cardState || !zipCode))
    ) {
      return;
    }
    const address: Address = {
      city,
      line1,
      line2,
      country,
      postal_code: zipCode,
      state: cardState,
      name,
    };
    handleSubmit(undefined, address);
  };

  return (
    <div>
      <form id="payment-form" onSubmit={submitForm}>
        <div className={classes.rowContainer}>
          <div className={classes.innerRowContainer}>
            <Typography variant="body1" className={classes.title}>
              Full Name
            </Typography>
            <input
              className={classes.customizedTextField}
              type="text"
              placeholder="First and last name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.innerRowContainer}>
            <Typography variant="body1" className={classes.title}>
              Card
            </Typography>
            <div
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
              id="card-element"
              style={{ marginBottom: "0.75rem" }}
            >
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#32325d",
                      fontFamily: "Red Hat Display",
                      "::placeholder": {
                        color: "#a0aec0",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.innerRowContainer}>
            <Typography variant="body1" className={classes.title}>
              Address
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <input
                  className={classes.customizedTextField}
                  type="text"
                  placeholder="Address line 1"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLine1(event.target.value);
                  }}
                  style={{ marginBottom: 0 }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <input
                  className={classes.customizedTextField}
                  type="text"
                  placeholder="Address line 2"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLine2(event.target.value);
                  }}
                  style={{ marginBottom: 0 }}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <input
                  className={classes.customizedTextField}
                  type="text"
                  placeholder="City"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCity(event.target.value);
                  }}
                  style={{ marginBottom: 0 }}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <input
                  className={classes.customizedTextField}
                  type="text"
                  placeholder="State"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCardState(event.target.value);
                  }}
                  style={{ marginBottom: 0 }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <input
                  className={classes.customizedTextField}
                  type="text"
                  placeholder="Postal Code"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setZipCode(event.target.value);
                  }}
                  style={{ marginBottom: 0 }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <input
                  className={classes.customizedTextField}
                  type="text"
                  placeholder="Country"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCountry(event.target.value);
                  }}
                  style={{ marginBottom: 0 }}
                  required
                />
              </Grid>
            </Grid>
            <div
              className="text-gray-700 text-base mt-2"
              role="alert"
              style={{ color: "#AA2037" }}
            >
              {errorToDisplay ? errorToDisplay : null}
            </div>
          </div>
        </div>
        <Button
          className={classes.generalButton}
          onClick={submitForm}
          disabled={disabled || subscribing || !allAddressFieldsFilled}
        >
          {subscribing ? (
            <CircularProgress size={35} style={{ color: "white" }} />
          ) : (
            "Subscribe"
          )}
        </Button>
      </form>
    </div>
  );
}

/*function NewPaymentMethodTab(props: NewPaymentMethodTabProps) {
    const classes = useStyles();
    const { children, value, index} = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        className={classes.tabContainer}
      >
        {value === index && children}
      </div>
    );
  }*/

/*function PaymentRow({paymentMethod, selectedPaymentMethodId, onClick}:{
    paymentMethod: PaymentMethod,
    onClick: (paymentMethodId: string) => void,
    selectedPaymentMethodId: string | null,
}) {
    const classes = useStyles();
    return (
        <div style={{display: "flex", alignItems:'center'}} onClick={() => onClick(paymentMethod.id)}>
                <Radio
                    checked={selectedPaymentMethodId === paymentMethod.id}
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'A' }}
                    //color="primary"
                    classes={{
                        root: classes.radioRoot,
                        checked: classes.checked,
                    }}
                />
                <div className={classes.paymentMethodRow}>
                <Typography variant='body1' className={classes.text} style={{flexGrow:1}}>
                    <span style={{fontWeight:'bold'}}>{capitalizeFirstLetter(paymentMethod.brand)}</span>
                    {" ······ " + paymentMethod.last4}
                </Typography>
                <Typography variant='body1' className={classes.smallText} style={{flexGrow:1}}>
                    {"Expiration " + paymentMethod.exp_month + "/" + paymentMethod.exp_year}
                </Typography>
                </div>
        </div>
    )
} */

/*function capitalizeFirstLetter(brand: string) {
    return brand.charAt(0).toUpperCase() + brand.slice(1);
}*/

/*function ExistingPaymentMethodTab(props: PaymentMethodProps) {
    const classes = useStyles();
    const { handleSubmit, paymentMethods, subscribing, errorToDisplay } = props;
    const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);

    //return null;

    const handleClick = () => {
        if (!paymentMethodId) {
            return
        }
        handleSubmit(null, undefined, paymentMethodId);
    }

    const onChange = (paymentMethodId: string) => {
        setPaymentMethodId(paymentMethodId);
    }

    const content = paymentMethods.map(paymentMethod => {
        return (
            <PaymentRow key={paymentMethod.id} paymentMethod={paymentMethod} selectedPaymentMethodId={paymentMethodId} onClick={onChange} />
        )
    })

    return (<form id="payment-form">
              <div className={classes.rowContainer}>
                <div className={classes.innerRowContainer}>
                    {content}
                    <div className="text-gray-700 text-base mt-2" role="alert" style={{color:'#AA2037'}}>
                        {errorToDisplay ? errorToDisplay : null}
                    </div>
                </div>
            </div>
            <Button className={classes.generalButton} onClick={handleClick} disabled={!paymentMethodId || subscribing}>
                {subscribing ? <CircularProgress size={35} style={{color: 'white'}}/> : 'Subscribe'}
            </Button>
        </form>)

}*/

/*function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}*/

const CheckoutForm = ({ productSelected }: { productSelected: Product }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory<LocationState>();
  const store = useStore();
  const [subscribing, setSubscribing] = useState(false);
  const [errorToDisplay, setErrorToDisplay] = useState("");
  //const [value, setValue] = React.useState(0);

  //const [paymentMethods, setPaymentMethods] = useState<Array<PaymentMethod>>([]);

  /*useEffect(() => {
    axios("api/v1/stripe/payment_methods",{
        method: 'GET',
        withCredentials: true,
    })
    .then(response => response.data)
    .then(data => {
        data = data.data as Array<PaymentMethod>;
        setPaymentMethods(data);
    })
    .catch(error => {
        Sentry.captureException(error)
    })
}, []);*/

  async function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }: {
    subscription: Subscription;
    invoice?: Invoice;
    priceId: string;
    paymentMethodId: string;
    isRetry: boolean;
  }) {
    if (subscription && subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const newPaymentIntent = subscription.latest_invoice.payment_intent;

    if (newPaymentIntent.status === "requires_action" && stripe) {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        newPaymentIntent.client_secret || "",
        {
          payment_method: paymentMethodId,
        }
      );
      if (error) {
        throw error;
      } else if (paymentIntent?.status === "succeeded") {
        return { subscription, priceId, paymentMethodId };
      }
      throw new Error("Failed to complete your payment.");
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }: {
    subscription: Subscription;
    paymentMethodId: string;
    priceId: string;
  }) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      //localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      /*localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );*/
      throw new Error("Your card was declined.");
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function onSubscriptionComplete(result: Result) {
    // Payment was successful. Provision access to your service.
    axios("api/v1/auto_login", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => {
        if (response.status === 401) {
          history.push({ pathname: "/log_in", state: {} });
        }
        return response.data;
      })
      .then((data) => {
        data = data.data;
        if (data.user) {
          store.dispatch({ type: "UPDATE_USER_ACCTION", user: data.user });
          const locationState = history.location.state;
          if (locationState && locationState.from) {
            history.push(locationState.from);
          } else {
            history.push({ pathname: "/search", state: {} });
          }
        } else {
          store.dispatch({ type: "UPDATE_USER_ACCTION", user: null });
          history.push({ pathname: "/log_in", state: {} });
        }
      })
      .catch((error) => {
        store.dispatch({ type: "UPDATE_USER_ACCTION", user: null });
        history.push({ pathname: "/log_in", state: {} });
      });

    //setAccountInformation(result);

    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  function createSubscription({
    paymentMethodId,
  }: {
    paymentMethodId: string;
  }) {
    const priceId = productSelected.price_id;
    return (
      axios("api/v1/stripe/charges", {
        method: "POST",
        withCredentials: true,
        data: {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          timeout: 12000,
        },
      })
        .then((response) => {
          return response.data;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          result = result.data;
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result.subscription as Subscription,
            paymentMethodId: paymentMethodId,
            priceId: productSelected.name,
            isRetry: false,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setSubscribing(false);
          if (error.response && error.response.data) {
            error = error.response.data;
            setErrorToDisplay(
              (error.error && error.error.message) || fallBackErrorMessage
            );
            Sentry.captureException(error, {
              extra: { payment_method_id: paymentMethodId },
            });
          } else {
            setErrorToDisplay(fallBackErrorMessage);
          }
        })
    );
  }

  /*const handleTabIndexChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setErrorToDisplay('');
    setValue(newValue);
  };*/

  const handleSubmit = async (
    event: any,
    address?: Address,
    paymentMethodId?: string
  ) => {
    // Block native form submission.
    //event.preventDefault();

    setErrorToDisplay("");
    setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (!paymentMethodId) {
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        setSubscribing(false);
        setErrorToDisplay(
          "We are experiencing tehchnical issues and can't process your payment."
        );
        return;
      }

      // Use your card Element with other Stripe.js APIs
      const isoCountry = CountryCodes.find(
        (code) => code.name === address?.country.toLowerCase()
      );
      const isoCode = isoCountry ? isoCountry.code : address?.country;
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: address && address.name,
          address: {
            line1: address?.line1 || undefined,
            line2: address?.line2 || undefined,
            city: address?.city || undefined,
            state: address?.state,
            country: isoCode,
            postal_code: address?.postal_code,
          },
        },
      });

      if (error) {
        setSubscribing(false);
        setErrorToDisplay((error && error.message) || "");
        return;
      }

      paymentMethodId = paymentMethod?.id;
    }

    // If a previous payment was attempted, get the lastest invoice
    //const latestInvoicePaymentIntentStatus = localStorage.getItem(
    //    'latestInvoicePaymentIntentStatus'
    //);

    /*if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
      // Update the payment method and retry invoice payment
      const invoiceId = localStorage.getItem('latestInvoiceId');
      retryInvoiceWithNewPaymentMethod({
        paymentMethodId: paymentMethodId || '',
        invoiceId: invoiceId,
      });
      return;
    }*/

    if (!paymentMethodId) {
      setSubscribing(false);
      setErrorToDisplay("We are having trouble processing your payment.");
      return;
    }

    // Create the subscription
    createSubscription({
      paymentMethodId: paymentMethodId,
    });
  };

  var paymentContent = (
    <CardComponent
      errorToDisplay={errorToDisplay}
      disabled={!stripe || !elements}
      handleSubmit={handleSubmit}
      subscribing={subscribing}
    />
  );

  /*if (paymentMethods.length === 0) {
    paymentContent = (
        <CardComponent errorToDisplay={errorToDisplay} disabled={!stripe || !elements} handleSubmit={handleSubmit} subscribing={subscribing}/>
    )
  } else {
      paymentContent = (
          <div>
           <Tabs
                value={value}
                onChange={handleTabIndexChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
          <Tab label="New card" {...a11yProps(0)} />
          <Tab label="Existing cards" {...a11yProps(1)} />
        </Tabs>
        <NewPaymentMethodTab value={value} index={0}>
            <CardComponent errorToDisplay={errorToDisplay} disabled={!stripe || !elements} handleSubmit={handleSubmit} subscribing={subscribing}/>
        </NewPaymentMethodTab>
        <NewPaymentMethodTab value={value} index={1}>
            <ExistingPaymentMethodTab paymentMethods={paymentMethods} errorToDisplay={errorToDisplay} handleSubmit={handleSubmit} subscribing={subscribing}/>
        </NewPaymentMethodTab>
          </div>
      )
  }*/

  return (
    <div id="payment-form" className={classes.paymentForm}>
      <Card className={classes.card} elevation={5}>
        <div
          className={`font-bold text-xl mb-2 ${classes.titleSection}`}
          style={{ fontWeight: "bold" }}
        >
          Enter your payment details. <br />
          Your subscription will start now.
        </div>
        <p
          className="text-gray-700 text-base mt-3 mb-6"
          style={{ fontFamily: "SuperaGothic" }}
        >
          Total due now{" "}
          <span className="font-bold">{productSelected.price_name}</span>. Sales
          tax may apply based on your region.
        </p>
        <div className="w-full">{paymentContent}</div>
      </Card>
    </div>
  );
};

const fonts = [
  {
    cssSrc:
      "https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap",
  },
];

function PaymentForm(props: Props) {
  const element = (
    <Elements stripe={stripePromise} options={{ fonts: fonts }}>
      <CheckoutForm {...props} />
    </Elements>
  );
  return element;
}

export default PaymentForm;
