import React from "react";
import { Honor } from "../../../types/admitProfileTypes";
import ExpandablePanel from "./ExpandablePanel";
import { ReactComponent as HonorIcon } from "../../../resources/icons/honors.svg";
export type HonorSectionProps = {
  honors: Honor[];
};

function HonorSection(props: HonorSectionProps) {
  const content = props.honors.map((honor, index) => {
    return <HonorRow honor={honor} key={index} />;
  });

  return (
    <div style={{ marginBottom: 20 }}>
      <ExpandablePanel
        name="Honors"
        content={content}
        expandedByDefault={false}
        icon={<HonorIcon style={{ marginRight: 10 }} />}
      />
    </div>
  );
}

export type HonorRowProps = {
  honor: Honor;
};

function HonorRow(props: HonorRowProps) {
  return (
    <div style={{ overflowWrap: "break-word" }}>
      <div
        style={{
          fontSize: 14,
          fontFamily: "RedHatDisplay-SemiBold",
          color: "#0E7F8A",
        }}
      >
        {props.honor.honorLevel}
      </div>
      <div
        style={{
          fontFamily: "RedHatDisplay",
          fontSize: 14,
          textAlign: "left",
          marginTop: 5,
          marginBottom: 15,
        }}
      >
        {props.honor.description}
      </div>
    </div>
  );
}

export default HonorSection;
