import React from "react";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

interface Props {
  fontSize?: 1 | 2;
}

function PrivacyPolicy(props: Props) {
  const fontSize = props.fontSize || 2;

  const bigHeader = fontSize === 1 ? "h6" : "h4";
  const smallHeader = fontSize === 1 ? "h6" : "h5";

  return (
    <div style={{ textAlign: "left" }}>
      <Typography variant="body2" style={{ marginTop: 40 }}>
        This page describes the Terms of Use that that govern your use of
        CollegeSharing.com and the services we offer on the Site (collectively
        the "Site"), which are owned and operated by CollegeSharing, Inc.
        (referred to as "CollegeSharing," the "Company," "we," or "our" as
        applicable). You (on behalf of yourself and any company you represent)
        automatically agree to these Terms of Use and to our{" "}
        <Link to="/privacy_policy" style={{ color: "#0077FF" }} target="_blank">
          Privacy Policy
        </Link>{" "}
        simply by visiting, viewing, or using the Site.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20, fontWeight: "bold" }}>
        PLEASE NOTE THAT SECTION 9 OF THESE TERMS OF USE CONTAINS AN ARBITRATION
        CLAUSE AND A CLASS ACTION WAIVER PROVISION. IT AFFECTS HOW ANY DISPUTES
        BETWEEN YOU AND THE COMPANY WOULD BE RESOLVED.{" "}
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20, fontWeight: "bold" }}>
        PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE SITE. YOUR USE
        OF THE SITE INDICATES YOUR ACCEPTANCE OF THESE TERMS OF USE, AS MODIFIED
        FROM TIME TO TIME. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, PLEASE
        DO NOT USE THE SITE.
      </Typography>
      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        1. GENERAL
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        1.1 Modification of the Site
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        We reserve the right to modify the Site at any time with or without
        notice to you. For example, we may add or remove functionality or
        features, and we may suspend or stop a particular feature altogether. If
        you do not like any changes, you can stop using the Site at any time.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        1.2 Modification of Terms
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        We also reserve the right to modify these Terms of Use at any time, so
        be sure to check back regularly. By continuing to use or log in to the
        Site after these Terms of Use have changed, you indicate your agreement
        to the revised Terms of Use. If you do not agree to our changes, you can
        stop using the Site at any time.{" "}
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        1.3 Availability
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        The Site is available only to users located in the United States of
        America.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        2. YOUR USE OF THE SITE
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        2.1 Site Registration
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        If you register to use the Site, we may require you to create an account
        with a password chosen by you in order for you to make purchases,
        subscribe to the services, or use certain features on the Sites.{" "}
        <span style={{ fontWeight: "bold" }}>
          You must be at least 13 years or older to register as a user and
          create an account.
        </span>{" "}
        To create an account, you must provide truthful and accurate
        information. Do not impersonate anyone else when you create your
        account. If your registration information changes at any time, you agree
        to update your account to reflect those changes.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        2.2 Account Security
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        You may not share your account with anyone else. Please keep your
        account password confidential, and try not to use the same password on
        other websites. You are solely responsible for all activities, including
        purchases, that occur using your account. If you believe that your
        account has been compromised at any time, please contact us at
        support@collegesharing.com.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        2.3 Use Restrictions
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        The Site is available for your personal, noncommercial use. Please do
        not use the Site in a way that violates any laws, infringes anyone’s
        rights, is offensive, or interferes with the Site or any features on the
        Site (including any technological measures we employ to enforce these
        Terms of Use). Please do not attempt to gain unauthorized access to the
        Site or its associated computer systems or networks. If we (in our sole
        discretion) determine that you have acted inappropriately, we reserve
        the right to terminate your account and access to the Site (with or
        without refunding any payments you have made for access to the Site at
        our discretion), prohibit you from using the Site, and take appropriate
        legal action. Using the Site does not give you ownership of any
        intellectual property rights to the content that you access. You may not
        use content from the Site unless you obtain prior written permission
        from us, or unless you are otherwise permitted to do so by law. For
        example, you may not (a) sell or resell content from the Site, (b)
        plagiarize the Site content, (c) falsely represent that the content from
        the Site is your own work, or (d) post, re-post, re-publish, or share
        content from the Site on other websites, online forums, social media, or
        in any other location, digital or otherwise.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        2.4 Lawful Use
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        When you use the Site or any Content (defined below) provided on the
        Site, you agree to comply with all laws applicable to you.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        3. SERVICE TIERS & TRANSACTIONS
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        3.1 Subscription Service Tiers
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        Registered users of CollegeSharing may subscribe to or use the services
        available on the Site. As a subscriber, you may select a paid
        subscription to access paid features and content on the Site.
        Subscribers agree to pay CollegeSharing the fees listed on the{" "}
        <Link to="/payment_page" style={{ color: "#0077FF" }} target="_blank">
          subscription page
        </Link>{" "}
        for the subscription term listed on that page. You may modify your
        subscription by upgrading or downgrading your subscription tier at any
        time. When you modify your subscription, the change will become
        effective at the end of the then current subscription term.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        3.2 Contributors
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        Registered users of CollegeSharing may become Site contributors. As a
        contributor, you may contribute appropriate content to the Site and have
        the chance to be paid for your contributions.{" "}
        <span style={{ fontWeight: "bold" }}>
          You must be at least 18 years or older to contribute to the Site or to
          receive payments from CollegeSharing.
        </span>
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        3.3 Auto Renewal Subscriptions
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        To provide continuous service to paid subscribers who purchase
        subscriptions, CollegeSharing automatically renews those paid
        subscriptions. Such renewals are generally for the same duration as the
        original subscription term (for example, a one-month subscription will
        renew on a monthly basis and a one-year subscription will renew on an
        annual basis). By making a subscription purchase on the Site, you
        acknowledge that your account will be subject to the above-described
        automatic renewals.{" "}
        <span style={{ fontWeight: "bold" }}>
          At any time, if you do not wish your account to renew automatically or
          if you wish to cancel your subscription at any time, please click{" "}
          <Link
            to="/change_subscription"
            style={{ color: "#0077FF" }}
            target="_blank"
          >
            here
          </Link>{" "}
          to make changes to your subscription.
        </span>{" "}
        Please note that your paid subscription may be interrupted as a result
        of a canceled or expired credit card. It is your responsibility to
        maintain updated payment methods to continue accessing the Site.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        3.4 Effect of Cancellation
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        When you cancel your subscription, your subscription will remain active
        until the end of the subscription term but will not renew.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        3.5 Payments
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        All payments related to paid features on the Site or subscriptions must
        be made from a payment source on which you are the named account holder.
        You agree that we will not be liable for any loss caused by any
        unauthorized use of your credit card or any other method of payment by a
        third party in connection with the Site. Any attempt to defraud
        CollegeSharing through the use of credit cards or other methods of
        payment will result in immediate termination of your account and civil
        and/or criminal prosecution. In the case of suspected or fraudulent
        payment, including use of stolen credentials, by anyone, or any other
        fraudulent activity, we reserve the right to block your account. We are
        entitled to inform relevant authorities or entities (including credit
        reference agencies) of any payment fraud or other unlawful activity, and
        may employ collection services to recover payments.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        3.6 Payment and Financial Transaction Processing
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        We may use third party electronic payment processors and/or financial
        institutions ("Payment Processors") to process financial transactions,
        including to receive payments from you or to make payments to you. You
        irrevocably authorize us, as necessary, to instruct such Payment
        Processors to handle such transactions and you irrevocably agree that we
        may give such instructions on your behalf in accordance with your
        requests as submitted on the Site. You agree to be bound by the terms
        and conditions of use of each applicable Payment Processor. In the event
        of conflict between these Terms of Use and the Payment Processor terms
        and conditions of use, these Terms of Use shall govern.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        3.7 Relationship with CollegeSharing
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        To the extent that CollegeSharing compensates you for submissions to the
        Site, you are an independent contractor to CollegeSharing with
        independent taxation obligations.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        4. USER GENERATED CONTENT and SUBMISSIONS
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        4.1 Content License
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        We may provide opportunities for you to post or upload, documents, text,
        photographs, or other content at your option (collectively, "Content")
        on the Site. If you submit any Content via the Site by any means,
        including by document upload or by interacting directly with the Site,
        that information will be treated as non-confidential and
        non-proprietary. You grant CollegeSharing, its agents, licensees, and
        assigns an irrevocable, perpetual, world-wide, exclusive,
        sub-licensable, and transferable right and license to reproduce, encode,
        store, copy, transmit, publish, resell, post, broadcast, display,
        publicly perform, adapt, modify, create derivative works of, exhibit,
        and otherwise use that information. This license continues even if you
        stop using the Site. You cannot delete or modify Content that has been
        uploaded to the Site.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        4.2 Representations & Warranties
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        When you submit Content to the Site, you represent and warrant that: (a)
        you own the Content and have the right to upload the Content to the
        Site, (b) all Content, including college application materials that you
        submit, represents your own work, (c) you did not purchase any of the
        Content prior to uploading it to the Site, (d) the Content represents
        substantially your own work product, (e) you have redacted any sensitive
        personal information or other personally identifiable information, (f)
        you are truthfully representing the Content as your own prior submission
        to a college or university, and that (g) by uploading Content you agree
        to intentionally disclose the Content to other users on the Site subject
        to these Terms of Use.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        4.3 Acceptable Use Policy
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        When you submit Content to the Site, you agree that you will not (and
        will not permit any third party to):
        <li>
          Infringe any patent, trademark, trade secret, copyright, right of
          publicity, or other right of any other person or entity;
        </li>
        <li>
          Submit or upload content that is false, misleading, untruthful,
          threatening, unlawful, harassing, defamatory, libelous, deceptive,
          abusive, fraudulent, invasive of another person’s privacy, tortious,
          obscene, pornographic, offensive, profane, or otherwise inappropriate;
        </li>
        <li>
          Submit or upload content for any commercial purpose or for
          unauthorized or unsolicited advertising purposes;
        </li>
        <li>Impersonate any person or entity; or </li>
        <li>
          Disclose anyone’s un-redacted personal information without their
          consent.
        </li>
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        4.4 Indemnification for Content You Contribute
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        You agree to defend, indemnify, and hold harmless CollegeSharing, its
        parent and affiliate companies, and their respective officers,
        directors, employees, agents, representatives, insurers, and assigns
        from any and all claims arising out of any Content you post or upload to
        the Site.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        4.5 Release Agreement
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        You release and discharge CollegeSharing, its parent and affiliate
        companies, and their respective officers, directors, employees, agents,
        representatives, insurers, and assigns of any and all claims arising out
        of your disclosure of Content that you post or upload to the Site to
        other users on the Site for which you were compensated, including but
        not limited to any use of the Content you post or upload to the Site by
        a user of the Site for a purpose that CollegeSharing prohibits in
        Section 2.3 of these Terms of Use.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        4.6 Content Disclaimer
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        We are not responsible for, and do not endorse, Content posted by or
        uploaded by any user of the Site. Accordingly, we may not be held
        liable, directly or indirectly, for any loss or damage caused to you in
        connection with any Content posted by another person.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        COLLEGESHARING MAKES NO ASSURANCES WITH REGARD TO THE RELIABILITY,
        VALUE, ACCURACY, OR AUTHENTICITY OF THE CONTENT AVAILABLE ON THE SITE OR
        ITS PRIOR USE IN THE COLLEGE ADMISSIONS PROCESS BY ANY OTHER USER OF THE
        SITE, AND ANY USE OF THE CONTENT BY YOU IS SOLELY AT YOUR OWN RISK.{" "}
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        4.7 No Guaranteed Results
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        By uploading your Content, you agree that you may not be compensated for
        the Content. CollegeSharing reserves the right to reject the Content or
        to refrain from compensating you for your Content in its sole
        discretion. Any compensation provided to you in exchange for your
        Content is determined at the sole discretion of CollegeSharing. Payment
        amounts may vary and are not guaranteed. CollegeSharing will not sell
        college admissions materials that you upload to the Site without
        compensating you. You have the right to decline compensation without
        further obligation.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        4.8 Exclusivity
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        To be eligible for compensation for any Content (including but not
        limited to college admissions applications) that you upload to the Site,
        you agree that you have not sold and will not sell the same Content to
        any other person or on any other digital platform.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        5. TRADEMARKS AND COPYRIGHTS
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        5.1 CollegeSharing Rights
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        All trademarks, logos, and service marks displayed on the Site are
        registered and unregistered trademarks of the Company and/or of third
        parties who have authorized their use (collectively the "Trademarks").
        You may not use, copy, reproduce, republish, upload, post, transmit,
        distribute, or modify any of these Trademarks in any way. All of the
        materials on the Site are copyrighted, except where explicitly noted
        otherwise. We will enforce our intellectual property rights to the
        fullest extent of the law, including the seeking of criminal
        prosecution, if necessary.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        5.2 Copyright Violations
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        If you believe any information on the Site infringes your copyrights or
        applicable law, you may request that we remove the information (or
        disable access to that information) by contacting us at
        support@collegesharing.com.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        6. SOCIAL NETWORK FEATURES
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        The Site may include features that operate in conjunction with certain
        third party social networking websites that you visit, such as Facebook,
        Pinterest, Instagram, YouTube, LinkedIn, TikTok, and Twitter ("Social
        Network Features"). While your use of the Social Network Features is
        governed by these Terms of Use, your access to and use of third party
        social networking sites is governed by the terms posted on those sites.
        You are responsible for ensuring that your use of those social
        networking sites complies with any applicable terms of service or other
        agreements.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        7. OUR WARRANTIES AND DISCLAIMERS
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OF USE, THE COMPANY DOES
        NOT MAKE ANY SPECIFIC PROMISES ABOUT THE SITE OR ITS CONTENT. FOR
        EXAMPLE, WE DO NOT MAKE ANY COMMITMENTS ABOUT THE CONTENT ON THE SITE,
        THE SPECIFIC FUNCTIONS OF THE SITE, THAT THE SITE WILL BE FREE FROM
        ERRORS, VIRUSES, BUGS OR OTHER HARMFUL COMPONENTS, OR THE SITE’S
        RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE PROVIDE THE
        SITE "AS IS."
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED
        WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
        NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE AND
        DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        8. INDEMNIFICATION AND LIMITATION OF LIABILITY
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        You agree to defend, indemnify, and hold harmless CollegeSharing, its
        parent and affiliate companies, and their respective officers,
        directors, employees, agents, representatives, insurers, and assigns
        from any and all claims arising out of your breach of these Terms of
        Use, and any of your activities on the Site.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        EXCEPT WHERE PROHIBITED, COLLEGESHARING WILL NOT BE LIABLE FOR ANY
        INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES
        ARISING FROM YOUR USE OF THE SITE OR ANY THIRD PARTY’S USE OF THE SITE
        TO THE FULLEST EXTENT PERMITTED BY LAW. THESE EXCLUSIONS INCLUDE,
        WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOST DATA, COMPUTER
        FAILURE, OR THE VIOLATION OF YOUR RIGHTS BY ANY THIRD PARTY, EVEN IF
        COLLEGESHARING HAS BEEN ADVISED OF THE POSSIBILITY THEREOF AND
        REGARDLESS OF THE LEGAL OR EQUITABLE THEORY UPON WHICH THE CLAIM IS
        BASED. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF COLLEGESHARING
        ARISING OUT OF OR RELATED TO YOUR USE OF THE SITE EXCEED $500.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        9. DISPUTES
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20, fontWeight: "bold" }}>
        You agree that any dispute or claim arising out of your use of any of
        the Site or any products or subscriptions sold on the Site, including
        any dispute or claim as to the application, enforceability, scope, or
        interpretation of this agreement to arbitrate, will be resolved by
        binding arbitration, rather than in court, except that you may assert
        claims in small claims court if your claims qualify. The Federal
        Arbitration Act and federal arbitration law apply to these Terms of Use.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20, fontWeight: "bold" }}>
        There is no judge or jury in arbitration, and court review of an
        arbitration award is limited. However, an arbitrator can award on an
        individual basis the same damages and relief as a court (including
        injunctive and declaratory relief, or statutory damages), and must
        follow these Terms of Use.{" "}
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        Arbitration shall be administered by the{" "}
        <a
          style={{ color: "#0077FF" }}
          href="https://www.jamsadr.com/rules-streamlined-arbitration/"
        >
          Judicial Arbitration and Mediation Services, Inc. ("JAMS")
        </a>
        , pursuant to the most-current JAMS Streamlined Arbitration Rules &
        Procedures, and conducted by a single, neutral arbitrator. Arbitration
        will take place by phone or videoconference, unless an in-person hearing
        is requested by either party. In that case, the hearing will take place
        in the state where you reside. To the extent this agreement to arbitrate
        conflicts with the JAMS Policy on Consumer Arbitrations Pursuant to
        Pre-Dispute Clauses Minimum Standards of Procedural Fairness (the
        "Minimum Standards"), the Minimum Standards in that regard will apply.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        Disputes may also be referred to another arbitration organization if you
        and the Company agree in writing, or to an arbitrator appointed pursuant
        to Section 5 of the Federal Arbitration Act.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <span style={{ fontWeight: "bold" }}>
          We each agree that any dispute resolution proceedings will be
          conducted only on an individual basis and not in a class,
          consolidated, group, or representative action.
        </span>{" "}
        If for any reason a claim proceeds in court rather than in arbitration,
        we each waive any right to a jury trial. We also both agree that
        notwithstanding our agreement to only arbitrate disputes as stated
        above, you or we may bring suit in court to enjoin infringement or other
        misuse of intellectual property rights.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        10. EMAIL AND TEXT COMMUNICATIONS
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        You agree that all communications related to your use of the Site will
        be sent electronically and that such communications satisfy any legal
        requirement that such communications be in writing. All notices from the
        Company to you shall be deemed delivered and effective when sent to the
        e-mail address you provide to us. If you opt in to receive promotional
        emails, you will be able to later opt-out by following the instructions
        provided in those emails. You cannot however opt out of communications
        that are related to your use of the services available on the Site.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        11. ADDITIONAL DETAILS
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        11.1 Additional Features
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        Certain features on the Site may be subject to separate terms, which
        will be presented in conjunction with those features. Your use of those
        features will be governed by those separate terms.{" "}
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        11.2 Third-Party Links & Ads
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        The Site may contain links to third-party websites. That doesn’t mean
        that we control or endorse those websites or any goods or services sold
        on those websites. Similarly, the Site may contain ads from third
        parties. We do not control or endorse any products being advertised.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        11.3 Notices
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        When you use the Site or send communications to us via the Site, you are
        communicating with us electronically. Similarly, you consent to receive,
        from us electronically, communications related to your use of the Site.
        You agree that all agreements, notices, disclosures, and other
        communications that CollegeSharing provides to you electronically
        satisfy any legal requirement that such communications be in writing.
        All notices from CollegeSharing to you will be deemed delivered and
        effective when sent to the e-mail address you provide to us (if you do
        provide your email address to us).{" "}
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        11.4 No Waiver
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        If you do not comply with these Terms of Use, and we don’t take
        enforcement action right away, that does not constitute a waiver, and we
        not are giving up any rights that we may have (such as taking
        enforcement action in the future).{" "}
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        11.5 Governing Law
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        These Terms of Use are governed by and construed in accordance with the
        laws of the state of California without regard to its conflict of laws
        rules. For disputes that are not subject to arbitration, you expressly
        agree that the exclusive jurisdiction for any claim or dispute under
        these Terms of Use and or your use of any of the Site resides in the
        courts located in San Mateo County, California, and you further
        expressly agree to submit to the personal jurisdiction of such courts
        for the purpose of litigating any such claim or action. If it turns out
        that a particular provision in these Terms of Use is not enforceable,
        that will not affect any other provision.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        11.6 Headers
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        Headers are for convenience and do not affect the interpretation of
        these Terms of Use.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        11.7 California Notice
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        Except where you have received compensation for such content from us, if
        you are a California resident and are under 18 years of age you may
        request and obtain removal of content that you post on the Site by
        contacting us at [insert email address]. All requests must be labeled
        “California Removal Request” on the email subject line. All requests
        must provide a description of the content you want removed and
        information reasonably sufficient to permit us to locate that content.
        We are not responsible for notices that are not labeled or sent
        improperly, and we may not be able to respond if you do not provide
        adequate information. Exercising your right to removal may not result in
        complete or comprehensive removal of all content or information posted
        to the Site.
      </Typography>

      <Typography variant="body2" style={{ marginTop: 40 }}>
        CollegeSharing Inc.
      </Typography>
    </div>
  );
}

export default PrivacyPolicy;
