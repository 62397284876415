import React, { useState, useEffect } from "react";
import PageTitle from "../general/PageTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Dropzone from "../upload/DragAndDropZone";
import Button from "@material-ui/core/Button";
import SnackBar from "../general/SnackBar";
import ImageIcon from "@material-ui/icons/Image";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import HelpIcon from "@material-ui/icons/Help";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Spacing from "../general/Spacing";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light,
      //marginBottom: '5%',
    },
    paragraph: {
      margin: "auto auto",
      //width: "90%",
      //minWidth: "320px",
      textAlign: "left",
    },
    close: {
      padding: theme.spacing(0.5),
    },
    dropZone: {
      margin: "20px auto",
      //width: "90%",
      //minWidth: "320px",
    },
    questionBlock: {
      textAlign: "left",
      [theme.breakpoints.up("md")]: {
        padding: "20px 40px",
        paddingTop: "0px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px 30px",
        paddingTop: "0px",
      },
    },
    cardBlock: {
      textAlign: "left",
      [theme.breakpoints.up("md")]: {
        padding: "0px 40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0px 20px",
      },
    },
    row: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      minWidth: 0,
    },
    cardRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 16px",
    },
    card: {
      margin: "20px auto",
      //width: "90%",
      //minWidth: "320px",
    },
    fileName: {
      paddingLeft: "20px",
      overflowWrap: "break-word",
      textAlign: "left",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
  })
);

export enum UploadType {
  CASE,
  GENERAL_APPLICATION_PROFILE,
}

interface Props {
  disableBack: boolean;
  handleBack: () => void;
  handleNext: (uploadedFiles: Array<File>) => void;
  nextButtonText: string;
  files: Array<File>;
  uploadType: UploadType;
  shouldProceedToNext?: boolean;
  setShouldProceedToNext?: (value: boolean) => void;
  shouldProceedToPrevious?: boolean;
  setShouldProceedToPrevious?: (value: boolean) => void;
}

interface DemoDialogProps {
  open: boolean;
  onClose: () => void;
  isSupp: boolean;
}

function DemoDialog(props: DemoDialogProps) {
  const { onClose, open, isSupp } = props;
  let filePath;
  if (isSupp) {
    filePath = require("../../resources/supp.png");
  } else {
    filePath = require("../../resources/generalFile.png");
  }

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={open}>
      <DialogContent dividers>
        <Typography
          variant="body2"
          style={{
            fontFamily: "SuperaGothic",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Here is an example of the PDF file you may need to upload. The PDF
          file should have been available for download right before you
          submitted your application. The format and the content may vary based
          on the specific system and year.
        </Typography>
        <Spacing spacing={30} />
        <img
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
          src={filePath}
          alt=""
        />
      </DialogContent>
    </Dialog>
  );
}

function UploadPdfPage(props: Props) {
  const classes = useStyles();

  const [files, setFiles] = useState<Array<File>>(props.files);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [openDemo, setOpenDemo] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitFiles = (files: Array<File>) => {
    setFiles(files);
  };

  const handleNext = (uploadedFiles: Array<File>) => {
    if (uploadedFiles.length === 0) {
      setMessage("At least one uploaded file is required");
      return;
    }
    props.handleNext(uploadedFiles);
  };

  useEffect(() => {
    if (props.shouldProceedToNext === true && props.setShouldProceedToNext) {
      handleNext(files);
      props.setShouldProceedToNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToNext]);

  useEffect(() => {
    if (
      props.shouldProceedToPrevious === true &&
      props.setShouldProceedToPrevious
    ) {
      props.handleBack();
      props.setShouldProceedToPrevious(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToPrevious]);

  const callBack = () => {
    setMessage(undefined);
  };

  const onDelete = (index: number) => {
    const newFiles = Array.from(files);
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const uploadFiles = files.map((file, index) => {
    let icon = <PictureAsPdfIcon style={{ fontSize: "2rem" }} />;
    if (file.type !== "application/pdf") {
      icon = <ImageIcon style={{ fontSize: "2rem" }} />;
    }
    return (
      <div key={index}>
        <div className={classes.cardRow}>
          <div className={classes.row}>
            {icon}
            <div style={{ minWidth: 0 }}>
              <Typography variant="subtitle1" className={classes.fileName}>
                {file.name}
              </Typography>
            </div>
          </div>
          <IconButton
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={() => onDelete(index)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
      </div>
    );
  });

  const card = (
    <Card className={classes.card}>
      <CardContent style={{ padding: "0px 16px" }}>{uploadFiles}</CardContent>
    </Card>
  );

  const setUploadedFiles = (files: Array<File>) => {
    setFiles(files);
  };

  return (
    <div className={classes.root}>
      <PageTitle title="Upload Your Application PDF" />
      <div className={classes.questionBlock}>
        {props.uploadType === UploadType.GENERAL_APPLICATION_PROFILE && (
          <div
            className={classes.paragraph}
            style={{ marginTop: 20, marginBottom: 10 }}
          >
            <Typography
              variant="body1"
              style={{ fontFamily: "SuperaGothic", fontWeight: "bold" }}
            >
              {
                "Please upload a Common App PDF or UC System PDF to verify the information you have provided. "
              }
              <HelpIcon
                fontSize="inherit"
                style={{ verticalAlign: "middle" }}
                onClick={() => setOpenDemo(true)}
              />
              <br />
              {
                "You will be able to black off any sensitive information in Step 3 and Step 4. The PDF should match your answers from the previous step for:  "
              }
            </Typography>
          </div>
        )}
        {props.uploadType === UploadType.GENERAL_APPLICATION_PROFILE && (
          <div
            className={classes.paragraph}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Typography
              variant="body2"
              style={{ fontSize: 16, fontFamily: "RobotoCondensed" }}
            >
              <li>Extracurricular Activities</li>
              <li>Personal Statements</li>
              <li>Honors/Rewards</li>
              <li>SAT/ACT Scores</li>
              <li>AP/IB/A-Level Scores</li>
            </Typography>
          </div>
        )}
        {props.uploadType === UploadType.CASE && (
          <div
            className={classes.paragraph}
            style={{ marginTop: 20, marginBottom: 10 }}
          >
            <Typography
              variant="body1"
              style={{ fontFamily: "SuperaGothic", fontWeight: "bold" }}
            >
              {
                "Please upload the supplements section of the Common App PDF or UC System PDF to verify the information you have provided. "
              }
              <HelpIcon
                fontSize="inherit"
                style={{ verticalAlign: "middle" }}
                onClick={() => setOpenDemo(true)}
              />
              <br />
              {
                "You will be able to black off any sensitive information in Step 3 and Step 4. The PDFs should match the supplemental essays you have pasted in the previous section.  "
              }
            </Typography>
          </div>
        )}
        <DemoDialog
          onClose={() => setOpenDemo(false)}
          open={openDemo}
          isSupp={props.uploadType === UploadType.CASE}
        />
        <div
          className={classes.paragraph}
          style={{ marginTop: 10, marginBottom: 20 }}
        >
          <Typography
            variant="body1"
            style={{ fontFamily: "SuperaGothic", fontWeight: "bold" }}
          >
            {"Note: Individual file size must be less than 2MB."}
          </Typography>
        </div>
        <div className={classes.dropZone}>
          <Dropzone
            handleNext={submitFiles}
            sendAlert={() =>
              setMessage("Only PDF, PNG and JPEG formats are supported")
            }
            uploadedFiles={files}
            setUploadedFiles={setUploadedFiles}
          />
          <SnackBar message={message} callBack={callBack} />
        </div>
      </div>
      {files.length !== 0 && <PageTitle title="Uploaded Files" />}
      {files.length !== 0 && <div className={classes.cardBlock}>{card}</div>}
      <div style={{ padding: "20px 0px" }}>
        <Button
          disabled={props.disableBack}
          onClick={props.handleBack}
          className={classes.backButton}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleNext(files)}
        >
          {props.nextButtonText}
        </Button>
      </div>
    </div>
  );
}

export default UploadPdfPage;
