import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Banner from "../../general/Banner";
import Spacing from "../../general/Spacing";
import { Paper } from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router";
import Alert from "./PanelAlert";
import {
  isValidEmail,
  emailAlertMessage,
} from "../../../helpers/AccountInfoChecker";
import ColoredTextField from "./ColoredTextField";
import SubmitButton from "./SubmitButton";
import CardHeader from "./CardHeader";
import LoadingContent from "./LoadingContent";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: "0px 15px",
    },
    paper: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    card: {
      alignItems: "center",
      minWidth: "320px",
      maxWidth: "450px",
      width: "8",
      overflow: "hidden",
      margin: "0px auto",
    },
    header: {
      width: "100%",
      height: "120px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerText: {
      fontFamily: "Righteous",
      fontSize: "32px",
      color: "white",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
      fontSize: "18px",
    },
    root: {
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      paddingBottom: "80px",
      paddingTop: "80px",
    },
    labelRoot: {
      fontFamily: "SuperaGothic",
    },
    inputRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleText: {
      fontSize: "16px",
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      width: "120px",
      textAlign: "left",
    },
    textField: {
      width: "90%",
      maxWidth: "280px",
    },
    textFieldContainer: {
      width: "90%",
      margin: "auto",
    },
    alert: {
      fontFamily: "SuperaGothic",
      "& .MuiAlert-icon": {
        marginTop: "auto",
        marginBottom: "auto",
      },
    },
    cardContent: {
      margin: "30px",
    },
    container: {
      padding: "40px 20px",
      paddingBottom: "5px",
      backgroundColor: theme.palette.primary.main,
    },
  })
);

interface LocationState {
  from?: Location;
}

function LogInPage() {
  const classes = useStyles();
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );
  const [sentEmail, setSentEmail] = useState<boolean | undefined>(undefined);

  const history = useHistory<LocationState>();

  const onRedirect = () => {
    const from = history.location.state;
    history.push({ pathname: "/log_in", state: from });
  };

  const onSignIn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isValidEmail(email)) {
      setAlertMessage(emailAlertMessage);
      return;
    }
    setIsLoading(true);
    axios("users/passwords", {
      method: "POST",
      withCredentials: true,
      data: {
        user: {
          email: email,
        },
      },
    })
      .then((response) => {
        const data = response.data;
        if (data.error) {
          setAlertMessage("We cannot find an account with the email.");
          setIsLoading(false);
        } else {
          setSentEmail(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        //Sentry.captureException(error)
        setAlertMessage("We cannot find an account with the email.");
        setIsLoading(false);
      });
  };

  const onSubmitEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  var cardContent = null;

  if (sentEmail) {
    return (
      <div
        className={classes.root}
        style={{ paddingTop: "0px", paddingBottom: "30%" }}
      >
        <Helmet>
          <title>Reset password - CollegeSharing</title>
        </Helmet>
        <TopBanner />
      </div>
    );
  } else {
    if (!isLoading) {
      cardContent = (
        <SignInCardContent
          onRedirect={onRedirect}
          setEmail={onSubmitEmail}
          onSubmit={onSignIn}
          alertMessage={alertMessage}
        />
      );
    } else {
      cardContent = <LoadingContent title="Reset Password" />;
    }

    return (
      <div className={`page-container ${classes.root}`}>
        <Helmet>
          <title>Reset password - CollegeSharing</title>
        </Helmet>
        <Paper className={classes.card} elevation={8}>
          {cardContent}
        </Paper>
      </div>
    );
  }
}

function TopBanner() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Banner
        title="We have sent you an email to reset password"
        subtitle="You can follow instructions in the email to reset your password."
      />
    </div>
  );
}

function SignInCardContent({
  setEmail,
  onSubmit,
  onRedirect,
  alertMessage,
}: {
  setEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onRedirect: () => void;
  alertMessage?: string;
}) {
  return (
    <div>
      <CardHeader title="Reset Password" />
      <div className="card-wrapper">
        {alertMessage && (
          <div>
            <Alert severity="error">{alertMessage}</Alert>
            <Spacing spacing={24} />
          </div>
        )}
        <form onSubmit={onSubmit}>
          <div className="row-container">
            <div className="row-wrapper">
              <ColoredTextField
                title="Email"
                type="text"
                onChange={setEmail}
                placeholder="anna@gmail.com"
              />
            </div>
          </div>
          <SubmitButton text="SEND EMAIL" type="submit" filled={true} />
        </form>
        <Spacing spacing={8} />
        <SubmitButton text="LOG IN" onSubmit={onRedirect} filled={false} />
        <Spacing spacing={24} />
      </div>
    </div>
  );
}

export default LogInPage;
