import React, { useState, useEffect } from "react";
import axios from "axios";
import { PreviewCase, RenderableUser } from "../../types/types";
import { useHistory } from "react-router";
import AccordianTable from "../general/ExternalCaseTable";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import ProfileSection from "./profile/ProfileSection";
import { Typography } from "@material-ui/core";
import * as Sentry from "@sentry/browser";
import { useParams } from "react-router-dom";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import theme from "../../themes/Theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moneyInput: {
      width: "80%",
      maxWidth: "200px",
    },
    sideMargin: {
      margin: "10px 0px",
      width: "100%",
    },
    text: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    normalText: {
      fontFamily: "SuperaGothic",
      //marginBottom: '10px',
      fontSize: "16px",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
    },
    iconSize: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "80px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "100px",
      },
    },
    root: {
      [theme.breakpoints.down("sm")]: {
        margin: "0px 15px",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0rem 3rem",
      },
      //minHeight: "70%",
    },
    titleSection: {
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
  })
);

export default function EarningsPage() {
  const classes = useStyles();
  const [cases, setCases] = useState<Array<PreviewCase> | undefined>(undefined);
  const [user, setUser] = useState<RenderableUser | undefined>(undefined);
  const history = useHistory();
  const { user_id } = useParams();
  useEffect(() => {
    axios(`api/v1/get_cases_by_user/${user_id}`, {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        var embededData = data.data;
        if (embededData) {
          var cases = embededData.cases as Array<PreviewCase>;
          if (cases) {
            setCases(cases);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        } else {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        }
        Sentry.captureException(error);
      });

    axios(`api/v1/users/${user_id}`, {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        var embededData = data.data;
        if (embededData) {
          var pageUser = embededData.user as RenderableUser;
          setUser(pageUser);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        } else {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        }
        Sentry.captureException(error);
      });
  }, [history, user_id]);

  return (
    <div className={`${classes.root} page-container`}>
      <Helmet>
        <title>User profile - CollegeSharing</title>
      </Helmet>
      <div
        style={{
          display: "flex",
          marginTop: 40,
          alignItems: "center",
          overflow: "scroll",
        }}
      >
        <AccountCircleRoundedIcon
          className={classes.iconSize}
          style={{ color: theme.palette.primary.light }}
        />
        {user && (
          <div style={{ marginLeft: 10, textAlign: "left" }}>
            <Typography
              variant="h4"
              className={classes.titleSection}
              style={{ fontWeight: "bold", marginBottom: 5 }}
            >
              {user.userName}
            </Typography>
            <Typography variant="h6" style={{ fontFamily: "SuperaGothic" }}>
              Joined at: {user.joinedAt}
            </Typography>
          </div>
        )}
      </div>
      {!cases && (
        <img
          src={require("../../resources/green_loader.svg").default}
          width="150"
          style={{ margin: "auto" }}
          alt=""
        />
      )}
      {cases && (
        <ProfileSection title="Application Cases">
          <AccordianTable cases={cases} prefix="/profile/" />
        </ProfileSection>
      )}
    </div>
  );
}
