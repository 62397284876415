import React, { useEffect, useState } from "react";
import AcountItemPage from "./AccountItemCard";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "30px",
      backgroundColor: theme.palette.secondary.light,
      width: "100%",
      flexGrow: 1,
    },
  })
);

function AccountPage() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasUploadedCases, setHasUploadedCases] = useState<boolean>(false);
  useEffect(() => {
    axios("api/v1/has_uploaded_cases", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        data = data.data;
        const hasCases = data.has_uploaded_cases;
        setHasUploadedCases(hasCases);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setHasUploadedCases(false);
        Sentry.captureException(error);
      });
  }, []);
  const cards = options
    .filter(
      (option) =>
        (option.title !== "Payout" && option.title !== "Analytics") ||
        hasUploadedCases
    )
    .map((option, index) => {
      return (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
          <AcountItemPage {...option} />
        </Grid>
      );
    });
  return (
    <div className={classes.root}>
      <Helmet>
        <title>Account - CollegeSharing</title>
      </Helmet>
      {!isLoading && (
        <Grid container spacing={2}>
          {cards}
        </Grid>
      )}
      {isLoading && (
        <CircularProgress
          style={{ marginTop: "100px", marginBottom: "100px" }}
        />
      )}
    </div>
  );
}

const options = [
  {
    title: "Login & Security",
    url: "/login_and_security",
    description: "Update your password or display name",
    imageUrl: require("../../../resources/password.svg").default,
  },
  /*{
        title: "Payments",
        url: "/payment_methods",
        description: "Review and edit your payments methods",
        imageUrl: require('../../../resources/online-payment.png').default,
    },*/
  {
    title: "Payout",
    url: "/payout",
    description: "Payout your balance and review the payout history",
    imageUrl: require("../../../resources/payout.svg").default,
  },
  {
    title: "Subscriptions",
    url: "/change_subscription",
    description: "Cancel or change your subscriptions",
    imageUrl: require("../../../resources/subscription.svg").default,
  },
  {
    title: "Analytics",
    url: "/earnings",
    description: "View the analytics of your applications",
    imageUrl: require("../../../resources/statistics.svg").default,
  },
];

export default AccountPage;
