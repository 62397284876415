import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ProductCard from "../general/ProdutCard";
import { Product } from "../../types/types";
import { Typography } from "@material-ui/core";
import SubscriptionForm from "../general/SubscriptionForm";
import axios from "axios";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/browser";

//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: "left",
      //paddingTop: '30px',
      //paddingBottom: '30px',
      //width: '60%',
      //maxWidth: '450px',
      //minWidth: '320px',
      //margin: 'auto',
    },
    root: {
      backgroundColor: "#4e9298",
      //backgroundColor: theme.palette.primary.main,
      paddingBottom: "2rem",
    },
    title: {
      textAlign: "center",
      color: "white",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      marginBottom: "1.5rem",
      marginTop: "2rem",
    },
    autoSizing: {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "600px",
        width: "100%",
        margin: "10px",
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "600px",
        width: "70%",
        margin: "2rem",
      },
    },
  })
);

function Prices() {
  const classes = useStyles();
  const [productSelected, setProduct] = useState<Product | null>(null);
  const [products, setProducts] = useState<Array<Product>>([]);
  //const [customer] = useState(location.state.customer);

  useEffect(() => {
    axios("api/v1/subscription_plans", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        data = data.data as Array<Product>;
        if (data) {
          const downloadedProducts = data.map((p: Product) => {
            return {
              name: p.name,
              price_id: p.price_id,
              price_name: p.price_name,
              interval: "Every " + p.interval,
              billed: "Billed " + p.billed,
            };
          });
          setProducts(downloadedProducts);
        }
      })
      .catch((error) => {
        //Todo: error logger
        Sentry.captureException(error);
        // Todo: display error message maybe as a banner
      });

    /*fetch('http://localhost:3001/api/v1/subscription_plans', {
            method: 'GET',
            credentials: 'include',
        })
        .then(res => res.json())
        .then(data => {
            data = data.data as Array<Product>;
            console.log(data)
            const downloadedProducts = data.map((p: Product) => {
                return {name: p.name, price_id: p.price_id, price_name: p.price_name, interval: "Every " + p.interval, billed: "Billed " + p.billed}
            });
            setProducts(downloadedProducts);
        })
        .catch(error => {
            //Todo: add error handling
            console.log(error);
        });*/
  }, []);

  function handleClick(id: string) {
    const p = products.find((pro) => pro.price_id === id) || null;
    setProduct(p);
  }

  return (
    <div className={`${classes.root} page-container`}>
      <Helmet>
        <title>Subscribe - CollegeSharing</title>
      </Helmet>
      <div className="flex flex-wrap justify-center">
        <div className={classes.autoSizing}>
          <Typography variant="h4" className={classes.title}>
            Subscribe to a plan to access all the applications
          </Typography>

          <div className="flex justify-between mb-8">
            {products.map((product, index) => {
              return (
                <ProductCard
                  key={index}
                  product={product}
                  currentProductSelected={
                    (productSelected &&
                      product.price_id === productSelected.price_id) ||
                    false
                  }
                  handleClick={handleClick}
                />
              );
            })}
          </div>
          {productSelected ? (
            <div className={classes.container}>
              <SubscriptionForm productSelected={productSelected} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

/*const products = [
    {
        name: "Monthly Service",
        price_id: "prod_IIOe73yMpvElEm",
        price_name: "$19.99",
        priceId: "price_1Hhnt6EcA4pxUgIwQ4An0a4V",
        interval: "Every Month",
        billed: "Billed Monthly"
    },
    {
        name: "Yearly Service",
        price_id: "prod_IIOijuYhbv8yHb",
        price_name: "$119.99",
        priceId: "price_1Hhnw4EcA4pxUgIwkdL06kCt",
        interval: "Every Year",
        billed: "Billed Yearly"
    }
]*/

export default Prices;
