import React, { useState } from "react";
import { Product } from "../../../types/types";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import axios from "axios";
import ProductCard from "../../general/ProdutCard";
import * as Sentry from "@sentry/browser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "30px",
      backgroundColor: theme.palette.secondary.light,
      width: "100%",
      flexGrow: 1,
    },
    text: {
      fontFamily: "SuperaGothic",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    textField: {
      width: "90%",
      maxWidth: "280px",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    sideMargin: {
      margin: "0px 0px",
      width: "100%",
    },
    contentContainer: {
      paddingTop: "4rem",
      paddingBottom: "6rem",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "0.75rem",
      color: "#4a5568",
      letterSpacing: "0.025em",
      marginBottom: "0.5rem",
    },
    rowContainer: {
      display: "flex",
      flexWrap: "wrap",
      //marginLeft: '-0.75rem',
      //marginRight: '-0.75rem',
      marginBottom: "0.5rem",
    },
    innerRowContainer: {
      width: "100%",
      //paddingLeft: '0.75rem',
      //paddingRight: '0.75rem',
    },
    customizedTextField: {
      appearance: "none",
      fontFamily: "SuperaGothic",
      width: "100%",
      backgroundColor: "#edf2f7",
      borderWidth: "1px",
      borderRadius: "0.375rem",
      color: "#4a5568",
      padding: "0.75rem 0.5rem",
      marginBottom: "0.75rem",
      lineHeight: "1.25",
    },
    smallText: {
      fontFamily: "SuperaGothic",
      fontSize: "16px",
    },
    titleSection: {
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
  })
);

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  products: Array<Product>;
  currentProduct: Product;
  nextProduct?: Product;
  displayText?: string;
}

function ChangePasswordPanel(props: Props) {
  const classes = useStyles();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [errorToDisplay, setErrorToDisplay] = useState<string | null>(null);
  const [productSelected, setProductSelected] = useState<Product | null>(null);
  const handleCancel = () => {
    props.onClose();
    setProductSelected(null);
  };

  const handleSubmit = async (event: any) => {
    if (!productSelected) {
      setErrorToDisplay("You must select a plan.");
      return;
    }

    setIsAdding(true);

    axios("api/v1/stripe/update_subscription", {
      method: "POST",
      withCredentials: true,
      data: {
        price_id: productSelected?.price_id,
      },
    })
      .then((response) => {
        setIsAdding(false);
        props.onSubmit();
        setProductSelected(null);
      })
      .catch((error) => {
        setIsAdding(false);
        setErrorToDisplay(
          "Failed to update your subscription. Please try again."
        );
        Sentry.captureException(error);
      });
  };

  function handleClick(id: string) {
    const p = props.products.find((pro) => pro.price_id === id) || null;
    setProductSelected(p);
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      open={props.open}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Typography
          className={classes.titleSection}
          style={{ fontWeight: "bold" }}
        >
          Change subscription
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <div className="w-full">
          <div className={classes.rowContainer}>
            <div className={classes.innerRowContainer}>
              {props.displayText && (
                <Typography
                  variant="h6"
                  className={classes.smallText}
                  style={{ marginBottom: 10, marginTop: 10 }}
                >
                  {props.displayText}
                </Typography>
              )}
              {props.products
                .filter(
                  (product) =>
                    product.id !==
                    (props.nextProduct?.id || props.currentProduct.id)
                )
                .map((product, index) => {
                  return (
                    <ProductCard
                      key={index}
                      product={product}
                      currentProductSelected={
                        (productSelected &&
                          product.price_id === productSelected.price_id) ||
                        false
                      }
                      handleClick={handleClick}
                      shouldDisplayRadio
                      fullWidth
                    />
                  );
                })}

              <div
                className="text-gray-700 text-base mt-2 red-hat-display"
                role="alert"
                style={{ color: "red" }}
              >
                {errorToDisplay ? errorToDisplay : null}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCancel}
          color="primary"
          disabled={isAdding}
        >
          <Typography
            variant="body1"
            style={{
              fontFamily: "SuperaGothic",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Cancel
          </Typography>
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={isAdding || !productSelected}
        >
          <Typography
            variant="body1"
            style={{
              fontFamily: "SuperaGothic",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            {isAdding ? "Updating" : "Update"}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangePasswordPanel;
