import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { ReactComponent as SearchIcon } from "../../resources/icons/search.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      //margin: theme.spacing(1),
      boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.1)",
      borderRadius: 10,
      height: 48,
    },
    resize: {
      fontSize: 14,
      fontFamily: "RedHatDisplay-Medium",
      height: 34,
      paddingTop: 7,
      color: "rgba(14, 127, 138, 1)",
      border: "none",
    },
    inputRoot: {
      height: 48,
    },
    underline: {
      "&::before": {
        border: "none",
        display: "none",
      },
    },
  })
);

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholderText: string;
}

export default function FramelessTypeBar(props: Props) {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "white" }}>
      <TextField
        className={classes.margin}
        placeholder={props.placeholderText}
        onChange={props.onChange}
        fullWidth
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.resize,
            underline: classes.underline,
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginLeft: 11 }} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
