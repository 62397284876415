import React from "react";

interface Props {
  spacing: number;
}
function Spacing(props: Props) {
  const spacingStyle = {
    height: props.spacing + "px",
  };
  return <div style={spacingStyle}></div>;
}

export default Spacing;
