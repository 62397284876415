import React from "react";
import ExpandablePanel from "./ExpandablePanel";
import { ExtracurricularActivity } from "../../../types/admitProfileTypes";
import { ReactComponent as ActivityIcon } from "../../../resources/icons/activity.svg";

export type ActivitySectionProps = {
  activities: ExtracurricularActivity[];
};

function ActivitySection(props: ActivitySectionProps) {
  const content = props.activities.map((activity, index) => {
    return <ActivityRow activity={activity} key={index} />;
  });

  return (
    <div style={{ marginBottom: 20 }}>
      <ExpandablePanel
        name="Activities"
        content={content}
        expandedByDefault={false}
        icon={<ActivityIcon style={{ marginRight: 10 }} />}
      />
    </div>
  );
}

export type ActivityRowProps = {
  activity: ExtracurricularActivity;
};

function ActivityRow(props: ActivityRowProps) {
  return (
    <div style={{ overflowWrap: "break-word" }}>
      <div
        style={{
          fontSize: 14,
          fontFamily: "RedHatDisplay-SemiBold",
          color: "#0E7F8A",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "70%" }}>{props.activity.name}</div>
        <div
          style={{
            width: 75,
            backgroundColor: "rgba(14, 127, 138, 0.08)",
            borderRadius: 100,
            height: 30,
            fontSize: 14,
            color: "#0E7F8A",
            textAlign: "center",
            lineHeight: "30px",
          }}
        >
          {props.activity.yearsInvolved}
          {props.activity.yearsInvolved > 1 ? " Years" : " Year"}
        </div>
      </div>
      <div
        style={{
          fontFamily: "RedHatDisplay",
          fontSize: 14,
          textAlign: "left",
          marginTop: 5,
          marginBottom: 15,
        }}
      >
        {props.activity.description}
      </div>
    </div>
  );
}

export default ActivitySection;
