import React from "react";
import { Typography } from "@material-ui/core";
import TermsOfUse from "../general/TermsOfUse";

function ProductCard() {
  return (
    <div style={{ margin: "5%", textAlign: "left" }}>
      <Typography variant="h3" style={{ marginTop: 20, fontWeight: "bold" }}>
        Terms of Use of CollegeSharing
      </Typography>
      <Typography variant="body2" style={{ marginTop: 40 }}>
        Effective Date: April 2021
      </Typography>
      <TermsOfUse />
    </div>
  );
}

export default ProductCard;
