import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../general/PrimaryButton";
import callToActionBackground from "../../resources/callToActionBackground.svg";
import BottomImage from "../../resources/bottom.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      backgroundColor: theme.palette.primary.main,
    },
    root: {
      fontFamily: "SuperaGothic",
      textAlign: "left",
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    text: {
      margin: "auto",
      color: "white",
      backgroundImage: `url(${callToActionBackground})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        padding: "10%",
      },
      [theme.breakpoints.up("md")]: {
        width: "70%",
        paddingRight: "50px",
        paddingLeft: "5%",
        marginLeft: "5%",
        padding: "5%",
        paddingBottom: 0,
      },
    },
    image: {
      margin: "auto",
      width: "800px",
    },
    padding: {
      [theme.breakpoints.down("md")]: {
        padding: "60px 10%",
        paddingTop: 0,
        width: "90%",
        margin: "auto",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        padding: "0%",
        paddingRight: "5%",
        width: "50%",
        margin: "auto",
      },
    },
    titleSection: {
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
    spacing: {
      "@media (min-width:800px)": {
        marginTop: "25px",
        marginBottom: "15px",
      },
      "@media (max-width:800px)": {
        marginTop: "2vw",
        marginBottom: "0px",
      },
    },
  })
);

function CallToActionSection() {
  const styles = useStyles();
  const buttonStyle = {
    backgroundColor: "white",
    color: "#0A5961",
    margin: "0px",
  };
  return (
    <div className={styles.background}>
      <div className={`${styles.root}`}>
        <div className={styles.text}>
          <Typography
            variant="h3"
            className={styles.titleSection}
            style={{ fontWeight: "bold" }}
          >
            Sign Up Today
          </Typography>
          <Typography
            variant="body1"
            className={`roboto-condensed ${styles.spacing}`}
          >
            Unlock real essays, extracurriculars and SATs, NOW. Unlock the
            secrets to admission to elite colleges.
          </Typography>
        </div>

        <div className={styles.padding}>
          <div style={{ margin: "auto", textAlign: "center" }}>
            <PrimaryButton
              style={buttonStyle}
              link="/sign_up"
              label="call_to_action_section.sign_up_now"
            >
              <Typography variant="h5">Sign Up Now</Typography>
            </PrimaryButton>
          </div>
        </div>
      </div>
      <img src={BottomImage} alt="" />
    </div>
  );
}

export default CallToActionSection;
