import React, { useEffect, useState, useRef, MutableRefObject } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/browser";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles as profileStyles } from "../general_profile/ProfileStyles";
import { GeneralProfile, AdmitProfile } from "../../../types/admitProfileTypes";
import { BannerStatus } from "./types";
import { fetchProfile } from "./api";
import LeftPanel, { SmallScreenProfile, TopBannerProfile } from "./LeftPanel";
import MainPanel from "./MainPanel";
import { SmallSchoolSection } from "./SchoolSection";
import Banner from "../..//general/Banner";
import { styled } from "@material-ui/core/styles";
import TestSection from "./TestSection";
import HonorSection from "./HonorSection";
import ActivitySection from "./ActivitySection";
import EssaySection from "./EssaySection";
import AppBar from "@material-ui/core/AppBar";
import { Dialog, DialogTitle, Button, ButtonProps } from "@material-ui/core";
import "react-lazy-load-image-component/src/effects/blur.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../../themes/Theme";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";
import useVisibillity from "./UseVisibility";

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FadeTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: "40px 20px",
    },
    sticky: {
      position: "sticky",
      top: "65px",
      height: 40,
    },
  })
);

const StyldeButton = styled((props: ButtonProps) => (
  <Button disableRipple disableFocusRipple disableTouchRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontSize: 16,
  textAlign: "left",
  fontFamily: "RedHatDisplay-SemiBold",
  color: "rgba(14, 127, 138, 0.7)",
  flexGrow: 1,
  height: 40,
  backgroundColor: "rgba(14, 127, 138, 0)",
  "&:hover": {
    background: "none",
  },
}));

function ProfilePage() {
  const classes = useStyles();
  const profileClasses = profileStyles();
  const history = useHistory();
  const { id } = useParams();
  const [admitProfile, setAdmitProfile] = useState<AdmitProfile>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pages, setPages] = useState<string[]>();
  const [bannerStatus, setBannerStatus] = useState<BannerStatus>();
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const transition = fullScreen ? SlideTransition : FadeTransition;

  const profileRef = useRef<HTMLDivElement>(null);
  const schoolsRef = useRef<HTMLDivElement>(null);
  const testsRef = useRef<HTMLDivElement>(null);
  const credentialsRef = useRef<HTMLDivElement>(null);

  const [profileVisible, profileTopRef] = useVisibillity<HTMLDivElement>(
    125,
    300,
    true
  );
  const [schoolVisible, schoolsTopRef] = useVisibillity<HTMLDivElement>(
    125,
    300
  );
  const [testVisible, testsTopRef] = useVisibillity<HTMLDivElement>(125, 300);
  const [credentialsVisible, credentialsTopRef] =
    useVisibillity<HTMLDivElement>(125, 300, false, true);

  const onFinished = (value: AdmitProfile) => {
    setAdmitProfile(value);
    setIsLoading(false);
    setPages(mergePages(value));
  };

  const onOpenPdf = () => {
    setOpenPdf(true);
  };

  const onError = (error: any) => {
    setIsLoading(false);
    setAdmitProfile(undefined);
    if (error.response && error.response.data) {
      const response = error.response;
      if (response.status === 401) {
        history.replace({
          pathname: "/log_in",
          state: { from: history.location },
        });
        return;
      } else if (response.status === 307) {
        history.replace({
          pathname: "/payment_page",
          state: { from: history.location },
        });
        return;
      }
      error = error.response.data;
      // TODO: add error logger
      Sentry.captureException(error, { extra: { profile_id: id } });
      const subtitle =
        error.error?.message ||
        "We cannot find the application case you are looking for.";
      setBannerStatus({
        subtitle: subtitle,
        title: "Failed to Find the Resource",
      });
    } else {
      // TODO: add error logger
      Sentry.captureException(error, { extra: { profile_id: id } });
      setBannerStatus({
        subtitle: "We cannot find the application case you are looking for.",
        title: "Failed to Find the Resource",
      });
    }
  };

  useEffect(() => {
    fetchProfile(onFinished, onError, id);
  }, [id, history]); // eslint-disable-line react-hooks/exhaustive-deps

  if (bannerStatus) {
    return (
      <div className={`page-container`}>
        <Helmet>
          <title>Failed to find the resource - CollegeSharing</title>
        </Helmet>
        {bannerStatus && (
          <div className={classes.container}>
            <Banner
              title={bannerStatus.title}
              subtitle={bannerStatus.subtitle}
              severity={"failed"}
            />
          </div>
        )}
      </div>
    );
  }

  if (isLoading || !admitProfile) {
    const profile = admitProfile?.generalProfile;
    return (
      <div className={profileClasses.fullHeight}>
        <PageHelmet profile={profile} />
        <div className={profileClasses.fullHeight}>
          <CircularProgress color="primary" style={{ margin: "auto" }} />
        </div>
      </div>
    );
  }

  const scrollToElement = (ref: MutableRefObject<HTMLDivElement>) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div style={{ flexGrow: 1 }}>
      {pages && (
        <Dialog
          onClick={() => setOpenPdf(!openPdf)}
          scroll="body"
          classes={{
            container: profileClasses.dialogContainer,
            paper: profileClasses.dialogWidth,
          }}
          style={{
            zIndex: 2000,
            display: "block",
            margin: "auto",
            marginTop: fullScreen ? 30 : 0,
          }}
          open={openPdf}
          fullScreen={fullScreen}
          TransitionComponent={transition}
        >
          <div style={{}}>
            <DialogTitle
              className={profileClasses.smallScreen}
              classes={{ root: profileClasses.upperBorderRadius }}
              style={{
                position: "sticky",
                top: 0,
                width: "100%",
                backgroundColor: "white",

                borderBottom: "1px solid rgba(0,0,0,0.1)",
              }}
            >
              <CloseIcon style={{ cursor: "pointer" }} />
            </DialogTitle>
            {pages.map((url, index) => {
              return (
                <img
                  key={index}
                  src={url}
                  alt=""
                  style={{
                    margin: "auto",
                    paddingBottom: 20,
                    width: "100%",
                  }}
                  //wrapperClassName={classes.overflow}
                />
              );
            })}
          </div>
        </Dialog>
      )}
      <div className={profileClasses.pageLayoutByWidth}>
        <div className={profileClasses.middleScreen}>
          <TopBannerProfile
            generalProfile={admitProfile?.generalProfile}
            openPdf={onOpenPdf}
          />
        </div>
        <div className={profileClasses.leftPanel}>
          <LeftPanel
            generalProfile={admitProfile?.generalProfile}
            openPdf={onOpenPdf}
          />
        </div>
        <div className={profileClasses.mainPanel}>
          <MainPanel
            generalProfile={admitProfile.generalProfile}
            schools={admitProfile.schools}
          />
        </div>
      </div>
      <div className={profileClasses.smallScreen}>
        <AppBar position="static" color="default" className={classes.sticky}>
          {
            <div style={{ display: "flex", overflow: "scroll" }}>
              <StyldeButton
                onClick={() => scrollToElement(profileRef)}
                style={{
                  color: profileVisible ? "#0E7F8A" : "rgba(14, 127, 138, 0.5)",
                  fontWeight: profileVisible ? "bold" : "none",
                  fontFamily: profileVisible
                    ? "RedHatDisplay"
                    : "RedHatDisplay-SemiBold",
                }}
              >
                Profile
              </StyldeButton>
              <StyldeButton
                onClick={() => scrollToElement(schoolsRef)}
                style={{
                  color: schoolVisible ? "#0E7F8A" : "rgba(14, 127, 138, 0.5)",
                  fontWeight: schoolVisible ? "bold" : "none",
                  fontFamily: schoolVisible
                    ? "RedHatDisplay"
                    : "RedHatDisplay-SemiBold",
                }}
              >
                Schools
              </StyldeButton>
              <StyldeButton
                onClick={() => scrollToElement(testsRef)}
                style={{
                  color: testVisible ? "#0E7F8A" : "rgba(14, 127, 138, 0.5)",
                  fontWeight: testVisible ? "bold" : "none",
                  fontFamily: testVisible
                    ? "RedHatDisplay"
                    : "RedHatDisplay-SemiBold",
                }}
              >
                Tests
              </StyldeButton>
              <StyldeButton
                onClick={() => scrollToElement(credentialsRef)}
                style={{
                  color:
                    credentialsVisible &&
                    !profileVisible &&
                    !schoolVisible &&
                    !testVisible
                      ? "#0E7F8A"
                      : "rgba(14, 127, 138, 0.5)",
                  fontWeight:
                    credentialsVisible &&
                    !profileVisible &&
                    !schoolVisible &&
                    !testVisible
                      ? "bold"
                      : "none",
                  fontFamily:
                    credentialsVisible &&
                    !profileVisible &&
                    !schoolVisible &&
                    !testVisible
                      ? "RedHatDisplay"
                      : "RedHatDisplay-SemiBold",
                }}
              >
                Credentials
              </StyldeButton>
            </div>
          }
          {/*<StyledTabs value={tabValue} onChange={handleChange}>
            <StyledTab onClick={() => scrollToElement(profileRef)} label="Profile" value="Profile"/>
            <StyledTab onClick={() => scrollToElement(schoolsRef)} label="Schools" value="Schools" />
            <StyledTab onClick={() => scrollToElement(testsRef)} label="Tests" value="Tests" />
            <StyledTab onClick={() => scrollToElement(credentialsRef)} label="Credentials" value="Credentials" />
        </StyledTabs>*/}
        </AppBar>
        <div style={{ flex: 1 }}>
          <div
            ref={profileTopRef}
            style={{
              marginBottom: 10,
              //borderBottom: "15px solid rgba(0, 0, 0, 0.05)",
            }}
          >
            <div ref={profileRef} className="scroll-top">
              <SmallScreenProfile
                generalProfile={admitProfile.generalProfile}
                openPdf={onOpenPdf}
              />
            </div>
            <div
              style={{
                height: 15,
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                boxShadow: "rgb(0 0 0 / 5%) 2px 2px 10px inset",
              }}
            ></div>
          </div>
          <div
            ref={schoolsTopRef}
            style={{
              marginBottom: 20,
              //paddingBottom: 20,
            }}
          >
            <div
              ref={schoolsRef}
              className="test-scroll-top"
              style={{ marginBottom: 40 }}
            >
              <SmallSchoolSection schools={admitProfile.schools} />
            </div>
            <div
              style={{
                height: 15,
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                boxShadow: "rgb(0 0 0 / 5%) 2px 2px 10px inset",
              }}
            ></div>
          </div>
          <div
            ref={testsTopRef}
            style={{
              paddingBottom: 10,
            }}
          >
            <div
              ref={testsRef}
              className="scroll-top"
              style={{ marginBottom: 20 }}
            >
              <TestSection generalProfile={admitProfile.generalProfile} />
            </div>
            <div
              style={{
                height: 15,
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                boxShadow: "rgb(0 0 0 / 5%) 2px 2px 10px inset",
              }}
            ></div>
          </div>
          <div ref={credentialsTopRef} style={{ paddingTop: 5 }}>
            <div ref={credentialsRef} className="scroll-top">
              <div style={{ margin: 20 }}>
                <HonorSection honors={admitProfile.generalProfile.honors} />
                <ActivitySection
                  activities={admitProfile.generalProfile.activities}
                />
                <EssaySection essays={admitProfile.generalProfile.essays} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PageHelmet({ profile }: { profile?: GeneralProfile }) {
  return (
    <Helmet>
      <title>
        {profile && profile.shortSchoolName && profile.year
          ? `${profile.shortSchoolName} | ${profile.year} - CollegeSharing`
          : `View profile - CollegeSharing`}
      </title>
    </Helmet>
  );
}

function mergePages(profile: AdmitProfile): string[] {
  let result = profile.generalProfile.images;
  profile.schools.forEach((school) => {
    result = result.concat(school.images);
  });

  return result;
}

export default ProfilePage;
