import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography, StandardTextFieldProps } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "30px",
      backgroundColor: theme.palette.secondary.light,
      width: "100%",
      flexGrow: 1,
    },
    text: {
      fontFamily: "SuperaGothic",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    textField: {
      width: "90%",
      maxWidth: "280px",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    sideMargin: {
      margin: "0px 0px",
      width: "100%",
    },
    contentContainer: {
      paddingTop: "4rem",
      paddingBottom: "6rem",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "0.75rem",
      color: "#4a5568",
      letterSpacing: "0.025em",
      marginBottom: "0.5rem",
    },
    rowContainer: {
      display: "flex",
      flexWrap: "wrap",
      //marginLeft: '-0.75rem',
      //marginRight: '-0.75rem',
      marginBottom: "0.5rem",
    },
    innerRowContainer: {
      width: "100%",
      //paddingLeft: '0.75rem',
      //paddingRight: '0.75rem',
    },
    inputTextField: {
      appearance: "none",
      fontFamily: "SuperaGothic",
      width: "100%",
      backgroundColor: "#edf2f7",
      borderWidth: "1px",
      borderRadius: "0.375rem",
      color: "#4a5568",
      padding: "0.75rem 1rem",
      lineHeight: "1.25",
    },
    zeroPadding: {
      padding: "0px",
      margin: "0px",
      height: "100%",
      width: "100%",
    },
  })
);

interface ColoredTextFieldProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  placeholder?: string;
  title?: string;
  icon?: React.ReactNode;
  value?: string;
  backgroundcolor?: string;
  iconAtEnd?: boolean;
}

export default function ColoredTextField(
  props: ColoredTextFieldProps & StandardTextFieldProps
) {
  const classes = useStyles();
  return (
    <div className="w-full">
      {props.title && (
        <Typography variant="body1" className={classes.title}>
          {props.title}
        </Typography>
      )}
      <TextField
        {...props}
        value={props.value}
        onChange={props.onChange}
        margin="normal"
        classes={{ root: classes.zeroPadding }}
        InputProps={{
          className: classes.inputTextField,
          endAdornment: props.iconAtEnd ? props.icon : null,
          startAdornment: props.iconAtEnd ? null : props.icon,
          disableUnderline: true,
          style: {
            height: "100%",
            //padding: '10px 10px',
            padding: "0.75rem 1rem",
            borderRadius: "10px",
            backgroundColor: props.backgroundcolor || "#edf2f7",
            color: "#4a5568",
            border: `1px solid #e2e8f0`,
            //border: `1px solid ${theme.palette.primary.main}`,
          },
        }}
        type={props.type}
        placeholder={props.placeholder}
        inputProps={{
          style: {
            //color: 'gray',
            fontSize: "16px",
            padding: "0px",
            fontFamily: "SuperaGothic",
            border: "none",
          },
        }}
      />
    </div>
  );
}
