import React from "react";
import Accordion, { AccordionProps } from "@material-ui/core/Accordion";
import AccordionSummary, {
  AccordionSummaryProps,
} from "@material-ui/core/AccordionSummary";
import { styled } from "@material-ui/core/styles";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { ReactComponent as ArrowDownIcon } from "../../../resources/icons/arrowDown.svg";

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion elevation={0} {...props} />
))(({ theme }) => ({
  "&.MuiAccordion-root": {
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.1)",
    borderRadius: 20,
    padding: 0,
  },
  //border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    // display: 'none',
  },
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ArrowDownIcon style={{ height: 9, width: 17 }} />}
    //expandIcon={<KeyboardArrowDownRoundedIcon style={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  //backgroundColor: 'rgba(0, 0, 0, .03)',
  //flexDirection: 'row-reverse',
  height: 85,
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    //transform: 'rotate(90deg)',
  },
  "& .MuiAccordionSummary-content": {},
  "&.MuiAccordionSummary-root": {
    marginLeft: "20px",
    marginRight: 20,
    height: 80,
    padding: 0,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: "0px 20px",
  "&.MuiAccordionDetails-root": {
    display: "block",
    textAlign: "left",
  },

  //borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export type ExpandablePanelProps = {
  expandedByDefault: boolean;
  content: any;
  name: string;
  icon: any;
};

export default function ExpandablePanel(props: ExpandablePanelProps) {
  const [expanded, setExpanded] = React.useState<boolean>(
    props.expandedByDefault
  );

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledAccordion expanded={expanded} onChange={() => handleChange()}>
      <StyledAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        style={{ display: "flex", alignItems: "center" }}
      >
        {props.icon}
        <div
          style={{
            fontSize: 16,
            fontFamily: "RedHatDisplay",
            margin: "auto 0px",
            fontWeight: "bold",
          }}
        >
          {props.name}
        </div>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{props.content}</StyledAccordionDetails>
    </StyledAccordion>
  );
}
