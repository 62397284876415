import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import AboutUs from "../../resources/theReality.svg";
import Background from "../../resources/TheRealityBack.svg";
import textBackground from "../../resources/theRealityText.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      [theme.breakpoints.up("md")]: {
        //borderLeft: `3px ${theme.palette.primary.main} solid`,
        //borderRight: `3px ${theme.palette.primary.main} solid`,
        margin: "0px 3%",
        marginLeft: "0%",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "3%",
        border: `2px rgba(57, 125, 136, 0.2) solid`,
      },
    },
    root: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row-reverse",
        margin: "0px 7%",
        marginLeft: "0%",
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
        margin: "7%",
      },
    },
    imageContainer: {
      [theme.breakpoints.up("md")]: {
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundImage: `url(${Background})`,
        maxWidth: 800,

        maxHeight: 500,
        display: "flex",
        padding: "2%",
        width: "60%",
        paddingRight: "80px",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: 500,
        margin: "auto",
        marginTop: 40,
        width: "70%",
      },
    },
    shape: {
      margin: "auto",
      zIndex: 50,
      [theme.breakpoints.up("md")]: {
        maxWidth: 650,
        //maxHeight:450,
        width: "110%",
        paddingLeft: "10vw",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
        width: "80%",
      },
    },
    text: {
      textAlign: "left",
      margin: "auto",
      marginLeft: 0,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundImage: `url(${textBackground})`,
      [theme.breakpoints.up("md")]: {
        width: "55%",
        paddingLeft: "100px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  })
);

export default function AboutUsSection() {
  const classes = useStyles();
  return (
    <div className={classes.border}>
      <div className={classes.root}>
        <div className={classes.text}>
          <Typography
            variant="h4"
            style={{ fontFamily: "SuperaGothic-ExtraBold" }}
          >
            The Cost Of Truth
          </Typography>
          <Typography variant="body1" style={{ marginTop: 20 }}>
            However, most of the time the true insights are not readily
            available to students without a private college counselor. We’ve
            seen many students struggle to build effective narratives,
            ultimately losing their chances at a top school.
          </Typography>
        </div>
        <div className={classes.imageContainer}>
          <img className={classes.shape} src={AboutUs} alt="" />
        </div>
      </div>
    </div>
  );
}
