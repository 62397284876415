import React, { useState, useEffect } from "react";
import { Product, SubscriptionItem } from "../../../types/types";
import ProfileSection from "./ProfileSection";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import RowLeft from "./RowLeft";
import RowRight from "./RowRight";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import SimpleButton from "./SimpleButton";
import PrimaryButton from "../../general/PrimaryButton";
import ChangeSubscriptionPanel from "./ChangeSubscriptionPanel";
import CancelSubscriptionPanel from "./CancelSubscriptionPanel";
import EntireRow from "./Row";
import { useHistory } from "react-router";
import axios from "axios";
import { Helmet } from "react-helmet";
import Banner from "../../general/Banner";
import CircularProgress from "@material-ui/core/CircularProgress";
import Spacing from "../../general/Spacing";
import * as Sentry from "@sentry/browser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moneyInput: {
      width: "80%",
      maxWidth: "200px",
    },
    sideMargin: {
      margin: "10px 0px",
      width: "100%",
    },
    text: {
      fontFamily: "SuperaGothic",
      //fontWeight: 'bold',
    },
    smallText: {
      fontFamily: "SuperaGothic",
      fontSize: "16px",
    },
    normalText: {
      fontFamily: "SuperaGothic",
      //marginBottom: '10px',
      fontSize: "16px",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
    },
    root: {
      [theme.breakpoints.down("sm")]: {
        margin: "0px 15px",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0rem 3rem",
      },
      //minHeight: "70%",
    },
    container: {
      margin: "40px 20px",
    },
    progressContainer: {
      position: "absolute",
      height: "100%",
      width: "100%",
      top: "0",
      bottom: "0",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
  })
);

function LoginAndSecurityPage() {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [openCancelSubscription, setOpenCancelSubscription] =
    useState<boolean>(false);

  const [products, setProducts] = useState<Array<Product>>([]);
  const [subscriptionItem, setSubscriptionItem] =
    useState<SubscriptionItem | null>(null);
  const [shouldRender, setShouldRender] = useState<boolean>(true);
  const [resuming, setResuming] = useState<boolean>(false);
  const [errorToDisplay, setErrorToDisplay] = useState<string | undefined>(
    undefined
  );
  const [subscriptionError, setSubscriptionError] = useState<
    string | undefined
  >(undefined);
  const [resumeSubscriptionError, setResumeSubscriptionError] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    axios("api/v1/subscription_plans", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        data = data.data as Array<Product>;
        if (data) {
          const downloadedProducts = data.map((p: Product) => {
            return {
              id: p.id,
              name: p.name,
              price_id: p.price_id,
              price_name: p.price_name,
              interval: p.interval,
              billed: "Billed " + p.billed,
            };
          });
          setProducts(downloadedProducts);
          setSubscriptionError(undefined);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          error = error.response.data;
          setSubscriptionError(
            (error.error && error.error.message) ||
              "We had some technical challenges to retrieve your subscription plan."
          );
        } else {
          setSubscriptionError(
            "We had some technical challenges to retrieve your subscription plan."
          );
        }
        //Todo: add error logger
        Sentry.captureException(error);
      });
  }, []);

  useEffect(() => {
    if (!shouldRender) {
      return;
    }
    //setShouldRender(false);

    axios("api/v1/stripe/get_subscriptions", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        data = data.data as SubscriptionItem;
        if (data) {
          setErrorToDisplay(undefined);
          setSubscriptionItem(data);
          setShouldRender(false);
        }
      })
      .catch((error) => {
        setShouldRender(false);
        if (error.response && error.response.data) {
          error = error.response.data;
          setErrorToDisplay(
            (error.error && error.error.message) ||
              "We are faced with some technical challenges to retrieve your subscription."
          );
        } else {
          setErrorToDisplay(
            "We are faced with some technical challenges to retrieve your subscription."
          );
        }
        // TODO: add logger here
        Sentry.captureException(error);
      });
  }, [shouldRender]);

  if (subscriptionError) {
    return (
      <div className={`page-container`}>
        <div className={classes.container}>
          <Banner
            title="Failed to retrieve your subscription plan"
            subtitle={subscriptionError}
            severity={"warning"}
          />
        </div>
      </div>
    );
  }

  if (errorToDisplay) {
    return (
      <div className={`page-container`}>
        <div className={classes.container}>
          <Banner
            title="Failed to retrieve your subscription"
            subtitle={errorToDisplay}
            severity={"warning"}
          />
        </div>
      </div>
    );
  }

  if (products.length === 0 || shouldRender) {
    return (
      <div
        className={`${classes.root} page-container`}
        style={{ position: "relative" }}
      >
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  const currentProduct = products.find((pro) => {
    return pro.price_id === subscriptionItem?.price_id;
  });

  const nextProduct =
    (subscriptionItem?.next_period_price_id &&
      products.find((pro) => {
        return pro.price_id === subscriptionItem?.next_period_price_id;
      })) ||
    undefined;

  const buttonText = currentProduct
    ? "Change subscription"
    : "Create new subscription";

  const proceedToChangeSubscription = () => {
    if (currentProduct) {
      setOpen(true);
    } else {
      history.push({ pathname: "/payment_page" });
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setOpen(false);
    setShouldRender(true);
  };

  const proceedToResumeSubscription = () => {
    setResuming(true);
    axios("api/v1/stripe/resume_subscription", {
      method: "POST",
      withCredentials: true,
    })
      .then((response) => {
        setResumeSubscriptionError(undefined);
        setResuming(false);
        setShouldRender(true);
      })
      .catch((error) => {
        setResuming(false);
        setShouldRender(true);
        if (error.response && error.response.data) {
          error = error.response.data;
          setResumeSubscriptionError(
            (error.error && error.error.message) ||
              "We are faced with some technical challenges to resume your subscriptions."
          );
        } else {
          setResumeSubscriptionError(
            "We are faced with some technical challenges to resume your subscriptions."
          );
        }
        //TODO: log error
        Sentry.captureException(error);
      });
  };

  const proceedToCancelSubscription = () => {
    setOpenCancelSubscription(true);
  };

  const onCloseCancelSubscriptionPanel = () => {
    setOpenCancelSubscription(false);
  };

  const onCancelSubscription = () => {
    setOpenCancelSubscription(false);
    setShouldRender(true);
  };
  let displayText = undefined;

  if (nextProduct && currentProduct) {
    displayText =
      "Your subscription plan will change to " +
      nextProduct.name +
      " on " +
      subscriptionItem?.ends_at +
      ". Would you like to change your plan for the next billing cycle? Please select from below.";
  } else if (currentProduct) {
    displayText =
      "Your current plan is " +
      currentProduct.name +
      ". Would you like to change your plan for the next billing cycle? The change will take effect on " +
      subscriptionItem?.ends_at +
      ".";
  }
  return (
    <div className={`page-container`}>
      <Helmet>
        <title>Subscription management - CollegeSharing</title>
      </Helmet>
      {resumeSubscriptionError && (
        <div className={classes.container}>
          <Banner
            title="Failed to resume your subscription plan"
            subtitle={resumeSubscriptionError}
            severity={"warning"}
          />
        </div>
      )}
      <div className={classes.root}>
        {currentProduct && (
          <ChangeSubscriptionPanel
            open={open}
            onClose={onClose}
            onSubmit={onSubmit}
            products={products}
            currentProduct={currentProduct}
            nextProduct={nextProduct}
            displayText={displayText}
          />
        )}
        {currentProduct && (
          <CancelSubscriptionPanel
            open={openCancelSubscription}
            onClose={onCloseCancelSubscriptionPanel}
            onSubmit={onCancelSubscription}
          />
        )}
        <ProfileSection title="Active subscriptions">
          {currentProduct && (
            <Grid container spacing={3} className={classes.sideMargin}>
              <RowLeft>
                <Typography variant="h6" className={classes.text}>
                  {currentProduct.name}
                </Typography>
                <Spacing spacing={8} />
                <Typography variant="h6" className={classes.smallText}>
                  {currentProduct.price_name + " / " + currentProduct.interval}
                </Typography>
                {!nextProduct && (
                  <Typography variant="h6" className={classes.smallText}>
                    {subscriptionItem?.cancel_at_period_end
                      ? "Cancellation effective on: " +
                        subscriptionItem?.ends_at
                      : "Next billing date: " + subscriptionItem?.ends_at}
                  </Typography>
                )}
                {nextProduct && (
                  <Typography variant="h6" className={classes.smallText}>
                    {nextProduct.name +
                      " effective on: " +
                      subscriptionItem?.ends_at}
                  </Typography>
                )}
              </RowLeft>
              <RowRight>
                {subscriptionItem?.cancel_at_period_end === false && (
                  <SimpleButton onClick={proceedToCancelSubscription}>
                    <Typography variant="h6" className={classes.buttonText}>
                      Cancel
                    </Typography>
                  </SimpleButton>
                )}
                {subscriptionItem?.cancel_at_period_end && (
                  <SimpleButton
                    onClick={proceedToResumeSubscription}
                    disabled={resuming}
                  >
                    <Typography variant="h6" className={classes.buttonText}>
                      {resuming ? "Resuming" : "Resume"}
                    </Typography>
                  </SimpleButton>
                )}
              </RowRight>
            </Grid>
          )}
          {!currentProduct && (
            <Grid container spacing={3} className={classes.sideMargin}>
              <EntireRow>
                <Typography variant="body2" className={classes.text}>
                  You don't have an active subscription.
                </Typography>
              </EntireRow>
            </Grid>
          )}
          <Divider />
          {!subscriptionItem?.cancel_at_period_end && (
            <Grid container spacing={3} className={classes.sideMargin}>
              <EntireRow>
                <PrimaryButton
                  onClick={proceedToChangeSubscription}
                  label={buttonText}
                >
                  <Typography variant="h6" className={classes.buttonText}>
                    {buttonText}
                  </Typography>
                </PrimaryButton>
              </EntireRow>
            </Grid>
          )}
        </ProfileSection>
      </div>
    </div>
  );
}

export default LoginAndSecurityPage;
