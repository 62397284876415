import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import RoundedButton from "./PrimaryButton";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import { Submission } from "../pages/SubmissionListPage";

interface Props {
  submissions: Array<Submission>;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    body: {
      fontSize: 16,
      fontFamily: "SuperaGothic",
      fontWeight: "normal",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(4n+1)": {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.action.hover,
      },
      "& > *": {
        borderBottom: "unset",
      },
    },
  })
)(TableRow);

const StyledInnerCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      //backgroundColor: theme.palette.primary.main,
      //color: theme.palette.common.white,
      fontSize: "1rem",
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      fontFamily: "SuperaGothic",
      fontWeight: "normal",
    },
  })
)(TableCell);

const StyledInnerRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(4n+2)": {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function Row(props: Submission) {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {props.id}
        </StyledTableCell>
        <StyledTableCell align="right">{props.createdAt}</StyledTableCell>
        <StyledTableCell align="right">{props.updatedAt}</StyledTableCell>
      </StyledTableRow>
      <StyledInnerRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                style={{ fontFamily: "SuperaGothic", fontWeight: "bold" }}
              >
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledInnerCell>Type</StyledInnerCell>
                    <StyledInnerCell align="right">Status</StyledInnerCell>
                    <StyledInnerCell align="right">Review</StyledInnerCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.generalApplicationProfile && (
                    <TableRow>
                      <StyledInnerCell component="th" scope="row">
                        Profile
                      </StyledInnerCell>
                      <StyledInnerCell align="right">
                        {props.generalApplicationProfile.status}
                      </StyledInnerCell>
                      <StyledInnerCell align="right">
                        <RoundedButton
                          variant="outlined"
                          style={{
                            borderRadius: "30px",
                            color: "white",
                            border: "none",
                          }}
                          buttonStyle="secondary"
                          link={`/review_general/${props.generalApplicationProfile.id}`}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "16px",
                              fontFamily: "SuperaGothic",
                              lineHeight: "normal",
                            }}
                          >
                            Review
                          </Typography>
                        </RoundedButton>
                      </StyledInnerCell>
                    </TableRow>
                  )}
                  {props.draftCases.map((draftCase) => (
                    <TableRow key={draftCase.id}>
                      <StyledInnerCell component="th" scope="row">
                        Draft Case
                      </StyledInnerCell>
                      <StyledInnerCell align="right">
                        {draftCase.status}
                      </StyledInnerCell>
                      <StyledInnerCell align="right">
                        <RoundedButton
                          variant="outlined"
                          style={{
                            borderRadius: "30px",
                            color: "white",
                            border: "none",
                          }}
                          buttonStyle="secondary"
                          link={`/review/${draftCase.id}`}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "16px",
                              fontFamily: "SuperaGothic",
                              lineHeight: "normal",
                            }}
                          >
                            Review
                          </Typography>
                        </RoundedButton>
                      </StyledInnerCell>
                    </TableRow>
                  ))}
                  {props.cases.map((draftCase) => (
                    <TableRow key={draftCase.id}>
                      <StyledInnerCell component="th" scope="row">
                        Draft Case
                      </StyledInnerCell>
                      <StyledInnerCell align="right">
                        {draftCase.status}
                      </StyledInnerCell>
                      <StyledInnerCell align="right">
                        <RoundedButton
                          variant="outlined"
                          style={{
                            borderRadius: "30px",
                            color: "white",
                            border: "none",
                          }}
                          buttonStyle="secondary"
                          link={`/profile/${draftCase.id}`}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "16px",
                              fontFamily: "SuperaGothic",
                              lineHeight: "normal",
                            }}
                          >
                            Review
                          </Typography>
                        </RoundedButton>
                      </StyledInnerCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </StyledInnerRow>
    </React.Fragment>
  );
}

export default function CustomizedTables(props: Props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell align="right">Created At</StyledTableCell>
            <StyledTableCell align="right">Updated At</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.submissions.map((row) => (
            <Row {...row} key={row.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
