import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../general/PrimaryButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: "SuperaGothic",
      textAlign: "left",
      margin: "80px 10%",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        margin: "10%",
      },
      [theme.breakpoints.only("md")]: {
        display: "block",
        margin: "80px 10%",
      },
      [theme.breakpoints.up("lg")]: {
        display: "flex",
        marginRight: "8%",
        margin: "80px 10%",
      },
    },
    text: {
      margin: "auto",
      [theme.breakpoints.down("md")]: {
        //marginRight: '60px',
        marginBottom: "60px",
        marginRight: "auto",
        textAlign: "center",
        padding: "0",
        //marginRight: '20px',
        //marginLeft: '20px',
      },
      [theme.breakpoints.between("lg", "xl")]: {
        //marginRight: '60px',
        //marginBottom: '60px',
        paddingRight: "5%",
        textAlign: "center",
        margin: "auto 0",
        //padding: "5%"
        //marginRight: '20px',
        //marginLeft: '20px',
      },
      [theme.breakpoints.up("xl")]: {
        padding: "0",
        paddingRight: "5%",
        margin: "auto 0",
        marginBottom: "auto",
        textAlign: "left",
      },
    },
    smallText: {
      textAlign: "left",
    },
    imageWrapper: {
      [theme.breakpoints.down("lg")]: {
        //display: 'block',
        margin: "auto",
        //width: '80%',
        maxWidth: "700px",
        //width: '80%',
      },
      [theme.breakpoints.up("lg")]: {
        flexShrink: 0,
        width: "550px",
        //height: '325px',
        //display: 'flex',
      },
    },
    spacing: {
      "@media (min-width:800px)": {
        marginTop: "25px",
        marginBottom: "15px",
      },
      "@media (max-width:800px)": {
        marginTop: "5vw",
        marginBottom: "0px",
      },
    },
  })
);

function AnonymizedSection() {
  const styles = useStyles();
  return (
    <div className={`${styles.root}`}>
      <div className={styles.text}>
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          What Will You Do With My Application?
        </Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left" }}
          className={`roboto-condensed ${styles.spacing}`}
        >
          You’ll be able to use our online editing tool to{" "}
          <span style={{ fontWeight: "bold" }}>
            black out any sensitive information
          </span>{" "}
          you don’t want to share publicly, no matter if it’s a word, sentence
          or paragraph. Then we publish your application for our subscribers who
          use your information strictly for inspirational purposes. We do not
          tolerate plagiarism of any kind.
        </Typography>
        <div style={{ margin: "auto", textAlign: "center", marginTop: "60px" }}>
          <PrimaryButton
            link="/search"
            label="anonymized_section.view_applications"
          >
            <Typography variant="h5">View Applications</Typography>
          </PrimaryButton>
        </div>
      </div>
      <div className={styles.imageWrapper}>
        <video autoPlay muted loop playsInline>
          <source
            src={require("../../resources/animated.mp4")}
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
}

export default AnonymizedSection;
