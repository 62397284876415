export const passwordAlertMessage: string =
  "Passwords must be between 8 - 20 letters, including uppercase, lowercase letters and numbers.";
export const emailAlertMessage = "You must enter a valid email address";
export const userNameAlertMessage =
  "Display names must be between 6 - 20 characters, including only uppercase, lowercase letters, underscores or numbers.";

export default function isValidPassword(password?: string): boolean {
  if (!password) {
    return false;
  }
  const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
  return passw.test(password);
}

export function isValidEmail(email?: string): boolean {
  if (!email) {
    return false;
  }
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function isValidUserName(userName?: string): boolean {
  if (!userName) {
    return false;
  }
  const re = /^[0-9a-zA-Z_]{6,20}$/;
  return re.test(userName);
}
