import React from "react";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingTop: "15px",
    paddingRight: "30px",
    paddingLeft: "30px",
    paddingBottom: "5px",
    fontFamily: "SuperaGothic-ExtraBold",
    fontWeight: "bold",
    textAlign: "left",
    backgroundColor: theme.palette.secondary.light,
  },
}));

interface Props {
  title: string;
}
function PageTitle(props: Props) {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h6" className={classes.title}>
        {props.title}
      </Typography>
      <Divider />
    </div>
  );
}
export default PageTitle;
