import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import Spacing from "../general/Spacing";
import Typography from "@material-ui/core/Typography";
import theme from "../../themes/Theme";
import { SchoolCardContent } from "../../types/types";
import { save, unSave } from "../../api/RecordAPI";
import Backdrop from "@material-ui/core/Backdrop";
import SharingCard from "./SharingCard";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { logEvent } from "../../helpers/GoogleAnalyticsLogger";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "400px",
      minWidth: "280px",
      marginLeft: "auto",
      marginRight: "auto",
      height: "100%",
      "&:hover": {
        backgroundColor: "rgba(244,244,244,1)",
      },
    },
    bold: {
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    cardContent: {
      textAlign: "left",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    normalLineHeight: {
      lineHeight: "normal",
    },
    lineClamp3Height: {
      lineHeight: "1.3",
    },
    backdrop: {
      zIndex: 1500,
      color: "#fff",
      alignItems: "center",
    },
    overflow: {
      overflow: "hidden",
    },
    viewCount: {
      color: theme.palette.secondary.main,
      margin: "0px 10px",
      fontFamily: "SuperaGothic",
      fontSize: "16px",
      //fontWeight: 'bold',
    },
    icon: {
      fontSize: "20px",
      margin: "auto 0px",
      color: theme.palette.secondary.main,
    },
  })
);

interface Props {
  profile: SchoolCardContent;
  shouldShowAction?: boolean;
  visibleByDefault?: boolean;
}

function kFormatter(num: number) {
  return Math.abs(num) > 999
    ? (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.abs(num);
}

export default function RecipeReviewCard(props: Props) {
  const classes = useStyles();
  const [saved, setSaved] = useState(props.profile.saved);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

  const color = !saved ? theme.palette.text.secondary : "#dc034e";
  const buttonStyle = {
    color: color,
  };
  const onClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setSaved(!saved);
    if (saved) {
      unSave(props.profile.id, setSaved);
    } else {
      save(props.profile.id, setSaved);
    }
  };

  const onOpenBackDrop = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    logEvent({
      category: "Card",
      action: "Click",
      label: "profile_card.share",
      value: props.profile.id,
    });
    setOpenBackdrop(true);
  };

  const closeBackDrop = () => {
    setOpenBackdrop(false);
  };

  const sharingCard = (
    <SharingCard caseId={props.profile.id} close={closeBackDrop} />
  );
  return (
    <div style={{ height: "100%" }}>
      <Backdrop open={openBackdrop} classes={{ root: classes.backdrop }}>
        {sharingCard}
      </Backdrop>
      <Card
        className={`${classes.root} no-tap`}
        style={{ cursor: "pointer", position: "relative" }}
        elevation={2}
      >
        <Link to={`/profile/${props.profile.id}`} target="_blank">
          {props.visibleByDefault && (
            <img
              alt=""
              style={{
                width: "100%",
                maxHeight: 186,
                objectFit: "cover",
                overflow: "hidden",
              }}
              src={props.profile.coverUrl}
            />
          )}
          {!props.visibleByDefault && (
            <LazyLoadImage
              src={props.profile.coverUrl}
              height={186}
              effect="blur"
              visibleByDefault={props.visibleByDefault}
              placeholderSrc={""}
              wrapperClassName={classes.overflow}
            />
          )}
          <CardContent
            className={classes.cardContent}
            style={{ marginBottom: 50 }}
          >
            <Typography variant="h5" className={classes.bold}>
              {props.profile.shortSchoolName}
            </Typography>
            <Spacing spacing={12} />
            <Typography
              variant="h6"
              className={classes.normalLineHeight}
              style={{ color: theme.palette.secondary.main }}
            >
              {props.profile.yearName}
            </Typography>
            <Typography variant="h6" className={classes.normalLineHeight}>
              {props.profile.majorName}
            </Typography>
            <Spacing spacing={20} />
            {props.profile.title && (
              <Typography
                variant="h6"
                className={`${classes.lineClamp3Height} line-clamp-3`}
              >
                {props.profile.title}
              </Typography>
            )}
          </CardContent>
          {props.shouldShowAction && saved !== null && (
            <CardActions
              disableSpacing
              style={{ bottom: 0, position: "absolute" }}
            >
              <IconButton
                aria-label="add to favorites"
                style={buttonStyle}
                onClick={onClick}
              >
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share" onClick={onOpenBackDrop}>
                <ShareIcon />
              </IconButton>
              {props.profile.viewCount >= 100 && (
                <div
                  style={{
                    marginRight: "10px",
                    flexGrow: 1,
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Typography className={classes.viewCount}>
                    {kFormatter(props.profile.viewCount)}
                  </Typography>
                  <VisibilityIcon className={classes.icon} />
                </div>
              )}
            </CardActions>
          )}
        </Link>
      </Card>
    </div>
  );
}
