/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 16,
    },
  },
  noOption: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 16,
    },
  },
  autoComplete: {
    //width: '30%',
    //minWidth: '240px',
    //maxWidth: '320px',
    width: "100%",
  },
  clearIndicator: {
    //marginRight: 15,
  },
  resize: {
    fontSize: 16,
    paddingTop: "6px !important",
    paddingBottom: "7px !important",
    paddingRight: 10,
    lineHeight: "19px",
    //height: "19px !important",
  },
  input: {
    paddingTop: 6,
    paddingBottom: 7,
    paddingRight: 10,
    //height: "19px !important",
  },
  inputRoot: {
    '&&.MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
});

export interface Option {
  key: string;
  value: string;
}

interface Props {
  options: Array<Option>;
  value: string | null;
  placeholder?: string;
  handleSelect: (key: string | null) => void;
  whiteBackground?: boolean;
}

export default function AutoSelect(props: Props) {
  const classes = useStyles();

  return (
    <Autocomplete
      forcePopupIcon={false}
      className={classes.autoComplete}
      options={props.options}
      classes={{
        option: classes.option,
        noOptions: classes.noOption,
        clearIndicator: classes.clearIndicator,
        inputRoot: classes.inputRoot,
      }}
      value={props.options.find((option) => option.key === props.value) || null}
      autoHighlight
      getOptionSelected={(option: Option, value: Option) => {
        return option.value === value.value;
      }}
      getOptionLabel={(option) => option.value}
      renderOption={(option) => (
        <Typography variant="subtitle1">{option.value}</Typography>
      )}
      onChange={(event: any, value: Option | null) => {
        props.handleSelect(value ? value.key : null);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={true}
          multiline
          rowsMax={3}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            classes: { input: classes.resize },
            type: "text",
            //disableUnderline:true,
          }}
          inputProps={{
            ...params.inputProps,
            //className: classes.input,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
