import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Spacing from "../../general/Spacing";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardHeader from "./CardHeader";
//import Cookies from 'js-cookie';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      margin: "30px",
    },
  })
);

function LoadingContent({
  title,
  excludeHeader,
}: {
  title?: string;
  excludeHeader?: boolean;
}) {
  const classes = useStyles();
  return (
    <div>
      {!excludeHeader && title && <CardHeader title={title} />}
      <div className={classes.cardContent}>
        <CircularProgress />
        <Spacing spacing={24} />
      </div>
    </div>
  );
}

export default LoadingContent;
