import React, { useState, useEffect } from "react";
import { PayoutPageLayout } from "../../../types/types";
import ProfileSection from "./ProfileSection";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import RowLeft from "./RowLeft";
import RowRight from "./RowRight";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import InputTextField from "./InputTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SimpleButton from "./SimpleButton";
import SnackBar from "../../general/SnackBar";
import PrimaryButton from "../../general/PrimaryButton";
import EntireRow from "./Row";
import axios from "axios";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/browser";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moneyInput: {
      width: "80%",
      maxWidth: "200px",
    },
    sideMargin: {
      margin: "10px 0px",
      width: "100%",
    },
    label: {
      fontSize: theme.typography.body1.fontSize,
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    text: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    normalText: {
      fontFamily: "SuperaGothic",
      //marginBottom: '10px',
      //fontSize:'16px',
    },
    buttonText: {
      fontFamily: "SuperaGothic",
    },
    root: {
      [theme.breakpoints.down("sm")]: {
        margin: "0px 15px",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0rem 3rem",
      },
      //minHeight: "70%",
    },
  })
);

function PayoutPage() {
  const classes = useStyles();
  const [pageLayout, setPageLayout] = useState<PayoutPageLayout | null>(null);
  const [price, setPrice] = useState<string | undefined>(undefined);
  const [isTransferring, setIsTransferring] = useState<boolean>(false);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [balanceChanged, setBalancedChanged] = useState<boolean>(true);
  const [payoutPlatform, setPayoutPlatform] = useState<string>("paypal");
  const [paypalReceiver, setPaypalReceiver] = useState<string | undefined>(
    undefined
  );
  const [venmoReceiver, setVenmoReceiver] = useState<string | undefined>(
    undefined
  );

  const handlePayoutPlatform = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayoutPlatform((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (!balanceChanged) {
      return;
    }
    setBalancedChanged(false);
    axios("api/v1/stripe/get_payout_page_layout", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        data = data.data as PayoutPageLayout;
        setPageLayout(data);
      })
      .catch((error) => {
        // TODO: add error logger
        Sentry.captureException(error);
        // TODO: add banner to indicate error
      });
  }, [balanceChanged]);

  if (!pageLayout) {
    return <CircularProgress style={{ margin: "auto" }} />;
  }

  const callBack = () => {
    setMessage(undefined);
  };

  const proceedToStripe = () => {
    setIsRedirecting(true);
    axios("api/v1/stripe/get_button_url", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.data) {
          window.open(data.data.url, "_blank");
          setIsRedirecting(false);
          //window.location.href = data.data.url
        }
      })
      .catch((error) => {
        setIsRedirecting(false);
        setMessage("There is an error opening the link");
        // TODO: add error logger
        Sentry.captureException(error);
      });
  };

  const should_allow_stripe_payout =
    pageLayout.onboarding_finished && pageLayout.payout_enabled;

  let stripeSection = null;
  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value);
  };

  const handlePaypalReciver = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaypalReceiver(event.target.value);
  };

  const handleVenmoReceiver = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVenmoReceiver(event.target.value);
  };

  const handleTransfer = (accountType: string) => {
    if (!price) {
      setMessage("The amount you entered cannot be empty");
      return;
    }
    const amount = Number(price);
    if (!amount) {
      setMessage("You must provide a valid number as the amount");
      return;
    }

    if (
      payoutPlatform === "venmo" &&
      (venmoReceiver === undefined || venmoReceiver === "")
    ) {
      setMessage("You must provide a valid Venmo account");
      return;
    }

    if (
      payoutPlatform === "paypal" &&
      (paypalReceiver === undefined || paypalReceiver === "")
    ) {
      setMessage("You must provide a valid Paypal account");
      return;
    }
    setIsTransferring(true);

    let receiver = undefined;
    if (payoutPlatform === "paypal") {
      receiver = paypalReceiver;
    } else if (payoutPlatform === "venmo") {
      receiver = venmoReceiver;
    }

    axios("api/v1/stripe/payouts", {
      method: "POST",
      withCredentials: true,
      data: {
        amount: amount,
        account_type: accountType,
        receiver: receiver,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.data) {
          setBalancedChanged(true);
          setMessage(data.data.message);
          setIsTransferring(false);
        }
      })
      .catch((error) => {
        setMessage(
          "There is an error transerring to your account. Please try again later."
        );
        setIsTransferring(false);
        // TODO: add error logger
        Sentry.captureException(error);
      });
  };

  const venmoSection = (
    <Grid container spacing={3} className={classes.sideMargin}>
      <EntireRow>
        <Typography variant="body2" className={classes.normalText}>
          Please enter the Venmo account (phone number) and the amount you would
          like to cash out. The money will be transferred to your Venmo account
          in 48 hours; if you don't have an existing account, you will receive a
          text message to open a new Venmo account and claim the payment.
        </Typography>
      </EntireRow>
      <RowLeft>
        <div style={{ maxWidth: "300px" }}>
          <InputTextField
            value={venmoReceiver ? String(venmoReceiver) : ""}
            placeholder="Phone number"
            icon={
              <InputAdornment position="start" className={classes.buttonText}>
                <PhoneAndroidIcon />
              </InputAdornment>
            }
            onChange={handleVenmoReceiver}
          />
        </div>
      </RowLeft>
      <RowLeft>
        <div style={{ maxWidth: "300px" }}>
          <InputTextField
            value={price ? String(price) : ""}
            placeholder="8.5"
            icon={
              <InputAdornment position="start" className={classes.buttonText}>
                <AttachMoneyIcon />
              </InputAdornment>
            }
            onChange={handlePriceChange}
          />
        </div>
      </RowLeft>
      <RowRight>
        <SimpleButton
          onClick={() => handleTransfer("venmo")}
          disabled={isTransferring}
        >
          <Typography variant="h6" className={classes.buttonText}>
            {isTransferring ? "Transferring" : "Transfer"}
          </Typography>
        </SimpleButton>
      </RowRight>
    </Grid>
  );

  const paypalSection = (
    <Grid container spacing={3} className={classes.sideMargin}>
      <EntireRow>
        <Typography variant="body2" className={classes.normalText}>
          Please enter the Paypal account (email) and the amount you would like
          to cash out. The money will be transferred to your Paypal account in
          48 hours; if you don't have an existing account, you will receive an
          email notification to open a new Paypal account and claim the payout.
        </Typography>
      </EntireRow>
      <RowLeft>
        <div style={{ maxWidth: "300px" }}>
          <InputTextField
            value={paypalReceiver ? String(paypalReceiver) : ""}
            placeholder="Email"
            icon={
              <InputAdornment position="start" className={classes.buttonText}>
                <MailOutlineIcon />
              </InputAdornment>
            }
            onChange={handlePaypalReciver}
          />
        </div>
      </RowLeft>
      <RowLeft>
        <div style={{ maxWidth: "300px" }}>
          <InputTextField
            value={price ? String(price) : ""}
            placeholder="8.5"
            icon={
              <InputAdornment position="start" className={classes.buttonText}>
                <AttachMoneyIcon />
              </InputAdornment>
            }
            onChange={handlePriceChange}
          />
        </div>
      </RowLeft>
      <RowRight>
        <SimpleButton
          onClick={() => handleTransfer("paypal")}
          disabled={isTransferring}
        >
          <Typography variant="h6" className={classes.buttonText}>
            {isTransferring ? "Transferring" : "Transfer"}
          </Typography>
        </SimpleButton>
      </RowRight>
    </Grid>
  );
  if (should_allow_stripe_payout) {
    stripeSection = (
      <div>
        <Grid container spacing={3} className={classes.sideMargin}>
          <EntireRow>
            <Typography variant="body2" className={classes.normalText}>
              {pageLayout.instruction_text}
            </Typography>
          </EntireRow>
          <EntireRow>
            <PrimaryButton
              onClick={proceedToStripe}
              label={pageLayout.button_text}
            >
              <Typography variant="h6" className={classes.buttonText}>
                {isRedirecting ? "Redirecting ..." : pageLayout.button_text}
              </Typography>
            </PrimaryButton>
          </EntireRow>
        </Grid>
        <Grid container spacing={3} className={classes.sideMargin}>
          <EntireRow>
            <Typography variant="body2" className={classes.normalText}>
              Please enter the amount you would like to cash out. The money will
              be transferred to your Stripe account, and from there you can
              transfer the money to your bank acount or debit card. Note that a
              $0.25 service fee will be applied for each payout.
            </Typography>
          </EntireRow>
          <RowLeft>
            <div style={{ maxWidth: "300px" }}>
              <InputTextField
                value={price ? String(price) : ""}
                placeholder="8.5"
                onChange={handlePriceChange}
                icon={
                  <InputAdornment
                    position="start"
                    className={classes.buttonText}
                  >
                    <AttachMoneyIcon />
                  </InputAdornment>
                }
              />
            </div>
          </RowLeft>
          <RowRight>
            <SimpleButton
              onClick={() => handleTransfer("stripe")}
              disabled={isTransferring}
            >
              <Typography variant="h6" className={classes.buttonText}>
                {isTransferring ? "Transferring" : "Transfer"}
              </Typography>
            </SimpleButton>
          </RowRight>
        </Grid>
      </div>
    );
  } else {
    stripeSection = (
      <Grid container spacing={3} className={classes.sideMargin}>
        <EntireRow>
          <Typography variant="body2" className={classes.normalText}>
            {pageLayout.instruction_text}
          </Typography>
        </EntireRow>
        <EntireRow>
          <PrimaryButton
            onClick={proceedToStripe}
            label={pageLayout.button_text}
          >
            <Typography variant="h6" className={classes.buttonText}>
              {isRedirecting ? "Redirecting ..." : pageLayout.button_text}
            </Typography>
          </PrimaryButton>
        </EntireRow>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Payout management - CollegeSharing</title>
      </Helmet>
      <SnackBar message={message} callBack={callBack} />
      <ProfileSection title="Account balance">
        <Grid container spacing={3} className={classes.sideMargin}>
          <RowLeft>
            <Typography variant="h6" className={classes.text}>
              Account Balance:
            </Typography>
          </RowLeft>
          <RowRight>
            <Typography variant="h6" className={classes.text}>
              {pageLayout.balance}
            </Typography>
          </RowRight>
        </Grid>
        <Divider />
      </ProfileSection>
      <ProfileSection title="Make payouts">
        <Grid container spacing={3} className={classes.sideMargin}>
          <EntireRow>
            <Typography variant="body2" className={classes.normalText}>
              You may choose to accept payment using Paypal, Venmo, debit card
              or bank account. Please choose one of the payout options below.
            </Typography>
          </EntireRow>
          <RowLeft>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={payoutPlatform}
                onChange={handlePayoutPlatform}
              >
                <FormControlLabel
                  classes={{ label: classes.label }}
                  value="paypal"
                  control={<Radio color="primary" />}
                  label="Paypal"
                />
                <FormControlLabel
                  classes={{ label: classes.label }}
                  value="venmo"
                  control={<Radio color="primary" />}
                  label="Venmo"
                />
                <FormControlLabel
                  classes={{ label: classes.label }}
                  value="stripe"
                  control={<Radio color="primary" />}
                  label="Debit Card / Bank Account"
                />
              </RadioGroup>
            </FormControl>
          </RowLeft>
        </Grid>
        {payoutPlatform === "stripe" && stripeSection}
        {payoutPlatform === "paypal" && paypalSection}
        {payoutPlatform === "venmo" && venmoSection}
      </ProfileSection>
    </div>
  );
}

export default PayoutPage;
