import React from "react";
import ReviewStepper from "../general/ReviewStepper";
import theme from "../../themes/Theme";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function UploadApplicationPage() {
  const { id } = useParams();
  return (
    <div
      style={{
        backgroundColor: theme.palette.secondary.light,
        display: "flex",
        flexDirection: "column",
      }}
      className="page-container"
    >
      <Helmet>
        <title>Review application - CollegeSharing</title>
      </Helmet>
      <ReviewStepper id={id} />
    </div>
  );
}

export default UploadApplicationPage;
