import React, { useEffect, useState } from "react";
import Stepper from "../general/Stepper";
import GeneralApplicationStepper from "../general/GeneralApplicationStepper";
import theme from "../../themes/Theme";
import { Helmet } from "react-helmet";
import axios from "axios";
import { GeneralApplicationInfo } from "../../types/uploadTypes";
import * as Sentry from "@sentry/browser";
import { CircularProgress } from "@material-ui/core";

function UploadApplicationPage() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [generalInfo, setGeneralInfo] = useState<
    GeneralApplicationInfo | undefined
  >(undefined);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(true);
  useEffect(() => {
    if (!shouldRefresh) {
      return;
    }
    setShouldRefresh(false);
    axios(`api/v1/get_general_application_profile`, {
      method: "GET",
      //responseType: 'blob',
      withCredentials: true,
    })
      .then((response) => {
        const data = response.data.data;
        if (data) {
          setGeneralInfo(data.profile);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            //'file_name': fileName
          },
        });
        setIsLoading(false);
      });
  }, [shouldRefresh]);

  if (isLoading) {
    return (
      <div style={{ height: "100%" }}>
        <CircularProgress color="primary" style={{ margin: "auto" }} />
      </div>
    );
  }

  if (!generalInfo) {
    return (
      <div
        style={{
          backgroundColor: theme.palette.secondary.light,
          display: "flex",
          flexDirection: "column",
        }}
        className="page-container"
      >
        <Helmet>
          <title>Upload your college application - CollegeSharing</title>
        </Helmet>
        <GeneralApplicationStepper files={[]} title={null} />
      </div>
    );
  }
  return (
    <div
      style={{
        backgroundColor: theme.palette.secondary.light,
        display: "flex",
        flexDirection: "column",
      }}
      className="page-container"
    >
      <Helmet>
        <title>Upload your college application - CollegeSharing</title>
      </Helmet>
      <Stepper files={[]} proofFiles={[]} title={null} />
    </div>
  );
}

export default UploadApplicationPage;
