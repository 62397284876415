import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { CheckBoxItem } from "../../types/types";
import { Typography } from "@material-ui/core";
import { ReactComponent as UncheckedIcon } from "../../resources/icons/unchecked.svg";
import { ReactComponent as CheckedIcon } from "../../resources/icons/checked.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      marginBottom: "0px",
    },
    formControl: {
      ...theme.typography.subtitle1,
      fontFamily: "RedHatDisplay-Medium",
      marginLeft: 14,
      fontSize: 14,
    },
    checkBox: {
      padding: "2px",
    },
  })
);

interface CheckboxesGroupProps {
  input: string;
  title: string;
  list: Array<CheckBoxItem>;
  toggleCheckBoxItem: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function CheckboxesGroup(props: CheckboxesGroupProps) {
  const classes = useStyles();
  let checkboxes = props.list
    .filter((checkbox) =>
      checkbox.filterItem.name.toLowerCase().includes(props.input.toLowerCase())
    )
    .map((checkbox) => {
      return (
        <FormControlLabel
          key={checkbox.filterItem.id}
          style={{ marginLeft: "0px", marginBottom: 10 }}
          control={
            <Checkbox
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              className={classes.checkBox}
              checked={checkbox.selected}
              id={checkbox.filterItem.id}
              onChange={props.toggleCheckBoxItem}
              name={checkbox.filterItem.name}
            />
          }
          label={
            <Typography
              variant="body2"
              className={classes.formControl}
              style={{
                fontFamily: checkbox.selected
                  ? "RedHatDisplay-SemiBold"
                  : "RedHatDisplay-SemiBold",
                color: checkbox.selected ? "#0E7F8A" : "black",
              }}
            >
              {checkbox.filterItem.name}
            </Typography>
          }
        />
      );
    });

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <FormGroup>{checkboxes}</FormGroup>
      </FormControl>
    </div>
  );
}

export default React.memo(CheckboxesGroup);
