import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Banner from "../../general/Banner";
import { Typography } from "@material-ui/core";
import PrimaryButton from "../../general/PrimaryButton";
import axios from "axios";
import { Helmet } from "react-helmet";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Sentry from "@sentry/browser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "flex-start",
      overflow: "hidden",
    },
    paperEdge: {
      width: "10px",
      backgroundColor: theme.palette.primary.main,
      //marginRight: '20px',
    },
    titleFlex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      //borderBottom: '1px solid #e5e5e5',
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: "2rem",
      marginRight: "10px",
    },
    container: {
      margin: "40px 20px",
      //marginTop: '40px',
    },
    buttonStyle: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      marginTop: "40px",
      marginBottom: "10vw",
    },
    progressContainer: {
      position: "absolute",
      height: "100%",
      width: "100%",
      top: "0",
      bottom: "0",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
  })
);

interface BannerStatus {
  confirmed: boolean;
  subtitle: string;
  title: string;
}

function DeclineCasePage() {
  const classes = useStyles();
  const { id } = useParams();
  const [bannerStatus, setBannerStatus] = useState<BannerStatus | undefined>(
    undefined
  );

  useEffect(() => {
    axios(`api/v1/decline_case/${id}`, {
      method: "POST",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        setBannerStatus({
          confirmed: true,
          subtitle: data.message,
          title: "Case Has Been Deleted",
        });
      })
      .catch((error) => {
        // TODO: add error logger
        let subtitle;
        if (error.response && error.response.data) {
          if (error.response.status >= 500) {
            Sentry.captureException(error);
          }
          error = error.response.data;
          subtitle =
            (error.error && error.error.message) ||
            "Failed to decline the offer. Please try again.";
        } else {
          Sentry.captureException(error);
          subtitle =
            (error.error && error.error.message) ||
            "Failed to decline the offer. Please try again.";
        }
        setBannerStatus({
          confirmed: false,
          subtitle: subtitle,
          title: "Failed to Decline the Case",
        });
      });
  }, [id]);

  let content;
  if (bannerStatus === undefined) {
    content = (
      <div className={classes.progressContainer}>
        <CircularProgress />
      </div>
    );
  } else {
    const severity = bannerStatus.confirmed ? "confirmed" : "warning";
    content = (
      <div className={classes.container}>
        <Banner
          title={bannerStatus.title}
          subtitle={bannerStatus.subtitle}
          severity={severity}
        />
        <div style={{ overflow: "hidden", margin: "40px 0px" }}>
          <PrimaryButton
            style={{ float: "left", width: "280px" }}
            link={`/upload_status`}
            label="upload_more_applications"
            buttonStyle="primary"
          >
            <Typography variant="body1">Upload More Applications</Typography>
          </PrimaryButton>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container" style={{ position: "relative" }}>
      <Helmet>
        <title>Offer declined - CollegeSharing</title>
      </Helmet>
      {content}
    </div>
  );
}

export default DeclineCasePage;
