import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    generalButton: {
      ...theme.typography.subtitle1,
      fontFamily: "SuperaGothic",
      color: theme.palette.primary.main,
      backgroundColor: "white",
      "&:hover": {
        color: theme.palette.primary.dark,
        backgroundColor: "white",
      },
      textTransform: "none",
      alignItems: "center",
      padding: "0.5rem 2rem",
    },
  })
);

function CustomizedButton(props: ButtonProps) {
  const classes = useStyles();
  return (
    <Button
      className={classes.generalButton}
      {...props}
      variant="text"
      color="primary"
    >
      {props.children}
    </Button>
  );
}

export default CustomizedButton;
