import React, { useState, useEffect } from "react";
import Stepper, { FileWithPages } from "../general/GeneralApplicationStepper";
import theme from "../../themes/Theme";
import { Redirect } from "react-router";
import { FileData } from "../general/ReviewStepper";
import { GeneralApplicationInfo } from "../../types/uploadTypes";
import axios from "axios";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/browser";

function downloadFiles(
  fileData: Array<FileData>,
  onLoadedFiles: (files: Array<FileWithPages>) => void,
  setRedirect: (redirect: JSX.Element) => void
) {
  const promises: Array<Promise<File>> = [];
  for (let file of fileData) {
    promises.push(downloadFile(file.file_path));
  }
  const files: Array<FileWithPages> = [];
  Promise.all(promises)
    .then((downloadedFiles) => {
      downloadedFiles.forEach((file, index) => {
        files.push({ file: file, pages: fileData[index].pages });
      });
      onLoadedFiles(files);
    })
    .catch((error) => {
      Sentry.captureException(error, {
        extra: {
          //'file_data': fileData
        },
      });
      setRedirect(<Redirect to="/503" />);
    });
}

function downloadFile(fileName: string): Promise<File> {
  return new Promise((resolve, reject) => {
    axios(`api/v1/get_file`, {
      method: "POST",
      //responseType: 'blob',
      withCredentials: true,
      params: {
        file_path: fileName,
      },
    })
      .then((response) => {
        const link = response.data.data.link;
        const fileType = response.data.data.file_type;
        const file_names = fileName.split("/");
        fetch(link)
          .then((r) => r.blob())
          .then((blob) => {
            const file = new File([blob], file_names[file_names.length - 1], {
              type: fileType,
            });
            resolve(file);
          })
          .catch((error) => {
            Sentry.captureException(error, { extra: { file_name: fileName } });
            reject(error);
          });
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            //'file_name': fileName
          },
        });
        reject(error);
      });
  });
}

function UploadApplicationPage() {
  const [files, setFiles] = useState<Array<FileWithPages>>([]);
  //const [title, setTitle] = useState<string | null>(null);

  const [caseInfo, setCaseInfo] = useState<GeneralApplicationInfo | undefined>(
    undefined
  );
  const [title, setTitle] = useState<string | null>(null);
  const [redirect, setRedirect] = useState<JSX.Element | null>(null);

  useEffect(() => {
    axios(`api/v1/get_general_application_profile`, {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        var embededData = data.data;
        var caseData = embededData.profile;
        if (caseData) {
          if (
            caseData.status !== "pending_submission" &&
            caseData.status !== "needs_revision"
          ) {
            setRedirect(<Redirect to="/503" />);
            return;
          }
          setCaseInfo({
            schoolId: caseData.schoolId && String(caseData.schoolId),
            yearId: caseData.yearId && String(caseData.yearId),
            majorId: caseData.majorId && String(caseData.majorId),
            ethnicityId: caseData.ethnicityId && String(caseData.ethnicityId),
            usStateId: caseData.usStateId && String(caseData.usStateId),
            genderId: caseData.genderId && String(caseData.genderId),
            countryId: caseData.countryId && String(caseData.countryId),
            title: caseData.title,
            city: caseData.city,
            unweightedGpa:
              caseData.unweightedGpa && String(caseData.unweightedGpa),
            weightedGpa: caseData.weightedGpa && String(caseData.weightedGpa),
            additionalInformation: caseData.additionalInformation,
            sat: caseData.sat,
            act: caseData.act,
            aLevelTests: caseData.aLevelTests,
            collegeLevelTests: caseData.collegeLevelTests,
            satSubjectTests: caseData.satSubjectTests,
            essays: caseData.essays,
            activities: caseData.activities,
            honors: caseData.honors,
            classRank: caseData.classRank,
          });
          setTitle(caseData.title);
          downloadFiles(caseData.files, setFiles, setRedirect);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        // TODO: add error logger
        // TODO: use history.push
        setRedirect(<Redirect to="/503" />);
      });
  }, []);

  if (redirect) {
    return redirect;
  }

  if (files.length === 0 || !title || !caseInfo) {
    return (
      <img
        src={require("../../resources/green_loader.svg").default}
        width="150"
        style={{ margin: "auto" }}
        alt=""
      />
    );
  }

  return (
    <div
      style={{
        backgroundColor: theme.palette.secondary.light,
        display: "flex",
        flexDirection: "column",
      }}
      className="page-container"
    >
      <Helmet>
        <title>Edit your application - CollegeSharing</title>
      </Helmet>
      <Stepper files={files} title={title} caseInfo={caseInfo} mode={"edit"} />
    </div>
  );
}

export default UploadApplicationPage;
