import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import weNeedYourHelp from "../../resources/weNeedYourHelp.svg";
import Background from "../../resources/WeNeedYourHelpBack.svg";
import PrimaryButton from "../general/PrimaryButton";
import textBackground from "../../resources/weNeedYourHelpText.svg";
import Circle from "../../resources/circle.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      [theme.breakpoints.up("md")]: {
        //borderLeft: `3px ${theme.palette.primary.main} solid`,
        //borderRight: `3px ${theme.palette.primary.main} solid`,
        margin: "0px 3%",
        marginRight: "0%",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "3%",
        border: `2px rgba(57, 125, 136, 0.2) solid`,
      },
    },
    root: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        margin: "0px 7%",
        marginRight: "0%",
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
        margin: "7%",
      },
    },
    imageContainer: {
      [theme.breakpoints.up("md")]: {
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundImage: `url(${Background})`,
        maxWidth: 800,

        maxHeight: 500,
        display: "flex",
        padding: "2%",
        paddingLeft: "80px",
        width: "60%",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: 500,
        margin: "auto",
        marginTop: 40,

        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundImage: `url(${Circle})`,
      },
    },
    shape: {
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    text: {
      textAlign: "left",
      margin: "auto",
      marginLeft: 0,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundImage: `url(${textBackground})`,
      [theme.breakpoints.up("md")]: {
        paddingTop: 50,
        paddingBottom: 50,
        width: "50%",
        paddingRight: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    image: {
      margin: "auto",
      zIndex: 50,
      [theme.breakpoints.up("md")]: {
        maxWidth: 600,
        width: "100%",
        paddingRight: "10vw",
      },
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
  })
);

export default function AboutUsSection() {
  const classes = useStyles();
  return (
    <div className={classes.border}>
      <div className={classes.root}>
        <div className={classes.text}>
          <Typography
            variant="h4"
            style={{ fontFamily: "SuperaGothic-ExtraBold" }}
          >
            How Can I Help?
          </Typography>
          <Typography variant="body1" style={{ marginTop: 20 }}>
            CollegeSharing strives to bridge the opportunity gap by providing
            trustworthy and validated data to everyone in need. We encourage you
            to share your successful applications with us to help future
            applicants. As a thank you, we provide a cash reward for your
            contribution.
          </Typography>
          <div
            style={{
              margin: "40px auto",
              marginBottom: 0,
              textAlign: "center",
            }}
          >
            <PrimaryButton
              link="/upload_status"
              label="we_need_your_help.upload_now"
            >
              <Typography variant="h5">Upload Now</Typography>
            </PrimaryButton>
          </div>
        </div>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={weNeedYourHelp} alt="" />
        </div>
      </div>
    </div>
  );
}
