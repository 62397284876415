import React, { useState, useEffect } from "react";
import Stepper, { FileWithPages } from "../general/Stepper";
import theme from "../../themes/Theme";
import { CaseInfo } from "./EnterInformationPage";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router";
import { CaseData, ProofFileData, FileData } from "../general/ReviewStepper";
import axios from "axios";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/browser";

function downloadFiles(
  fileData: Array<FileData>,
  onLoadedFiles: (files: Array<FileWithPages>) => void,
  setRedirect: (redirect: JSX.Element) => void
) {
  const promises: Array<Promise<File>> = [];
  for (let file of fileData) {
    promises.push(downloadFile(file.file_path));
  }
  const files: Array<FileWithPages> = [];
  Promise.all(promises)
    .then((downloadedFiles) => {
      downloadedFiles.forEach((file, index) => {
        files.push({ file: file, pages: fileData[index].pages });
      });
      onLoadedFiles(files);
    })
    .catch((error) => {
      Sentry.captureException(error, {
        extra: {
          //'file_data': fileData
        },
      });
      setRedirect(<Redirect to="/503" />);
    });
}

function loadPureFiles(
  fileData: Array<ProofFileData>,
  onLoadedFiles: (files: Array<File>) => void,
  setRedirect: (redirect: JSX.Element) => void
) {
  const promises: Array<Promise<File>> = [];
  for (let file of fileData) {
    promises.push(downloadFile(file.file_path));
  }
  const files: Array<File> = [];
  Promise.all(promises)
    .then((downloadedFiles) => {
      downloadedFiles.forEach((file, index) => {
        files.push(file);
      });
      onLoadedFiles(files);
    })
    .catch((error) => {
      Sentry.captureException(error, {
        extra: {
          //'file_data': fileData
        },
      });
      setRedirect(<Redirect to="/503" />);
    });
}

function downloadFile(fileName: string): Promise<File> {
  return new Promise((resolve, reject) => {
    axios(`api/v1/get_file`, {
      method: "POST",
      //responseType: 'blob',
      withCredentials: true,
      params: {
        file_path: fileName,
      },
    })
      .then((response) => {
        const link = response.data.data.link;
        const fileType = response.data.data.file_type;
        const file_names = fileName.split("/");
        fetch(link)
          .then((r) => r.blob())
          .then((blob) => {
            const file = new File([blob], file_names[file_names.length - 1], {
              type: fileType,
            });
            resolve(file);
          })
          .catch((error) => {
            Sentry.captureException(error, { extra: { file_name: fileName } });
            reject(error);
          });
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            //'file_name': fileName
          },
        });
        reject(error);
      });
  });
}

function UploadApplicationPage() {
  const { id } = useParams();
  const [files, setFiles] = useState<Array<FileWithPages>>([]);
  const [proofFiles, setProofFiles] = useState<Array<File> | undefined>(
    undefined
  );
  //const [title, setTitle] = useState<string | null>(null);

  const [caseInfo, setCaseInfo] = useState<CaseInfo | undefined>(undefined);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [redirect, setRedirect] = useState<JSX.Element | null>(null);
  const [status, setStatus] = useState<string | undefined>(undefined);

  useEffect(() => {
    axios(`api/v1/draft_cases/${id}`, {
      method: "GET",
      withCredentials: true,
      data: {
        page: "edit",
      },
    })
      .then((response) => response.data)
      .then((data) => {
        var embededData = data.data;
        if (data.error || !embededData) {
          // TODO: user history.push
          setRedirect(<Redirect to="/503" />);
        } else {
          var caseData = embededData.case as CaseData;
          if (caseData) {
            setStatus(embededData.case.status);
            setCaseInfo({
              schoolId: caseData.school_id ? String(caseData.school_id) : "-1",
              yearId: String(caseData.year_id),
              majorId: caseData.major_id ? String(caseData.major_id) : "-1",
              newlyAddedMajorName: caseData.newly_added_major_name,
              newlyAddedSchoolName: caseData.newly_added_school_name,
              applicationRoundId: String(caseData.application_round_id),
              scholarshipId: String(caseData.scholarship_id),
              essays: caseData.essays,
              legacy: caseData.legacy || false,
            });
            setTitle(caseData.title);
            downloadFiles(caseData.files, setFiles, setRedirect);
            loadPureFiles(caseData.proof_files, setProofFiles, setRedirect);
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        // TODO: add error logger
        // TODO: use history.push
        setRedirect(<Redirect to="/503" />);
      });
  }, [id]);

  if (redirect) {
    return redirect;
  }

  if (files.length === 0 || !proofFiles || !title || !caseInfo || !id) {
    return (
      <img
        src={require("../../resources/green_loader.svg").default}
        width="150"
        style={{ margin: "auto" }}
        alt=""
      />
    );
  }

  if (status !== "pending_submission" && status !== "needs_revision") {
    return <Redirect to="/503" />;
  }

  return (
    <div
      style={{
        backgroundColor: theme.palette.secondary.light,
        display: "flex",
        flexDirection: "column",
      }}
      className="page-container"
    >
      <Helmet>
        <title>Edit your application - CollegeSharing</title>
      </Helmet>
      <Stepper
        caseId={id}
        files={files}
        proofFiles={proofFiles}
        title={title}
        caseInfo={caseInfo}
      />
    </div>
  );
}

export default UploadApplicationPage;
