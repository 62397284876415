import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { CaseEarning } from "../../types/types";
import RoundedButton from "./PrimaryButton";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";

interface Props {
  case_earnings: Array<CaseEarning>;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    body: {
      fontSize: 16,
      fontFamily: "SuperaGothic",
      fontWeight: "normal",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(4n+1)": {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.action.hover,
      },
      "& > *": {
        borderBottom: "unset",
      },
    },
  })
)(TableRow);

const StyledInnerCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      //backgroundColor: theme.palette.primary.main,
      //color: theme.palette.common.white,
      fontSize: "1rem",
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      fontFamily: "SuperaGothic",
      fontWeight: "normal",
    },
  })
)(TableCell);

const StyledInnerRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(4n+2)": {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function Row(props: CaseEarning) {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {props.school_name}
        </StyledTableCell>
        <StyledTableCell align="right">{props.year_name}</StyledTableCell>
        <StyledTableCell align="right">{props.total_views}</StyledTableCell>
        <StyledTableCell align="right">
          ${props.onboarding_reward}
        </StyledTableCell>
        <StyledTableCell align="right">
          <RoundedButton
            variant="outlined"
            style={{ borderRadius: "30px", color: "white", border: "none" }}
            buttonStyle="secondary"
            link={`/profile/${props.id}`}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: "16px",
                fontFamily: "SuperaGothic",
                lineHeight: "normal",
              }}
            >
              View
            </Typography>
          </RoundedButton>
        </StyledTableCell>
      </StyledTableRow>
      <StyledInnerRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                style={{ fontFamily: "SuperaGothic", fontWeight: "bold" }}
              >
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledInnerCell>Month</StyledInnerCell>
                    <StyledInnerCell align="right">Views</StyledInnerCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.monthly_earnings.map((monthly_earning) => (
                    <TableRow key={monthly_earning.month}>
                      <StyledInnerCell component="th" scope="row">
                        {monthly_earning.month}
                      </StyledInnerCell>
                      <StyledInnerCell align="right">
                        {monthly_earning.views}
                      </StyledInnerCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={1}
        ></TableCell>
      </StyledInnerRow>
    </React.Fragment>
  );
}

export default function CustomizedTables(props: Props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>School</StyledTableCell>
            <StyledTableCell align="right">Year</StyledTableCell>
            <StyledTableCell align="right">Views</StyledTableCell>
            <StyledTableCell align="right">Onboarding Reward</StyledTableCell>
            <StyledTableCell align="right">Details</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.case_earnings.map((row) => (
            <Row {...row} key={row.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
