const countryToISOCode = [
  {
    name: "united states",
    code: "US",
  },
  {
    name: "china",
    code: "CN",
  },
  {
    name: "united kingdom",
    code: "GB",
  },
  {
    name: "belgium",
    code: "BE",
  },
  {
    name: "brazil",
    code: "BR",
  },
  {
    name: "canada",
    code: "CA",
  },
  {
    name: "canada",
    code: "CA",
  },
  {
    name: "cambodia",
    code: "KH",
  },
  {
    name: "chile",
    code: "CL",
  },
  {
    name: "chile",
    code: "CL",
  },
  {
    name: "colombia",
    code: "CO",
  },
  {
    name: "congo",
    code: "CG",
  },
  {
    name: "congo",
    code: "CG",
  },
  {
    name: "croatia",
    code: "HR",
  },
  {
    name: "cuba",
    code: "CU",
  },
  {
    name: "denmark",
    code: "DK",
  },
  {
    name: "dominica",
    code: "DM",
  },
  {
    name: "egypt",
    code: "EG",
  },
  {
    name: "estonia",
    code: "EE",
  },
  {
    name: "finland",
    code: "FI",
  },
  {
    name: "france",
    code: "FR",
  },
  {
    name: "georgia",
    code: "GE",
  },
  {
    name: "germany",
    code: "DE",
  },
  {
    name: "greece",
    code: "GR",
  },
  {
    name: "Greenland",
    code: "GL",
  },
  {
    name: "haiti",
    code: "HT",
  },
  {
    name: "haiti",
    code: "HT",
  },
  {
    name: "hong kong",
    code: "HK",
  },
  {
    name: "hungary",
    code: "HU",
  },
  {
    name: "iceland",
    code: "IS",
  },
  {
    name: "india",
    code: "IN",
  },
  {
    name: "indonesia",
    code: "ID",
  },
  {
    name: "ireland",
    code: "IE",
  },
  {
    name: "israel",
    code: "IL",
  },
  {
    name: "italy",
    code: "IT",
  },
  {
    name: "jamaica",
    code: "JM",
  },
  {
    name: "japan",
    code: "JP",
  },
  {
    name: "korea",
    code: "KR",
  },
  {
    name: "macao",
    code: "MO",
  },
  {
    name: "malaysia",
    code: "MY",
  },
  {
    name: "mexico",
    code: "MX",
  },
  {
    name: "monaco",
    code: "MC",
  },
  {
    name: "singapore",
    code: "SG",
  },
  {
    name: "south africa",
    code: "ZA",
  },
  {
    name: "spain",
    code: "ES",
  },
  {
    name: "sweden",
    code: "SE",
  },
  {
    name: "switzerland",
    code: "CH",
  },
  {
    name: "taiwan",
    code: "TW",
  },
  {
    name: "thailand",
    code: "TH",
  },
  {
    name: "turkey",
    code: "TR",
  },
];

export default countryToISOCode;
