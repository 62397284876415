import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import theme from "../../themes/Theme";
import Button from "@material-ui/core/Button";
import PageTitle from "../general/PageTitle";
import { RectWithId } from "../upload/ImageEditor";
import { FileWithPages } from "../general/Stepper";
import { Typography } from "@material-ui/core";
import Spacing from "../general/Spacing";
import { Paper } from "@material-ui/core";
import WhiteCircle from "../../resources/whiteCircle.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: "0px 15px",
    },
    paper: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    imageContainer: {
      display: "flex",
      alignItems: "center",
      margin: "5px",
      height: "calc(100% - 10px)",
    },
    redHatDisplay: {
      fontFamily: "SuperaGothic",
    },
    border: {
      borderBottom: "1px solid #e5e5e5",
    },
    pageContainer: {
      margin: "10px",
      height: "calc(100% - 20px)",
      textAlign: "left",
      overflow: "hidden",
    },
    page: {
      margin: "5%",
      width: "90%",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    clickButton: {
      position: "absolute",
      marginTop: "5px",
      marginLeft: "5px",
      color: theme.palette.primary.main,
    },
    imageHeader: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: "300px",
      },
    },
  })
);

const available_types = [
  "Testing",
  "Activities",
  "Essays",
  "Profile",
  "Honors",
  "Others",
];

export interface Page {
  purePage?: string;
  renderablePage?: string;
  selected: boolean;
  rects: Array<RectWithId>;
  types: Array<string>;
}

interface Props {
  disableBack: boolean;
  handleBack: () => void;
  handleNext: (uploadedFiles: Array<FileWithPages>) => void;
  nextButtonText: string;
  files: Array<FileWithPages>;
  shouldProceedToNext?: boolean;
  setShouldProceedToNext?: (value: boolean) => void;
  shouldProceedToPrevious?: boolean;
  setShouldProceedToPrevious?: (value: boolean) => void;
}

function SelectPagesPage(props: Props) {
  const classes = useStyles();
  const [filesWithPages, setFilesWithPages] = useState<Array<FileWithPages>>(
    props.files
  );
  var concatedPages: Array<Page> = [];
  props.files.forEach((file) => {
    concatedPages = concatedPages.concat(
      file.pages.filter((page) => page.purePage)
    );
  });

  const onPageClick = (type: string, num: number) => {
    let newFiles = Array.from(filesWithPages);
    var count = 0;
    for (let i = 0; i < newFiles.length; i++) {
      if (count + newFiles[i].pages.length > num) {
        if (newFiles[i].pages[num - count].types.includes(type)) {
          newFiles[i].pages[num - count].types = newFiles[i].pages[
            num - count
          ].types.filter((includedType) => includedType !== type);
        } else {
          newFiles[i].pages[num - count].types.push(type);
        }
        break;
      } else {
        count += newFiles[i].pages.length;
      }
    }
    setFilesWithPages(newFiles);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      props.shouldProceedToPrevious === true &&
      props.setShouldProceedToPrevious
    ) {
      props.handleBack();
      props.setShouldProceedToPrevious(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToPrevious]);

  useEffect(() => {
    if (props.shouldProceedToNext === true && props.setShouldProceedToNext) {
      props.handleNext(filesWithPages);
      props.setShouldProceedToNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToNext]);

  const select_pages_section = available_types.map((type) => {
    const section = GetSelectTypeSection(concatedPages, type, onPageClick);
    return (
      <div key={type} className={classes.border}>
        <Spacing spacing={12} />
        <Typography variant="h5" className={classes.redHatDisplay}>
          {type}
        </Typography>
        <Spacing spacing={8} />
        {section}
      </div>
    );
  });

  if (concatedPages === null || concatedPages.length === 0) {
    return null;
  }

  return (
    <div>
      <PageTitle title="Assign Page to a Type" />
      <div className={classes.contentContainer}>
        {select_pages_section}
        <div style={{ padding: "20px 0px" }}>
          <Button
            disabled={props.disableBack}
            onClick={props.handleBack}
            className={classes.backButton}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.handleNext(filesWithPages)}
          >
            {props.nextButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
}

function GetSelectTypeSection(
  pages: Array<Page>,
  type: string,
  onPageClick: (type: string, num: number) => void
) {
  const classes = useStyles();
  if (pages !== null && pages.length !== 0) {
    let grids = pages.map((dataUrl, index) => {
      if (!dataUrl.selected) {
        return null;
      }
      const color = dataUrl.types.includes(type)
        ? theme.palette.primary.main
        : theme.palette.secondary.main;
      const borderStyle = dataUrl.types.includes(type)
        ? `2px solid ${theme.palette.primary.main}`
        : `2px solid ${theme.palette.secondary.main}`;
      return (
        <Grid key={index} item xs={6} md={4} lg={3} xl={2}>
          <div
            className={classes.pageContainer}
            style={{ border: borderStyle }}
            onClick={() => onPageClick(type, index)}
          >
            <CheckCircleIcon
              className={classes.clickButton}
              style={{
                color: color,
                backgroundImage: `url(${WhiteCircle})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                fontSize: "2rem",
              }}
            />
            <div className={classes.imageContainer}>
              <Paper
                className={classes.paper}
                elevation={dataUrl.types.includes(type) ? 5 : 1}
                square={true}
              >
                <img
                  src={dataUrl.purePage}
                  className={classes.page}
                  alt=""
                ></img>
              </Paper>
            </div>
          </div>
        </Grid>

        /*<Grid key={index} item xs={4} md={3} lg={2} xl={1}>
                    <div className={classes.pageContainer} style={{outline: borderStyle}} onClick={() => onPageClick(type, index)}>
                        <CheckCircleIcon className={classes.clickButton} style={{color: color, fontSize:'2rem'}}/>
                        <img key={index} src={dataUrl.purePage} className={classes.page} alt=''></img>
                    </div>
                </Grid>*/
      );
    });
    return (
      <Grid container spacing={0}>
        {grids}
      </Grid>
    );
  }

  return null;
}

export default SelectPagesPage;
