import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, CardContent } from "@material-ui/core";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: "0px 15px",
      backgroundColor: "rgba(14, 127, 138, 0.8)",
    },
    paper: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    innerCard: {
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
      [theme.breakpoints.up("md")]: {
        padding: "16px",
      },
    },
    card: {
      //backgroundColor: '#E2F0F2',
      borderRadius: "10px",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        padding: "0vw",
      },
      [theme.breakpoints.up("md")]: {
        padding: "12px",
        height: "100%",
      },
      width: "auto",
    },
    margin: {
      [theme.breakpoints.down("md")]: {
        margin: "5%",
      },
      [theme.breakpoints.up("md")]: {
        margin: "10%",
      },
      height: "100%",
    },
    image: {
      width: "40%",
      margin: "auto",
    },
    h6: {
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 24,
      },
    },
  })
);

interface Props {
  icon: string;
  index: number;
  title: string;
  text: string;
  updateImage: (index?: number) => void;
  shouldElevate: boolean;
  clickable: boolean;
  transparent?: boolean;
}

function InstructionCard(props: Props) {
  const classes = useStyles();
  const onMouseOver = () => {
    props.updateImage(props.index);
  };
  const backgroundColor = props.transparent
    ? "rgba(14, 127, 138, 0)"
    : "#E2F0F2";
  const shade = props.transparent
    ? "none"
    : props.shouldElevate
    ? "0px 8px 19px #2D8093"
    : "0px 1px 3px #2D8093";

  return (
    <Card
      className={classes.card}
      elevation={0}
      style={{
        backgroundColor: backgroundColor,
        cursor: props.clickable ? "pointer" : "auto",
        boxShadow: shade,
      }}
      onClick={onMouseOver}
    >
      <CardContent className={classes.innerCard}>
        <div style={{ display: "flex", margin: "auto" }}>
          <div
            style={{
              color: "#0077FF",
              backgroundColor: "#C4E6EB",
              borderRadius: "50%",
              fontSize: 20,
              fontFamily: "SuperaGothic",
              width: 32,
              height: 32,
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: 20,
                fontWeight: "bold",
                fontFamily: "SuperaGothic-ExtraBold",
                textAlign: "center",
              }}
            >
              {props.index}
            </Typography>
          </div>
          <Typography
            variant="h6"
            style={{
              marginLeft: 10,
              fontSize: 20,
              fontWeight: "bold",
              fontFamily: "SuperaGothic-ExtraBold",
              textAlign: "left",
            }}
          >
            {props.title}
          </Typography>
        </div>
        <Typography
          variant="body2"
          style={{
            marginTop: 25,
            fontFamily: "SuperaGothic",
            textAlign: "left",
          }}
        >
          {props.text}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default InstructionCard;
