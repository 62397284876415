import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import theme from "../../themes/Theme";
import { saveProfile, unSaveProfile } from "../../api/RecordAPI";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GeneralProfilePreview } from "../../types/admitProfileTypes";
import { Avatar } from "@material-ui/core";
import { ReactComponent as AvatarIcon } from "../../resources/icons/middle_screen_avatar.svg";
import { ReactComponent as BlueSchoolIcon } from "../../resources/icons/blue_school.svg";
import { ReactComponent as AwardIcon } from "../../resources/icons/award.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "400px",
      minWidth: "280px",
      marginLeft: "auto",
      marginRight: "auto",
      height: "100%",
      "&:hover": {
        background: "rgba(250,250,250,0.8)",
      },
      boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.1)",
      borderRadius: 20,
    },
    cardContent: {
      textAlign: "left",
      paddingLeft: "14px",
      paddingRight: "14px",
      paddingTop: 0,
    },
    normalLineHeight: {
      lineHeight: "normal",
    },
    lineClamp3Height: {
      lineHeight: "1.5",
    },
    backdrop: {
      zIndex: 1500,
      color: "#fff",
      alignItems: "center",
    },
    overflow: {
      overflow: "hidden",
      margin: 14,
      borderRadius: 14,
      flexGrow: 0,
    },
    viewCount: {
      color: theme.palette.secondary.main,
      margin: "0px 10px",
      fontFamily: "SuperaGothic",
      fontSize: "16px",
      //fontWeight: 'bold',
    },
    icon: {
      fontSize: "20px",
      margin: "auto 0px",
      color: theme.palette.secondary.main,
    },
  })
);

interface Props {
  profile: GeneralProfilePreview;
  shouldShowAction?: boolean;
  visibleByDefault?: boolean;
}

export default function AdmitProfileCard(props: Props) {
  const classes = useStyles();
  const [saved, setSaved] = useState(props.profile.saved);

  const color = !saved ? theme.palette.text.secondary : "#dc034e";
  const buttonStyle = {
    color: color,
  };
  const onClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setSaved(!saved);
    if (saved) {
      unSaveProfile(props.profile.id, setSaved);
    } else {
      saveProfile(props.profile.id, setSaved);
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <Card
        className={`${classes.root} no-tap`}
        style={{ cursor: "pointer", position: "relative" }}
        elevation={0}
      >
        <Link to={`/admit_profile/${props.profile.id}`} target="_blank">
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <LazyLoadImage
              src={props.profile.coverUrl}
              height={165}
              effect="blur"
              visibleByDefault={props.visibleByDefault}
              placeholderSrc={""}
              wrapperClassName={classes.overflow}
            />
            <div
              style={{
                fontFamily: "RedHatDisplay-SemiBold",
                fontSize: 14,
                padding: "0px 8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                background: "rgba(0, 0, 0, 0.4)",
                color: "white",
                height: 37,
                borderRadius: 10,
                position: "absolute",
                top: 25,
                right: 25,
              }}
            >
              {props.profile.shortSchoolName}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
              }}
            >
              <CardContent className={classes.cardContent} style={{}}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    style={{
                      border: "2px solid rgba(14, 127, 138, 0.5)",
                      backgroundColor: "rgba(255, 255, 255, 0.25)",
                      width: 49,
                      height: 49,
                      marginRight: 8,
                    }}
                  >
                    <AvatarIcon style={{ width: 24 }} />
                  </Avatar>
                  <div>
                    <div
                      style={{
                        fontFamily: "RedHatDisplay-Bold",
                        fontSize: 20,
                        lineHeight: "24px",
                      }}
                    >
                      {props.profile.userName}
                    </div>
                    <div
                      style={{
                        fontFamily: "RedHatDisplay-SemiBold",
                        fontSize: 14,
                        color: "#0E7F8A",
                        marginTop: 8,
                        lineHeight: "17px",
                      }}
                    >
                      {props.profile.year}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: 1,
                    width: "100%",
                    marginTop: 15,
                    marginBottom: 14,
                    background:
                      "linear-gradient(90deg, rgba(56, 185, 194, 0) 0%, rgba(56, 185, 194, 0.5) 48.96%, rgba(255, 255, 255, 0) 100%, rgba(56, 185, 194, 0) 100%)",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 13,
                  }}
                >
                  <BlueSchoolIcon style={{ marginRight: 10 }} />
                  <div
                    style={{
                      fontSize: 14,
                      fontFamily: "RedHatDisplay-SemiBold",
                      color: "#0E7F8A",
                    }}
                  >
                    {props.profile.major}
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    fontSize: 14,
                    lineHeight: "150%",
                    maxHeight: "4.5em",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    overflow: "hidden",
                    fontFamily: "RedHatDisplay-Medium",
                  }}
                >
                  {props.profile.introduction}
                </div>
              </CardContent>
              {
                <CardActions
                  disableSpacing
                  style={{
                    bottom: 0,
                    width: "100%",
                    paddingTop: 0,
                    paddingLeft: 14,
                    paddingBottom: 14,
                    paddingRight: 14,
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      background: "rgba(14, 127, 138, 0.1)",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: 10,
                      fontSize: 14,
                      padding: "8px 14px",
                      paddingLeft: 8,
                      fontFamily: "RedHatDisplay-Bold",
                    }}
                  >
                    <AwardIcon style={{ marginRight: 5 }} />
                    Admitted to {props.profile.numSchoolsAdmittedTo} schools
                  </div>
                  {props.shouldShowAction && saved !== null && (
                    <IconButton
                      aria-label="add to favorites"
                      style={buttonStyle}
                      onClick={onClick}
                    >
                      <FavoriteIcon />
                    </IconButton>
                  )}
                </CardActions>
              }
            </div>
          </div>
        </Link>
      </Card>
    </div>
  );
}
