import React, { useState, useEffect } from "react";
import axios from "axios";
import { Payout } from "../../types/types";
import { useHistory } from "react-router";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import ProfileSection from "./profile/ProfileSection";
import Grid from "@material-ui/core/Grid";
import * as Sentry from "@sentry/browser";
import SnackBar from "../general/SnackBar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import RoundedButton from "../general/PrimaryButton";
import Typography from "@material-ui/core/Typography";
import ConfirmPaymentPanel from "../general/ConfirmPayoutPanel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moneyInput: {
      width: "80%",
      maxWidth: "200px",
    },
    sideMargin: {
      margin: "10px 0px",
      width: "100%",
    },
    text: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    normalText: {
      fontFamily: "SuperaGothic",
      //marginBottom: '10px',
      fontSize: "16px",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
    },
    root: {
      [theme.breakpoints.down("sm")]: {
        margin: "0px 15px",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0rem 3rem",
      },
      //minHeight: "70%",
    },
  })
);

export default function EarningsPage() {
  const classes = useStyles();
  const [pendingPayouts, setPendingPayouts] = useState<Array<Payout>>([]);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const history = useHistory();
  useEffect(() => {
    if (!refresh) {
      return;
    }
    setRefresh(false);
    axios(`api/v1/stripe/get_pending_payouts`, {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        var embededData = data.data;
        if (embededData) {
          var pending_payouts = embededData as Array<Payout>;
          if (pending_payouts) {
            setPendingPayouts(pending_payouts);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        } else {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        }
        Sentry.captureException(error);
      });
  }, [history, refresh]);

  return (
    <div className={`${classes.root} page-container`}>
      <Helmet>
        <title>Pending payouts - CollegeSharing</title>
      </Helmet>
      <SnackBar message={message} callBack={() => setMessage(undefined)} />
      {pendingPayouts && (
        <ProfileSection title="Key Statistics">
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
              <Typography variant="h3">{pendingPayouts.length}</Typography>
              <Typography variant="h6">Pending Payouts</Typography>
            </Grid>
          </Grid>
        </ProfileSection>
      )}
      {pendingPayouts && (
        <ProfileSection title="Pending Payouts">
          <CustomizedTables
            payouts={pendingPayouts}
            prefix="/review/"
            refresh={() => setRefresh(true)}
            displayErrorMessage={setMessage}
          />
        </ProfileSection>
      )}
    </div>
  );
}

interface TableProps {
  payouts: Array<Payout>;
  prefix: string;
  refresh: () => void;
  displayErrorMessage: (message: string) => void;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    body: {
      fontSize: 16,
      fontFamily: "SuperaGothic",
      fontWeight: "normal",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(2n+1)": {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.action.hover,
      },
      "& > *": {
        borderBottom: "unset",
      },
    },
  })
)(TableRow);

const useTableStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

interface RowProps {
  payout: Payout;
  prefix: string;
  refresh: () => void;
  displayErrorMessage: (message: string) => void;
}

function Row(props: RowProps) {
  const [open, setOpen] = useState<boolean>(false);
  const confirmPayout = () => {
    axios(`api/v1/stripe/confirm_payout`, {
      method: "POST",
      withCredentials: true,
      data: {
        id: props.payout.id,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        props.refresh();
      })
      .catch((error) => {
        props.displayErrorMessage("Failed to mark the payout as completed");
        Sentry.captureException(error);
      });
  };

  const onSubmit = () => {
    confirmPayout();
    setOpen(false);
  };
  return (
    <React.Fragment>
      <ConfirmPaymentPanel
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={onSubmit}
        payout={props.payout}
      />
      <StyledTableRow>
        <StyledTableCell component="th" scope="row">
          {props.payout.id}
        </StyledTableCell>
        <StyledTableCell align="right">{props.payout.userId}</StyledTableCell>
        <StyledTableCell align="right">{props.payout.amount}</StyledTableCell>
        <StyledTableCell align="right">
          {props.payout.accountType}
        </StyledTableCell>
        <StyledTableCell align="right">{props.payout.receiver}</StyledTableCell>
        <StyledTableCell align="right">
          <RoundedButton
            variant="outlined"
            style={{ borderRadius: "30px", color: "white", border: "none" }}
            buttonStyle="secondary"
            onClick={() => setOpen(true)}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: "16px",
                fontFamily: "SuperaGothic",
                lineHeight: "normal",
              }}
            >
              Confirm
            </Typography>
          </RoundedButton>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

function CustomizedTables(props: TableProps) {
  const classes = useTableStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Payout ID</StyledTableCell>
            <StyledTableCell align="right">User ID</StyledTableCell>
            <StyledTableCell align="right">Amount</StyledTableCell>
            <StyledTableCell align="right">Payment Platform</StyledTableCell>
            <StyledTableCell align="right">Account</StyledTableCell>
            <StyledTableCell align="right">Details</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.payouts.map((payout) => (
            <Row
              payout={payout}
              prefix={props.prefix}
              key={payout.id}
              refresh={props.refresh}
              displayErrorMessage={props.displayErrorMessage}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
