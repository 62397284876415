import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import ProfileSection from "./profile/ProfileSection";
import { Typography } from "@material-ui/core";
import * as Sentry from "@sentry/browser";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrimaryButton from "../general/PrimaryButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import theme from "../../themes/Theme";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { SimpleDraftCase } from "../../types/serverSideTypes";
import SimpleDraftCaseTable from "../general/SimpleDraftCaseTable";
import UploadSuccessPage from "./UploadSuccessPage";
import Banner from "../general/Banner";
import ApplicationSubmissionModal from "../general/ApplicationSubmissionModal";
const queryString = require("query-string");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moneyInput: {
      width: "80%",
      maxWidth: "200px",
    },
    sideMargin: {
      margin: "10px 0px",
      width: "100%",
    },
    container: {
      margin: "40px 0px",
      //marginTop: '40px',
    },
    text: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    normalText: {
      fontFamily: "SuperaGothic",
      //marginBottom: '10px',
      fontSize: "16px",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
    },
    root: {
      [theme.breakpoints.down("sm")]: {
        margin: "0px 15px",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0rem 3rem",
      },
      //minHeight: "70%",
    },
  })
);

export default function EarningsPage() {
  const classes = useStyles();
  //const [earning, setEarning] = useState<Earning | undefined>(undefined);

  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const showBanner = parsed["showBanner"];

  const [initialUpload, setInitialUpload] = useState<boolean | undefined>(
    undefined
  );
  const [step, setStep] = useState<string | undefined>(undefined);
  const [draftCases, setDraftCases] = useState<Array<SimpleDraftCase>>([]);
  const [activeCases, setActiveCases] = useState<Array<SimpleDraftCase>>([]);

  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    axios(`api/v1/get_upload_page_layout`, {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        var embededData = data.data;
        if (embededData) {
          setInitialUpload(embededData.initial_upload);
          setStep(embededData.step);
          if (embededData.draft_cases) {
            setDraftCases(embededData.draft_cases || []);
            setActiveCases(embededData.active_cases || []);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          // TODO: add error logger
          //history.replace({pathname: '/503', state: {from: history.location}});
        } else {
          // TODO: add error logger
          //history.replace({pathname: '/503', state: {from: history.location}});
        }
        Sentry.captureException(error);
      });
  }, []);

  const submitForReview = () => {
    setOpen(false);
    setUploading(true);
    axios(`api/v1/submissions`, {
      method: "POST",
      withCredentials: true,
    })
      .then((response) => {
        window.scrollTo(0, 0);
        setUploading(false);
        setUploadSuccess(true);
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        setUploading(false);
        setUploadSuccess(false);
      });
  };

  if (initialUpload === undefined || step === undefined) {
    return <CircularProgress style={{ margin: "auto" }} />;
  }

  if (uploadSuccess) {
    return <UploadSuccessPage />;
  }

  if (initialUpload) {
    let focusIndex;
    if (step === "general_profile") {
      focusIndex = 0;
    } else if (
      step === "supplements" &&
      (!draftCases || draftCases.length === 0)
    ) {
      focusIndex = 1;
    } else {
      focusIndex = 2;
    }
    const text = focusIndex < 2 ? "Add School" : "Add Another School";
    const firstButtonText = focusIndex === 0 ? "Get Started" : "Edit Profile";
    const firstButtonLink = focusIndex === 0 ? "/upload" : "/edit_general";

    let lineColor = undefined;

    if (focusIndex === 0) {
      lineColor = theme.palette.secondary.main;
    } else if (focusIndex === 2) {
      lineColor = theme.palette.primary.main;
    }
    return (
      <div
        className={`${classes.root} page-container`}
        style={{ marginTop: 30 }}
      >
        <Helmet>
          <title>Uploaded Applications - CollegeSharing</title>
        </Helmet>
        {showBanner && (
          <div className={classes.container}>
            <Banner
              title={"Application Successfully Saved"}
              subtitle={
                "Your application has been successfully saved! Please submit your application once you have uploaded all the essays."
              }
            />
          </div>
        )}
        {open && (
          <ApplicationSubmissionModal
            open={open}
            handleClose={() => setOpen(false)}
            handleConfirm={() => submitForReview()}
            schools={draftCases}
          />
        )}
        <div style={{ display: "flex", maxWidth: 600 }}>
          <div>
            <AccountCircleIcon style={{ fontSize: 48 }} color={"primary"} />
            <div
              style={{
                width: 2,
                bottom: 0,
                margin: "5px auto",
                backgroundImage:
                  focusIndex === 0
                    ? `linear-gradient(${theme.palette.primary.main}, ${theme.palette.secondary.main})`
                    : undefined,
                backgroundColor:
                  step === "general_profile"
                    ? undefined
                    : theme.palette.primary.main,
                height: "calc(100% - 58px)",
              }}
            ></div>
          </div>
          <div style={{ textAlign: "left", marginLeft: 20, marginBottom: 40 }}>
            <Typography
              style={{
                fontSize: 24,
                fontWeight: "bold",
                fontFamily:
                  focusIndex === 0 ? "SuperaGothic-ExtraBold" : "SuperaGothic",
              }}
            >
              Step 1: Tell Us About Yourself
            </Typography>
            <Typography style={{ fontSize: 16 }}>
              Answer some general questions about your application, including
              standard tests, activities and more.
            </Typography>
            <PrimaryButton
              style={{ marginTop: 20, marginBottom: 40 }}
              href={firstButtonLink}
              label="upload_status.upload_general"
            >
              <Typography variant="h6" style={{ fontFamily: "RedHatDisplay" }}>
                {firstButtonText}
              </Typography>
            </PrimaryButton>
          </div>
        </div>
        <div style={{ display: "flex", maxWidth: 600 }}>
          <div>
            <AccountBalanceIcon
              style={{ fontSize: 48 }}
              color={focusIndex >= 1 ? "primary" : "secondary"}
            />
            <div
              style={{
                width: 2,
                bottom: 0,
                margin: "5px auto",
                backgroundImage:
                  focusIndex === 1
                    ? `linear-gradient(${theme.palette.primary.main}, ${theme.palette.secondary.main})`
                    : undefined,
                backgroundColor: lineColor,
                height: "calc(100% - 58px)",
              }}
            ></div>
          </div>
          <div
            style={{
              textAlign: "left",
              marginLeft: 20,
              marginBottom: 40,
              width: "calc(100% - 68px)",
            }}
          >
            <Typography
              style={{
                fontSize: 24,
                fontWeight: "bold",
                fontFamily:
                  focusIndex === 1 ? "SuperaGothic-ExtraBold" : "SuperaGothic",
              }}
            >
              Step 2: Upload School Supplements
            </Typography>
            <Typography style={{ fontSize: 16 }}>
              Upload supplemental essays for every school you were accepted to;
              you will also need to provide acceptance letters to verify your
              admission status.
            </Typography>
            <PrimaryButton
              style={{ marginTop: 20, marginBottom: 40 }}
              href="/upload"
              label="upload_status.add_school"
              disabled={focusIndex < 1}
            >
              <Typography
                variant="h6"
                style={{ fontFamily: "RedHatDisplay", color: "white" }}
              >
                {text}
              </Typography>
            </PrimaryButton>
            <div style={{ marginBottom: 40 }}>
              <SimpleDraftCaseTable
                cases={draftCases}
                mode={"edit"}
                buttonText={"Edit"}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", maxWidth: 600 }}>
          <div>
            <CloudUploadIcon
              style={{ fontSize: 48 }}
              color={focusIndex === 2 ? "primary" : "secondary"}
            />
          </div>
          <div style={{ textAlign: "left", marginLeft: 20, marginBottom: 40 }}>
            <Typography
              style={{
                fontSize: 24,
                fontWeight: "bold",
                fontFamily:
                  focusIndex === 2 ? "SuperaGothic-ExtraBold" : "SuperaGothic",
              }}
            >
              Step 3: Submit
            </Typography>
            <Typography style={{ fontSize: 16 }}>
              Submit to CollegeSharing and wait for us to make an offer through
              email.
            </Typography>
            <PrimaryButton
              style={{ marginTop: 20, marginBottom: 40 }}
              buttonStyle="highlighted"
              onClick={() => setOpen(true)}
              label="upload_status.submit_fore_review"
              disabled={focusIndex !== 2 || uploading}
            >
              {!uploading && (
                <Typography
                  variant="h6"
                  style={{ fontFamily: "RedHatDisplay" }}
                >
                  Submit for Review
                </Typography>
              )}
              {uploading && (
                <CircularProgress size={32} style={{ color: "white" }} />
              )}
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
  //return null

  const hasUploadedCases =
    draftCases.filter(
      (draftCase) => draftCase.dbStatus === "pending_submission"
    ).length !== 0;
  const buttonText = hasUploadedCases ? "Add Another School" : "Add School";
  const mergedCases = draftCases
    .filter((draftCase) => draftCase.dbStatus !== "pending_submission")
    .concat(activeCases);
  return (
    <div className={`${classes.root} page-container`}>
      <Helmet>
        <title>Uploaded Applications - CollegeSharing</title>
      </Helmet>
      {showBanner && (
        <div className={classes.container}>
          <Banner
            title={"Application Successfully Saved"}
            subtitle={
              "Your application has been successfully saved! Please submit your application once you have uploaded all the essays."
            }
          />
        </div>
      )}
      {open && (
        <ApplicationSubmissionModal
          open={open}
          handleClose={() => setOpen(false)}
          handleConfirm={() => submitForReview()}
          schools={draftCases.filter(
            (draftCase) => draftCase.dbStatus === "pending_submission"
          )}
        />
      )}
      <ProfileSection title="Upload School Supplements">
        <Typography variant="body2" className={classes.normalText}>
          Click the button to below to add more schools specific documents for
          schools you were accepted to. You will be required to submit the
          Common App pdf file as well as proof of acceptance.
        </Typography>
        <PrimaryButton
          style={{ marginTop: 20, marginBottom: 30 }}
          link="/upload"
          label="upload_status.add_school"
        >
          <Typography variant="h6" style={{ fontFamily: "RedHatDisplay" }}>
            {buttonText}
          </Typography>
        </PrimaryButton>
        <div style={{ marginBottom: 30 }}>
          <SimpleDraftCaseTable
            cases={draftCases.filter(
              (draftCase) => draftCase.dbStatus === "pending_submission"
            )}
            mode={"edit"}
            buttonText={"Edit"}
          />
        </div>
        {hasUploadedCases && (
          <PrimaryButton
            style={{ marginBottom: 40 }}
            buttonStyle="highlighted"
            onClick={() => setOpen(true)}
            label="upload_status.submit_for_review"
            disabled={!hasUploadedCases || uploading}
          >
            {!uploading && (
              <Typography variant="h6" style={{ fontFamily: "RedHatDisplay" }}>
                Submit for Review
              </Typography>
            )}
            {uploading && (
              <CircularProgress size={32} style={{ color: "white" }} />
            )}
          </PrimaryButton>
        )}
      </ProfileSection>
      <ProfileSection title="Previously Submitted Supplements">
        <Typography variant="body2" className={classes.normalText}>
          See below for a list of school supplements you have previously
          submitted.
        </Typography>
        <div style={{ marginBottom: 40, marginTop: 20 }}>
          <SimpleDraftCaseTable
            cases={mergedCases}
            mode={"profile"}
            buttonText={"View"}
            showHeader
            onlyShowActionForActiveCase
          />
        </div>
      </ProfileSection>
    </div>
  );
}
