import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Spacing from "../general/Spacing";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    landingImage: {
      "@media (min-width:800px)": {
        marginTop: 40,
        marginLeft: 60,
        width: "50%",
      },
      "@media (max-width:800px)": {
        width: "80%",
        margin: "auto",
      },
    },
    link: {
      fontFamily: "SuperaGothic",
      color: theme.palette.primary.main,
      fontSize: 20,
    },
    textContainer: {
      paddingTop: "5%",
      "@media (min-width:800px)": {
        textAlign: "left",
        width: "30%",
      },
      "@media (max-width:800px)": {
        textAlign: "left",
        paddingBottom: "10%",
        display: "inline-block",
      },
    },
    container: {
      justifyContent: "center",
      "@media (min-width:800px)": {
        display: "flex",
        alignItems: "flex-start",
      },
      "@media (max-width:800px)": {
        display: "block",
        textAlign: "center",
      },
      marginLeft: "5%",
      marginRight: "5%",
      marginBottom: "5%",
    },
  })
);

interface Props {
  message?: string;
}
function GuestLandingPage(props: Props) {
  const styles = useStyles();

  return (
    <div>
      <Helmet>
        <title>Service Unavailable - CollegeSharing</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <Typography variant="h1" style={{ fontFamily: "Righteous" }}>
            Ooops!
          </Typography>
          <Spacing spacing={40} />
          <Typography variant="body1" style={{ fontFamily: "SuperaGothic" }}>
            {props.message ||
              "The server is temporarily unavailable. Please try again later!"}
          </Typography>
          <Spacing spacing={20} />
          <Link to="/" className={styles.link}>
            Home
          </Link>
          <br />
          <Link to="/search" className={styles.link}>
            Browse
          </Link>
          <br />
          <Link to="/host_landing" className={styles.link}>
            Upload
          </Link>
        </div>
        <img
          className={`${styles.landingImage}`}
          src={require("../../resources/ServiceUnavailable.png")}
          alt=""
        />
      </div>
    </div>
  );
}

export default GuestLandingPage;
