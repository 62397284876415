import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../general/PrimaryButton";
import verification from "../../resources/commonApp.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: "SuperaGothic",
      textAlign: "left",
      backgroundColor: "#F0F7F8",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    text: {
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        padding: "10%",
      },
      [theme.breakpoints.up("md")]: {
        padding: "80px 10%",
        width: "50%",
        paddingRight: "50px",
        paddingLeft: "5%",
        marginLeft: "5%",
      },
    },
    image: {
      margin: "auto",
      width: "auto",
    },
    padding: {
      [theme.breakpoints.down("md")]: {
        padding: "60px 10%",
        paddingTop: 0,
        width: "90%",
        margin: "auto",
      },
      [theme.breakpoints.up("md")]: {
        margin: "auto",
        //display:'flex',
        padding: "5% 0%",
        paddingLeft: "0%",
        paddingRight: "5%",
        width: "50%",
        maxWidth: "900px",
      },
    },
    spacing: {
      "@media (min-width:800px)": {
        marginTop: "25px",
        marginBottom: "15px",
      },
      "@media (max-width:800px)": {
        marginTop: "5vw",
        marginBottom: "0px",
      },
    },
    spacingShort: {
      "@media (min-width:800px)": {
        marginTop: "25px",
        marginBottom: "15px",
      },
      "@media (max-width:800px)": {
        marginTop: "2vw",
        marginBottom: "0px",
      },
    },
    fitBackground: {},
    wrapperClassName: {
      display: "flex",
    },
    conditionalText: {
      display: "block",
      "@media (max-width:500px)": {
        display: "none",
      },
    },
    titleSection: {
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      fontWeight: "bold",
    },
  })
);

function VerifiedSection() {
  const styles = useStyles();
  return (
    <div className={`${styles.root}`}>
      <div className={styles.text}>
        <Typography
          variant="h3"
          className={styles.titleSection}
          style={{ fontWeight: "bold" }}
        >
          We Believe In The Power Of Raw Data
        </Typography>
        <Typography
          variant="body1"
          className={`roboto-condensed ${styles.spacing}`}
        >
          At CollegeSharing, we avoid making conclusions for you. In fact, many
          admits don't fully understand why they get accepted.
        </Typography>
        <Typography
          variant="body1"
          className={`roboto-condensed ${styles.spacingShort}`}
        >
          Instead, CollegeSharing features real student profiles with original
          Common App PDFs. Subcribe today to unveil college application secrets
          from authentic statistics and unprocessed data.
        </Typography>
        <div style={{ margin: "auto", textAlign: "center", marginTop: "60px" }}>
          <PrimaryButton link="/sign_up" label="verified_section.sign_up_now">
            <Typography variant="h5">Sign Up Now</Typography>
          </PrimaryButton>
        </div>
      </div>

      <div className={styles.padding}>
        <img
          alt=""
          className={styles.image}
          style={{ margin: "auto" }}
          src={verification}
        />
      </div>
    </div>
  );
}

export default VerifiedSection;
