import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#0E7F8A",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      dark: "#999999",
      main: "#A79D9D",
      light: "#F5F5F5",
      // dark: will be calculated from palette.secondary.main,
    },
    // error: will use the default color
  },
  typography: {
    fontFamily: [
      "SuperaGothic",
      "RedHatDisplay",
      "Righteous",
      "RobotoCondensed",
    ].join(","),
    h3: {
      fontFamily: "SuperaGothic-ExtraBold",
    },
    h4: {
      fontFamily: "SuperaGothic",
    },
    h5: {
      fontFamily: "SuperaGothic",
    },
    h6: {
      fontFamily: "RobotoCondensed",
    },
    subtitle1: {
      fontFamily: "RobotoCondensed",
    },
  },
});

theme = responsiveFontSizes(theme);

theme.typography.h4 = {
  fontFamily: "SuperaGothic",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.6rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1.8rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.0rem",
  },
};

theme.typography.body1 = {
  fontFamily: "SuperaGothic",
  fontWeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.2rem",
  },
};

theme.typography.body2 = {
  fontFamily: "SuperaGothic",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.2rem",
  },
};

export default theme;
