import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      fontFamily: "SuperaGothic",
      textTransform: "none",

      [theme.breakpoints.down("sm")]: {
        padding: "30px",
        display: "flex",
      },
      [theme.breakpoints.up("md")]: {
        padding: "12%",
        display: "inline-block",
      },
      color: "#536C72",
      textAlign: "left",
      borderRadius: "30px",
      height: "100%",
    },
    alternativeImage: {
      width: "40%",
      maxWidth: 60,
      margin: "auto",
      marginLeft: "0",
      marginRight: "0",
      display: "none",
      "@media (max-width:500px)": {
        display: "block",
      },
    },
    image: {
      "@media (max-width:500px)": {
        display: "none",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        width: "20%",
        marginRight: "10%",
      },
      [theme.breakpoints.up("md")]: {
        width: "40%",
      },
    },
    title: {
      fontSize: "32px",
    },
    question: {
      fontFamily: "RobotoCondensed",
      fontWeight: "bold",
    },
    paragraph: {
      fontFamily: "RobotoCondensed",
    },
    conditionalMarginTop: {
      [theme.breakpoints.down("md")]: {
        marginTop: 0,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: 20,
      },
    },
    titleCondition: {
      "@media (max-width:500px)": {
        margin: "auto",
        marginLeft: "20px",
      },
    },
    spacing: {
      [theme.breakpoints.between("xs", "sm")]: {
        marginBottom: "5px",
      },
      "@media (max-width:500px)": {
        marginBottom: "10px",
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: "10px",
      },
    },
  })
);

interface Props {
  imageUrl: string;
  title: string;
  question: string;
  paragraph: string;
}

function CategoryCard(props: Props) {
  const styles = useStyles();
  return (
    <Grid item lg={4} md={4} sm={12} xs={12}>
      <Card
        className={styles.card}
        elevation={0}
        style={{ boxShadow: "0px 29px 99px #C4DDE3" }}
      >
        <img className={styles.image} src={props.imageUrl} alt="" />
        <div>
          <div
            style={{ display: "flex", alignItems: "stretch", marginBottom: 20 }}
          >
            <div className={styles.alternativeImage}>
              <img src={props.imageUrl} alt="" />
            </div>
            <Typography
              variant="h5"
              className={`${styles.conditionalMarginTop} ${styles.titleCondition}`}
              style={{ fontWeight: "bold" }}
            >
              {props.title}
            </Typography>
          </div>
          <Typography
            variant="body2"
            className={`roboto-condensed-bold ${styles.spacing}`}
            style={{ fontFamily: "SuperaGothic-ExtraBold", fontWeight: "bold" }}
          >
            {props.question}
          </Typography>
          <Typography
            variant="body2"
            className={`roboto-condensed`}
            style={{ fontFamily: "SuperaGothic" }}
          >
            {props.paragraph}
          </Typography>
        </div>
      </Card>
    </Grid>
  );
}

export default CategoryCard;
