import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import EnterInfoPage, { CaseInfo } from "../pages/ReviewInformationPage";
import SelecPagesPage, { Page } from "../pages/SelectPagesPage";
import BlackOffPage from "../pages/BlackOffPage";
import SelectTypesPage from "../pages/SelectTypesPage";
import ReviewProofFilesPage from "../pages/ReviewFilePage";
import axios from "axios";
import SubmitDecisionPage, { CaseDecision } from "../pages/SubmitDecisionPage";
import UploadingCasePage from "../pages/UploadingCasePage";
import Banner from "./Banner";
import * as Sentry from "@sentry/browser";
import { useHistory } from "react-router-dom";
import { Essay } from "../../types/uploadTypes";
import SubmissionListPage from "../pages/SubmissionListPage";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      //position: 'absolute',
      display: "flex",
      flexDirection: "column",
    },
    labelRoot: {
      padding: "10px 0px",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    navigationButton: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    arrow: {
      color: theme.palette.primary.main,
      fontSize: 32,
      "&:hover": {
        color: "#0A5961",
      },
    },
    stepper: {
      position: "sticky",
      top: "65px",
      //borderTop: "1px solid #e5e5e5",
      borderBottom: "1px solid #e5e5e5",
      zIndex: 40,
      //paddingTop: "10px",
      //paddingBottom: "10px",
      paddingLeft: 0,
      paddingRight: 0,
    },
    stepLabel: {
      fontFamily: "SuperaGothic",
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 16,
      },
    },
    container: {
      margin: "40px 20px",
    },
    labelContainer: {
      "& $alternativeLabel": {
        marginTop: 4,
      },
    },
    alternativeLabel: {},
  })
);

function getStepContent(
  stepIndex: number,
  nodes: Array<() => React.ReactNode>
): React.ReactNode {
  if (stepIndex < nodes.length) {
    return nodes[stepIndex]();
  }
  return <div></div>;
}

interface Props {
  id: string;
}

export interface FileWithPages {
  file: File;
  pages: Array<Page>;
}

export interface CaseData {
  id: number;
  user_id: number | null;
  school_id: number | null;
  year_id: number;
  major_id: number | null;
  general_application_id: number | null;
  title?: string;
  newly_added_school_name: string | null;
  newly_added_major_name: string | null;
  files: Array<FileData>;
  proof_files: Array<ProofFileData>;
  application_round_id: number;
  scholarship_id: number;
  essays: Array<Essay>;
  legacy?: boolean;
}

export interface FileData {
  file_path: string;
  pages: Array<Page>;
}

export type ProofFileData = {
  file_path: string;
};

function downloadFiles(
  fileData: Array<FileData>,
  onLoadedFiles: (files: Array<FileWithPages>) => void,
  setBannerStatus: (bannerStatus: BannerStatus) => void
) {
  const promises: Array<Promise<File>> = [];
  for (let file of fileData) {
    promises.push(downloadFile(file.file_path));
  }
  const files: Array<FileWithPages> = [];
  Promise.all(promises)
    .then((downloadedFiles) => {
      downloadedFiles.forEach((file, index) => {
        files.push({ file: file, pages: fileData[index].pages });
      });
      onLoadedFiles(files);
    })
    .catch((error) => {
      setBannerStatus({
        subtitle:
          "We had technical challenges to load the application case you are looking for.",
        title: "Failed to load the resource",
      });
      Sentry.captureException(error, {
        extra: {
          //"file_data": fileData
        },
      });
    });
}

function loadPureFiles(
  fileData: Array<ProofFileData>,
  onLoadedFiles: (files: Array<File>) => void,
  setBannerStatus: (bannerStatus: BannerStatus) => void
) {
  const promises: Array<Promise<File>> = [];
  for (let file of fileData) {
    promises.push(downloadFile(file.file_path));
  }
  const files: Array<File> = [];

  Promise.all(promises)
    .then((downloadedFiles) => {
      downloadedFiles.forEach((file, index) => {
        files.push(file);
      });
      onLoadedFiles(files);
    })
    .catch((error) => {
      setBannerStatus({
        subtitle:
          "We had technical challenges to load the application case you are looking for.",
        title: "Failed to load the resource",
      });
      Sentry.captureException(error, {
        extra: {
          //"proof_file_data": fileData
        },
      });
    });
}

function downloadFile(fileName: string): Promise<File> {
  return new Promise((resolve, reject) => {
    axios(`api/v1/get_file`, {
      method: "POST",
      //responseType: 'blob',
      withCredentials: true,
      params: {
        file_path: fileName,
      },
    })
      .then((response) => {
        const link = response.data.data.link;
        const fileType = response.data.data.file_type;
        const file_names = fileName.split("/");
        fetch(link)
          .then((r) => r.blob())
          .then((blob) => {
            const file = new File([blob], file_names[file_names.length - 1], {
              type: fileType,
            });
            resolve(file);
          })
          .catch((error) => {
            Sentry.captureException(error, {
              extra: {
                //"file_name": fileName
              },
            });
            reject(error);
          });
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            file_name: fileName,
          },
        });
        reject(error);
      });
  });
}

function getFinishPage(isLoading: boolean): React.ReactNode {
  if (isLoading) {
    return (
      <UploadingCasePage
        title={"Your supplements are being saved"}
        description={
          "Your supplements are being saved. It will take a few seconds. Please don’t close the page during this time."
        }
      />
    );
  }
  return <SubmissionListPage />;
}

interface BannerStatus {
  subtitle: string;
  title: string;
}

export default function ReviewStepper(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [caseId, setCaseId] = useState<number>();
  const [files, setFiles] = useState<Array<FileWithPages>>([]);
  const [proofFiles, setProofFiles] = useState<Array<File>>([]);
  //const [redirect, setRedirect] = useState<JSX.Element | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [caseInfo, setCaseInfo] = useState<CaseInfo | undefined>(undefined);
  const [bannerStatus, setBannerStatus] = useState<BannerStatus | undefined>(
    undefined
  );
  const [shouldProceedToNext, setShouldProceedToNext] =
    useState<boolean>(false);
  const [shouldProceedToPrevious, setShouldProceedToPrevious] =
    useState<boolean>(false);

  //const history = useHistory();
  useEffect(() => {
    axios(`api/v1/draft_cases/${props.id}`, {
      method: "GET",
      withCredentials: true,
      data: {
        page: "review",
      },
    })
      .then((response) => response.data)
      .then((data) => {
        var embededData = data.data;
        if (data.error || !embededData) {
          setBannerStatus({
            subtitle: "Failed to load the resource you are looking for.",
            title: "We have encountered technical issues",
          });
        } else {
          var caseData: CaseData = embededData.case as CaseData;
          if (caseData) {
            setCaseId(caseData.id);
            setCaseInfo({
              schoolId: caseData.school_id ? String(caseData.school_id) : "-1",
              yearId: String(caseData.year_id),
              majorId: caseData.major_id ? String(caseData.major_id) : "-1",
              newlyAddedMajorName: caseData.newly_added_major_name,
              newlyAddedSchoolName: caseData.newly_added_school_name,
              title: caseData.title,
              userId: String(caseData.user_id),
              applicationRoundId: String(caseData.application_round_id),
              scholarshipId: String(caseData.scholarship_id),
              essays: caseData.essays,
              legacy: caseData.legacy,
            });
            downloadFiles(caseData.files, setFiles, setBannerStatus);
            loadPureFiles(caseData.proof_files, setProofFiles, setBannerStatus);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          error = error.response.data;
          const subtitle =
            (error.error && error.error.message) ||
            "We cannot find the case you are looking for.";
          setBannerStatus({
            subtitle: subtitle,
            title: "Failed to find the resource",
          });
        } else {
          setBannerStatus({
            subtitle:
              "We cannot find the application case you are looking for.",
            title: "Failed to find the resource",
          });
        }
        Sentry.captureException(error, {
          extra: {
            case_id: props.id,
          },
        });
      });
  }, [props.id]);

  if (bannerStatus) {
    return (
      <div>
        {bannerStatus && (
          <div className={classes.container}>
            <Banner
              title={bannerStatus.title}
              subtitle={bannerStatus.subtitle}
              severity={"failed"}
            />
          </div>
        )}
      </div>
    );
  }

  if (caseId === null) {
    return (
      <div>
        {bannerStatus && (
          <div className={classes.container}>
            <Banner
              title="Failed to find the resource"
              subtitle="We cannot find the application case you are looking for."
              severity={"failed"}
            />
          </div>
        )}
      </div>
    );
  }

  if (files.length === 0 || !caseInfo) {
    return (
      <img
        src={require("../../resources/green_loader.svg").default}
        width="150"
        style={{ margin: "auto" }}
        alt=""
      />
    );
  }

  const onEnterInfo = (caseInfo: CaseInfo) => {
    setCaseInfo(caseInfo);
  };

  const onSaveRectsAndBack = (selectedFiles: Array<FileWithPages>) => {
    setFiles(selectedFiles);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const postDraftCaseToBackend = (caseDecision: CaseDecision) => {
    setLoading(true);
    handleNext();
    let formData = new FormData();
    if (caseInfo) {
      //TODO
      formData.append("user_id", String(caseInfo.userId));
      formData.append("year_id", String(caseInfo.yearId));
      formData.append(
        "application_round_id",
        String(caseInfo.applicationRoundId)
      );
      formData.append("scholarship_id", String(caseInfo.scholarshipId));
      if (caseInfo?.schoolId && caseInfo.schoolId !== "-1") {
        formData.append("school_id", String(caseInfo?.schoolId));
      }
      formData.append("legacy", String(caseInfo?.legacy || false));
      if (caseInfo?.majorId && caseInfo.majorId !== "-1") {
        formData.append("major_id", String(caseInfo?.majorId));
      }
      if (caseInfo?.newlyAddedMajorName) {
        formData.append(
          "newly_added_major_name",
          String(caseInfo?.newlyAddedMajorName)
        );
      }
      if (caseInfo?.title) {
        formData.append("title", caseInfo?.title);
      }
      formData.append("draft_case_id", String(caseId));
      formData.append("status", "pending_confirmation");
    }

    formData.append("accepted", String(caseDecision.accepted));
    if (caseDecision.price) {
      formData.append("offer_price", String(caseDecision.price));
    }
    if (caseDecision.reason) {
      formData.append("rejection_reason", String(caseDecision.reason));
    }

    for (let i = 0; i < proofFiles.length; i++) {
      formData.append(`proof_files[${i}]`, proofFiles[i]);
    }

    if (caseInfo?.essays) {
      for (let i = 0; i < caseInfo.essays.length; i++) {
        let essayTopicId = caseInfo.essays[i].essayTopicId;
        let title = caseInfo.essays[i].title;
        let value = caseInfo.essays[i].value;
        if (essayTopicId) {
          formData.append(`essays[${i}][essay_topic_id]`, essayTopicId);
        }
        if (title) {
          formData.append(`essays[${i}][title]`, title);
        }
        if (value) {
          formData.append(`essays[${i}][value]`, value);
        }
      }
    }

    renderPages(files)
      .then((newFiles) => {
        for (let i = 0; i < newFiles.length; i++) {
          formData.append(`files[${i}][file]`, newFiles[i].file);
          for (let j = 0; j < newFiles[i].pages.length; j++) {
            formData.append(
              `files[${i}][pages][${j}]`,
              JSON.stringify(newFiles[i].pages[j])
            );
          }
        }

        axios("api/v1/cases", {
          method: "POST",
          withCredentials: true,
          data: formData,
          timeout: 0,
        })
          .then((response) => response.data)
          .then((data) => {
            setLoading(false);
            if (data.error) {
              setBannerStatus({
                subtitle:
                  "We had technical difficulties to upload the case you submitted.",
                title: "Failed to upload your case",
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              error = error.response.data;
              const subtitle =
                (error.error && error.error.message) ||
                "We had technical difficulties to upload the case you submitted.";
              setBannerStatus({
                subtitle: subtitle,
                title: "Failed to upload your case",
              });
            } else {
              setBannerStatus({
                subtitle:
                  "We had technical difficulties to upload the case you submitted.",
                title: "Failed to upload your case",
              });
            }
            Sentry.captureException(error, {
              extra: {
                //files: files
              },
            });
          });
      })
      .catch((error) => {
        setBannerStatus({
          subtitle:
            "We had technical difficulties to upload the case you submitted.",
          title: "Failed to upload your case",
        });
        Sentry.captureException(error, {
          extra: {
            //files: files
          },
        });
      });
  };

  const onSelectPages = (selectedFiles: Array<FileWithPages>) => {
    setFiles(selectedFiles);
    handleNext();
  };

  const enterInfoPage = () => (
    <EnterInfoPage
      disableBack={true}
      handleBack={handleBack}
      handleNext={handleNext}
      nextButtonText={"Next"}
      handleEnterInfo={onEnterInfo}
      caseInfo={caseInfo}
      shouldProceedToNext={shouldProceedToNext}
      setShouldProceedToNext={setShouldProceedToNext}
    />
  );

  const pageSelector = () => (
    <SelecPagesPage
      disableBack={false}
      handleBack={handleBack}
      handleNext={onSelectPages}
      nextButtonText={"Next"}
      files={files}
      shouldProceedToNext={shouldProceedToNext}
      setShouldProceedToNext={setShouldProceedToNext}
      shouldProceedToPrevious={shouldProceedToPrevious}
      setShouldProceedToPrevious={setShouldProceedToPrevious}
    />
  );

  const blackOffPage = () =>
    files.length !== 0 && (
      <BlackOffPage
        disableBack={false}
        handleBack={onSaveRectsAndBack}
        handleNext={onSelectPages}
        nextButtonText={"Next"}
        files={files}
        shouldProceedToNext={shouldProceedToNext}
        setShouldProceedToNext={setShouldProceedToNext}
        shouldProceedToPrevious={shouldProceedToPrevious}
        setShouldProceedToPrevious={setShouldProceedToPrevious}
      />
    );

  const selectTypesPage = () =>
    files.length !== 0 && (
      <SelectTypesPage
        disableBack={false}
        handleBack={handleBack}
        handleNext={onSelectPages}
        nextButtonText={"Next"}
        files={files}
        shouldProceedToNext={shouldProceedToNext}
        setShouldProceedToNext={setShouldProceedToNext}
        shouldProceedToPrevious={shouldProceedToPrevious}
        setShouldProceedToPrevious={setShouldProceedToPrevious}
      />
    );

  const reviewProofFilesPage = () => (
    <ReviewProofFilesPage
      disableBack={false}
      handleBack={handleBack}
      handleNext={handleNext}
      nextButtonText={"Next"}
      files={proofFiles.map((file) => {
        return { file: file, pages: [] };
      })}
      shouldProceedToNext={shouldProceedToNext}
      setShouldProceedToNext={setShouldProceedToNext}
      shouldProceedToPrevious={shouldProceedToPrevious}
      setShouldProceedToPrevious={setShouldProceedToPrevious}
    />
  );

  const submitDecisionPage = () => (
    <SubmitDecisionPage
      disableBack={false}
      handleBack={handleBack}
      handleNext={postDraftCaseToBackend}
      nextButtonText={"Submit"}
      shouldProceedToPrevious={shouldProceedToPrevious}
      setShouldProceedToPrevious={setShouldProceedToPrevious}
    />
  );

  const steps = ["Start", "Select", "Blackoff", "Types", "Proofs", "Submit"];
  const nodes: Array<() => React.ReactNode> = [
    enterInfoPage,
    pageSelector,
    blackOffPage,
    selectTypesPage,
    reviewProofFilesPage,
    submitDecisionPage,
  ];

  if (activeStep === steps.length && !loading) {
    history.push({ pathname: "/submissions" });
    return null;
  }

  return (
    <div className={`${classes.root} page-container`}>
      <div
        className={classes.stepper}
        style={{
          display: "flex",
          backgroundColor: "white",
          overflow: "scroll",
        }}
      >
        <Button
          className={classes.navigationButton}
          style={{
            flexGrow: 0,
            width: 40,
            minWidth: 40,
            margin: "auto 0px auto 20px",
          }}
          disabled={activeStep === 0}
          onClick={() => setShouldProceedToPrevious(true)}
        >
          {activeStep !== 0 && (
            <ChevronLeftIcon
              className={classes.arrow}
              style={{ margin: "auto" }}
            />
          )}
        </Button>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          classes={{ root: classes.labelRoot }}
          style={{ flexGrow: 1 }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                classes={{
                  alternativeLabel: classes.alternativeLabel,
                  labelContainer: classes.labelContainer,
                }}
              >
                <Typography
                  className={classes.stepLabel}
                  variant="subtitle1"
                  style={{ fontSize: 16 }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Button
          className={classes.navigationButton}
          style={{
            flexGrow: 0,
            width: 40,
            minWidth: 40,
            margin: "auto 20px auto 0px",
          }}
          disabled={activeStep >= steps.length - 1}
          onClick={() => setShouldProceedToNext(true)}
        >
          {activeStep < steps.length - 1 && (
            <ChevronRightIcon
              className={classes.arrow}
              style={{ margin: "auto" }}
            />
          )}
        </Button>
      </div>
      <div
        className="page-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {activeStep === steps.length ? (
          <div
            className="page-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {getFinishPage(loading)}
          </div>
        ) : (
          <div
            className="page-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {getStepContent(activeStep, nodes)}
          </div>
        )}
      </div>
    </div>
  );
}

function renderPages(
  files: Array<FileWithPages>
): Promise<Array<FileWithPages>> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const promises: Array<Array<Promise<Page>>> = [];
      for (let file of files) {
        promises.push(loadRenderableImage(file));
      }
      let newFiles: Array<FileWithPages> = Array.from(files);

      promises.forEach((promise, index) => {
        Promise.all(promise)
          .then((newPages) => {
            newFiles[index].pages = newPages;
            if (index === files.length - 1) {
              resolve(newFiles);
            }
          })
          .catch((error) => {
            Sentry.captureException(error, {
              extra: {
                //"files": files
              },
            });
            reject(error);
          });
      });
    }, 500);
  });
}

function loadRenderableImage(file: FileWithPages): Array<Promise<Page>> {
  const promises: Array<Promise<Page>> = [];
  for (let page of file.pages) {
    promises.push(renderPageWithRects(page));
  }
  return promises;
}

function renderPageWithRects(page: Page): Promise<Page> {
  return new Promise((resolve, reject) => {
    //setTimeout(() => {
    if (page.purePage) {
      var image = new Image();

      image.onload = (event) => {
        try {
          const canvas = document.createElement("canvas");
          const canvasContext =
            canvas.getContext("2d") || new CanvasRenderingContext2D();
          canvas.height = image.height;
          canvas.width = image.width;
          canvas.style.height = "100%";
          canvas.style.width = "100%";
          // first paint the entire background to white
          canvasContext.fillStyle = "#FFF";
          canvasContext.fillRect(0, 0, canvas.width, canvas.height);

          canvasContext.drawImage(image, 0, 0);
          canvasContext.fillStyle = "black";
          for (let rect of page.rects) {
            canvasContext.fillRect(rect.x, rect.y, rect.width, rect.height);
          }
          //throw new Error('Something bad happened');
          resolve({
            selected: page.selected,
            rects: page.rects,
            purePage: page.purePage,
            types: page.types,
            renderablePage: canvas.toDataURL("image/jpeg"),
          });
        } catch (error) {
          reject("Error drawing rects on the image.");
        }
      };
      image.onerror = (event) => {
        reject("Failed to drat the renderable image.");
      };
      image.src = page.purePage;
    } else {
      reject("The page doesn't have purePage.");
    }
    //},300)
  });
}
