import React, { useState } from "react";
import { Props as SideFilterProps } from "./SideFilter";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ReactComponent as FilterByIcon } from "../../resources/icons/filter_by.svg";
import { CheckBoxItem } from "../../types/types";
import { Button } from "@material-ui/core";
import SideFilterAccordion from "./SideFilterAccordion";
import SideFilterContent from "./SideFilterContent";

export interface Props {
  sideFilters: Array<SideFilterProps>;
  openFilter?: string;
  clearAll: () => void;
  handleApply: (updatedFilter: Map<string, Array<CheckBoxItem>>) => void;
  callBack?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleButton: {
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        margin: "0.3rem 0.1rem",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0.3rem 0.5rem",
      },
    },
    popoverBackground: {
      //zIndex: -1,
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    sticky: {
      [theme.breakpoints.down("sm")]: {},
      [theme.breakpoints.up("md")]: {
        position: "sticky",
        top: 102,
        height: "calc(100vh - 102px)",
        minHeight: 200,
        overflow: "scroll",
      },
    },
    filterByText: {
      color: theme.palette.primary.main,
      fontSize: 16,
      marginLeft: 5,
      fontFamily: "RedHatDisplay-Medium",
    },
    clearAllText: {
      color: theme.palette.primary.main,
      fontSize: 18,
      fontFamily: "RedHatDisplay-Bold",
      textDecoration: "underline",
    },
    applyButton: {
      position: "fixed",
      bottom: 0,
      width: "100%",
      zIndex: 500,
      padding: "0px 30px",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    topButton: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    filterBottom: {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 140,
      },
      [theme.breakpoints.up("md")]: {
        paddingBottom: 30,
      },
    },
  })
);

function adjustSelecterList(
  list: Array<CheckBoxItem>,
  selectedIds: Array<string>
) {
  const updatedList = list.map((item) => {
    return {
      filterItem: item.filterItem,
      selected: selectedIds.includes(item.filterItem.id),
    };
  });
  return updatedList;
}

export default function SideFilterBar(props: Props) {
  const classes = useStyles();
  //const [localFilter, setLocalFilter] = useState<Array<SideFilterProps>>(props.sideFilters);
  let defaultSelected: Map<string, Array<string>> = new Map();
  props.sideFilters.forEach((filter) => {
    defaultSelected.set(
      filter.title,
      filter.list
        .filter((item) => item.selected)
        .map((item) => item.filterItem.id)
    );
  });
  const [selected, setSelected] =
    useState<Map<string, Array<string>>>(defaultSelected);

  const [openAccordion, setOpenAccoridon] = useState<string>(undefined);

  const updateLocalFilter = (title: string, selectedIds: Array<string>) => {
    /*const updated = localFilter.map(filter => {
            if (filter.title !== title) {
                return filter
            } else {
                return {title, list:adjustSelecterList(filter.list, selectedIds)}
            }
        })*/
    let result = new Map(selected);
    result.set(title, selectedIds);
    setSelected(result);
  };

  const setExpanded = (title: string) => {
    if (title === openAccordion) {
      setOpenAccoridon(undefined);
    } else {
      setOpenAccoridon(title);
    }
  };

  const handleApply = () => {
    let mapFilter: Map<string, Array<CheckBoxItem>> = new Map();
    selected.forEach((value, key) => {
      const list = props.sideFilters.find((item) => item.title === key).list;
      mapFilter.set(key, adjustSelecterList(list, value));
    });
    window.scrollTo(0, 0);
    props.handleApply(mapFilter);
    if (props.callBack) {
      props.callBack();
    }
  };

  const clearAll = () => {
    let result = new Map();
    selected.forEach((value, key) => {
      result.set(key, []);
    });
    setSelected(result);

    let mapFilter: Map<string, Array<CheckBoxItem>> = new Map();
    result.forEach((value, key) => {
      const list = props.sideFilters.find((item) => item.title === key).list;
      mapFilter.set(key, adjustSelecterList(list, value));
    });
    window.scrollTo(0, 0);
    props.handleApply(mapFilter);
    if (props.callBack) {
      props.callBack();
    }
  };

  const filters = props.sideFilters.map((filter, index) => {
    const open = openAccordion === filter.title;
    const numSelected = defaultSelected.get(filter.title).length;
    const content = !open ? null : (
      <SideFilterContent
        title={filter.title}
        list={filter.list}
        selected={selected.get(filter.title)}
        updateSearchFilter={(selectedIds: Array<string>) =>
          updateLocalFilter(filter.title, selectedIds)
        }
        hideSearchBar={["Categories", "Rankings"].includes(filter.title)}
      />
    );
    return (
      <div key={filter.title}>
        <SideFilterAccordion
          expanded={openAccordion === filter.title}
          expandedByDefault={"Schools" === filter.title}
          switchExpanded={() => setExpanded(filter.title)}
          content={content}
          name={filter.title}
          number={numSelected !== 0 ? numSelected : undefined}
        />
        <div
          style={{
            height: 1,
            background:
              index === props.sideFilters.length - 1
                ? "white"
                : "linear-gradient(90deg, rgba(22, 176, 191, 0) 0%, rgba(22, 176, 191, 0.473958) 52.6%, rgba(22, 176, 191, 0) 100%)",
          }}
        ></div>
      </div>
    );
  });

  return (
    <div className={classes.sticky}>
      <div
        className={classes.topButton}
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 30px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <FilterByIcon />
          <div className={classes.filterByText}>Filter By:</div>
        </div>
        <button className={classes.clearAllText} onClick={clearAll}>
          Clear All
        </button>
      </div>
      <div
        className={classes.topButton}
        style={{ marginTop: 24, padding: "0px 30px" }}
      >
        <Button
          onClick={handleApply}
          variant="contained"
          color="primary"
          disableElevation
          style={{
            borderRadius: 12,
            color: "white",
            width: "100%",
            height: 44,
            fontFamily: "RedHatDisplay-Bold",
            fontSize: 18,
            textTransform: "none",
          }}
        >
          Apply
        </Button>
      </div>
      <div className={classes.applyButton}>
        <div
          style={{
            height: 25,
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
            width: "100%",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            justifyContent: "center",
            padding: "20px 30px",
            paddingTop: 0,
          }}
        >
          <button className={classes.clearAllText} onClick={clearAll}>
            Clear All
          </button>
        </div>
        <div
          style={{
            paddingBottom: "max(70px, 2*env(safe-area-inset-bottom))",
            backgroundColor: "white",
          }}
        >
          <Button
            onClick={handleApply}
            variant="contained"
            color="primary"
            disableElevation
            style={{
              borderRadius: 12,
              color: "white",
              width: "100%",
              height: 44,
              fontFamily: "RedHatDisplay-Bold",
              fontSize: 18,
              textTransform: "none",
            }}
          >
            Apply
          </Button>
        </div>
      </div>
      <div className={classes.filterBottom}>{filters}</div>
    </div>
  );
}
