import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AboutUsSection from "./AboutUsSection";
import WeNeedYourHelpSection from "./WeNeedYourHelpSection";
import TheReality from "./TheRealitySeciton";
import Spacing from "../general/Spacing";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "hidden",
      backgroundColor: "#F4F7F7",
      //borderBottom: `3px ${theme.palette.primary.main} solid`,
      [theme.breakpoints.up("md")]: {
        paddingTop: "60px",
        paddingBottom: "60px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0% 0",
      },
    },
    hide: {
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    spacingAcrossSection: {
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

export default function OurStorySection() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AboutUsSection />
      <div className={classes.spacingAcrossSection}>
        <Spacing spacing={30} />
      </div>
      <div className={classes.hide}>
        <TheReality />
        <div className={classes.spacingAcrossSection}>
          <Spacing spacing={30} />
        </div>
      </div>
      <WeNeedYourHelpSection />
    </div>
  );
}
