import React, { useState, useEffect } from "react";
import { Route, RouteProps } from "react-router";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import { useStore } from "react-redux";
import axios from "axios";
import PageNotFoundPage from "../components/errors/PageNotFound";
import ErrorBoundary from "./ErrorBoundary";

const PrivateRoute: React.SFC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { path } = rest;
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const store = useStore();

  useEffect(() => {
    axios("api/v1/auto_login", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => {
        if (response.status === 401) {
          history.push({
            pathname: "log_in",
            state: { from: history.location },
          });
        }
        return response.data;
      })
      .then((data) => {
        data = data.data;
        if (data.user) {
          if (!isLoggedIn) {
            setIsLoggedIn(true);
            store.dispatch({ type: "UPDATE_USER_ACCTION", user: data.user });
          }
        } else {
          if (isLoggedIn) {
            setIsLoggedIn(false);
            store.dispatch({ type: "UPDATE_USER_ACCTION", user: null });
          }
        }
      })
      .catch((error) => {
        setIsLoggedIn(false);
        store.dispatch({ type: "UPDATE_USER_ACCTION", user: null });
      });
  }, [history, isLoggedIn, store]);

  if (!Component || isLoggedIn === undefined) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          ADMIN_PAGES.includes(path) &&
          store.getState().user &&
          store.getState().user.permission !== "admin" ? (
            <PageNotFoundPage />
          ) : (
            <ErrorBoundary>
              <Component {...props} />
            </ErrorBoundary>
          )
        ) : (
          <Redirect
            to={{
              pathname: "/log_in",
              state: { from: history.location },
            }}
          />
        )
      }
    />
  );
};

const ADMIN_PAGES: Array<string | string[] | undefined> = [
  "/review/:id",
  "/cases",
  "/pending_payouts",
  "/submissions",
  "/review_general/:id",
];

export default PrivateRoute;
