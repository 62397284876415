import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PrimaryButton from "../general/PrimaryButton";
import PreviewSection from "../host_landing/PreviewSection";
import Typography from "@material-ui/core/Typography";
import AnonymizedSection from "../host_landing/AnonymizedSection";
import InstructionSection from "../host_landing/InstructionSection";
import { Helmet } from "react-helmet";
import hostlandingbackground from "../../resources/HostLandingBackground.svg";
import WhatToUploadSection from "../host_landing/WhatToUploadSection";
import GetEstimationSection from "../host_landing/GetEstimationSection";
import FaqSection from "../host_landing/FaqSection";
import FullLogo from "../../resources/WhiteFullLogo.svg";
import OurStorySection from "../host_landing/OurStorySection";
import IncomeSection from "../host_landing/IncomeSection";
import LandingImage from "../../resources/HostLanding.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    landingSection: {
      color: "white",
      fontFamily: "SuperaGothic",
      backgroundColor: theme.palette.primary.main,
      textTransform: "none",
      width: "100%",
      justifyContent: "center",
      minWidth: "320px",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        borderRadius: "0px",
        display: "block",
      },
      [theme.breakpoints.up("md")]: {
        height: "550px",
        borderRadius: "0px 0px 0px 100px",
        display: "flex",
      },
    },
    landingImage: {
      height: "100%",
      marginLeft: "50px",
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
        display: "block",
      },
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },
    landingTextSection: {
      //width: 'auto',
      //maxWidth: '600px',
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10vw",
        paddingLeft: "5vw",
        margin: "5vw",
        marginTop: 0,
        //marginBottom: '5vw',
      },
      [theme.breakpoints.only("md")]: {
        marginLeft: "10%",
        marginRight: "0vw",
        margin: "auto",
        //marginTop: '4vw',
        //paddingTop: '2vw',
        //paddingLeft: '6vw',
        //marginLeft: '4vw',
        paddingRight: "0vw",
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: "10%",
        marginRight: "5vw",
        margin: "auto",
        //marginTop: '4vw',
        //paddingTop: '2vw',
        //paddingLeft: '6vw',
        //marginLeft: '4vw',
        paddingRight: "0vw",
      },
      //paddingRight: '5vw',
      //marginRight: '5vw',
      marginBottom: "3vw",
      textAlign: "left",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },
    titleSection: {
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
    conditionalHeight: {
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
      [theme.breakpoints.up("md")]: {
        height: "100%",
      },
    },
    descriptionTextSection: {
      marginTop: "20px",
      display: "block",
      //fontSize: theme.typography.body2.fontSize,
    },
    startBrowsingButton: {
      backgroundColor: "#0A5961",
    },
    categorySection: {
      width: "100%",
      display: "flex",
    },
    wrapper: {
      //height: '100%',
      marginLeft: "25px",
      marginRight: "25px",
      flexShrink: 0,
      margin: "auto",
      maxWidth: 560,
      [theme.breakpoints.down("sm")]: {
        display: "block",
        width: "80%",
        maxWidth: 500,
        margin: "auto",
        paddingBottom: "10vw",
      },
      [theme.breakpoints.between("md", "lg")]: {
        //padding: '0px 20px',
        height: "100%",
        display: "block",
        width: "40%",
        minWidth: "360px",
        marginRight: "70px",
      },
      [theme.breakpoints.up("xl")]: {
        height: "100%",
        display: "block",
        width: "40%",
        minWidth: "400px",
        marginRight: "70px",
      },
    },
    imageWrapper: {
      [theme.breakpoints.down("lg")]: {
        height: "90%",
        width: "100%",
      },
      [theme.breakpoints.up("lg")]: {
        height: "100%",
        width: "100%",
      },
    },
    stretchButton: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        //width: 'auto',
      },
    },
    conditionalLogo: {
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  })
);

interface Props {}
function GuestLandingPage(props: Props) {
  const styles = useStyles();
  const buttonStyle = {
    backgroundColor: "white",
    color: "#0A5961",
    marginTop: "40px",
    marginBottom: "2vw",
    //width: '100%',
    //width: '100%',
  };

  return (
    <div>
      <Helmet>
        <title>Upload your application - CollegeSharing</title>
      </Helmet>
      <div style={{ backgroundColor: "#F4F7F7" }}>
        <div className={`${styles.landingSection}`}>
          <div
            className={`${styles.landingTextSection}`}
            style={{ backgroundImage: `url(${hostlandingbackground})` }}
          >
            <img
              alt=""
              className={styles.conditionalLogo}
              style={{ marginBottom: 30, width: 200 }}
              src={FullLogo}
            ></img>
            <Typography variant="h3" className={`${styles.titleSection}`}>
              Get Paid To Share Your College Application
            </Typography>
            <Typography
              variant="body1"
              className={`${styles.descriptionTextSection}`}
            >
              We pay an average of{" "}
              <span style={{ fontWeight: "bold" }}>$89</span> to someone who
              contributes accepted college applications to our platform.
            </Typography>
            <Typography
              variant="body1"
              className={`${styles.descriptionTextSection}`}
            >
              It’s our way of saying thank you for giving high schoolers real
              insights into how to get in their dream schools.
            </Typography>
            <div style={{ textAlign: "center", width: "100%" }}>
              <PrimaryButton
                style={buttonStyle}
                link="/upload_status"
                label="header_section.upload_now"
              >
                <Typography variant="h5">Upload My Application</Typography>
              </PrimaryButton>
            </div>
          </div>
          <div className={styles.wrapper}>
            <img
              alt=""
              className={styles.conditionalHeight}
              style={{ margin: "auto" }}
              src={LandingImage}
            />
          </div>
        </div>
      </div>
      <OurStorySection />
      <IncomeSection />
      <GetEstimationSection />
      <AnonymizedSection />
      <PreviewSection />
      <WhatToUploadSection />
      <InstructionSection />
      <FaqSection />
    </div>
  );
}

export default GuestLandingPage;
