import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Essay } from "../../types/uploadTypes";
import RoundedButton from "./PrimaryButton";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { FilterLineItem } from "../../types/serverSideTypes";
import StandardAutoComplete, { Option } from "../general/StandardAutoComplete";

interface Props {
  activities: Array<Essay>;
  setActivities: (activities: Array<Essay>) => void;
  essayTopics: Array<FilterLineItem>;
  schoolId?: string;
  shouldFilterBySchoolId: boolean;
  mode?: string;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    body: {
      fontSize: 16,
      fontFamily: "SuperaGothic",
      fontWeight: "normal",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-child(4n)": {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.action.hover,
      },
      "&:nth-child(4n-1)": {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.action.hover,
      },
      "& > *": {
        borderBottom: "unset",
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  resize: {
    fontSize: 16,
    lineHeight: "19px",
  },
});

interface RowProps {
  activity: Essay;
  delete: () => void;
  setActivity: (activity: Essay) => void;
  options: Array<Option>;
}

function Row(props: RowProps) {
  const styles = useStyles();
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Object.assign({}, props.activity, {
      value: event.target.value,
    });
    props.setActivity(newValue);
    //setName(event.target.value);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Object.assign({}, props.activity, {
      title: event.target.value,
    });
    props.setActivity(newValue);
    //setName(event.target.value);
  };

  const handleEssayTopicChange = (key: string | null) => {
    const newValue = Object.assign({}, props.activity, {
      essayTopicId: key || undefined,
    });
    props.setActivity(newValue);
    //setName(event.target.value);
  };

  const text =
    props.options.length !== 0
      ? "If not found, type in the essay prompt here"
      : "Please type in the the essay prompt here";

  return (
    <React.Fragment>
      <StyledTableRow>
        {props.options.length !== 0 && (
          <StyledTableCell
            style={{ verticalAlign: "bottom", paddingBottom: 6 }}
          >
            <StandardAutoComplete
              options={props.options}
              handleSelect={handleEssayTopicChange}
              value={props.activity.essayTopicId || null}
              placeholder={"Pick an essay topic"}
            />
          </StyledTableCell>
        )}
        <StyledTableCell
          size="small"
          style={{ verticalAlign: "bottom", paddingBottom: 6 }}
        >
          <TextField
            multiline
            rowsMax={3}
            InputProps={{ classes: { input: styles.resize } }}
            style={{ width: "100%" }}
            placeholder={text}
            value={props.activity.title || ""}
            onChange={handleTitleChange}
          />
        </StyledTableCell>
        {props.options.length === 0 && <StyledTableCell></StyledTableCell>}
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell>
          <TextField
            multiline
            rowsMax={5}
            InputProps={{ classes: { input: styles.resize } }}
            style={{ width: "100%" }}
            placeholder="Paste the essay here"
            value={props.activity.value || ""}
            onChange={handleNameChange}
          />
        </StyledTableCell>
        <StyledTableCell>
          <RoundedButton
            variant="outlined"
            style={{ borderRadius: "30px", color: "white", border: "none" }}
            buttonStyle="secondary"
            onClick={props.delete}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: "16px",
                fontFamily: "SuperaGothic",
                lineHeight: "normal",
              }}
            >
              Delete
            </Typography>
          </RoundedButton>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function CustomizedTables(props: Props) {
  const classes = useStyles();

  const updateActivities = (index: number, activity: Essay) => {
    const newArray = props.activities.map((a) => Object.assign({}, a));
    newArray[index] = activity;
    props.setActivities(newArray);
  };

  const deleteActivity = (index: number) => {
    const newArray = props.activities.map((a) => Object.assign({}, a));
    newArray.splice(index, 1);
    props.setActivities(newArray);
  };

  const addActivity = () => {
    const newArray = props.activities.map((a) => Object.assign({}, a));
    newArray.push({
      title: undefined,
      essayTopicId: undefined,
      value: undefined,
    });
    props.setActivities(newArray);
  };

  let essayTopics = props.essayTopics;
  if (props.schoolId && props.shouldFilterBySchoolId) {
    essayTopics = props.essayTopics.filter(
      (topic) => topic.school_id === props.schoolId
    );
  } else if (props.mode === "personal_statement") {
    essayTopics = props.essayTopics.filter((topic) => !topic.school_id);
  }

  const options = getFilter(essayTopics);
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Essay Prompt</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.activities.map((row, index) => (
            <Row
              activity={row}
              delete={() => deleteActivity(index)}
              setActivity={(newActivity: Essay) =>
                updateActivities(index, newActivity)
              }
              options={options}
              key={index}
            />
          ))}
          <StyledTableRow>
            <StyledTableCell colSpan={3}>
              <RoundedButton
                variant="outlined"
                style={{
                  borderRadius: "30px",
                  color: "white",
                  border: "none",
                  margin: "auto",
                }}
                buttonStyle="primary"
                onClick={addActivity}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "16px",
                    fontFamily: "SuperaGothic",
                    lineHeight: "normal",
                  }}
                >
                  Add
                </Typography>
              </RoundedButton>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function getFilter(items: Array<FilterLineItem>): Array<Option> {
  const options = items.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });
  return options;
}
