import { combineReducers } from "redux";
import userReducer from "./UserReducer";

const allReducers = combineReducers({
  //loggedIn: isLoggedIn,
  user: userReducer,
  //searchPageStatus,
  //searchPageFilter,
  //searchPageStatus: loadPaginatedSchoolsReducer,
});

export default allReducers;
