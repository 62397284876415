import React from "react";
import Grid from "@material-ui/core/Grid";

interface Props {
  children?: React.ReactNode;
}

function RowLeft(props: Props) {
  return (
    <Grid item xs={8} sm={9} lg={9}>
      {props.children}
    </Grid>
  );
}

export default RowLeft;
