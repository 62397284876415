export const HOST_LANDING = "/host_landing";
export const SEARCH = "/search";
export const PROFILE_SEARCH = "/profile_search";
export const PROFILE = "/profile";
export const ADMIT_PROFILE = "/admit_profile";
export const SAVED_CASES = "/saved_cases";
export const UPLOAD = "/upload";
export const REVIEW = "/review";
export const EDIT = "/edit";
export const ACCEPT_CASE = "/accept_case";
export const DECLINE_CASE = "/decline_case";
export const SIGN_UP = "/sign_up";
export const LOG_IN = "/log_in";
export const EMAIL_CONFIRMATION = "/email_confirmation";
export const RESET_PASSWORD = "/reset_password";
export const CHANGE_PASSWORD = "/change_password";
export const PAYMENT_PAGE = "/payment_page";
export const PAYOUT = "/payout";
export const ACCOUNT = "/account";
export const LOGIN_AND_SECURITY = "/login_and_security";
export const CHANGE_SUBSCRIPTION = "/change_subscription";
export const EARNINGS = "/earnings";
export const CASES = "/cases";
export const USER = "user";
export const LICENSE_AGREEMENT = "/license_agreement";
export const UNLOCK_ACCOUNT = "/unlock_account";
export const PRIVACY_POLICY = "/privacy_policy";
export const TERMS_OF_USE = "/terms_of_use";
export const FAQ = "/faq";
export const PENDING_PAYOUTS = "/pending_payouts";
export const UPLOAD_STATUS = "/upload_status";
export const EDIT_GENERAL = "/edit_general";
export const REVIEW_GENERAL = "/review_general";
export const SUBMISSIONS = "/submissions";

export const PATHS_TO_EXCLUDE_HEADER = [];
