import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { PreviewCase } from "../../types/types";
import RoundedButton from "./PrimaryButton";
import Typography from "@material-ui/core/Typography";

interface Props {
  cases: Array<PreviewCase>;
  prefix: string;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    body: {
      fontSize: 16,
      fontFamily: "SuperaGothic",
      fontWeight: "normal",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(2n+1)": {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.action.hover,
      },
      "& > *": {
        borderBottom: "unset",
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

interface RowProps {
  case: PreviewCase;
  prefix: string;
}

function Row(props: RowProps) {
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell component="th" scope="row">
          {props.case.school_short_name}
        </StyledTableCell>
        <StyledTableCell align="right">{props.case.year_name}</StyledTableCell>
        <StyledTableCell align="right">{props.case.major_name}</StyledTableCell>
        <StyledTableCell align="right">
          {props.case.application_round}
        </StyledTableCell>
        <StyledTableCell align="right">
          <RoundedButton
            variant="outlined"
            style={{ borderRadius: "30px", color: "white", border: "none" }}
            buttonStyle="secondary"
            link={props.prefix + props.case.id}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: "16px",
                fontFamily: "SuperaGothic",
                lineHeight: "normal",
              }}
            >
              View
            </Typography>
          </RoundedButton>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function CustomizedTables(props: Props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>School</StyledTableCell>
            <StyledTableCell align="right">Year</StyledTableCell>
            <StyledTableCell align="right">Major</StyledTableCell>
            <StyledTableCell align="right">Cycle</StyledTableCell>
            <StyledTableCell align="right">Details</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.cases.map((previewCase) => (
            <Row
              case={previewCase}
              prefix={props.prefix}
              key={previewCase.id}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
