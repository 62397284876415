import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    font: {
      fontFamily: "Montserrat",
    },
    colorDefault: {
      "& .MuiAvatar-root": {
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
    },
    avatar: {
      [theme.breakpoints.down("xs")]: {
        backgroundColor: "white",
        width: 60,
        height: 60,
      },
      border: "1px solid rgba(255, 255, 255, 0.8)",
      backgroundColor: "rgba(255, 255, 255, 0.25)",
      width: 55,
      height: 55,
    },
    bannerIcon: {
      width: "70%",
    },
    supplementLine: {
      height: 1,
      background:
        "linear-gradient(90deg, rgba(22, 176, 191, 0) 0%, rgba(22, 176, 191, 0.473958) 52.6%, rgba(22, 176, 191, 0) 100%)",
    },
    gradientLine: {
      width: "100%",
      //marginTop:13,
      //bottom: 0,
      height: 1,
      position: "absolute",
      [theme.breakpoints.down("xs")]: {
        background:
          "linear-gradient(90deg, rgba(56, 185, 194, 0) 0%, rgba(56, 185, 194, 0.18) 48.96%, rgba(56, 185, 194, 0) 100%)",
      },
      background:
        "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 48.96%, rgba(255, 255, 255, 0) 100%)",
    },
    widerGradientLine: {
      width: "100%",
      height: 1,
      background:
        "linear-gradient(90deg, rgba(56, 185, 194, 0) 5%, rgba(56, 185, 194, 1) 48.96%, rgba(56, 185, 194, 0) 95%)",
    },
    profileLabel: {
      [theme.breakpoints.down("xs")]: {
        background: "rgba(56, 185, 194, 0.1)",
        color: "#38B9C2",
      },
      background:
        "linear-gradient(105.13deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.08) 100%)",
      borderRadius: 15,
      border: "1px solid rgba(255, 255, 255, 0.3)",
      //padding:15,
      color: "white",
      overflowWrap: "break-word",
    },
    whiteBorderedBox: {
      [theme.breakpoints.down("xs")]: {
        boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.1)",
        background: "#FFFFFF",
        borderRadius: 20,
        color: "black",
        border: "none",
      },
      background:
        "linear-gradient(105.13deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.08) 100%)",
      borderRadius: 15,
      border: "1px solid rgba(255, 255, 255, 0.3)",
      //padding:15,
      color: "white",
      overflowWrap: "break-word",
    },
    fullHeight: {
      height: "100%",
    },
    fullWidth: {
      width: "100%",
    },
    enrolledAt: {
      position: "relative",
      minHeight: 50,
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        background:
          "linear-gradient(100.83deg, rgba(56, 185, 194, 0.02) 7.88%, rgba(56, 185, 194, 0.12) 48.97%, rgba(56, 185, 194, 0.02) 91.82%)",
      },
      background:
        "linear-gradient(100.83deg, rgba(255, 255, 255, 0.02) 7.88%, rgba(255, 255, 255, 0.18) 48.97%, rgba(255, 255, 255, 0.02) 91.82%)",
    },
    leftPanel: {
      //width: "25%",
      minWidth: 350,
      maxWidth: 350,
      backgroundColor: theme.palette.primary.main,
      minHeight: 600,
      padding: 25,
      flex: "1 1 30%",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
    },
    middleScreen: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      [theme.breakpoints.between("sm", "md")]: {
        display: "block",
      },
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    mainPanel: {
      //width: "75%",
      flex: "1 1 70%",
      minWidth: 0,
    },
    conditionalSplit: {
      [theme.breakpoints.down("sm")]: {
        display: "block",
        margin: 10,
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        margin: 20,
        marginTop: 0,
      },
    },
    conditionalSplitItem: {
      [theme.breakpoints.down("sm")]: {
        margin: 10,
        width: "calc(100% - 20px)",
      },
      [theme.breakpoints.up("md")]: {
        margin: 0,
        width: "calc(50% - 20px)",
      },
    },
    testMainSection: {
      margin: 20,
      marginBottom: 10,
      [theme.breakpoints.down("sm")]: {
        margin: 10,
      },
      flexWrap: "wrap",
      display: "flex",
      justifyContent: "flex-start",
      //border: "1px solid rgba(56, 185, 194, 0.5)",
      //boxSizing: "border-box",
      //borderRadius: 20,
    },
    testSection: {
      //flex:"1",
      //minWidth:280,
      margin: "10px",
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      marginBottom: 12,
      //margin:20,
      /*[theme.breakpoints.down("md")]: {
        width: "calc(50% - 20px)",
      },
      [theme.breakpoints.up("lg")]: {
        width: "calc(33.33% - 20px)",
      },
      [theme.breakpoints.up("xl")]: {
        width: "calc(25% - 20px)",
      },*/
    },

    threeBadgeItemTestSection: {
      width: "calc(33.33% - 20px)",
    },
    fourBadgeItemTestSection: {
      [theme.breakpoints.up("sm")]: {
        width: "calc(25% - 20px)",
      },
    },

    twoItemTestSection: {
      [theme.breakpoints.down("xs")]: {
        width: "calc(100% - 20px)",
      },
      [theme.breakpoints.up("sm")]: {
        width: "calc(50% - 20px)",
      },
    },
    threeItemTestSection: {
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 20px)",
      },
      [theme.breakpoints.up("md")]: {
        width: "calc(33.33% - 20px)",
      },
    },
    fourItemTestSection: {
      [theme.breakpoints.down("xs")]: {
        width: "calc(100% - 20px)",
      },
      [theme.breakpoints.up("sm")]: {
        width: "calc(50% - 20px)",
      },
      [theme.breakpoints.up("md")]: {
        width: "calc(25% - 20px)",
      },
    },
    sixItemTestSection: {
      [theme.breakpoints.down("xs")]: {
        width: "calc(100% - 20px)",
      },
      [theme.breakpoints.up("sm")]: {
        width: "calc(50% - 20px)",
      },
      [theme.breakpoints.up("md")]: {
        width: "calc(33.33% - 20px)",
      },
    },
    textResultPanel: {
      [theme.breakpoints.down("sm")]: {
        maxHeight: null,
        overflow: null,
      },
      maxHeight: 188,
      overflow: "scroll",
      flex: "1 1 auto",
      background: "rgba(229, 245, 246, 0.5)",
      borderRadius: 12,
      paddingTop: 6,
      paddingBottom: 14,
    },
    accordionRoot: {
      background: "#FFFFFF",
      boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.1)",
      borderRadius: 20,
    },
    schoolSection: {
      background: "rgba(229, 245, 246, 0.5)",
      borderRadius: 20,
    },
    pageLayoutByWidth: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      [theme.breakpoints.between("sm", "md")]: {
        display: "block",
      },
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
    },
    pageVisibilityByWidth: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    smallScreen: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
      },
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    upperBorderRadius: {
      [theme.breakpoints.down("xs")]: {
        borderRadius: "20px 20px 0px 0px",
      },
      borderRadius: 15,
    },
    dialogContainer: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        borderRadius: "20px 20px 0px 0px",
      },
      borderRadius: 15,
      maxWidth: 960,
      width: "90%",
      margin: "auto",
    },
    dialogWidth: {
      [theme.breakpoints.down("xs")]: {
        borderRadius: "20px 20px 0px 0px",
        width: "100%",
      },
      minHeight: "calc(100% - 80px)",
      borderRadius: 15,
      margin: "40px 15px",
      width: "calc(100% - 30px)",
      maxWidth: 960,
    },
    largeScreenWithBlock: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    smallScreenWithFlex: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
      },
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    styledTabs: {
      "& .MuiTabs-indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
      },
      "& .MuiTabs-indicatorSpan": {
        maxWidth: 40,
        width: "100%",
        backgroundColor: "#635ee7",
      },
    },
    fontColorByScreenSize: {
      [theme.breakpoints.down("xs")]: {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.up("sm")]: {
        color: null,
      },
    },
    rowLabelTitle: {
      [theme.breakpoints.down("xs")]: {
        color: "rgba(14, 127, 138, 0.65)",
      },
      color: "rgba(255, 255, 255, 0.65)",
    },
  })
);

export { useStyles };
