import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import allReducers from "./reducers";
import { Provider } from "react-redux";
import theme from "./themes/Theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { initialStoreState, StoreState } from "./types/storeState";
import thunk from "redux-thunk";
import axios from "axios";
import * as Sentry from "@sentry/react";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.timeout = 12000;

//axios.defaults.headers.post['Content-Type'] = 'application/json';
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
});

export type AppDispatch = typeof store.dispatch;
const persistedState = loadFromLocalStorage();
const store = createStore(allReducers, persistedState, applyMiddleware(thunk));

store.subscribe(() => saveToLocalStorage(store.getState()));

axios.interceptors.request.use(
  (request) => {
    // Edit request config
    return request;
  },
  (error) => {
    Sentry.setContext("request", error.request);
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // Edit response config
    return response;
  },
  (error) => {
    Sentry.setContext("response", error.response);
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);

function saveToLocalStorage(state: StoreState) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    Sentry.captureException(err);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return initialStoreState;
    return JSON.parse(serializedState);
  } catch (err) {
    Sentry.captureException(err);
    return initialStoreState;
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
