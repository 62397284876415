import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Spacing from "../general/Spacing";
import Loader from "../../resources/white_loader.gif";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.85,
      padding: "80px 50px",
      height: "100%",
      //paddingBottom: '40%',
    },
    close: {
      padding: theme.spacing(0.5),
    },
  })
);

interface Props {
  title?: string;
  description?: string;
}
function UploadingCasePage(props: Props) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`${classes.root} page-container`}>
      <Typography
        variant="h4"
        style={{ color: "white", fontFamily: "SuperaGothic-ExtraBold" }}
      >
        {props.title || "Your Profile Is Being Saved"}
      </Typography>
      <Spacing spacing={24} />
      <Typography
        variant="body1"
        style={{ color: "white", fontFamily: "SuperaGothic" }}
      >
        {props.description ||
          "Your profile is being saved. It will take a few seconds to a minute. Please don’t close the page during this time."}
      </Typography>
      <Spacing spacing={32} />

      <img src={Loader} width="150" style={{ margin: "auto" }} alt="" />
    </div>
  );
}

export default UploadingCasePage;
