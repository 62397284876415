import React from "react";
import { Essay } from "../../../types/admitProfileTypes";
import ExpandablePanel from "./ExpandablePanel";
import { ReactComponent as HonorIcon } from "../../../resources/icons/honors.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../../themes/Theme";
export type EssaySectionProps = {
  essays: Essay[];
};

function EssaySection(props: EssaySectionProps) {
  const content = props.essays.map((essay, index) => {
    return <EssayRow essay={essay} key={index} />;
  });
  const smallScreen = useMediaQuery(theme.breakpoints.only("sm"), {
    noSsr: true,
  });

  return (
    <div style={{ marginBottom: 20 }}>
      <ExpandablePanel
        name="Personal Statements"
        content={content}
        expandedByDefault={!smallScreen}
        icon={<HonorIcon style={{ marginRight: 10 }} />}
      />
    </div>
  );
}

export type EssayRowProps = {
  essay: Essay;
};

function EssayRow(props: EssayRowProps) {
  return (
    <div style={{ overflowWrap: "break-word" }}>
      <div
        style={{
          fontSize: 14,
          fontFamily: "RedHatDisplay-SemiBold",
          color: "#0E7F8A",
        }}
      >
        {props.essay.title || props.essay.essayTopic}
      </div>
      <div
        style={{
          fontFamily: "RedHatDisplay",
          fontSize: 14,
          textAlign: "left",
          marginTop: 15,
          marginBottom: 15,
          whiteSpace: "pre-wrap",
        }}
      >
        {props.essay.value}
      </div>
    </div>
  );
}

export default EssaySection;
