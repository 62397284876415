import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CategoryCard from "./VerticalCard";
import PrimaryButton from "../general/PrimaryButton";
import Typography from "@material-ui/core/Typography";
import satImage from "../../resources/sat.svg";
import essayImage from "../../resources/essays.svg";
import activitiesImage from "../../resources/activities.svg";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "@media (min-width:800px)": {
        marginTop: "30px",
      },
      "@media (max-width:800px)": {
        marginTop: "5vw",
      },
    },
    buttonWrap: {
      marginTop: "60px",
      marginBottom: "40px",
    },
    textAlign: {
      [theme.breakpoints.down("md")]: {
        textAlign: "left",
      },
      [theme.breakpoints.up("md")]: {
        textAlign: "center",
      },
    },
    spacing: {
      "@media (min-width:800px)": {
        marginTop: "25px",
        //marginBottom: '50px',
      },
      "@media (max-width:800px)": {
        marginTop: "5vw",
        //marginBottom: '8vw',
      },
    },
    conditionalText: {
      display: "block",
      "@media (max-width:500px)": {
        //display: "none",
      },
    },
    titleSection: {
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      fontWeight: "bold",
    },
    container: {
      [theme.breakpoints.down("sm")]: {
        margin: "10%",
      },
      [theme.breakpoints.up("md")]: {
        margin: "80px 10%",
      },
    },
  })
);

function CategorySection() {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Typography
        variant="h3"
        style={{ fontWeight: "bold", color: "black" }}
        className={styles.titleSection}
      >
        Real "Yeses" From Real Schools
      </Typography>
      <Typography
        variant="body1"
        className={`roboto-condensed ${styles.textAlign} ${styles.conditionalText} ${styles.spacing}`}
      >
        Don’t rely on "maybe" advice. CollegeSharing runs on raw data and
        authentic student profiles to help you stand out through your
        application journey.
      </Typography>
      <Grid
        className={`${styles.root}`}
        container
        alignItems={"stretch"}
        spacing={5}
      >
        {cardContents.map((cardContent) => {
          return <CategoryCard key={cardContent.title} {...cardContent} />;
        })}
      </Grid>
      <div className={styles.buttonWrap}>
        <PrimaryButton link="sign_up" label="category_section.sign_up_now">
          <Typography variant="h5">Sign Up Now</Typography>
        </PrimaryButton>
      </div>
    </div>
  );
}

const cardContents = [
  {
    imageUrl: essayImage,
    title: "Essays",
    question: 'A great essay says "Yes".',
    paragraph: 'Check out how 1000+ students "write" their way into the Ivies.',
  },
  {
    imageUrl: satImage,
    title: "Tests",
    question: "Is your SAT good enough?",
    paragraph: "Make an educated guess based on thousands of past data points.",
  },
  {
    imageUrl: activitiesImage,
    title: "Activities",
    question: "Your time is limited.",
    paragraph:
      "Follow the winners, and focus on things that truly set you apart.",
  },
];

export default CategorySection;
