import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { AlertProps } from "@material-ui/lab/Alert";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textFieldContainer: {
      width: "90%",
      margin: "auto",
    },
    alert: {
      fontFamily: "SuperaGothic",
      //fontSize: '16px',
      //textAlign: 'left',
      /*"& .MuiAlert-icon": {
            marginTop: 'auto',
            marginBottom: 'auto',
        }*/
    },
    root: {
      fontFamily: "SuperaGothic",
      textAlign: "left",
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

export default function PanelAlert(props: AlertProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Alert severity={props.severity}>
        {props.title && (
          <AlertTitle style={{ fontWeight: "bold", fontSize: "16px" }}>
            {props.title}
          </AlertTitle>
        )}
        <div style={{ fontSize: "16px" }}>{props.children}</div>
      </Alert>
    </div>
  );
}
