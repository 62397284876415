import React from "react";
import { GeneralProfile, Test } from "../../../types/admitProfileTypes";
import { useStyles as profileStyles } from "./ProfileStyles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { styled } from "@material-ui/core/styles";
import { ReactComponent as NoteIcon } from "../../../resources/icons/note.svg";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.MuiLinearProgress-colorPrimary`]: {
    borderRadius: 8,
    backgroundColor: "rgba(56, 185, 194, 0.05)",
    boxShadow: "inset 2px 2px 10px rgba(0, 0, 0, 0.05)",
    height: 5,
  },
  [`& .MuiLinearProgress-bar`]: {
    borderRadius: 8,
    backgroundColor: "#38B9C2",
    boxShadow: "inset 2px 2px 6px rgba(255, 255, 255, 0.3)",
    height: 5,
  },
}));

export type TestSectionProps = {
  generalProfile: GeneralProfile;
};

function TestSection(props: TestSectionProps) {
  const profileClasses = profileStyles();
  const profile = props.generalProfile;
  const tests = getTests(profile);
  let sections = [];

  if (tests.length <= 2) {
    sections = tests.map((test) => {
      return (
        <RenderableTestPanel
          key={test.name}
          {...test}
          className={profileClasses.twoItemTestSection}
        />
      );
    });
  } else if (tests.length === 3) {
    sections = tests.map((test) => {
      return (
        <RenderableTestPanel
          key={test.name}
          {...test}
          className={profileClasses.threeItemTestSection}
        />
      );
    });
  } else if (tests.length === 4) {
    sections = tests.map((test) => {
      return (
        <RenderableTestPanel
          key={test.name}
          {...test}
          className={profileClasses.fourItemTestSection}
        />
      );
    });
  } else if (tests.length === 6) {
    sections = tests.map((test) => {
      return (
        <RenderableTestPanel
          key={test.name}
          {...test}
          className={profileClasses.sixItemTestSection}
        />
      );
    });
  } else if (tests.length === 5) {
    sections = tests.map((test) => {
      return (
        <RenderableTestPanel
          key={test.name}
          {...test}
          className={profileClasses.sixItemTestSection}
        />
      );
    });
  }

  return <div className={profileClasses.testMainSection}>{sections}</div>;
}

type RenderableTest = {
  name: string;
  tests: Test[];
  total?: string;
  className?: string;
};

function RenderableTestPanel(test: RenderableTest) {
  const profileClasses = profileStyles();
  const testLists = test.tests.map((individualTest, index) => {
    return <ProgressBar {...individualTest} key={index} />;
  });
  return (
    <div className={`${profileClasses.testSection} ${test.className}`}>
      <div
        style={{
          fontSize: 16,
          alignItems: "center",
          display: "flex",
          fontFamily: "RedHatDisplay",
          marginBottom: 10,
          fontWeight: "bold",
        }}
      >
        <NoteIcon style={{ marginRight: 10 }} />
        <div style={{ marginRight: 10 }}>{test.name}</div>
        {test.total && <div style={{ color: "#38B9C2" }}>{test.total}</div>}
      </div>
      <div
        className={profileClasses.textResultPanel}
        style={{ paddingBottom: 20, paddingTop: 10 }}
      >
        {testLists}
      </div>
    </div>
  );
}

function ProgressBar(test: Test) {
  return (
    <div style={{ paddingTop: 6, paddingLeft: 20, paddingRight: 20 }}>
      <div
        style={{
          fontSize: 13,
          marginRight: "20%",
          fontFamily: "RedHatDisplay-SemiBold",
        }}
      >
        {test.name}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <BorderLinearProgress
          variant="determinate"
          value={test.percent * 100}
          style={{ width: "calc(95% - 35px)" }}
        />
        <div
          style={{
            color: "#38B9C2",
            fontSize: 15,
            lineHeight: "16px",
            fontWeight: "bold",
            fontFamily: "RedHatDisplay",
            flex: "1 1 auto",
            //width: "15%",
            marginLeft: "5%",
            //paddingRight: "5%",
          }}
        >
          {test.score}
        </div>
      </div>
    </div>
  );
}

function getTests(profile: GeneralProfile) {
  let result: RenderableTest[] = [];
  if (profile.sat) {
    result = result.concat({
      name: "SAT",
      tests: profile.sat,
      total: String(computeSatTotal(profile.sat)),
    });
  }
  if (profile.act) {
    result = result.concat({
      name: "ACT",
      tests: profile.act,
      total: String(computeActTotal(profile.act)),
    });
  }
  if (profile.aLevelTests.length !== 0) {
    result = result.concat({
      name: "A-Level tests",
      tests: profile.aLevelTests.map((test) => {
        return { ...test, name: test.name.slice(7, test.name.length) };
      }),
    });
  }
  if (profile.apTests.length !== 0) {
    result = result.concat({
      name: "AP tests",
      tests: profile.apTests.map((test) => {
        return { ...test, name: test.name.slice(3, test.name.length) };
      }),
    });
  }
  if (profile.satSubjectTests.length !== 0) {
    result = result.concat({
      name: "SAT subject tests",
      tests: profile.satSubjectTests,
    });
  }
  if (profile.ibTests.length !== 0) {
    result = result.concat({
      name: "IB tests",
      tests: profile.ibTests.map((test) => {
        return { ...test, name: test.name.slice(3, test.name.length) };
      }),
    });
  }
  result = result.sort((a, b) => {
    /*if (a.total && !b.total) {
            return -1
        }
        if (b.total && !a.total) {
            return 1
        }*/
    return a.tests.length - b.tests.length;
  });
  return result;
}

function computeSatTotal(subjects: Test[]) {
  let total = 0;
  subjects.forEach((subject) => {
    total += Number(subject.score);
  });
  return total;
}

function computeActTotal(subjects: Test[]) {
  let total = 0;
  subjects.forEach((subject) => {
    total += Number(subject.score);
  });
  return Math.round(total / 4.0);
}

export default TestSection;
