import React, { useEffect, useState } from "react";
import PageTitle from "../general/PageTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import SearchableSelector, { Option } from "../general/SearchableSelector";
import {
  FilterLineItem,
  AlternativeFilter,
  GeneralApplicationFilter,
} from "../../types/serverSideTypes";
import { Essay } from "../../types/uploadTypes";
import { FilterList, OriginalFilterItem } from "../../types/types";
import Spacing from "../general/Spacing";
import RadioOptions from "../general/RadioOptions";
import Button from "@material-ui/core/Button";
import SnackBar from "../general/SnackBar";
import axios from "axios";
import InputTextField from "./profile/InputTextField";
import * as Sentry from "@sentry/browser";
import EssaysTable from "../general/EssaysTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light,
      //marginBottom: '5%',
    },
    question: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      padding: "0px 5px",
    },
    questionBlock: {
      textAlign: "left",
      [theme.breakpoints.up("sm")]: {
        padding: "20px 40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px 30px",
      },
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
  })
);

export interface CaseInfo {
  schoolId: string;
  yearId: string;
  majorId: string;
  applicationRoundId: string;
  scholarshipId: string;
  newlyAddedSchoolName: string | null;
  newlyAddedMajorName: string | null;
  essays: Array<Essay>;
  legacy?: boolean;
}

export interface SimpleCaseInfo {
  school_name: string;
  year: string;
  id: number;
  status: string;
}

interface Props {
  caseInfo?: CaseInfo;
  disableBack: boolean;
  handleBack: () => void;
  handleNext: () => void;
  handleEnterInfo: (caseInfo: CaseInfo) => void;
  nextButtonText: string;
  shouldProceedToNext: boolean;
  setShouldProceedToNext: (value: boolean) => void;
  //loadFilesByIdAndStatus: (caseId: number, status: string, callback: (caseId: number, status: string) => void, failureCallback: () => void) => void
}

function convertFilterFormat(filter: Array<FilterList>, title: string) {
  return filter.find((item) => item.title === title)?.listStatus || [];
}

export interface CaseIdentification {
  caseId: number;
  status: string;
}

function validateEssays(essays: Array<Essay>) {
  for (let i = 0; i < essays.length; i++) {
    const test = essays[i];
    if (!test.essayTopicId && !test.title) {
      return "Please pick an essay topic or manually type in your essay's topic";
    }
    if (!test.value) {
      return "Please make sure you have pasted your essays";
    }
  }
}

function EnterInformationPage(props: Props) {
  const classes = useStyles();

  const caseInfo = props.caseInfo;
  const [filter, setFilter] = useState<GeneralApplicationFilter | null>(null);
  const [alternativeFilter, setAlternativeFilter] =
    useState<AlternativeFilter | null>(null);
  const [schoolId, setSchoolId] = useState<string | null>(
    caseInfo?.schoolId || null
  );
  const [yearId, setYearId] = useState<string | null>(caseInfo?.yearId || null);
  const [majorId, setMajorId] = useState<string | null>(
    caseInfo?.majorId || null
  );
  const [applicationRoundId, setApplicationRoundId] = useState<string | null>(
    caseInfo?.applicationRoundId || null
  );
  const [scholarshipId, setScholarshipId] = useState<string | null>(
    caseInfo?.scholarshipId || null
  );
  const [essays, setEssays] = useState<Array<Essay>>(caseInfo?.essays || []);
  const [newlyAddedSchoolName, setNewlyAddedSchoolName] = useState<
    string | null
  >(caseInfo?.newlyAddedSchoolName || null);
  const [newlyAddedMajorName, setNewlyAddedMajorName] = useState<string | null>(
    caseInfo?.newlyAddedMajorName || null
  );
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [accepted, setAccepted] = useState<boolean | null>(
    caseInfo ? true : null
  );
  const [legacy, setLegacy] = useState<boolean | null>(caseInfo?.legacy);

  useEffect(() => {
    axios("api/v1/get_general_profile_filters", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        var downloadedFilter = data.data as Array<FilterList>;
        if (downloadedFilter) {
          downloadedFilter = downloadedFilter.map((item) => {
            // do not allow users to choose empty school or major
            if (
              (item.title === "Schools" || item.title === "Majors") &&
              false
            ) {
              return {
                title: item.title,
                listStatus: addOtherOption(item.listStatus),
              };
            }
            return item;
          });
          const transformedFilter = {
            schools: convertFilterFormat(downloadedFilter, "Schools"),
            majors: convertFilterFormat(downloadedFilter, "Majors"),
            years: convertFilterFormat(downloadedFilter, "Years"),
            ethnicities: convertFilterFormat(downloadedFilter, "Ethnicities"),
            genders: convertFilterFormat(downloadedFilter, "Genders"),
            countries: convertFilterFormat(downloadedFilter, "Countries"),
            essay_topics: convertFilterFormat(downloadedFilter, "Essay Topics"),
            honor_levels: convertFilterFormat(downloadedFilter, "Honor Levels"),
            sat_subject_test_names: convertFilterFormat(
              downloadedFilter,
              "SAT Subject Test Names"
            ),
            us_states: convertFilterFormat(downloadedFilter, "US States"),
            a_level_test_names: convertFilterFormat(
              downloadedFilter,
              "A Level Test Names"
            ),
            a_level_test_scores: convertFilterFormat(
              downloadedFilter,
              "A Level Test Scores"
            ),
            college_level_test_names: convertFilterFormat(
              downloadedFilter,
              "College Level Test Names"
            ),
            college_level_test_scores: convertFilterFormat(
              downloadedFilter,
              "College Level Test Scores"
            ),
          };
          setFilter(transformedFilter);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        // TODO: add error logger
        // TODO: add banner
      });

    axios("api/v1/get_alternative_filters", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        var downloadedFilter = data.data as Array<FilterList>;
        if (downloadedFilter) {
          const transformedFilter = {
            application_rounds: convertFilterFormat(
              downloadedFilter,
              "Application Rounds"
            ),
            scholarships: convertFilterFormat(downloadedFilter, "Scholarships"),
          };
          setAlternativeFilter(transformedFilter);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        // TODO: add error logger
        // TODO: add banner
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const radioOptions = [
    {
      key: "yes",
      value: "Yes",
    },
    {
      key: "no",
      value: "No",
    },
  ];

  const handleSelectSchool = (key: string | null) => {
    if (schoolId === "-1" && key !== "-1") {
      setNewlyAddedSchoolName(null);
    }
    setSchoolId(key);
  };

  const handleSelectYear = (key: string | null) => {
    setYearId(key);
  };

  const handleSelectMajor = (key: string | null) => {
    if (majorId === "-1" && key !== "-1") {
      setNewlyAddedMajorName(null);
    }
    setMajorId(key);
  };

  const handleNext = () => {
    if (
      !schoolId ||
      !majorId ||
      !yearId ||
      !applicationRoundId ||
      !scholarshipId ||
      accepted === null ||
      legacy === null
    ) {
      setMessage(
        getNotificationMessage(
          schoolId,
          majorId,
          yearId,
          accepted,
          applicationRoundId,
          scholarshipId,
          legacy
        )
      );
      return;
    }
    if (schoolId === "-1" && !newlyAddedSchoolName) {
      setMessage("Please specify the school name");
      return;
    }
    if (majorId === "-1" && !newlyAddedMajorName) {
      setMessage("Please specify your prospective major");
      return;
    }
    if (!accepted) {
      setMessage("You may only upload admitted applications");
      return;
    }
    let notification = validateEssays(essays);
    if (notification) {
      setMessage(notification);
      return;
    }
    props.handleEnterInfo({
      schoolId,
      yearId,
      majorId,
      newlyAddedMajorName,
      newlyAddedSchoolName,
      applicationRoundId,
      scholarshipId,
      essays,
      legacy,
    });
    props.handleNext();
  };

  useEffect(() => {
    if (props.shouldProceedToNext) {
      handleNext();
      props.setShouldProceedToNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToNext]);

  const handleAccepted = (value: string) => {
    setAccepted(value === "Yes" ? true : false);
  };

  const handleLegacy = (value: string) => {
    setLegacy(value === "Yes" ? true : false);
  };

  const callBack = () => {
    setMessage(undefined);
  };

  const onSchoolNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewlyAddedSchoolName(event.target.value);
  };

  const onMajorNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewlyAddedMajorName(event.target.value);
  };

  /*const loadCaseById = (caseId: number, status: string, callback: (caseId: number, status: string) => void) => {
        props.loadFilesByIdAndStatus(caseId, status, callback, () => {});
    }*/

  const radioQuestion = (
    <RadioOptions
      options={radioOptions}
      handleSelect={handleAccepted}
      value={accepted ? "Yes" : accepted === false ? "No" : null}
    />
  );

  const legacyQuestion = (
    <RadioOptions
      options={radioOptions}
      handleSelect={handleLegacy}
      value={legacy ? "Yes" : legacy === false ? "No" : null}
    />
  );

  return (
    <div className={classes.root}>
      <PageTitle title="Enter School Specific Information" />
      <div className={classes.questionBlock}>
        {GetQuestionTitle(
          "*1. Which college are these supplemental essays for?"
        )}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(filter !== null ? filter?.schools : []),
          handleSelectSchool,
          schoolId,
          "Stanford University"
        )}
        {schoolId === "-1" && (
          <div>
            <Spacing spacing={12} />
            {GetQuestionTitle("If not found, please specify below:")}
            <Spacing spacing={8} />
            <InputTextField
              value={newlyAddedSchoolName ? newlyAddedSchoolName : ""}
              onChange={onSchoolNameChange}
              style={{ maxWidth: "320px", minWidth: "240px", width: "30%" }}
            />
          </div>
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("*2. Did you get accepted into this college?")}
        <Spacing spacing={8} />
        {radioQuestion}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle(
          "*3. Which year is this application for? (Class of 2019 means that you graducated from college in 2019.)"
        )}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(filter !== null ? filter?.years : []),
          handleSelectYear,
          yearId,
          "Class of 2020"
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle(
          "*4. What is your prospective major as indicated on the application?"
        )}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(filter !== null ? filter?.majors : []),
          handleSelectMajor,
          majorId,
          "Psychology"
        )}
        {majorId === "-1" && (
          <div>
            <Spacing spacing={12} />
            {GetQuestionTitle("If not found, please specify below:")}
            <Spacing spacing={8} />
            <InputTextField
              value={newlyAddedMajorName || ""}
              onChange={onMajorNameChange}
              style={{ maxWidth: "320px", minWidth: "240px", width: "30%" }}
            />
          </div>
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("*5. What round was this application for?")}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(
            alternativeFilter !== null
              ? alternativeFilter?.application_rounds
              : []
          ),
          setApplicationRoundId,
          applicationRoundId,
          "Regular Round"
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("*6. Did you get any scholarship from this school?")}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(
            alternativeFilter !== null ? alternativeFilter?.scholarships : []
          ),
          setScholarshipId,
          scholarshipId,
          "$5000"
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("*7. Are you a legacy student for this college?")}
        <Spacing spacing={8} />
        {legacyQuestion}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("8. Please paste your school-specific supplements.")}
        <Spacing spacing={8} />
        <EssaysTable
          schoolId={schoolId || undefined}
          shouldFilterBySchoolId={true}
          activities={essays}
          setActivities={setEssays}
          essayTopics={filter?.essay_topics || []}
        />
      </div>
      <SnackBar message={message} callBack={callBack} />
      <div style={{ padding: "20px 0px" }}>
        <Button
          disabled={props.disableBack}
          onClick={props.handleBack}
          className={classes.backButton}
        >
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          {props.nextButtonText}
        </Button>
      </div>
    </div>
  );
}

function getNotificationMessage(
  schoolId: string | null,
  majorId: string | null,
  yearId: string | null,
  accepted: boolean | null,
  applicationRoundId: string | null,
  scholarshipId: string | null,
  legacy: boolean | null
): string {
  var missingFields = [];
  if (!schoolId) {
    missingFields.push(" Q1");
  }
  if (accepted == null) {
    missingFields.push(" Q2");
  }
  if (!yearId) {
    missingFields.push(" Q3");
  }
  if (!majorId) {
    missingFields.push(" Q4");
  }
  if (!applicationRoundId) {
    missingFields.push(" Q5");
  }
  if (!scholarshipId) {
    missingFields.push(" Q6");
  }
  if (legacy === null || legacy === undefined) {
    missingFields.push(" Q7");
  }
  if (missingFields.length > 1) {
    return "These questions are required:" + missingFields.toString();
  } else {
    return "You need to answer" + missingFields.toString();
  }
}

function getFilter(items: Array<FilterLineItem>): Array<Option> {
  const options = items.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });
  return options;
}

function addOtherOption(
  options: Array<OriginalFilterItem>
): Array<OriginalFilterItem> {
  if (!options) {
    return options;
  }
  options.splice(0, 0, { id: "-1", name: "Other" });
  return options;
}

function GetDropdownList(
  options: Array<Option>,
  handleSelect: (key: string | null) => void,
  value: string | null,
  placeholder?: string
) {
  return (
    <div style={{ minWidth: "240px", maxWidth: "320px", width: "30%" }}>
      <SearchableSelector
        options={options}
        handleSelect={handleSelect}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
}

function GetQuestionTitle(question: string) {
  //const classes = useStyles();
  return (
    <Typography
      variant="body1"
      style={{
        fontFamily: "SuperaGothic",
        fontWeight: "bold",
        padding: "0px 5px",
      }}
    >
      {question}
    </Typography>
  );
}

export default EnterInformationPage;
