import { FilterItem, SchoolCardContent } from "./types";

export type ServerCategoryStatus = {
  title: string;
  listStatus: Array<FilterItem>;
};

export type Page = {
  size: number;
  offSet: number;
};

export enum OrderByField {
  MOST_VIEWED = "MOST_VIEWED",
  NEWLY_ADDED = "NEWLY_ADDED",
  MOST_SAVED = "MOST_SAVED",
}

export const orderByMap = [
  {
    name: "Most Viewed",
    type: OrderByField.MOST_VIEWED,
  },
  {
    name: "New",
    type: OrderByField.NEWLY_ADDED,
  },
  {
    name: "Most Saved",
    type: OrderByField.MOST_SAVED,
  },
];

export enum OrderType {
  ASC = 0,
  DESC = 1,
}

export type GetPaginatedSchoolsResponse = {
  cases: Array<SchoolCardContent>;
  totalPageNumbers: number;
  totalResultsCount: number;
};

export type FilterLineItem = {
  id: string;
  name: string;
  school_id?: string;
  type?: string;
  score?: string;
};

export type Filter = {
  schools: Array<FilterLineItem>;
  majors: Array<FilterLineItem>;
  years: Array<FilterLineItem>;
  rankings: Array<FilterLineItem>;
  categories: Array<FilterLineItem>;
};

export type AlternativeFilter = {
  application_rounds: Array<FilterLineItem>;
  scholarships: Array<FilterLineItem>;
};

export type GeneralApplicationFilter = {
  schools: Array<FilterLineItem>;
  majors: Array<FilterLineItem>;
  years: Array<FilterLineItem>;
  genders: Array<FilterLineItem>;
  ethnicities: Array<FilterItem>;
  countries: Array<FilterLineItem>;
  essay_topics: Array<FilterLineItem>;
  honor_levels: Array<FilterLineItem>;
  sat_subject_test_names: Array<FilterLineItem>;
  us_states: Array<FilterLineItem>;
  a_level_test_names: Array<FilterLineItem>;
  a_level_test_scores: Array<FilterLineItem>;
  college_level_test_names: Array<FilterLineItem>;
  college_level_test_scores: Array<FilterLineItem>;
};

export interface SimpleDraftCase {
  id: string;
  status: string;
  dbStatus: string;
  schoolName: string;
  timeSubmitted: string;
}
