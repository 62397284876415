import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "30px",
      backgroundColor: theme.palette.secondary.light,
      width: "100%",
      flexGrow: 1,
    },
    text: {
      fontFamily: "SuperaGothic",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    textField: {
      width: "90%",
      maxWidth: "280px",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    sideMargin: {
      margin: "0px 0px",
      width: "100%",
    },
    contentContainer: {
      paddingTop: "4rem",
      paddingBottom: "6rem",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "0.75rem",
      color: "#4a5568",
      letterSpacing: "0.025em",
      marginBottom: "0.5rem",
    },
    smallText: {
      fontSize: "0.8rem",
      color: "#4a5568",
      marginBottom: "0.5rem",
    },
    rowContainer: {
      display: "flex",
      flexWrap: "wrap",
      //marginLeft: '-0.75rem',
      //marginRight: '-0.75rem',
      marginBottom: "0.5rem",
    },
    innerRowContainer: {
      width: "100%",
      //paddingLeft: '0.75rem',
      //paddingRight: '0.75rem',
    },
    customizedTextField: {
      appearance: "none",
      fontFamily: "SuperaGothic",
      width: "100%",
      backgroundColor: "#edf2f7",
      borderWidth: "1px",
      borderRadius: "0.375rem",
      color: "#4a5568",
      padding: "0.75rem 0.5rem",
      marginBottom: "0.75rem",
      lineHeight: "1.25",
    },
  })
);

interface ColoredTextFieldProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  placeholder: string;
  title: string;
  text?: string;
}

export default function ColoredTextField(props: ColoredTextFieldProps) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  let type = props.type;
  if (props.type === "password") {
    type = showPassword ? "text" : "password";
  }

  return (
    <div className="w-full">
      <Typography variant="body1" className={classes.title}>
        {props.title}
      </Typography>
      <div
        style={{
          display: "flex",
          position: "relative",
          justifyContent: "center",
        }}
      >
        <input
          className={classes.customizedTextField}
          type={type}
          placeholder={props.placeholder}
          onChange={props.onChange}
          required
        />
        {props.type === "password" && (
          <i style={{ position: "absolute", right: "10px", top: 10 }}>
            {!showPassword && (
              <VisibilityIcon
                onClick={toggleShowPassword}
                style={{ fontSize: 16, color: "gray" }}
              />
            )}
            {showPassword && (
              <VisibilityOffIcon
                onClick={toggleShowPassword}
                style={{ fontSize: 16, color: "gray" }}
              />
            )}
          </i>
        )}
      </div>
      {props.text && (
        <Typography variant="body2" className={classes.smallText}>
          {props.text}
        </Typography>
      )}
    </div>
  );
}
