import React from "react";
import Grid from "@material-ui/core/Grid";

interface Props {
  children?: React.ReactNode;
}

function Row(props: Props) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {props.children}
    </Grid>
  );
}

export default Row;
