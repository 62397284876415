import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../general/PrimaryButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quoteSection: {
      padding: "0px 10%",
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("md")]: {
        display: "inline-block",
        height: "auto",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        //height: '550px',
        flexDirection: "row-reverse",
      },
    },
    padding: {
      maxWidth: "500px",
      margin: "auto",
      marginTop: "30px",
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        //display:'flex',
        padding: "0%",
        paddingLeft: "0%",
        width: "50%",
      },
    },
    image: {
      maxWidth: "500px",
      margin: "auto",
      padding: "0%",
      [theme.breakpoints.down("md")]: {
        display: "block",
        margin: "auto",
        maxHeight: "100%",
        width: "100%",
        marginTop: "50px",
        marginBottom: "50px",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        width: "50%",
      },
    },
    spacing: {
      "@media (min-width:800px)": {
        marginTop: "25px",
        marginBottom: "50px",
      },
      "@media (max-width:800px)": {
        marginTop: "5vw",
        marginBottom: "3vw",
      },
    },
    button: {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
      [theme.breakpoints.up("md")]: {
        textAlign: "left",
      },
    },
    titleSection: {
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      fontWeight: "bold",
    },
    text: {
      textAlign: "left",
      //paddingTop: '10%',
      margin: "auto",

      [theme.breakpoints.down("sm")]: {
        paddingTop: "10%",
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: "60px",
        paddingBottom: "60px",
        width: "60%",
        paddingLeft: "10%",
      },
    },
  })
);

function QuoteSection() {
  const styles = useStyles();
  const buttonStyle = {
    backgroundColor: "white",
    color: "#0A5961",
    marginBottom: "0px",
  };
  return (
    <div className={styles.quoteSection}>
      <div className={styles.text}>
        <Typography
          variant="h3"
          className={styles.titleSection}
          style={{ fontWeight: "bold", color: "white" }}
        >
          Who Are We?
        </Typography>
        <Typography
          variant="body1"
          className={`roboto-condensed ${styles.spacing}`}
          style={{ color: "white" }}
        >
          CollegeSharing was founded by a group of Stanford University students,
          which means we know firsthand how challenging college admission can
          be. Our mission is to democratize the process by providing real world
          insights traditionally only accessible to a small group of students.
        </Typography>
        <div style={{ margin: "auto", textAlign: "center", marginTop: "40px" }}>
          <PrimaryButton
            style={buttonStyle}
            link="/search"
            label="vision.start_browsing"
          >
            <Typography variant="h5">Start Browsing</Typography>
          </PrimaryButton>
        </div>
      </div>
      <div className={styles.padding}>
        <img
          alt=""
          style={{ margin: "auto" }}
          src={require("../../resources/visionimage.svg").default}
        />
      </div>
    </div>
  );
}

export default QuoteSection;
