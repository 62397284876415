import React from "react";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

interface Props {
  fontSize?: 1 | 2;
}

function PrivacyPolicy(props: Props) {
  const fontSize = props.fontSize || 2;

  const bigHeader = fontSize === 1 ? "h6" : "h4";
  const smallHeader = fontSize === 1 ? "h6" : "h5";

  return (
    <div style={{ textAlign: "left" }}>
      <Typography variant="body2" style={{ marginTop: 40 }}>
        This Privacy Policy describes the types of information CollegeSharing
        Inc. (“CollegeSharing”) collects from and about you when you visit our
        website, www.collegesharing.com (“Site”). This Privacy Policy also
        explains how CollegeSharing may use and disclose such information, as
        well as your ability to control certain uses of it.{" "}
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        By using the Site, you agree to the collection, use, and disclosure of
        your information as described in this Privacy Policy, and agree to the
        CollegeSharing{" "}
        <Link to="/terms_of_use" style={{ color: "#0077FF" }} target="_blank">
          Terms of Use
        </Link>
        , which are incorporated by reference. If you do not agree, please do
        not access or use the Site.{" "}
      </Typography>
      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        1. INFORMATION WE COLLECT
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        We may obtain information about you, including (A) information you
        provide to us directly; (B) information that we automatically collect,
        and (C) information we collect from other sources. This includes data
        that identifies you personally whether directly (i.e., your name) or
        indirectly (i.e., information about your online use).{" "}
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        A. Information You Provide
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        CollegeSharing collects information from you when you choose to share it
        with us. This may include when you use our Site, register or create an
        online account, make a purchase, subscribe to our services, contribute
        to CollegeSharing, request information or contact customer service, or
        otherwise communicate with or contact us. The information we collect may
        include your name, address, email address, telephone or mobile number,
        username, date of birth, gender, and other information that you
        voluntarily provide when you post comments while using the Site.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        You may also provide us with certain information in connection with
        specification transactions:
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <li>
          <span style={{ fontWeight: "bold" }}>
            Information You Provide When You Subscribe or Make a Purchase.{" "}
          </span>
          If you make a purchase from us, we collect a credit/debit card number
          and related financial information (such as CVV, expiration date, and
          billing address).{" "}
        </li>
        <li>
          <span style={{ fontWeight: "bold" }}>
            Information You Provide When You Become a CollegeSharing
            Contributor.
          </span>{" "}
          If you choose to contribute to CollegeSharing, we may require you to
          provide personal information for purposes of accepting payments,
          including your debit card or bank account number, social security
          number, or government issued identification number. We collect this
          information to process payments to you, detect and prevent fraud, and
          generate tax reports. We may also ask for your educational background,
          admissions letter, and for other information that may be contained in
          your college admissions essay, application, or other documents that
          you upload to the Site.
        </li>
        <li>
          <span style={{ fontWeight: "bold" }}>
            Information You Provide about a Third Party.
          </span>{" "}
          If you send someone else a communication from the Site, such as
          sending an invitation to a friend, the information you provide (e.g.,
          name, e-mail address) will be used to facilitate the communication and
          will not be used for any other marketing purpose, unless such person
          registers with us or becomes a customer, we obtain consent from such
          person, or we explicitly say otherwise at the point of collection.{" "}
        </li>
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        B. Information Automatically Collected
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        Whenever you visit or interact with the Site, we and our advertising and
        analytics service providers may use cookies or similar technologies to
        automatically or passively collect certain information your online
        activity. For example, we automatically collect the following
        information about you:
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <li>
          <span style={{ fontWeight: "bold" }}>
            Computer or Device Information.
          </span>{" "}
          We may automatically collect your Internet Protocol (“IP”) address or
          other unique identifiers or information from the computer or other
          device you use to access the Site.
        </li>
        <li>
          <span style={{ fontWeight: "bold" }}>Usage Information.</span> We may
          collect information about your use of the Site, including the date and
          time you visit the Site, the areas or pages of the Site that you
          visit, the amount of time you spend viewing or using the Site, the
          number of times you return to the Site, other click-stream or Site
          usage data, and other sites that you may visit.
        </li>
        <li>
          <span style={{ fontWeight: "bold" }}>
            Use of Third Party Analytic Technologies.
          </span>{" "}
          We may use third parties’ analytics and tracking tools to better
          understand who is using the Site, how people are using the Site, how
          to improve the effectiveness of the Site and related content, and to
          help us or those third parties serve more targeted advertising to you
          across the Internet. These tools may use technology such as cookies,
          pixel tags, log files, HTML5 cookies, or other technologies to
          automatically collect and store certain information. They may also
          combine information they collect from your interaction with the Site
          with information they collect from other sources.
        </li>
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        To learn more about your advertising choices relating to the collection
        and use of your information, please see the “Your Choices and Opt-Outs”
        section below.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        C. Information Collected From Other Sources
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        We may acquire information from third party sources, such as our
        business partners, analytics vendors, advertising networks, and search
        information providers, to help us personalize your experience, target
        our communications so that we can inform you of services or other offers
        that may be of interest to you, measure ad quality and responses, and
        for internal business analysis or other business purposes. We may
        combine the information we receive from and about you, including
        information you provide to us, information we automatically collect, and
        information from third party sources.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        2. INFORMATION USE{" "}
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        CollegeSharing may use the information we collect from and about you for
        the following purposes:
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <li>
          To respond to your inquiries, fulfill orders for our services,
          products, or subscriptions, and contact and communicate with you when
          necessary;
        </li>
        <li>
          To use and disclose your credit/debit card information and bank
          account information only to process payments and prevent fraud;{" "}
        </li>
        <li>
          To address problems with the Site or our business, protect the
          security or integrity of the Site and our business, and to determine
          whether applicable policies are being violated.
        </li>
        <li>To review the usage and operations of our Site;</li>
        <li>
          To send marketing messages to you or to advertise to you on our Site
          or across the Internet;
        </li>
        <li>To improve the site and develop new products or services; </li>
        <li>
          To conduct analysis to enhance or improve our content, products, and
          services; In an aggregated non-specific format for analytical and
          demographic purposes; and
        </li>
        <li>
          For other purposes disclosed at the time you provide your information
          or otherwise with your consent.
        </li>
      </Typography>
      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        3. INFORMATION WE SHARE WITH OTHERS
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        We may share the information we collect from and about you as described
        below.
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        A. Sharing of Information by You
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        When you use the Site, you may contribute content such as your college
        application and other personal information and post comments about
        available college applications. Any information you may disclose on our
        Site, including in public areas on the Site, becomes public information.
        Please exercise caution when disclosing personal information in these
        public areas.
      </Typography>

      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        B. Sharing of Information by Us
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        We may share your personal information with the following:
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <li>
          <span style={{ fontWeight: "bold" }}>Service Providers:</span> We may
          share your information with service providers that provide business,
          professional or technical support functions for us (including to the
          extent necessary to process payments in order to fulfill transactions
          on the Site), help us operate our business and the Site, or administer
          activities on our behalf.
        </li>
        <li>
          <span style={{ fontWeight: "bold" }}>Third Parties:</span> We may
          share your application materials, education information, and in some
          cases other personal information that you provide when you contribute
          content to the Site to subscribers of CollegeSharing. Subscribers’ use
          of such information is subject to our{" "}
          <Link to="/terms_of_use" target="_blank" style={{ color: "#0077FF" }}>
            Terms of Use
          </Link>
          .
        </li>
        <li>
          <span style={{ fontWeight: "bold" }}>Legal Matters; Safety:</span> We
          may access and disclose your information to respond to subpoenas,
          judicial processes, or government requests and investigations, or in
          connection with an investigation on matters related to public safety,
          as permitted by law, or otherwise as required by law. We may disclose
          your information to protect the security of our Site, servers, network
          systems, and databases. We also may disclose your information as
          necessary, if we believe that there has been a violation of our Terms
          of Use, any other legal document or contract related to our services,
          or the rights of any third party.
        </li>
        <li>
          <span style={{ fontWeight: "bold" }}>
            Sale or Transfer of Business or Assets:
          </span>{" "}
          We may sell or purchase assets during the normal course of our
          business. If another entity acquires us or any of our assets,
          information we have collected about you may be transferred to such
          entity. In addition, if any bankruptcy or reorganization proceeding is
          brought by or against us, such information may be considered an asset
          of ours and may be sold or transferred to third parties. Should such a
          sale or transfer occur, we will use reasonable efforts to try to
          require that the transferee use personal information provided through
          this Site in a manner that is consistent with this Privacy Policy.
        </li>
        <li>
          <span style={{ fontWeight: "bold" }}>
            Aggregate or Anonymous Non-Personal Information:
          </span>{" "}
          We may also share aggregate, anonymous, or de-identified non-personal
          information with third parties for their marketing or analytics uses.
        </li>
        <li>
          <span style={{ fontWeight: "bold" }}>Other:</span> We also may share
          your information as disclosed to you at the time of collection.
        </li>
      </Typography>
      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        4. YOUR CHOICES AND OPT-OUTS
      </Typography>
      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        A. Cookies, Tracking Options and California Do Not Track Disclosures
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        Certain parts of our Site require cookies. You may adjust your device or
        Internet browser settings to limit certain tracking or to decline
        cookies, but by doing so, you may not be able to use certain features of
        the Site or take full advantage of all of our offerings. Please refer to
        your device’s settings or your Internet browser’s “Help” section for
        more information on how to delete and/or disable your device or browser
        from receiving cookies or controlling your tracking preferences. Our
        system may not recognize Do Not Track requests or headers from some or
        all browsers.{" "}
      </Typography>

      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        B. Interest-Based Advertising Choices
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        To understand your choices for receiving more relevant advertising
        provided on the Site or across other websites and online services,
        please review the information below:
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        <li>
          To learn more about such interest-based advertising, and to opt out of
          such collection and use for interest-based advertising by the Digital
          Advertising Alliance (DAA) participating companies, please visit
          http://optout.aboutads.info.
        </li>
        <li>
          If you wish to prevent your data from being used by Google Analytics,
          Google has developed the Google Analytics opt-out browser add-on
          available https://tools.google.com/dlpage/gaoptout/.{" "}
        </li>
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        Even if you opt out, you still may receive advertising from us or
        advertising from third parties if they are not a DAA participating
        company.
      </Typography>

      <Typography
        variant={smallHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        C. Updating Your Information
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        You may update certain personal information through your account
        available by{" "}
        <Link
          to="/login_and_security"
          target="_blank"
          style={{ color: "#0077FF" }}
        >
          clicking here
        </Link>{" "}
        or, if you are a contributor to the Site, through your payments account
        which is linked from your CollegeSharing account profile. You may also
        close your account at any time.{" "}
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        5. THIRD-PARTY CONTENT, LINKS, AND PLUG-INS{" "}
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        The Site may have links to third-party websites, which may have privacy
        policies that differ from our own. We are not responsible for the
        practices of such sites.{" "}
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        The Site may also offer you the ability to interact with social plugins
        from social media sites, which may allow us and/or the social media site
        to receive data from or about you. In some cases, we may know that you
        clicked on a social plugin, such as a Twitter Follow button, or receive
        other information from the social media sites. Similarly, if you have
        previously provided personal information to a third-party operating a
        plug-in on this Site, then such third-party may recognize you on this
        Site. Your use of social network plugins is subject to each social media
        site’s privacy policy, which may be different from ours, so please read
        these policies carefully to understand their policies and your options.
        As with linked sites, we have no control over the information that is
        collected, stored, or used by social network plugins, and are not
        responsible for the practices of such sites.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        6. CHILDREN’S PRIVACY
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        Protecting children’s privacy is important to us. We do not direct the
        Site to, nor do we knowingly collect any personal information from,
        children under the age of 13. If CollegeSharing learns that a child
        under the age of thirteen has provided personally identifiable
        information to the Site, it will use reasonable efforts to remove such
        information from its files.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        7. REVISIONS TO THIS PRIVACY POLICY
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        We reserve the right, at our sole discretion, to change, modify, add,
        remove, or otherwise revise portions of this Privacy Policy at any time.
        When we do, we will post the change(s) on the Site. Your continued use
        of the Site following the posting of changes to these terms means you
        accept these changes. If we change the Privacy Policy in a material way,
        we will provide appropriate notice to you.
      </Typography>

      <Typography
        variant={bigHeader}
        style={{ marginTop: 20, fontWeight: "bold" }}
      >
        8. HOW TO CONTACT US
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        If you have any questions or concerns about this Privacy Policy or the
        practices described here, you may contact us at
        support@collegesharing.com.
      </Typography>

      <Typography variant="body2" style={{ marginTop: 40 }}>
        CollegeSharing Inc.
      </Typography>
    </div>
  );
}

export default PrivacyPolicy;
