import React, { useEffect } from "react";
import { useStore } from "react-redux";
import Nav from "./components/nav/Nav";
import "./App.css";
import { Router, Switch, Redirect, useLocation } from "react-router-dom";
import ProtectedRoute from "./helpers/ProtectedRoute";
import BoundedRoute from "./helpers/BoundedRoute";
import GuestLandingPage from "./components/pages/GuestLandingPage";
import HostLandingPage from "./components/pages/HostLandingPage";
import AdmitProfileSearchPage from "./components/pages/AdmitProfileSearchPage";
import UploadApplicationPage from "./components/pages/UploadApplicationPage";
import ReviewApplicationPage from "./components/pages/ReviewApplicationPage";
import EditApplicationPage from "./components/pages/EditApplicationPage";
import PageNotFoundPage from "./components/errors/PageNotFound";
import ServiceUnavailablePage from "./components/errors/ServiceUnavailable";
import AcceptCasePage from "./components/pages/case/AcceptCasePage";
import DeclineCasePage from "./components/pages/case/DeclineCasePage";
import Footer from "./components/general/Footer";
import SignUpPage from "./components/pages/account/SignUpPage";
import LogInPage from "./components/pages/account/LogInPage";
import EmailConfirmationPage from "./components/pages/account/EmailConfirmationPage";
import ResetPasswordPage from "./components/pages/account/ResetPassWordPage";
import ChangePasswordPage from "./components/pages/account/ChangePasswordPage";
import CheckoutPage from "./components/pages/CheckoutPage";
import PayoutPage from "./components/pages/profile/PayoutPage";
import AccountPage from "./components/pages/profile/AccountPage";
//import PaymentMethodsPage from './components/pages/profile/PaymentMethodsPage';
import LoginAndSecurityPage from "./components/pages/profile/LoginAndSecurityPage";
import SubscriptionManagementPage from "./components/pages/profile/SubscriptionManagementPage";
import EarningsPage from "./components/pages/profile/EarningsPage";
import CaseListPage from "./components/pages/CaseListPage";
import ReactGa from "react-ga";
import { User } from "./types/types";
import { createBrowserHistory } from "history";
import "reflect-metadata";
import "./PaymentForm.css";
import "./components/css/Global.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import UserProfilePage from "./components/pages/SubmittedCasesPage";
import EndUserLicenseAgreement from "./components/pages/EndUserLicenseAgreement";
import UnlockAccountPage from "./components/pages/account/UnlockAccountPage";
import FaqPage from "./components/pages/FaqPage";
import ScrollToTop from "./helpers/ScrollToTop";
import PrivacyPolicyPage from "./components/pages/PrivacyPolicyPage";
import PendingPayoutPage from "./components/pages/PendingPayoutsPage";
import TermsOfUsePage from "./components/pages/TermsOfUsePage";
import UploadStatusPage from "./components/pages/UploadStatusPage";
import EditGeneralProfilePage from "./components/pages/EditGeneralProfilePage";
import ReviewGeneralProfilePage from "./components/pages/ReviewGeneralProfilePage";
import SubmissionListPage from "./components/pages/SubmissionListPage";
import AdmitProfilePage from "./components/pages/general_profile/AdmitProfilePage";
import {
  HOST_LANDING,
  PROFILE_SEARCH,
  SEARCH,
  PROFILE,
  ADMIT_PROFILE,
  SAVED_CASES,
  UPLOAD,
  REVIEW,
  EDIT,
  ACCEPT_CASE,
  DECLINE_CASE,
  SIGN_UP,
  LOG_IN,
  EMAIL_CONFIRMATION,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  PAYMENT_PAGE,
  PAYOUT,
  ACCOUNT,
  LOGIN_AND_SECURITY,
  CHANGE_SUBSCRIPTION,
  EARNINGS,
  USER,
  CASES,
  LICENSE_AGREEMENT,
  UNLOCK_ACCOUNT,
  PRIVACY_POLICY,
  FAQ,
  TERMS_OF_USE,
  PENDING_PAYOUTS,
  UPLOAD_STATUS,
  EDIT_GENERAL,
  REVIEW_GENERAL,
  SUBMISSIONS,
  PATHS_TO_EXCLUDE_HEADER,
} from "./constants/paths";

const history = createBrowserHistory();
history.listen((location) => {
  ReactGa.pageview(location.pathname + location.search);
  axios("api/v1/log_page_view", {
    method: "POST",
    withCredentials: true,
    data: {
      path: location.pathname,
      search: location.search,
    },
  });
});

function App() {
  const { pathname } = useLocation();

  return (
    <div
      className="App"
      style={{ display: "flex", flexDirection: "column", minHeight: "100%" }}
    >
      <Helmet>
        <title>CollegeSharing</title>
      </Helmet>

      {!PATHS_TO_EXCLUDE_HEADER.includes(pathname) && <Nav />}
      <ScrollToTop />
      <div
        style={{
          marginTop: "65px",
          justifyContent: "space-around",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 320,
          minHeight: 150,
        }}
      >
        <Switch>
          <BoundedRoute path="/" exact component={GuestLandingPage} />
          <BoundedRoute path={HOST_LANDING} exact component={HostLandingPage} />
          <BoundedRoute path={SEARCH} component={AdmitProfileSearchPage} />
          <BoundedRoute
            path={PROFILE_SEARCH}
            component={AdmitProfileSearchPage}
          />
          <BoundedRoute path={`${PROFILE}/:id`} component={AdmitProfilePage} />
          <BoundedRoute
            path={`${ADMIT_PROFILE}/:id`}
            component={AdmitProfilePage}
          />
          <ProtectedRoute
            path={SAVED_CASES}
            exact
            component={() => <AdmitProfileSearchPage onlyShowSaveCases />}
          />
          <ProtectedRoute
            path={UPLOAD}
            exact
            component={UploadApplicationPage}
          />
          <ProtectedRoute
            path={`${REVIEW}/:id`}
            component={ReviewApplicationPage}
          />
          <ProtectedRoute path={`${EDIT}/:id`} component={EditApplicationPage} />
          <BoundedRoute path="/404" exact component={PageNotFoundPage} />
          <BoundedRoute path="/503" exact component={ServiceUnavailablePage} />
          <ProtectedRoute
            path={`${ACCEPT_CASE}/:id`}
            component={AcceptCasePage}
          />
          <ProtectedRoute
            path={`${DECLINE_CASE}/:id`}
            component={DeclineCasePage}
          />
          <BoundedRoute path={SIGN_UP} exact component={SignUpPage} />
          <BoundedRoute path={LOG_IN} exact component={LogInPage} />
          <BoundedRoute
            path={EMAIL_CONFIRMATION}
            component={EmailConfirmationPage}
          />
          <BoundedRoute path={RESET_PASSWORD} component={ResetPasswordPage} />
          <BoundedRoute path={CHANGE_PASSWORD} component={ChangePasswordPage} />
          <ProtectedRoute path={PAYMENT_PAGE} exact component={CheckoutPage} />
          <ProtectedRoute path={PAYOUT} exact component={PayoutPage} />
          <ProtectedRoute path={ACCOUNT} exact component={AccountPage} />
          <ProtectedRoute
            path={LOGIN_AND_SECURITY}
            exact
            component={LoginAndSecurityPage}
          />
          <ProtectedRoute
            path={CHANGE_SUBSCRIPTION}
            exact
            component={SubscriptionManagementPage}
          />
          <ProtectedRoute path={EARNINGS} exact component={EarningsPage} />
          <ProtectedRoute path={CASES} exact component={CaseListPage} />
          <BoundedRoute path={`${USER}/:user_id`} component={UserProfilePage} />
          <BoundedRoute
            path={LICENSE_AGREEMENT}
            exact
            component={EndUserLicenseAgreement}
          />
          <BoundedRoute path={UNLOCK_ACCOUNT} component={UnlockAccountPage} />
          <BoundedRoute path={FAQ} component={FaqPage} />
          <BoundedRoute path={PRIVACY_POLICY} component={PrivacyPolicyPage} />
          <BoundedRoute path={TERMS_OF_USE} component={TermsOfUsePage} />
          <ProtectedRoute
            path={PENDING_PAYOUTS}
            component={PendingPayoutPage}
          />
          <ProtectedRoute path={UPLOAD_STATUS} component={UploadStatusPage} />
          <ProtectedRoute
            path={EDIT_GENERAL}
            component={EditGeneralProfilePage}
          />
          <ProtectedRoute
            path={`${REVIEW_GENERAL}/:id`}
            component={ReviewGeneralProfilePage}
          />
          <ProtectedRoute path={SUBMISSIONS} component={SubmissionListPage} />
          <Redirect to="/404" />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default function AppWrapper() {
  const store = useStore();
  const user: User = store.getState().user;
  useEffect(() => {
    ReactGa.initialize("UA-184480971-1", {
      gaOptions: {
        userId: user && String(user.id),
      },
    });
    ReactGa.pageview(history.location.pathname + history.location.search);
  }, [user]);

  window.onbeforeunload = function (e: BeforeUnloadEvent) {
    if (document.activeElement?.tagName.toLowerCase() !== "a") {
      window.scrollTo(0, 0);
    }
  };
  return (
    <Router history={history}>
      <App />
    </Router>
  );
}
