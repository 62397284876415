import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ProfileCard from "../general/ProfileCard";
import PrimaryButton from "../general/PrimaryButton";
import Typography from "@material-ui/core/Typography";
import { SchoolCardContent } from "../../types/types";
import { GetPaginatedSchoolsResponse } from "../../types/serverSideTypes";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outer: {
      overflow: "auto",
      width: "100%",
    },
    previewSection: {
      display: "flex",
      padding: "30px",
      paddingTop: 0,
      width: "fit-content",
      margin: "0 auto",
    },
    profileCardWrap: {
      padding: "10px",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        width: "300px",
      },
      [theme.breakpoints.up("md")]: {
        width: "350px",
      },
    },
    buttonWrap: {
      paddingTop: "10px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10%",
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: "80px",
      },
    },
    textAlign: {
      [theme.breakpoints.down("md")]: {
        textAlign: "left",
      },
      [theme.breakpoints.up("md")]: {
        textAlign: "center",
      },
    },
    spacing: {
      "@media (min-width:800px)": {
        marginTop: "25px",
        marginBottom: "50px",
      },
      "@media (max-width:800px)": {
        marginTop: "5vw",
        marginBottom: "8vw",
      },
    },
    titleSection: {
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      fontWeight: "bold",
    },
    container: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "10%",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "80px",
      },
    },
  })
);

interface Props {
  shouldExcludeButton?: boolean;
}

function PreviewSection(props: Props) {
  const styles = useStyles();
  const [cards, setCards] = useState<Array<SchoolCardContent>>([]);
  useEffect(() => {
    axios("api/v1/get_preview_cases", {
      method: "POST",
      withCredentials: true,
      data: {
        requester_id: 1,
      },
    })
      .then((response) => {
        const data = response.data.data as GetPaginatedSchoolsResponse;
        setCards(data.cases);
      })
      .catch((error) => {
        Sentry.captureException(error);
        // log error if needed
      });
  }, []);
  if (cards.length === 0) {
    return <div></div>;
  }
  const profileCards = cards.map((card) => {
    return (
      <Box key={card.id}>
        <div className={styles.profileCardWrap}>
          <ProfileCard visibleByDefault profile={card} shouldShowAction />
        </div>
      </Box>
    );
  });
  return (
    <div>
      <Typography
        variant="h3"
        className={`${styles.titleSection} ${styles.container}`}
        style={{
          fontWeight: "bold",
          color: "black",
          marginLeft: "10%",
          marginRight: "10%",
        }}
      >
        Featured Applications
      </Typography>
      <Typography
        variant="body1"
        className={`roboto-condensed ${styles.spacing} ${styles.textAlign}`}
        style={{ marginLeft: "10%", marginRight: "10%" }}
      >
        Browse some featured applications below that have been accepted into top
        institutions.
      </Typography>
      <div className={styles.outer}>
        <div className={styles.previewSection}>
          <Box flexDirection="row" display="flex">
            {profileCards}
          </Box>
        </div>
      </div>
      {!props.shouldExcludeButton && (
        <div className={styles.buttonWrap}>
          <PrimaryButton link="/search" label="preview_section.browse_more">
            <Typography variant="h5">Browse More</Typography>
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}

export default PreviewSection;
