import React from "react";
import theme from "../../themes/Theme";
import { Typography } from "@material-ui/core";

export default function FaqPage() {
  const faqs = faqList.map((faq, index) => {
    return (
      <div
        key={index}
        style={{
          padding: "30px 0",
          textAlign: "left",
          borderBottom: `1px solid ${theme.palette.secondary.light}`,
        }}
      >
        <FaqBlock question={faq.question} answer={faq.answer} />
      </div>
    );
  });

  return (
    <div style={{ margin: "6%" }}>
      <Typography
        variant="h3"
        style={{ fontWeight: "bold", marginBottom: "4%" }}
      >
        Frequently Asked Questions
      </Typography>
      {faqs}
    </div>
  );
}

interface Props {
  question: string;
  answer: Array<string>;
}

function FaqBlock(props: Props) {
  const answers = props.answer.map((oneAnswer, index) => {
    return (
      <Typography
        key={index}
        variant="body2"
        style={{ fontSize: 16, fontFamily: "SuperaGothic", paddingBottom: 15 }}
      >
        {oneAnswer}
      </Typography>
    );
  });
  return (
    <div>
      <div
        style={{
          borderLeft: `4px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.secondary.light,
          display: "flex",
        }}
      >
        <Typography
          variant="h3"
          style={{
            color: theme.palette.primary.main,
            fontFamily: "SuperaGothic",
            padding: 15,
          }}
        >
          Q.
        </Typography>
        <Typography
          variant="body2"
          style={{
            padding: 15,
            fontWeight: "bold",
            fontFamily: "SuperaGothic",
            margin: "auto 0",
          }}
        >
          {props.question}
        </Typography>
      </div>
      <div
        style={{
          borderLeft: `4px solid ${theme.palette.secondary.main}`,
          display: "flex",
        }}
      >
        <Typography
          variant="h3"
          style={{
            color: theme.palette.secondary.main,
            fontFamily: "SuperaGothic",
            padding: 15,
          }}
        >
          A.
        </Typography>
        <div
          style={{
            paddingTop: 15,
            fontFamily: "SuperaGothic",
            margin: "auto 0",
          }}
        >
          {answers}
        </div>
      </div>
    </div>
  );
}

const faqList = [
  {
    question: "What kinds of applications does CollegeSharing accept?",
    answer: [
      "CollegeSharing takes accepted applications to any undergraduate program at the top 100 national universities or the top 50 liberal arts colleges. The application has to be admitted after Jan 2005.",
    ],
  },
  {
    question: "What formats of application does CollegeSharing accept?",
    answer: [
      "Right before you submit your application on the Common App or the UC Application system, you will have the opportunity to download your application as a PDF file. To verify the authenticity of your application, CollegeSharing ONLY accepts these PDF files as they are OR the screenshots of these PDF files. If you don’t feel comfortable uploading the entire PDF, you may choose to screenshot part of the downloaded PDF and submit the screenshots to CollegeSharing.",
    ],
  },
  {
    question:
      "What steps should I take to upload an application to CollegeSharing?",
    answer: [
      "In general, there are three steps to upload an application:",
      "First, find the PDF of your college application downloaded from the Common App or the UC Application system. Depending on how you stored them, they might be located in your laptop or online cloud storage. If you don’t want to upload the entire application, you might choose to take screenshots of the PDF file.",
      "Second, proceed to https://www.collegesharing.com/upload to fill out the basic information about your application. After you upload the PDF or screenshots, you will be able to black off any sensitive information in your application.",
      "Thirdly, proceed to https://www.collegesharing.com/upload_status and add supplements to the schools you have been admitted into. You will also be asked to upload proof files to verify your acceptance status to that college: this can be your admission letter, proof of enrollment, etc..",
      "Finally, we will review your submission in 2 business days and email you back to make a cash offer. If you are satisfied with the amount of reward we have offered, you can commit to make the contribution and accept the reward right away! Rest assured: We won't put up your application without your confirmation.",
    ],
  },
  {
    question: "What if I cannot find my application in the PDF format?",
    answer: [
      "Unfortunately, currently we only accept downloaded PDFs from the Common App or the UC Application System (and the screenshots of these PDFs). We impose this requirement to ensure the authenticity of the applications being submitted to us as well as to present the applications in their most original format.",
    ],
  },
  {
    question: "How much does CollegeSharing pay for each application?",
    answer: [
      "CollegeSharing pays $50-$150 for each application. We will evaluate the application you submit based two factors: the acceptance rate of the specific colleges and the amount of information you disclose in your application. The more information you disclose (SATs, GPAs, activities, essays, etc,), the more CollegeSharing will pay for your application.",
    ],
  },
  {
    question:
      "Where can I check the status of previously submitted supplements?",
    answer: [
      "You can check the status of previously submitted supplements on https://www.collegesharing.com/upload_status. There are a few possible statuses:",
      '"Uploaded": your application has been uploaded BUT hasn’t been submitted for review yet.',
      '"Submitted": your application has been submitted and we will review it within 2 business days.',
      '"Approved": your application has been reviewed and approved by CollegeSharing, and we have sent an email offering the cash reward.',
      '"Revision needed": your application has been reviewed by CollegeSharing but we need more information from you; we have sent an email providing details about our request.',
      '"Declined": you have declined the cash offered by CollegeSharing and the submitted materials have been deleted from our database.',
      '"Active": you have accepted the offer made by CollegeSharing and the application is now active on our website',
    ],
  },
  {
    question:
      "Can I add more supplements to other schools after the initial submission?",
    answer: [
      "Yes. CollegeSharing will take as many supplements as long they are qualified. After you have submitted your initial package, you can always proceed to https://www.collegesharing.com/upload_status to upload more supplements to other schools. Again, we will review your submission in 2 business days and email you back to make a cash offer.",
    ],
  },
  {
    question: "How can I accept payment from CollegeSharing?",
    answer: [
      "CollegeSharing can make payment to Paypal, Venmo, your bank account or your debit card. Once you have committed to contribute your application to CollegeSharing, you can add your preferred payout methods by going to Account → Payout. Typically, you will be able to receive payment from CollegeSharing within 48 hours.",
    ],
  },
  {
    question: "Will my personal information be leaked?",
    answer: [
      'At CollegeSharing, we try our best to protect our users’ personal information. Any information you have "blacked-offed" when you upload the application will be completely hidden and won’t be accessible by our subscribers. However, CollegeSharing doesn’t make efforts to hide any more information on your behalf; therefore, before submitting your application, please review your documents carefully and make sure all the sensitive information has been hidden properly.',
    ],
  },
  {
    question:
      "How long does it take for CollegeSharing to review my submission?",
    answer: [
      "On average, we will get back to you within 2 business days. We might make an offer right away, or we might reach out for more information if we find it difficult to verify your acceptance status with the documents you have submitted.",
    ],
  },
  {
    question: "Is it possible for CollegeSharing to reject my submission?",
    answer: [
      "Typically CollegeSharing doesn’t reject submissions. However, if we think the documents you have submitted are not enough to prove your acceptance status, we might reach out to you asking for more information.",
    ],
  },
  {
    question: "Do you take applications to Master’s programs?",
    answer: [
      "Currently we only take accepted applications to the undergrad program. However, we have plans to expand to Master’s programs in the near future. Please keep a close eye on our website!",
    ],
  },
  {
    question: "Can I upload someone else’s college application?",
    answer: [
      "No. It’s strongly forbidden to upload applications that don’t belong to you, and we require every individual to only upload applications authored by himself. Owners of the applications have the full right to seek legal measures in such unfortunate occurrences. ",
    ],
  },
  {
    question:
      "Will I be able to delete or modify my application once it has been uploaded?",
    answer: [
      "You won’t be able to delete your application once you have contributed to us. In general, we don’t provide an easy way to modify your applications either. However, if you discovered any sensitive information you have forgotten to hide in the original upload, you can always reach out to support@collegesharing.com to request a small change and we will try our best to accommodate such requests. However, we reserve the right to deny such modification requests.",
    ],
  },
  {
    question:
      "Can I sell my essays to other websites after I have contributed them to CollegeSharing?",
    answer: [
      "No. Essays that we agree to purchase become the sole property of CollegeSharing, and may not be resold or otherwise used commercially without CollegeSharing’s permission. CollegeSharing considers essays NOT purchased by CollegeSharing to be and remain the sole property of the person who submits the essays, and such essays will not be used by CollegeSharing in any way, shape or form. ",
    ],
  },
  {
    question: "How do I contact CollegeSharing for questions?",
    answer: [
      "For any questions, you can contact CollegeSharing by emailing us at support@collegesharing.com.",
    ],
  },
];
