import React, { useState } from "react";
import { useStore } from "react-redux";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PrimaryButton from "../general/PrimaryButton";
import Menu from "../general/Menu";
import { User } from "../../types/types";
import { Typography } from "@material-ui/core";
import MenuDrawer from "./Drawer";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FullLogo from "../../resources/fullLogo.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadButtonWrap: {
      textDecoration: "none",
      "& > *": {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    uploadButton: {
      color: theme.palette.primary.main,
      fontSize: "20px",
      //fontSize: theme.typography.h5.fontSize,
      fontFamily: "SuperaGothic",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "white",
        color: "#0A5961",
      },
    },
    logoBlock: {
      marginLeft: "20px",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      [theme.breakpoints.up("sm")]: {
        display: "inline-block",
      },
      //width: '240px',
      //minWidth: '240px',
    },
    antiLogoBlock: {
      marginLeft: "20px",
      [theme.breakpoints.down("xs")]: {
        display: "inline-block",
      },
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    nav: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "65px",
      background: "white",
      borderBottom: "1px solid #e5e5e5",
      position: "fixed",
      width: "100%",
      left: "0",
      top: "0",
      borderTop: "0",
      zIndex: 1300,
    },
    navBarRight: {
      justifyContent: "flex-end",
      marginLeft: 30,
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    menuButton: {
      marginLeft: "10px",
      marginRight: "10px",
      [theme.breakpoints.down("md")]: {
        display: "inline-block",
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    backdrop: {
      zIndex: 1500,
      color: "#fff",
    },
  })
);

function Nav() {
  const navStyle = {
    color: "black",
  };
  const paddingStyle = {
    marginRight: "30px",
  };

  const classes = useStyles();
  const store = useStore();
  const [user, setUser] = useState<User | null>(store.getState().user);
  //const [user, setUser] = useState<boolean | null>(store.getState().user);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

  store.subscribe(() => {
    //setLoggedIn(store.getState().loggedIn)
    setUser(store.getState().user);
  });

  /*useEffect(() => {
      /*if (!loggedIn) {
        setUser(undefined);
        return;
      }
      fetch('http://localhost:3001/api/v1/auto_login', {
        method: 'GET',
        credentials: 'include',
      })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        if (data.user) {
          setUser(data.user as User);
          store.dispatch({type: "UPDATE_IS_LOGGINED_IN_ACCTION", status: true})
        } else {
          setUser(null);
          store.dispatch({type: "UPDATE_IS_LOGGINED_IN_ACCTION", status: false})
        }
      })
      .catch(error => {
        setUser(null);
        store.dispatch({type: "UPDATE_IS_LOGGINED_IN_ACCTION", status: false})
      })
    },[loggedIn, store])*/
  return (
    <div className={classes.nav}>
      <Backdrop open={openBackdrop} classes={{ root: classes.backdrop }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="nav-bar-left">
        <div className={`${classes.logoBlock} logo-text`}>
          <Link to="/">
            <img src={FullLogo} style={{ height: "40px" }} alt=""></img>
          </Link>
        </div>
        <SearchBar />
      </div>
      <div className={classes.menuButton}>
        <MenuDrawer setOpenBackdrop={setOpenBackdrop} />
      </div>
      <div className={classes.navBarRight}>
        <Link
          style={navStyle}
          to="/host_landing"
          className={classes.uploadButtonWrap}
        >
          <Button className={classes.uploadButton}>Upload</Button>
        </Link>
        {!user && (
          <Link
            style={navStyle}
            to="/sign_up"
            className={classes.uploadButtonWrap}
          >
            <Button className={classes.uploadButton}>Sign Up</Button>
          </Link>
        )}
        <Link
          style={navStyle}
          to="/search"
          className={classes.uploadButtonWrap}
        >
          <Button className={classes.uploadButton}>Browse</Button>
        </Link>
        {user && !user.subscribed && (
          <Link
            style={navStyle}
            to="/payment_page"
            className={classes.uploadButtonWrap}
          >
            <Button className={classes.uploadButton}>Subscribe</Button>
          </Link>
        )}
        {!user && (
          <PrimaryButton style={paddingStyle} link="/log_in" label="nav.log_in">
            <Typography
              style={{ fontFamily: "SuperaGothic", fontSize: "20px" }}
              noWrap={true}
            >
              Log In
            </Typography>
          </PrimaryButton>
        )}
        {user && (
          <div style={paddingStyle}>
            <Menu user={user} setOpenBackdrop={setOpenBackdrop} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Nav;
