import { AdmitProfile } from "../../../types/admitProfileTypes";
import axios from "axios";

export function fetchProfile(
  onFinished: (value: AdmitProfile) => void,
  onError: (error: any) => void,
  id: string
) {
  axios(`api/v1/get_entire_profile/${id}`, {
    method: "GET",
    withCredentials: true,
  })
    .then((response) => response.data)
    .then((data) => {
      onFinished(data.data);
    })
    .catch((error) => {
      onError(error);
    });
}
