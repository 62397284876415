import axios from "axios";
export function save(caseId: number, setSaved: (value: boolean) => void) {
  axios("api/v1/save_records", {
    method: "POST",
    withCredentials: true,
    data: {
      case_id: caseId,
    },
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.error) {
      }
      setSaved(true);
    })
    .catch((error) => {
      // TODO: log error
    });
}

export function unSave(caseId: number, setSaved: (value: boolean) => void) {
  axios(`api/v1/save_records/${caseId}`, {
    method: "DELETE",
    withCredentials: true,
    data: {
      case_id: caseId,
    },
  })
    .then((response) => response.data)
    .then((data) => {
      setSaved(false);
    })
    .catch((error) => {
      // TODO: log error
    });
}

export function saveProfile(
  profileId: number,
  setSaved: (value: boolean) => void
) {
  axios("api/v1/profile_save_records", {
    method: "POST",
    withCredentials: true,
    data: {
      general_application_profile_id: profileId,
    },
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.error) {
      }
      setSaved(true);
    })
    .catch((error) => {
      // TODO: log error
    });
}

export function unSaveProfile(
  profileId: number,
  setSaved: (value: boolean) => void
) {
  axios(`api/v1/profile_save_records/${profileId}`, {
    method: "DELETE",
    withCredentials: true,
    data: {
      general_application_profile_id: profileId,
    },
  })
    .then((response) => response.data)
    .then((data) => {
      setSaved(false);
    })
    .catch((error) => {
      // TODO: log error
    });
}
