import { SearchPageStatus } from "./types";
import { User } from "./types";

export type StoreState = {
  //loggedIn: boolean | null,
  user: User | null;
  //searchPageStatus: SearchPageStatus,
  //searchPageFilter: SearchPageFilter,
};

export const defaultSearchPageStatus: SearchPageStatus = {
  loading: true,
  schools: [],
};

export const initialStoreState: StoreState = {
  //loggedIn: null,
  user: null,
  /*searchPageStatus: defaultSearchPageStatus,
    searchPageFilter: {
        pageNumber: 1,
        categoryStatuses: [],
        orderBy: OrderByField.MOST_VIEWED,
    },*/
};

export default initialStoreState;
