import React, { useEffect, useState } from "react";
import PageTitle from "../general/PageTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Spacing from "../general/Spacing";
import RadioOptions from "../general/RadioOptions";
import Button from "@material-ui/core/Button";
import SnackBar from "../general/SnackBar";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputTextField from "./profile/InputTextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light,
      //marginBottom: '5%',
    },
    question: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      padding: "0px 5px",
    },
    questionBlock: {
      textAlign: "left",
      [theme.breakpoints.up("sm")]: {
        padding: "20px 40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px 30px",
      },
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
  })
);

export interface CaseDecision {
  accepted: boolean;
  reason?: string;
  price?: number;
}

interface Props {
  disableBack: boolean;
  handleBack: () => void;
  handleNext: (CaseDecision: CaseDecision) => void;
  //handleCaseDecision: (CaseDecision: CaseDecision) => void,
  nextButtonText: string;
  shouldProceedToPrevious?: boolean;
  setShouldProceedToPrevious?: (value: boolean) => void;
}

function EnterInformationPage(props: Props) {
  const classes = useStyles();

  const [price, setPrice] = useState<string | undefined>(undefined);
  const [reason, setReason] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [accepted, setAccepted] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNext = () => {
    if (!accepted) {
      if (!reason) {
        setMessage("A reason must be specified for a rejected case");
        return;
      } else if (reason.length < 30) {
        setMessage("The reason you gave is too short");
        return;
      }
    } else {
      if (!price) {
        setMessage("A price must be provided for an accepted case");
        return;
      } else {
        const amount = Number(price);
        if (!amount) {
          setMessage("You must provide a valid number as the price");
          return;
        }
      }
    }
    props.handleNext({
      accepted,
      reason,
      price: price ? Number(price) : undefined,
    });
    //props.handleNext();
  };

  useEffect(() => {
    if (
      props.shouldProceedToPrevious === true &&
      props.setShouldProceedToPrevious
    ) {
      props.handleBack();
      props.setShouldProceedToPrevious(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToPrevious]);

  const callBack = () => {
    setMessage(undefined);
  };

  const handleAccepted = (value: string) => {
    setAccepted(value === "Yes" ? true : false);
    setPrice(undefined);
    setReason(undefined);
  };

  const handleTextfieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReason(event.target.value);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value);
  };

  const radioOptions = [
    {
      key: "yes",
      value: "Yes",
    },
    {
      key: "no",
      value: "No",
    },
  ];

  const radioQuestion = (
    <RadioOptions
      options={radioOptions}
      handleSelect={handleAccepted}
      value={accepted ? "Yes" : accepted === false ? "No" : null}
    />
  );

  return (
    <div className={classes.root}>
      <PageTitle title="Submit Your Review" />
      <div className={classes.questionBlock}>
        {GetQuestionTitle("Do you want to accept this case?")}
        <Spacing spacing={8} />
        {radioQuestion}
      </div>
      {!accepted && (
        <div className={classes.questionBlock}>
          {GetQuestionTitle(
            "Please specify a reason why you want to reject the case."
          )}
          <Spacing spacing={8} />
          <InputTextField
            value={reason}
            onChange={handleTextfieldChange}
            multiline
            minRows={4}
            style={{ width: "70%", minWidth: "300px" }}
          />
        </div>
      )}
      {accepted && (
        <div className={classes.questionBlock}>
          {GetQuestionTitle("Please make an offer to the user.")}
          <Spacing spacing={8} />
          <InputTextField
            value={price ? String(price) : ""}
            onChange={handlePriceChange}
            style={{ maxWidth: "320px", minWidth: "240px", width: "30%" }}
            icon={<InputAdornment position="start">$</InputAdornment>}
          />
        </div>
      )}
      <SnackBar message={message} callBack={callBack} />
      <div style={{ padding: "20px 0px" }}>
        <Button
          disabled={props.disableBack}
          onClick={props.handleBack}
          className={classes.backButton}
        >
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          {props.nextButtonText}
        </Button>
      </div>
    </div>
  );
}

function GetQuestionTitle(question: string) {
  return (
    <Typography
      variant="body1"
      style={{
        fontFamily: "SuperaGothic",
        fontWeight: "bold",
        padding: "0px 5px",
      }}
    >
      {question}
    </Typography>
  );
}

export default EnterInformationPage;
