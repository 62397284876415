import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import EnterInfoPage, { CaseInfo } from "../pages/EnterInformationPage";
import SelecPagesPage, { Page } from "../pages/SelectPagesPage";
import UploadPdfPage from "../pages/UploadPdfPage";
import BlackOffPage from "../pages/BlackOffPage";
import UploadProofPage from "../pages/UploadProofPage";
import UploadSuccessPage from "../pages/SaveSuccessPage";
import UploadingCasePage from "../pages/UploadingCasePage";
import { logEvent, logModalView } from "../../helpers/GoogleAnalyticsLogger";
import axios, { AxiosRequestConfig } from "axios";
import Banner from "./Banner";
import * as Sentry from "@sentry/browser";
import { useStore } from "react-redux";
import { User } from "../../types/types";
import ScrollToTop from "../../helpers/ScrollToTop";
import { useHistory } from "react-router-dom";
import { UploadType } from "../pages/UploadPdfPage";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    labelRoot: {
      padding: "10px 0px",
      //paddingTop: "10px",
      //paddingBottom: '10px'
    },
    container: {
      margin: "40px 20px",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stepper: {
      position: "sticky",
      top: "65px",
      //borderTop: "1px solid #e5e5e5",
      borderBottom: "1px solid #e5e5e5",
      zIndex: 40,
      //paddingTop: "10px",
      //paddingBottom: "10px",
      paddingLeft: 0,
      paddingRight: 0,
    },
    stepLabel: {
      fontFamily: "SuperaGothic",
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 16,
      },
    },
    labelContainer: {
      "& $alternativeLabel": {
        marginTop: 4,
      },
    },
    arrow: {
      color: theme.palette.primary.main,
      fontSize: 32,
      "&:hover": {
        color: "#0A5961",
      },
    },
    navigationButton: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    alternativeLabel: {},
  })
);

function getStepContent(
  stepIndex: number,
  nodes: Array<() => React.ReactNode>
): React.ReactNode {
  if (stepIndex < nodes.length) {
    return nodes[stepIndex]();
  }
  return <div></div>;
}

interface Props {
  files: Array<FileWithPages>;
  proofFiles: Array<File>;
  title: string | null;
  caseInfo?: CaseInfo;
  caseId?: string;
}

export interface FileWithPages {
  file: File;
  pages: Array<Page>;
}

function getFinishPage(isLoading: boolean): React.ReactNode {
  if (isLoading) {
    return (
      <UploadingCasePage
        title={"Your supplements are being saved"}
        description={
          "Your supplements are being saved. It will take a few seconds to a minute. Please don’t close the page during this time."
        }
      />
    );
  }
  return (
    <UploadSuccessPage
      title={"Supplements Successfully Saved"}
      subtitle={"Your supplements have been successfully saved!"}
    />
  );
}

interface BannerStatus {
  subtitle: string;
  title: string;
}

export default function HorizontalLabelPositionBelowStepper(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [bannerStatus, setBannerStatus] = useState<BannerStatus | undefined>(
    undefined
  );
  const [shouldProceedToNext, setShouldProceedToNext] =
    useState<boolean>(false);
  const [shouldProceedToPrevious, setShouldProceedToPrevious] =
    useState<boolean>(false);
  const handleNext = () => {
    logEvent({
      category: "Stepper",
      action: "Click",
      value: activeStep,
      label: "next_button",
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setBannerStatus(undefined);
  };

  const handleBack = () => {
    logEvent({
      category: "Stepper",
      action: "Click",
      value: activeStep,
      label: "back_button",
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setBannerStatus(undefined);
  };

  const [files, setFiles] = useState<Array<FileWithPages>>(props.files);
  const [proofFiles, setProofFiles] = useState<Array<File>>(props.proofFiles);

  const [caseInfo, setCaseInfo] = useState<CaseInfo | undefined>(
    props.caseInfo
  );
  const [loading, setLoading] = useState<boolean>(false);

  const store = useStore();
  const user: User = store.getState().user;

  const onEnterInfo = (caseInfo: CaseInfo) => {
    setCaseInfo(caseInfo);
  };

  const onSaveRectsAndBack = (selectedFiles: Array<FileWithPages>) => {
    setFiles(selectedFiles);
    handleBack();
    //setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmitFiles = (uploadedFiles: Array<File>) => {
    var newFiles: Array<FileWithPages> = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      var existingFile = files.find((file) => file.file === uploadedFiles[i]);
      if (existingFile) {
        newFiles.push(existingFile);
      } else {
        newFiles.push({ file: uploadedFiles[i], pages: [] });
      }
    }
    setFiles(newFiles);
    handleNext();
  };

  const postDraftCaseToBackend = (proofFiles: Array<File>) => {
    logEvent({ category: "Button", action: "Click", label: "submit_button" });
    setLoading(true);
    handleNext();
    setProofFiles(proofFiles);
    //setTitle(title);
    let formData = new FormData();
    if (caseInfo) {
      formData.append("year_id", String(caseInfo?.yearId));
      formData.append(
        "application_round_id",
        String(caseInfo.applicationRoundId)
      );
      formData.append("scholarship_id", String(caseInfo.scholarshipId));
      if (caseInfo?.schoolId && caseInfo.schoolId !== "-1") {
        formData.append("school_id", String(caseInfo?.schoolId));
      }
      if (caseInfo?.majorId && caseInfo.majorId !== "-1") {
        formData.append("major_id", String(caseInfo?.majorId));
      }
      if (caseInfo?.newlyAddedSchoolName) {
        formData.append(
          "newly_added_school_name",
          String(caseInfo?.newlyAddedSchoolName)
        );
      }
      formData.append("legacy", String(caseInfo?.legacy || false));
      if (caseInfo?.newlyAddedMajorName) {
        formData.append(
          "newly_added_major_name",
          String(caseInfo?.newlyAddedMajorName)
        );
      }
    }

    for (let i = 0; i < proofFiles.length; i++) {
      formData.append(`proof_files[${i}]`, proofFiles[i]);
    }
    formData.append("status", "pending_review");

    if (caseInfo?.essays) {
      for (let i = 0; i < caseInfo.essays.length; i++) {
        let essayTopicId = caseInfo.essays[i].essayTopicId;
        let title = caseInfo.essays[i].title;
        let value = caseInfo.essays[i].value;
        if (essayTopicId) {
          formData.append(`essays[${i}][essay_topic_id]`, essayTopicId);
        }
        if (title) {
          formData.append(`essays[${i}][title]`, title);
        }
        if (value) {
          formData.append(`essays[${i}][value]`, value);
        }
      }
    }

    renderPages(files)
      .then((newFiles) => {
        for (let i = 0; i < newFiles.length; i++) {
          formData.append(`files[${i}][file]`, newFiles[i].file);
          for (let j = 0; j < newFiles[i].pages.length; j++) {
            formData.append(
              `files[${i}][pages][${j}]`,
              JSON.stringify(newFiles[i].pages[j])
            );
          }
        }

        var path = "api/v1/draft_cases";
        var method: AxiosRequestConfig["method"] = "POST";
        if (props.caseId) {
          path = `api/v1/draft_cases/${props.caseId}`;
          method = "PUT";
        }

        axios(path, {
          method: method,
          withCredentials: true,
          data: formData,
          timeout: 0,
        })
          .then((response) => response.data)
          .then((data) => {
            setLoading(false);
            if (data.error) {
              setBannerStatus({
                subtitle:
                  "We had technical difficulties to upload the case you submitted.",
                title: "Failed to upload your case",
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              error = error.response.data;
              const subtitle =
                (error.error && error.error.message) ||
                "We had technical difficulties to upload the case you submitted.";
              setBannerStatus({
                subtitle: subtitle,
                title: "Failed to upload your case",
              });
            } else {
              setBannerStatus({
                subtitle:
                  "We had technical difficulties to upload the case you submitted.",
                title: "Failed to upload your case",
              });
            }
            // TODO: add error logger
            Sentry.captureException(error);
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            setLoading(false);
          });
      })
      .catch((error) => {
        setBannerStatus({
          subtitle:
            "We had technical difficulties to upload the case you submitted.",
          title: "Failed to upload your case",
        });
        // TODO: add error logger
        Sentry.captureException(error);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setLoading(false);
      });
  };

  const onSelectPages = (selectedFiles: Array<FileWithPages>) => {
    //const actualPages = selectedPages.filter(page => page.selected);
    setFiles(selectedFiles);
    handleNext();
  };

  const enterInfoPage = () => {
    logModalView("enter_info_modal");
    return (
      <EnterInfoPage
        disableBack={true}
        handleBack={handleBack}
        handleNext={handleNext}
        nextButtonText={"Next"}
        handleEnterInfo={onEnterInfo}
        caseInfo={caseInfo}
        shouldProceedToNext={shouldProceedToNext}
        setShouldProceedToNext={setShouldProceedToNext}
      />
    );
  };

  const uploadPdfPage = () => {
    logModalView("upload_pdf_modal");
    return (
      <UploadPdfPage
        uploadType={UploadType.CASE}
        disableBack={false}
        handleBack={handleBack}
        handleNext={onSubmitFiles}
        nextButtonText={"Next"}
        files={files.map((file) => {
          return file.file;
        })}
        shouldProceedToNext={shouldProceedToNext}
        setShouldProceedToNext={setShouldProceedToNext}
        shouldProceedToPrevious={shouldProceedToPrevious}
        setShouldProceedToPrevious={setShouldProceedToPrevious}
      />
    );
  };

  const pageSelector = () => {
    logModalView("select_page_modal");
    return (
      <SelecPagesPage
        disableBack={false}
        handleBack={handleBack}
        handleNext={onSelectPages}
        nextButtonText={"Next"}
        files={files}
        shouldProceedToNext={shouldProceedToNext}
        setShouldProceedToNext={setShouldProceedToNext}
        shouldProceedToPrevious={shouldProceedToPrevious}
        setShouldProceedToPrevious={setShouldProceedToPrevious}
      />
    );
  };

  const blackOffPage = () => {
    logModalView("black_off_modal");
    return (
      files.length !== 0 && (
        <BlackOffPage
          disableBack={false}
          handleBack={onSaveRectsAndBack}
          handleNext={onSelectPages}
          nextButtonText={"Next"}
          files={files}
          shouldProceedToNext={shouldProceedToNext}
          setShouldProceedToNext={setShouldProceedToNext}
          shouldProceedToPrevious={shouldProceedToPrevious}
          setShouldProceedToPrevious={setShouldProceedToPrevious}
        />
      )
    );
  };

  var pageNumber = 0;
  var blackoutNumber = 0;
  files.forEach((tempFile) => {
    pageNumber += tempFile.pages.length;
    tempFile.pages.forEach((tempPage) => {
      blackoutNumber += tempPage.rects.length;
    });
  });
  const uploadProofPage = () => {
    logModalView("upload_proof_modal");

    return (
      <UploadProofPage
        disableBack={false}
        handleBack={handleBack}
        handleNext={postDraftCaseToBackend}
        //submit={postDraftCaseToBackend}
        nextButtonText={"Finish"}
        files={proofFiles}
        pageNumber={pageNumber}
        blackoutNumber={blackoutNumber}
        schoolId={caseInfo?.schoolId || null}
        yearId={caseInfo?.yearId || null}
        showUserAgreement={props.caseId === undefined || props.caseId === null}
        requireProofs={
          !Boolean(
            user.schoolId &&
              caseInfo?.schoolId &&
              String(user.schoolId) === String(caseInfo?.schoolId)
          )
        }
        shouldProceedToPrevious={shouldProceedToPrevious}
        setShouldProceedToPrevious={setShouldProceedToPrevious}
      />
    );
  };
  const steps = ["Start", "Upload", "Select", "Blackoff", "Finish"];
  const nodes: Array<() => React.ReactNode> = [
    enterInfoPage,
    uploadPdfPage,
    pageSelector,
    blackOffPage,
    uploadProofPage,
  ];

  if (activeStep === steps.length && !loading) {
    history.push({ pathname: "/upload_status", search: "?showBanner=true" });
    return null;
  }

  return (
    <div className={`${classes.root} page-container`}>
      <div
        className={classes.stepper}
        style={{
          display: "flex",
          backgroundColor: "white",
          overflow: "scroll",
        }}
      >
        <Button
          className={classes.navigationButton}
          style={{
            flexGrow: 0,
            width: 40,
            minWidth: 40,
            margin: "auto 0px auto 20px",
          }}
          disabled={activeStep === 0}
          onClick={() => setShouldProceedToPrevious(true)}
        >
          {activeStep !== 0 && (
            <ChevronLeftIcon
              className={classes.arrow}
              style={{ margin: "auto" }}
            />
          )}
        </Button>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          classes={{ root: classes.labelRoot }}
          style={{ flexGrow: 1 }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                classes={{
                  alternativeLabel: classes.alternativeLabel,
                  labelContainer: classes.labelContainer,
                }}
              >
                <Typography className={classes.stepLabel} variant="body1">
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Button
          className={classes.navigationButton}
          style={{
            flexGrow: 0,
            width: 40,
            minWidth: 40,
            margin: "auto 20px auto 0px",
          }}
          disabled={activeStep >= steps.length - 1}
          onClick={() => setShouldProceedToNext(true)}
        >
          {activeStep < steps.length - 1 && (
            <ChevronRightIcon
              className={classes.arrow}
              style={{ margin: "auto" }}
            />
          )}
        </Button>
      </div>
      <div
        className="page-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {activeStep === steps.length ? (
          <div
            className="page-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <ScrollToTop />
            {getFinishPage(loading)}
          </div>
        ) : (
          <div
            className="page-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div>
              <ScrollToTop />
              {bannerStatus && (
                <div className={classes.container}>
                  <Banner
                    title={bannerStatus.title}
                    subtitle={bannerStatus.subtitle}
                    severity={"failed"}
                  />
                </div>
              )}
            </div>
            {getStepContent(activeStep, nodes)}
          </div>
        )}
      </div>
    </div>
  );
}

function renderPages(
  files: Array<FileWithPages>
): Promise<Array<FileWithPages>> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const promises: Array<Array<Promise<Page>>> = [];
      for (let file of files) {
        promises.push(loadRenderableImage(file));
      }
      let newFiles: Array<FileWithPages> = Array.from(files);

      promises.forEach((promise, index) => {
        Promise.all(promise)
          .then((newPages) => {
            newFiles[index].pages = newPages;
            if (index === files.length - 1) {
              resolve(newFiles);
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
      /*Promise.all(promises.map(promise => Promise.all(promise))).then(newPages => {
          newPages.forEach((value, index) => {
              files[index].pages = value
              //newFiles.push({file: files[index].file, pages: value})
          })
          //setFiles(newFiles)
          resolve(newFiles)
      })*/
    }, 500);
  });
}

function loadRenderableImage(file: FileWithPages): Array<Promise<Page>> {
  const promises: Array<Promise<Page>> = [];
  for (let page of file.pages) {
    promises.push(renderEmptyPageWithRects(page));
  }
  return promises;
}

function renderEmptyPageWithRects(page: Page): Promise<Page> {
  return new Promise((resolve, reject) => {
    //setTimeout(() => {
    if (page.purePage) {
      resolve({
        selected: page.selected,
        rects: page.rects,
        types: page.types,
        purePage: undefined,
        renderablePage: undefined,
      });
    }
    //},300)
  });
}
