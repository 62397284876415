import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Honor } from "../../types/uploadTypes";
import { FilterLineItem } from "../../types/serverSideTypes";
import RoundedButton from "./PrimaryButton";
import Typography from "@material-ui/core/Typography";
import StandardAutoComplete, { Option } from "../general/StandardAutoComplete";
import TextField from "@material-ui/core/TextField";

interface Props {
  honors: Array<Honor>;
  setHonors: (tests: Array<Honor>) => void;
  honorLevels: Array<FilterLineItem>;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    body: {
      fontSize: 16,
      fontFamily: "SuperaGothic",
      fontWeight: "normal",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-child(2n+1)": {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.action.hover,
      },

      "& > *": {
        borderBottom: "unset",
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  resize: {
    fontSize: 16,
    lineHeight: "19px",
  },
});

interface RowProps {
  honor: Honor;
  delete: () => void;
  setHonor: (activity: Honor) => void;
  options: Array<Option>;
}

function Row(props: RowProps) {
  const styles = useStyles();
  const handleSubjectChange = (key: string | null) => {
    const newValue = Object.assign({}, props.honor, {
      honorLevelId: key || undefined,
    });
    props.setHonor(newValue);
    //setName(event.target.value);
  };
  const handleScoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Object.assign({}, props.honor, {
      description: event.target.value,
    });
    props.setHonor(newValue);
    //setYearsInvolved(Number(event.target.value));
  };

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell style={{ verticalAlign: "bottom", paddingBottom: 6 }}>
          <StandardAutoComplete
            options={props.options}
            handleSelect={handleSubjectChange}
            value={props.honor.honorLevelId || null}
            placeholder={"School/Local"}
          />
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ verticalAlign: "bottom", paddingBottom: 6 }}
        >
          <TextField
            multiline
            rowsMax={3}
            InputProps={{ classes: { input: styles.resize } }}
            style={{ width: "100%" }}
            placeholder="Honor Societies"
            value={props.honor.description || ""}
            onChange={handleScoreChange}
          />
        </StyledTableCell>
        <StyledTableCell>
          <RoundedButton
            variant="outlined"
            style={{ borderRadius: "30px", color: "white", border: "none" }}
            buttonStyle="secondary"
            onClick={props.delete}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: "16px",
                fontFamily: "SuperaGothic",
                lineHeight: "normal",
              }}
            >
              Delete
            </Typography>
          </RoundedButton>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function CustomizedTables(props: Props) {
  const classes = useStyles();

  const updateTests = (index: number, activity: Honor) => {
    const newArray = props.honors.map((a) => Object.assign({}, a));
    newArray[index] = activity;
    props.setHonors(newArray);
  };

  const deleteTest = (index: number) => {
    const newArray = props.honors.map((a) => Object.assign({}, a));
    newArray.splice(index, 1);
    props.setHonors(newArray);
  };

  const addTest = () => {
    const newArray = props.honors.map((a) => Object.assign({}, a));
    newArray.push({ honorLevelId: undefined, description: undefined });
    props.setHonors(newArray);
  };

  const options = getFilter(props.honorLevels);
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Level</StyledTableCell>
            <StyledTableCell size="small">Description</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.honors.map((row, index) => (
            <Row
              options={options}
              honor={row}
              delete={() => deleteTest(index)}
              setHonor={(newActivity: Honor) => updateTests(index, newActivity)}
              key={index}
            />
          ))}
          <StyledTableRow>
            <StyledTableCell colSpan={3}>
              <RoundedButton
                variant="outlined"
                style={{
                  borderRadius: "30px",
                  color: "white",
                  border: "none",
                  margin: "auto",
                }}
                buttonStyle="primary"
                onClick={addTest}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "16px",
                    fontFamily: "SuperaGothic",
                    lineHeight: "normal",
                  }}
                >
                  Add
                </Typography>
              </RoundedButton>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function getFilter(items: Array<FilterLineItem>): Array<Option> {
  const options = items.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });
  return options;
}
