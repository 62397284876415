import React from "react";
import { withStyles, Theme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Button, { ButtonProps } from "@material-ui/core/Button";

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#0A5961",
    },
    borderRadius: "0px 5px 5px 0px",
    height: "100%",
    alignItems: "center",
  },
}))(Button);

export default function CustomizedButtons(props: ButtonProps) {
  return (
    <ColorButton
      disableElevation
      variant="contained"
      color="primary"
      {...props}
    >
      <SearchIcon />
    </ColorButton>
  );
}
