import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Spacing from "../../general/Spacing";
import Banner from "../../general/Banner";
import { Paper } from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router";
import Alert from "./PanelAlert";
import isValidPassword, {
  passwordAlertMessage,
} from "../../../helpers/AccountInfoChecker";
import ColoredTextField from "./ColoredTextField";
import SubmitButton from "./SubmitButton";
import CardHeader from "./CardHeader";
import LoadingContent from "./LoadingContent";
import { useStore } from "react-redux";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/browser";

const queryString = require("query-string");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: "0px 15px",
    },
    paper: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    card: {
      alignItems: "center",
      minWidth: "320px",
      maxWidth: "450px",
      width: "80%",
      overflow: "hidden",
      margin: "0px auto",
    },
    header: {
      width: "100%",
      height: "120px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerText: {
      fontFamily: "Righteous",
      fontSize: "32px",
      color: "white",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
      fontSize: "18px",
    },
    root: {
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      paddingBottom: "80px",
      paddingTop: "80px",
    },
    labelRoot: {
      fontFamily: "SuperaGothic",
    },
    inputRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleText: {
      fontSize: "16px",
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      width: "120px",
      textAlign: "left",
    },
    textField: {
      width: "90%",
      maxWidth: "280px",
    },
    textFieldContainer: {
      width: "90%",
      margin: "auto",
    },
    alert: {
      fontFamily: "SuperaGothic",
      "& .MuiAlert-icon": {
        marginTop: "auto",
        marginBottom: "auto",
      },
    },
    cardContent: {
      margin: "30px",
    },
    link: {
      fontFamily: "SuperaGothic",
    },
    container: {
      padding: "40px 20px",
      paddingBottom: "5px",
      backgroundColor: theme.palette.primary.main,
      //marginTop: '40px',
    },
  })
);

interface LocationState {
  from?: Location;
}

function LogInPage() {
  const classes = useStyles();
  const store = useStore();
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [passwordConfirmation, setPasswordConfirmation] = useState<
    string | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );
  const [redirectToLogin, setRedirectToLogin] = useState<boolean | undefined>(
    undefined
  );

  const history = useHistory<LocationState>();
  const parsed = queryString.parse(history.location.search);

  const onRedirect = () => {
    const from = history.location.state;
    history.push({ pathname: "/log_in", state: from });
  };

  const onSignIn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isValidPassword(password)) {
      setAlertMessage(passwordAlertMessage);
      return;
    }
    if (password !== passwordConfirmation) {
      setAlertMessage("Passwords don't match");
      return;
    }
    setIsLoading(true);
    axios("users/passwords", {
      method: "PUT",
      withCredentials: true,
      data: {
        user: {
          password: password,
          password_confirmation: passwordConfirmation,
          reset_password_token: parsed["reset_password_token"],
        },
      },
    })
      .then((response) => {
        const data = response.data;
        if (data.error) {
          if (response.status === 205) {
            setRedirectToLogin(true);
          } else {
            setAlertMessage("Failed to reset passworkd.");
          }
          setIsLoading(false);
        } else {
          //setIsLoading(false)

          const locationState = history.location.state;
          store.dispatch({
            type: "UPDATE_IS_LOGGINED_IN_ACCTION",
            status: true,
          });
          store.dispatch({ type: "UPDATE_USER_ACCTION", user: data.data.user });
          if (locationState && locationState.from) {
            history.push(locationState.from);
          } else {
            history.push({ pathname: "/search", state: {} });
          }
          //Cookies.set("token", data.data.token, { secure: true, sameSite: 'strict'})
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        setAlertMessage("Failed to reset passworkd. Please try again");
        setIsLoading(false);
      });
  };

  const onSubmitPasswordConfirmation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirmation(event.target.value);
  };

  const onSubmitPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  var cardContent = null;

  if (redirectToLogin) {
    cardContent = (
      <div>
        <Helmet>
          <title>Sign in - CollegeSharing</title>
        </Helmet>
        <TopBanner />
        <LogInPage />
      </div>
    );
  }
  if (!isLoading) {
    cardContent = (
      <SignInCardContent
        onRedirect={onRedirect}
        setPassword={onSubmitPassword}
        setPasswordConfirmation={onSubmitPasswordConfirmation}
        onSubmit={onSignIn}
        alertMessage={alertMessage}
      />
    );
  } else {
    cardContent = <LoadingContent title="Reset Password" />;
  }

  return (
    <div className={`page-container ${classes.root}`}>
      <Helmet>
        <title>Change password - CollegeSharing</title>
      </Helmet>
      <Paper className={classes.card} elevation={8}>
        {cardContent}
      </Paper>
    </div>
  );
}

function TopBanner() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Banner
        title="Your password has been updated"
        subtitle="Please log in below to access the content."
      />
    </div>
  );
}

function SignInCardContent({
  setPassword,
  setPasswordConfirmation,
  onSubmit,
  onRedirect,
  alertMessage,
}: {
  setPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setPasswordConfirmation: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onRedirect: () => void;
  alertMessage?: string;
}) {
  return (
    <div className="page-container">
      <CardHeader title="Reset Password" />
      <div className="card-wrapper">
        {alertMessage && (
          <div>
            <Alert severity="error">{alertMessage}</Alert>
            <Spacing spacing={24} />
          </div>
        )}
        <form onSubmit={onSubmit}>
          <div className="row-container">
            <div className="row-wrapper">
              <ColoredTextField
                title="New Password"
                type="password"
                onChange={setPassword}
                placeholder="new password"
              />
            </div>
            <div className="row-wrapper">
              <ColoredTextField
                title="Confirm New Password"
                type="password"
                onChange={setPasswordConfirmation}
                placeholder="confirm new password"
              />
            </div>
          </div>
          <SubmitButton text="SUBMIT" type="submit" filled={true} />
        </form>
        <Spacing spacing={8} />
        <SubmitButton text="LOG IN" onSubmit={onRedirect} filled={false} />
        <Spacing spacing={24} />
      </div>
    </div>
  );
}

export default LogInPage;
