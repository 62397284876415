import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Spacing from "../../general/Spacing";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    bold: {
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
    media: {
      //height: 0,
      paddingTop: "60px", // 16:9
      //margin: '20px 30px',
      //marginBottom: '0px',
    },
    cardContent: {
      textAlign: "left",
      //paddingLeft: '20px',
      //paddingRight: '20px',
      padding: "0px",
      paddingTop: "10px",
    },
    normalLineHeight: {
      //lineHeight: '1.5',
      fontFamily: "SuperaGothic",
      fontSize: "16px",
    },
    lineClamp3Height: {
      lineHeight: "1.1",
    },
    image: {
      backgroundSize: "60px",
      backgroundPositionX: "left",
    },
  })
);

interface Props {
  url: string;
  title: string;
  description: string;
  imageUrl: string;
}

export default function RecipeReviewCard(props: Props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea
        component={Link}
        to={props.url}
        style={{ height: "100%", padding: "20px 30px" }}
      >
        <CardMedia
          className={classes.media}
          image={props.imageUrl}
          classes={{
            root: classes.image,
          }}
        />
        <CardContent className={classes.cardContent}>
          <Typography variant="body1" className={classes.bold}>
            {props.title}
          </Typography>
          <Spacing spacing={8} />
          <Typography variant="body1" className={classes.normalLineHeight}>
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
