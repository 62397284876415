import { CheckBoxItem, UrlFilter, SearchPageFilter } from "./types";
import { ServerCategoryStatus, OrderByField } from "./serverSideTypes";

export const UPDATE_FILTER = "UPDATE_FILTER";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const TOGGLE_CHECK_BOX = "TOGGLE_CHECK_BOX";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const REVERT_FILTER = "REVERT_FILTER";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const UPDATE_PAGE_NUMBER = "UPDATE_PAGE_NUMBER";
export const LOAD_INITIAL_FILTER = "LOAD_INITIAL_FILTER";
export const UPDATE_ORDER_BY = "UPDATE_ORDER_BY";
export const LOAD_FILTER_FROM_URL = "LOAD_FILTER_FROM_URL";

export interface UpdateFilterAction {
  type: "UPDATE_FILTER";
  name: string;
  newFilter: Array<CheckBoxItem>; //School, Category
  callBack: (searchPageFilter: SearchPageFilter) => void;
}

export interface UpdateFiltersAction {
  type: "UPDATE_FILTERS";
  updatedFilter: Map<string, Array<CheckBoxItem>>;
  callBack: (searchPageFilter: SearchPageFilter) => void;
}

export interface LoadFilterFromUrlAction {
  type: "LOAD_FILTER_FROM_URL";
  urlFilter: UrlFilter;
}

export interface ToggleCheckBoxAction {
  type: "TOGGLE_CHECK_BOX";
  id: string;
  value: boolean;
}

export interface Action {
  type: "CLEAR_FILTER";
}

export interface RevertFilterAction {
  type: "REVERT_FILTER";
  oldFilter: Array<CheckBoxItem>;
}

export interface ClearAllFiltersAction {
  type: "CLEAR_ALL_FILTERS";
  callBack: (searchPageFilter: SearchPageFilter) => void;
}

export interface UpdatePageNumberAction {
  type: "UPDATE_PAGE_NUMBER";
  pageNumber: number;
  callBack: (searchPageFilter: SearchPageFilter) => void;
}

export interface LoadInitialFilterAction {
  type: "LOAD_INITIAL_FILTER";
  initialFilter: Array<ServerCategoryStatus>;
  urlFilter: UrlFilter;
}

export interface UpdateOrderByAction {
  type: "UPDATE_ORDER_BY";
  orderBy: OrderByField;
  callBack: (searchPageFilter: SearchPageFilter) => void;
}
