import React, { useState, useEffect } from "react";
import PageTitle from "../general/PageTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Dropzone from "../upload/DragAndDropZone";
import Button from "@material-ui/core/Button";
import SnackBar from "../general/SnackBar";
import ImageIcon from "@material-ui/icons/Image";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import UserAgreementDialog from "../general/UserAgreementModal";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import theme from "../../themes/Theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light,
      paddingBottom: "5px",
    },
    revenueEstimationSection: {
      justifyContent: "center",
      "@media (min-width:1200px)": {
        display: "flex",
        justifyContent: "flex-start",
      },
      "@media (max-width:1200px)": {
        display: "block",
        marginBottom: "0px",
      },
    },
    questionBlock: {
      textAlign: "left",
      [theme.breakpoints.up("md")]: {
        padding: "20px 40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px 30px",
      },
    },
    cardBlock: {
      textAlign: "left",
      [theme.breakpoints.up("md")]: {
        padding: "0px 40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0px 20px",
      },
    },
    close: {
      padding: theme.spacing(0.5),
    },
    dropZone: {
      margin: "20px auto",
      //width: "90%",
      //minWidth: "320px",
    },
    row: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      minWidth: 0,
    },
    cardRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 16px",
    },
    card: {
      margin: "20px auto",
      //width: "90%",
      //minWidth: "320px",
    },
    paragraph: {
      margin: "auto auto",
      //width: "90%",
      //minWidth: "320px",
      textAlign: "left",
    },
    fileName: {
      paddingLeft: "20px",
      overflowWrap: "break-word",
      textAlign: "left",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    viewUserAgreement: {
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "transparent",
      },
    },
  })
);

interface Props {
  disableBack: boolean;
  handleBack: () => void;
  handleNext: (proofFiles: Array<File>) => void;
  //submit: () => void,
  nextButtonText: string;
  files: Array<File>;
  pageNumber: number;
  blackoutNumber: number;
  schoolId: string | null;
  yearId: string | null;
  requireProofs: boolean;
  showUserAgreement?: boolean;
  shouldProceedToPrevious?: boolean;
  setShouldProceedToPrevious?: (value: boolean) => void;
}

function UploadPdfPage(props: Props) {
  const classes = useStyles();

  const [files, setFiles] = useState<Array<File>>(props.files);
  const [message, setMessage] = useState<string | undefined>(undefined);
  //const [title, setTitle] = useState<string | null>(props.title);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [userAgreementConfirmed, setUserAgreementConfirmed] =
    useState<boolean>(false);
  const [ageConfirmed, setAgeConfirmed] = useState<boolean>(false);

  useEffect(() => {
    if (
      props.shouldProceedToPrevious === true &&
      props.setShouldProceedToPrevious
    ) {
      props.handleBack();
      props.setShouldProceedToPrevious(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToPrevious]);

  const submitFiles = (files: Array<File>) => {
    setFiles(files);
  };

  const handleModalClose = (checked: boolean, ageChecked: boolean) => {
    setAgeConfirmed(ageChecked);
    setUserAgreementConfirmed(checked);
    setOpenDialog(false);
  };

  const handleNext = () => {
    if (props.requireProofs && files.length === 0) {
      setMessage("At least one uploaded file is required");
      return;
    }

    if (props.showUserAgreement && !userAgreementConfirmed) {
      setMessage("Please review and sign the user agreement");
      return;
    }

    if (props.showUserAgreement && !ageConfirmed) {
      setMessage(
        "You must be at least 18 years old to contribute your application"
      );
      return;
    }

    props.handleNext(files);
  };

  const callBack = () => {
    setMessage(undefined);
  };

  const onDelete = (index: number) => {
    const newFiles = Array.from(files);
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uploadFiles = files.map((file, index) => {
    let icon = <PictureAsPdfIcon style={{ fontSize: "2rem" }} />;
    if (file.type !== "application/pdf") {
      icon = <ImageIcon style={{ fontSize: "2rem" }} />;
    }
    return (
      <div key={index}>
        <div className={classes.cardRow}>
          <div className={classes.row}>
            {icon}
            <div style={{ minWidth: 0 }}>
              <Typography variant="subtitle1" className={classes.fileName}>
                {file.name}
              </Typography>
            </div>
          </div>
          <IconButton
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={() => onDelete(index)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
      </div>
    );
  });

  const card = (
    <Card className={classes.card}>
      <CardContent style={{ padding: "0px 16px" }}>{uploadFiles}</CardContent>
    </Card>
  );

  const setUploadedFiles = (files: Array<File>) => {
    setFiles(files);
  };

  return (
    <div className={classes.root}>
      <PageTitle title="Upload Proof of Acceptance" />
      <div className={classes.questionBlock}>
        {props.requireProofs && (
          <div className={classes.paragraph} style={{ marginBottom: 10 }}>
            <Typography
              variant="body1"
              style={{ fontFamily: "SuperaGothic", fontWeight: "bold" }}
            >
              {
                "Please attach any relevant files that can prove your admission to this institution. This may be in the form of a letter of acceptance, proof of enrollment or any other files that can confirm admission."
              }
            </Typography>
          </div>
        )}
        {props.requireProofs && (
          <div className={classes.paragraph} style={{ marginTop: 10 }}>
            <Typography
              variant="body1"
              style={{ fontFamily: "SuperaGothic", fontWeight: "bold" }}
            >
              {"Note: Individual file size must be less than 2MB."}
            </Typography>
          </div>
        )}
        {props.requireProofs && (
          <div className={classes.dropZone}>
            <Dropzone
              handleNext={submitFiles}
              sendAlert={() => setMessage("Only PDFS are supported")}
              uploadedFiles={files}
              setUploadedFiles={setUploadedFiles}
            />
          </div>
        )}
      </div>
      {props.requireProofs && files.length !== 0 && (
        <PageTitle title="Uploaded Files" />
      )}
      {props.requireProofs && files.length !== 0 && (
        <div className={classes.cardBlock}>{card}</div>
      )}
      {props.showUserAgreement && (
        <div style={{ marginTop: 50 }}>
          {userAgreementConfirmed && ageConfirmed && (
            <CheckCircleIcon style={{ color: theme.palette.primary.main }} />
          )}
          {(!userAgreementConfirmed || !ageConfirmed) && (
            <ErrorIcon style={{ color: "red" }} />
          )}
          <Button
            variant="text"
            classes={{ root: classes.viewUserAgreement }}
            style={{ textTransform: "none", fontSize: 18 }}
            disableRipple
            onClick={() => setOpenDialog(true)}
          >
            {!userAgreementConfirmed || !ageConfirmed
              ? "Sign the user agreement"
              : "User agreement confirmed!"}
          </Button>
        </div>
      )}
      {props.showUserAgreement && (
        <UserAgreementDialog
          open={openDialog}
          handleConfirm={handleModalClose}
          handleClose={() => setOpenDialog(false)}
          checked={userAgreementConfirmed}
          ageChecked={ageConfirmed}
        />
      )}
      <SnackBar message={message} callBack={callBack} />
      <div style={{ padding: "20px 0px" }}>
        <Button
          disabled={props.disableBack}
          onClick={props.handleBack}
          className={classes.backButton}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleNext()}
          disabled={
            props.showUserAgreement === true &&
            (!userAgreementConfirmed || !ageConfirmed)
          }
        >
          {props.nextButtonText}
        </Button>
      </div>
    </div>
  );
}

export default UploadPdfPage;
