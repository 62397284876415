import React, { ReactNode } from "react";
import ErrorPage from "../components/errors/PageNotFound";
import * as Sentry from "@sentry/react";

interface State {
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

interface Props {
  children: ReactNode;
}
export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined, errorInfo: undefined };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    Sentry.captureException(this.state.error);
    // You can also log error messages to an error reporting service here
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const childNow = React.Children.only(this.props.children);
    const childPrev = React.Children.only(prevProps.children);

    if (childNow !== childPrev) {
      this.setState({ errorInfo: undefined, error: undefined });
    }
  }

  render() {
    if (this.state.error) {
      // Error path
      return <ErrorPage message="We had some trouble rendering your page." />;
    }
    // Normally, just render children
    return this.props.children;
  }
}
