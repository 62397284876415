import React, { useState, useEffect } from "react";
import axios from "axios";
import { Earning } from "../../../types/types";
import { useHistory } from "react-router";
import AccordianTable from "../../general/AccordianTable";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import ProfileSection from "./ProfileSection";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import * as Sentry from "@sentry/browser";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moneyInput: {
      width: "80%",
      maxWidth: "200px",
    },
    sideMargin: {
      margin: "10px 0px",
      width: "100%",
    },
    text: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    normalText: {
      fontFamily: "SuperaGothic",
      //marginBottom: '10px',
      fontSize: "16px",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
    },
    root: {
      [theme.breakpoints.down("sm")]: {
        margin: "0px 15px",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0rem 3rem",
      },
      //minHeight: "70%",
    },
  })
);

export default function EarningsPage() {
  const classes = useStyles();
  const [earning, setEarning] = useState<Earning | undefined>(undefined);
  const history = useHistory();
  useEffect(() => {
    axios(`api/v1/earnings`, {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        var embededData = data.data;
        if (embededData) {
          var earning = embededData.earnings as Earning;
          if (earning) {
            setEarning(earning);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        } else {
          // TODO: add error logger
          history.replace({
            pathname: "/503",
            state: { from: history.location },
          });
        }
        Sentry.captureException(error);
      });
  }, [history]);

  if (!earning) {
    return <CircularProgress style={{ margin: "auto" }} />;
  }

  return (
    <div className={`${classes.root} page-container`}>
      <Helmet>
        <title>Analytics - CollegeSharing</title>
      </Helmet>
      {earning && (
        <ProfileSection title="Key statistics">
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
              <Typography variant="h3">${earning?.total_earnings}</Typography>
              <Typography variant="h6">Total Reward</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
              <Typography variant="h3">
                {earning?.total_active_cases}
              </Typography>
              <Typography variant="h6">Active Applications</Typography>
            </Grid>
          </Grid>
        </ProfileSection>
      )}
      {earning && earning.case_earnings.length !== 0 && (
        <ProfileSection title="Detailed analytics">
          <AccordianTable case_earnings={earning.case_earnings} />
        </ProfileSection>
      )}
    </div>
  );
}
