import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Spacing from "../general/Spacing";
import FooterColumn from "../general/components/FooterColumn";
import WhiteLogo from "../../resources/whiteLogo.svg";
import { Divider, Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import Background from "../../resources/footer_background.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      //backgroundColor: '#898989',
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundImage: `url(${Background})`,
      padding: "5% 5%",
      minWidth: 320,
    },
    iconWrapper: {
      margin: 8,
      marginLeft: 0,
      "&:hover": {
        color: "rgba(255,255,255,1)",
      },
    },
    outer: {
      width: "auto",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        justifyContent: "flex-start",
        flexWrap: "wrap",
      },
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-start",
        flexWrap: "nowrap",
      },
    },
    logo: {
      color: "white",
    },
    companyOverview: {
      width: "auto",
      //maxWidth: '400px',
      paddingRight: "5%",
      paddingLeft: "5%",
      textAlign: "left",
      color: "white",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        paddingBottom: "50px",
        paddingTop: "30px",
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "400px",
        paddingBottom: "30px",
      },
    },
  })
);

function PageFooter() {
  const styles = useStyles();
  const companyOverview = (
    <div className={`${styles.companyOverview}`}>
      <img src={WhiteLogo} style={{ width: 200 }} alt=""></img>
      <Spacing spacing={10} />
      <div className="roboto-condensed footer-font-size-1200">
        CollegeSharing strives to bridge the gap between current college
        applicants and past admits.
      </div>
    </div>
  );

  const columns = footerContent.map((column) => {
    return <FooterColumn key={column.title} {...column} />;
  });
  return (
    <div className={styles.container}>
      <div className={styles.outer}>
        {companyOverview}
        {columns}
      </div>
      <Divider
        style={{ margin: "0", backgroundColor: "rgba(255,255,255,0.2)" }}
      />
      <Spacing spacing={10} />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          color: "rgba(255,255,255,0.6)",
          justifyContent: "space-between",
          alignItems: "stretch",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        <div>
          <Typography
            style={{
              margin: "auto 0",
              fontSize: 16,
              fontFamily: "RobotoCondensed",
              textAlign: "left",
            }}
          >
            @2021 CollegeSharing Inc,
          </Typography>
          <Typography
            style={{
              margin: "auto 0",
              fontSize: 16,
              fontFamily: "RobotoCondensed",
              textAlign: "left",
            }}
          >
            530 Lawrence Expy PMB 983, Sunnyvale, CA 94085
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <FacebookIcon
            className={styles.iconWrapper}
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.facebook.com/collegesharing", "_blank")
            }
          />
          <InstagramIcon
            className={styles.iconWrapper}
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.instagram.com/collegesharing", "_blank")
            }
          />
        </div>
      </div>
    </div>
  );
}

const footerContent = [
  {
    title: "Quick Links",
    contentList: [
      {
        name: "Browse",
        link: "/search",
      },
      {
        name: "Upload",
        link: "/host_landing",
      },
      {
        name: "Subscribe",
        link: "/payment_page",
      },
      {
        name: "FAQ",
        link: "/faq",
      },
    ],
  },
  {
    title: "Legal",
    contentList: [
      {
        name: "Terms of Use",
        link: "/terms_of_use",
      },
      {
        name: "Privacy Policy",
        link: "/privacy_policy",
      },
      {
        name: "Licenses",
        link: "/license_agreement",
      },
    ],
  },
  {
    title: "Connect",
    contentList: [
      {
        name: "support@collegesharing.com",
      },
      {
        name: "Chat with us",
        external: true,
        link: "https://m.me/collegesharing",
      },
    ],
  },
];

export default PageFooter;
