import React, { useState, useEffect } from "react";
import { PDFDocumentProxy } from "pdfjs-dist/webpack";
import * as PDFJS from "pdfjs-dist/webpack";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import theme from "../../themes/Theme";
import Button from "@material-ui/core/Button";
import PageTitle from "../general/PageTitle";
import { RectWithId } from "../upload/ImageEditor";
import { FileWithPages } from "../general/Stepper";
import { Paper } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import WhiteCircle from "../../resources/whiteCircle.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light,
      //marginBottom: '5%',
    },
    paragraph: {
      margin: "auto auto",
      width: "90%",
      minWidth: "320px",
      textAlign: "left",
    },
    contentContainer: {
      margin: "0px 15px",
    },
    pageContainer: {
      margin: "10px",
      height: "calc(100% - 20px)",
      textAlign: "left",
      overflow: "hidden",
    },
    loadingContainer: {
      margin: "10px",
      height: "calc(100% - 20px)",
      backgroundColor: "white",
      overflow: "hidden",
    },
    imageContainer: {
      display: "flex",
      alignItems: "center",
      margin: "5px",
      height: "calc(100% - 10px)",
    },
    paper: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    page: {
      margin: "3%",
      width: "94%",
      //position: 'relative',
      //top: '50%',
      //transform: 'translateY(-50%)',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    clickButton: {
      position: "absolute",
      marginTop: "5px",
      marginLeft: "5px",
      color: theme.palette.primary.main,
    },
    imageHeader: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: "300px",
      },
    },
  })
);

export interface Page {
  purePage?: string;
  renderablePage?: string;
  selected: boolean;
  rects: Array<RectWithId>;
  types: Array<string>;
}

interface Props {
  disableBack: boolean;
  handleBack: () => void;
  handleNext: (uploadedFiles: Array<FileWithPages>) => void;
  nextButtonText: string;
  files: Array<FileWithPages>;
  shouldProceedToNext?: boolean;
  setShouldProceedToNext?: (value: boolean) => void;
  shouldProceedToPrevious?: boolean;
  setShouldProceedToPrevious?: (value: boolean) => void;
}

function SelectPagesPage(props: Props) {
  const classes = useStyles();

  const [filesWithPages, setFilesWithPages] = useState<Array<FileWithPages>>(
    props.files
  );
  const [redirect, setRedirect] = useState<JSX.Element | null>(null);
  const [shouldDisableStepChange, setShouldDisableStepChange] =
    useState<boolean>(false);

  const onPageLoadSuccess = (newFiles: Array<FileWithPages>) => {
    setFilesWithPages(newFiles);
    setShouldDisableStepChange(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (props.files === null || props.files.length === 0) {
      return;
    }
    const unrendered_files = props.files.filter(
      (file) => file.pages.length === 0
    );
    // if some files don't have any pages
    if (unrendered_files.length !== 0) {
      setShouldDisableStepChange(true);
      getImageFromPdf(props.files, onPageLoadSuccess, setRedirect);
    } else {
      // if some file has unexpected page objects that don't have purePage field
      var shouldRender =
        props.files.filter(
          (file) => file.pages.filter((page) => !page.purePage).length !== 0
        ).length !== 0;
      if (shouldRender) {
        setShouldDisableStepChange(true);
        getImageFromPdf(props.files, onPageLoadSuccess, setRedirect);
      }
    }
  }, [props.files]);

  const handleNext = (uploadedFiles: Array<FileWithPages>) => {
    if (shouldDisableStepChange) {
      return;
    }
    props.handleNext(uploadedFiles);
  };

  useEffect(() => {
    if (props.shouldProceedToNext === true && props.setShouldProceedToNext) {
      handleNext(filesWithPages);
      props.setShouldProceedToNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToNext]);

  const handleBack = () => {
    if (shouldDisableStepChange) {
      return;
    }
    props.handleBack();
  };

  useEffect(() => {
    if (
      props.shouldProceedToPrevious === true &&
      props.setShouldProceedToPrevious
    ) {
      handleBack();
      props.setShouldProceedToPrevious(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToPrevious]);

  if (redirect) {
    return redirect;
  }

  const onPageClick = (fileIndex: number, pageIndex: number) => {
    let newFiles = Array.from(filesWithPages);
    newFiles[fileIndex].pages[pageIndex].selected =
      !newFiles[fileIndex].pages[pageIndex].selected;

    setFilesWithPages(newFiles);
  };

  let data: React.ReactNode = null;

  let allGrids: Array<JSX.Element> = [];
  props.files.forEach((file, fileIndex) => {
    let nonemptyPages = file.pages.filter((page) => page.purePage);
    if (nonemptyPages.length !== 0) {
      let grids = nonemptyPages.map((dataUrl, index) => {
        const color = dataUrl.selected
          ? theme.palette.primary.main
          : theme.palette.secondary.main;
        const borderStyle = dataUrl.selected
          ? `2px solid ${theme.palette.primary.main}`
          : `2px solid ${theme.palette.secondary.main}`;
        return (
          <Grid
            item
            xs={6}
            md={4}
            lg={3}
            xl={2}
            key={String(fileIndex) + "" + String(index)}
          >
            <div
              className={classes.pageContainer}
              style={{ border: borderStyle, cursor: "pointer" }}
              onClick={() => onPageClick(fileIndex, index)}
            >
              <CheckCircleIcon
                className={classes.clickButton}
                style={{
                  color: color,
                  backgroundImage: `url(${WhiteCircle})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  fontSize: "2rem",
                }}
              />
              <div className={classes.imageContainer}>
                <Paper
                  className={classes.paper}
                  elevation={dataUrl.selected ? 5 : 1}
                  square={true}
                >
                  <img
                    src={dataUrl.purePage}
                    className={classes.page}
                    alt=""
                  ></img>
                </Paper>
              </div>
            </div>
          </Grid>
        );
      });
      allGrids = allGrids.concat(grids);
    } else {
      let grid = (
        <Grid item xs={6} md={4} lg={3} xl={2} key={fileIndex}>
          <div
            className={classes.pageContainer}
            style={{ border: `2px solid ${theme.palette.primary.main}` }}
          >
            <div className={classes.imageContainer}>
              <Paper className={classes.paper} elevation={5} square={true}>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
                  className={classes.page}
                  alt=""
                ></img>
              </Paper>
            </div>
          </div>
        </Grid>
      );
      allGrids.push(grid);
    }
  });

  data = (
    <Grid container spacing={0}>
      {allGrids}
    </Grid>
  );

  /*if (concatedPages !== null && concatedPages.length !== 0) {
        let grids = concatedPages.map((dataUrl, index) => {
            const color = dataUrl.selected ? theme.palette.primary.main : theme.palette.secondary.main;
            const borderStyle = dataUrl.selected? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.secondary.main}`
            return (
                <Grid key={index} item xs={6} md={4} lg={3} xl={2}>
                    <div className={classes.pageContainer} style={{outline: borderStyle}} onClick={() => onPageClick(index)}>
                        <CheckCircleIcon className={classes.clickButton} style={{color: color, fontSize:'2rem'}}/>
                        <img key={index} src={dataUrl.purePage} className={classes.page} alt=''></img>
                    </div>
                </Grid>
            )
        });
        data = (
            <Grid container spacing={0}>
                {grids}
            </Grid>
        )
    }*/

  return (
    <div className={classes.root}>
      <PageTitle title="Select Pages to Upload" />
      <div className={classes.contentContainer}>
        {data}
        <div style={{ padding: "20px 0px" }}>
          <Button
            disabled={props.disableBack || shouldDisableStepChange}
            onClick={handleBack}
            className={classes.backButton}
          >
            Back
          </Button>
          <Button
            disabled={shouldDisableStepChange}
            variant="contained"
            color="primary"
            onClick={() => handleNext(filesWithPages)}
          >
            {props.nextButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
}

function getImageFromPdf(
  files: Array<FileWithPages>,
  onLoadSuccess: (newFiles: Array<FileWithPages>) => void,
  setRedirect: (redirect: JSX.Element) => void
) {
  let loadImagePromises: Array<Promise<Array<Page>> | null> = [];
  let parsedFiles: Array<FileWithPages> = Array.from(files);

  for (let i = 0; i < files.length; i++) {
    const unrendered_pages = files[i].pages.filter((page) => !page.purePage);
    if (
      files[i].file &&
      files[i].pages.length !== 0 &&
      unrendered_pages.length === 0
    ) {
      loadImagePromises.push(null);
    } else {
      const loadImagePromise = loadImage(files[i]);
      loadImagePromises.push(loadImagePromise);
    }
  }
  Promise.all(loadImagePromises)
    .then((singlePdfPages) => {
      for (let i = 0; i < singlePdfPages.length; i++) {
        const filePages = singlePdfPages[i];
        if (filePages !== null) {
          parsedFiles[i].pages = filePages;
        }
      }
      onLoadSuccess(parsedFiles);
    })
    .catch((error) => {
      Sentry.captureException(error);
      setRedirect(<Redirect to="/503" />);
    });
}

function loadImage(file: FileWithPages): Promise<Array<Page>> {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    reader.onerror = (error) => {
      reject("Failed to load images from the file.");
    };
    if (file.file.type === "image/jpeg" || file.file.type === "image/png") {
      reader.onloadend = () => {
        resolve([
          {
            purePage: reader.result as string,
            selected: file.pages.length > 0 ? file.pages[0].selected : true,
            rects: file.pages.length > 0 ? file.pages[0].rects : [],
            types: file.pages.length > 0 ? file.pages[0].types : [],
          },
        ]);
      };
      reader.readAsDataURL(file.file);
      return;
    }
    reader.onloadend = () => {
      PDFJS.getDocument(reader.result as string).promise.then(
        (pdf) => {
          const pages = [];
          for (let i = 0; i < pdf.numPages; i++) {
            pages.push(
              renderPage(
                i + 1,
                pdf,
                file.pages.length === pdf.numPages ? file.pages[i] : null
              )
            );
          }
          Promise.all(pages)
            .then((resolvedPages) => {
              resolve(resolvedPages);
            })
            .catch((error) => {
              Sentry.captureException(error);
              reject(error);
            });
        },
        function (reason) {
          // PDF loading error
          Sentry.captureException(new Error(reason));
          reject(reason);
        }
      );
    };
    reader.readAsDataURL(file.file);
  });
}

function renderPage(
  num: number,
  pdf: PDFDocumentProxy,
  existing_page: Page | null
): Promise<Page> {
  return new Promise((resolve, reject) => {
    pdf.getPage(num).then(
      (page) => {
        const scale = 5.0;
        const viewport = page.getViewport({ scale });
        const canvas = document.createElement("canvas");
        const canvasContext =
          canvas.getContext("2d") || new CanvasRenderingContext2D();
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        canvas.style.height = "100%";
        canvas.style.width = "100%";
        canvas.style.background = "rgba(0,0,0,0)";
        page
          .render({
            canvasContext,
            viewport,
          })
          .promise.then(
            () => {
              const result = {
                purePage: canvas.toDataURL("image/jpeg"),
                selected: existing_page ? existing_page.selected : true,
                rects: existing_page ? existing_page.rects : [],
                types: existing_page ? existing_page.types : [],
              };
              resolve(result);
            },
            function (reason) {
              Sentry.captureException(new Error(reason));
              reject(reason);
            }
          );
      },
      function (reason) {
        Sentry.captureException(new Error(reason));
        reject(reason);
      }
    );
  });
}

export default SelectPagesPage;
