import { GeneralProfile } from "../../../types/admitProfileTypes";

import { useStyles as profileStyles } from "./ProfileStyles";
import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../../themes/Theme";
import { Grid, Typography, Avatar, Button } from "@material-ui/core";
import { ReactComponent as BookIcon } from "../../../resources/icons/book.svg";
import { ReactComponent as GenderIcon } from "../../../resources/icons/gender.svg";
import { ReactComponent as CountryIcon } from "../../../resources/icons/country.svg";
import { ReactComponent as StateIcon } from "../../../resources/icons/state.svg";
import { ReactComponent as CityIcon } from "../../../resources/icons/city.svg";
import { ReactComponent as EthniticyIcon } from "../../../resources/icons/ethnicity.svg";
import { ReactComponent as BlueBookIcon } from "../../../resources/icons/blue_book.svg";
import { ReactComponent as BlueGenderIcon } from "../../../resources/icons/blue_gender.svg";
import { ReactComponent as BlueCountryIcon } from "../../../resources/icons/blue_country.svg";
import { ReactComponent as BlueStateIcon } from "../../../resources/icons/blue_state.svg";
import { ReactComponent as BlueCityIcon } from "../../../resources/icons/blue_city.svg";
import { ReactComponent as BlueEthniticyIcon } from "../../../resources/icons/blue_ethnicity.svg";
import { ReactComponent as SchoolIcon } from "../../../resources/icons/school.svg";
import { ReactComponent as CalendarIcon } from "../../../resources/icons/calendar.svg";
import { ReactComponent as BlueSchoolIcon } from "../../../resources/icons/blue_school.svg";
import { ReactComponent as AvatarIcon } from "../../../resources/icons/avatar.svg";
import { ReactComponent as BlueAvatarIcon } from "../../../resources/icons/small_screen_avatar.svg";
import { ReactComponent as WhiteSupplementIcon } from "../../../resources/icons/white_supplement.svg";
import { ReactComponent as BlueSupplementIcon } from "../../../resources/icons/blue_supplement.svg";
import { ReactComponent as LightBlueGPAIcon } from "../../../resources/icons/light_blue_gpa.svg";
import { ReactComponent as LightBlueClassRankIcon } from "../../../resources/icons/light_blue_class_rank.svg";
import { ReactComponent as LightBlueCalendarIcon } from "../../../resources/icons/light_blue_year.svg";
import { ReactComponent as LightBlueEthnicityIcon } from "../../../resources/icons/light_blue_ethnicity.svg";
import { ReactComponent as LightBlueGenderIcon } from "../../../resources/icons/light_blue_gender.svg";
import { ReactComponent as LightBlueMajorIcon } from "../../../resources/icons/light_blue_major.svg";
import { ReactComponent as LightBlueCountryIcon } from "../../../resources/icons/light_blue_country.svg";
import { ReactComponent as LightBlueStateIcon } from "../../../resources/icons/light_blue_state.svg";
import { ReactComponent as LightBlueCityIcon } from "../../../resources/icons/light_blue_city.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarImage: {
      overflow: "hidden",
      objectFit: "contain",
    },
    overflow: {
      overflow: "hidden",
      width: "100%",
    },
    pdfButton: {
      [theme.breakpoints.down("sm")]: {
        border: "1px solid white",
        marginBottom: 25,
        fontSize: 14,
        color: "white",
        display: "flex",
        height: 35,
        borderRadius: 8,
      },
      [theme.breakpoints.up("md")]: {
        border: "1px solid white",
        marginBottom: 25,
        color: "white",
        display: "flex",
        height: 45,
        fontSize: 16,
        borderRadius: 12,
      },
    },

    rightPanel: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    bottomPanel: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    bannerAvatar: {
      [theme.breakpoints.down("sm")]: {
        width: 135,
        height: 135,
      },
      [theme.breakpoints.up("md")]: {
        width: 165,
        height: 165,
      },
    },
    bannerItemsContainer: {
      zIndex: -20,
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        marginTop: -15,
        paddingLeft: 30,
        //paddingLeft:180
      },
      [theme.breakpoints.up("md")]: {
        marginTop: -45,
        paddingLeft: 210,
      },
    },
  })
);

export type LeftPanelProps = {
  generalProfile: GeneralProfile;
  openPdf: () => void;
};

export function TopBannerProfile(props: LeftPanelProps) {
  const classes = useStyles();
  const profile = props.generalProfile;

  let badgeItems: BadgeItem[] = [];
  badgeItems.push({
    name: "Major",
    value: profile.major,
    icon: <LightBlueMajorIcon />,
  });
  if (profile.gender) {
    badgeItems.push({
      name: "Gender",
      value: profile.gender,
      icon: <LightBlueGenderIcon />,
    });
  }
  if (profile.ethnicity) {
    badgeItems.push({
      name: "Ethnicity",
      value: profile.ethnicity,
      icon: <LightBlueEthnicityIcon />,
    });
  }
  badgeItems.push({
    name: profile.weightedGpa ? "Weighted GPA" : "Unweighted GPA",
    value: profile.weightedGpa || profile.unweightedGpa,
    icon: <LightBlueGPAIcon />,
  });
  if (profile.classRanking && profile.classRanking !== "N/A") {
    badgeItems.push({
      name: "Class Rank",
      value: profile.classRanking,
      icon: <LightBlueClassRankIcon />,
    });
  }
  if (
    profile.country !== "United States" &&
    profile.country !== "United States of America"
  ) {
    badgeItems.push({
      name: "Country",
      value: profile.country,
      icon: <LightBlueCountryIcon />,
    });
  }
  if (profile.usState) {
    badgeItems.push({
      name: "State",
      value: profile.usState,
      icon: <LightBlueStateIcon />,
    });
  }
  if (profile.city) {
    badgeItems.push({
      name: "City",
      value: profile.city,
      icon: <LightBlueCityIcon />,
    });
  }

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const includeYearInBadgeItems =
    isSmallScreen || [2, 5, 7].includes(badgeItems.length);
  if (includeYearInBadgeItems) {
    badgeItems.splice(1, 0, {
      name: "Year",
      value: profile.year,
      icon: <LightBlueCalendarIcon />,
    });
  }

  const items = badgeItems.map((item) => (
    <BadgeItemRow {...item} num={badgeItems.length} key={item.name} />
  ));

  return (
    <div>
      <div
        className={classes.bottomPanel}
        style={{
          position: "absolute",
          zIndex: 900,
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ width: 180, marginTop: 15, marginRight: 30 }}>
          <PdfButton openPdf={props.openPdf} />
        </div>
      </div>
      <div
        style={{
          background: "linear-gradient(180deg, #6A629D 0%, #119EAC 100%)",
          width: "100%",
          height: isSmallScreen ? 180 : 160,
        }}
      ></div>

      <div
        style={{
          display: "flex",
          marginTop: -120,
          marginLeft: 30,
          marginRight: 35,
          zIndex: 100,
        }}
      >
        <div
          className={classes.bannerAvatar}
          style={{
            backgroundColor: "white",
            border: "4px solid white",
            borderRadius: 5,
            boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.1)",
          }}
        >
          <LazyLoadImage
            src={profile.smallSchoolImageUrl}
            height={isSmallScreen ? 127 : 157}
            width={isSmallScreen ? 127 : 157}
            effect="blur"
            visibleByDefault={true}
            wrapperClassName={classes.avatarImage}
            style={{ maxWidth: "180%", width: "auto", height: "100%" }}
          />
        </div>
        <div style={{ display: "flex", height: 120, flexGrow: 1 }}>
          <BannerTitle
            name={profile.userName}
            year={profile.shortSchoolName}
            introduction={profile.introduction}
          />
          <div
            className={classes.rightPanel}
            style={{
              marginLeft: 40,
              minWidth: 200,
              height: 120,
              flexDirection: "column",
              justifyContent: includeYearInBadgeItems
                ? "flex-end"
                : "space-between",
              flexGrow: 0,
            }}
          >
            <div
              style={{ display: includeYearInBadgeItems ? "none" : "block" }}
            >
              <EnrolledAtBanner year={profile.year} />
            </div>
            <PdfButton openPdf={props.openPdf} />
          </div>
        </div>
      </div>
      <div
        className={classes.bannerItemsContainer}
        style={{
          paddingRight: 35,
          alignItems: "center",
          background: "rgba(245, 245, 245, 0.5)",
          boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexGrow: 1,
            paddingTop: 20,
            paddingBottom: 15,
          }}
        >
          {items}
        </div>
      </div>
    </div>
  );
}

function PdfButton({ openPdf }: { openPdf: () => void }) {
  const classes = useStyles();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Button
      disableRipple
      onClick={openPdf}
      className={classes.pdfButton}
      style={{
        width: "100%",
        textTransform: "none",
        fontFamily: "RedHatDisplay-SemiBold",
      }}
    >
      <WhiteSupplementIcon
        style={{
          marginRight: 12,
          width: smallScreen ? 20 : 24,
          height: smallScreen ? 20 : 24,
          display: "flex",
        }}
      />
      <div>Application PDF</div>
    </Button>
  );
}

type BadgeItem = {
  name: string;
  value: string;
  icon: React.ReactNode;
  num?: number;
};

function BadgeItemRow(props: BadgeItem) {
  const profileClasses = profileStyles();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let wrapperClass;
  if (!props.num) {
    return <div></div>;
  }
  if (props.num <= 3 || props.num === 5 || props.num === 6) {
    wrapperClass = profileClasses.threeBadgeItemTestSection;
  } else {
    wrapperClass = profileClasses.fourBadgeItemTestSection;
  }

  return (
    <div className={wrapperClass} style={{ margin: 10 }}>
      <div style={{ display: "flex" }}>
        <div style={{ width: 24, height: 24 }}>{props.icon}</div>
        <div
          style={{
            marginLeft: smallScreen ? 8 : 10,
            textAlign: "left",
            fontSize: smallScreen ? 14 : 16,
            color: "#0E7F8A",
            fontFamily: "RedHatDisplay-Medium",
          }}
        >
          {props.name}
        </div>
      </div>
      <div
        style={{
          marginLeft: smallScreen ? 32 : 34,
          textAlign: "left",
          fontSize: smallScreen ? 14 : 16,
          fontFamily: "RedHatDisplay-Bold",
        }}
      >
        {props.value}
      </div>
    </div>
  );
}

function EnrolledAtBanner({ year }: { year: string }) {
  const profileClasses = profileStyles();
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        color: "white",
        zIndex: 800,
      }}
    >
      <CalendarIcon
        style={{ fontSize: 20, marginRight: 10, zIndex: 800 }}
        className={profileClasses.pageVisibilityByWidth}
      />
      <BlueSchoolIcon
        style={{ fontSize: 20, marginRight: 10, zIndex: 800 }}
        className={profileClasses.smallScreen}
      />
      <Typography
        style={{
          fontFamily: "RedHatDisplay-SemiBold",
          fontSize: 18,
          zIndex: 800,
          textAlign: "left",
          overflowWrap: "break-word",
          minWidth: 180,
          flexGrow: 1,
        }}
      >
        {year}
      </Typography>
    </div>
  );
}

type BannerTitleProps = {
  year: string;
  name: string;
  introduction: string;
};

function BannerTitle(props: BannerTitleProps) {
  return (
    <div
      style={{
        marginLeft: 25,
        flexGrow: 1,
        zIndex: 100,
        color: "white",
        height: 120,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
        <div
          style={{
            fontSize: 24,
            fontFamily: "RedHatDisplay",
            fontWeight: "bold",
          }}
        >
          {props.name}
        </div>
        <div
          style={{
            width: 2,
            background:
              "linear-gradient(rgba(229, 245, 247, 0.05),rgba(229, 245, 247, 0.2), rgba(229, 245, 247, 0.05))",
            height: 33,
            marginLeft: 18,
          }}
        ></div>
        <div
          style={{
            fontSize: 18,
            fontFamily: "RedHatDisplay",
            marginLeft: 18,
          }}
        >
          {props.year}
        </div>
      </div>
      <div
        style={{
          fontSize: 14,
          fontFamily: "RedHatDisplay",
          textAlign: "left",
          marginBottom: 20,

          lineHeight: "120%",
          maxHeight: "3.6em",
          overflow: "hidden",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          textOverflow: "ellipsis",
          display: "-webkit-box",
        }}
      >
        {props.introduction}
      </div>
    </div>
  );
}

export function SmallScreenProfile(props: LeftPanelProps) {
  const profileClasses = profileStyles();
  const profile = props.generalProfile;

  let gpaName = profile.weightedGpa ? "Weighted GPA" : "Unweighted GPA";

  return (
    <div style={{ margin: 20 }}>
      <div style={{ marginBottom: 20 }}>
        <Card generalProfile={profile} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flexGrow: 1 }}>
          <Label
            name={gpaName}
            value={profile.weightedGpa || profile.unweightedGpa}
          />
        </div>
        <div style={{ width: 20 }}></div>
        <div style={{ flexGrow: 1 }}>
          <Label name={"Class Ranking"} value={profile.classRanking} />
        </div>
      </div>
      <div
        style={{ marginTop: 20, textAlign: "left" }}
        className={`${profileClasses.whiteBorderedBox} ${profileClasses.fullWidth}`}
      >
        <RowLabel
          name={"Major"}
          value={profile.major}
          icon={<BlueBookIcon />}
          shouldShowBottonLine
        />
        {profile.gender && (
          <RowLabel
            name={"Gender"}
            value={profile.gender}
            icon={<BlueGenderIcon />}
            shouldShowBottonLine
          />
        )}
        {profile.ethnicity && (
          <RowLabel
            name={"Ethnicity"}
            value={profile.ethnicity}
            icon={<BlueEthniticyIcon />}
            shouldShowBottonLine
          />
        )}
        <RowLabel
          name={"Country"}
          value={profile.country}
          icon={<BlueCountryIcon />}
          shouldShowBottonLine
        />
        {profile.usState && (
          <RowLabel
            name={"State"}
            value={profile.usState}
            icon={<BlueStateIcon />}
            shouldShowBottonLine
          />
        )}
        {profile.city && (
          <RowLabel
            name={"City"}
            value={profile.city}
            icon={<BlueCityIcon />}
            shouldShowBottonLine
          />
        )}
      </div>
      <Button
        disableRipple
        onClick={props.openPdf}
        style={{
          border: "1px solid #0E7F8A",
          maxWidth: 250,
          display: "flex",
          margin: "32px auto",
          textTransform: "none",
          fontSize: 18,
          fontFamily: "RedHatDisplay-SemiBold",
          width: "100%",
          color: "#0E7F8A",
          borderRadius: 12,
          height: 55,
        }}
      >
        <BlueSupplementIcon style={{ marginRight: 12 }} />
        Application PDF
      </Button>
    </div>
  );
}

function LeftPanel(props: LeftPanelProps) {
  const profileClasses = profileStyles();
  const profile = props.generalProfile;

  let gpaName = profile.weightedGpa ? "Weighted GPA" : "Unweighted GPA";

  return (
    <div style={{ position: "sticky", top: 90 }}>
      <div style={{ marginBottom: 20 }}>
        <Card generalProfile={profile} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flexGrow: 1 }}>
          <Label
            name={gpaName}
            value={profile.weightedGpa || profile.unweightedGpa}
          />
        </div>
        <div style={{ width: 20 }}></div>
        <div style={{ flexGrow: 1 }}>
          <Label name={"Class Ranking"} value={profile.classRanking} />
        </div>
      </div>
      <div
        style={{ marginTop: 20, textAlign: "left" }}
        className={`${profileClasses.whiteBorderedBox} ${profileClasses.fullWidth}`}
      >
        <RowLabel
          name={"Major"}
          value={profile.major}
          icon={<BookIcon />}
          shouldShowBottonLine
        />
        {profile.gender && (
          <RowLabel
            name={"Gender"}
            value={profile.gender}
            icon={<GenderIcon />}
            shouldShowBottonLine
          />
        )}
        {profile.ethnicity && (
          <RowLabel
            name={"Ethnicity"}
            value={profile.ethnicity}
            icon={<EthniticyIcon />}
            shouldShowBottonLine
          />
        )}
        {profile.country !== "United States" &&
          profile.country !== "United States of America" && (
            <RowLabel
              name={"Country"}
              value={profile.country}
              icon={<CountryIcon />}
              shouldShowBottonLine
            />
          )}
        {profile.usState && (
          <RowLabel
            name={"State"}
            value={profile.usState}
            icon={<StateIcon />}
            shouldShowBottonLine
          />
        )}
        {profile.city && (
          <RowLabel
            name={"City"}
            value={profile.city}
            icon={<CityIcon />}
            shouldShowBottonLine
          />
        )}
      </div>
      <Button
        disableRipple
        onClick={props.openPdf}
        style={{
          border: "1px solid white",
          maxWidth: 250,
          display: "flex",
          margin: "32px auto",
          textTransform: "none",
          fontSize: 18,
          fontFamily: "RedHatDisplay-SemiBold",
          width: "100%",
          color: "white",
          borderRadius: 12,
          height: 55,
        }}
      >
        <WhiteSupplementIcon style={{ marginRight: 12 }} />
        Application PDF
      </Button>
    </div>
  );
}

type CardProps = {
  generalProfile: GeneralProfile;
};

function Card(props: CardProps) {
  const profileClasses = profileStyles();
  const profile = props.generalProfile;

  return (
    <div
      style={{ textAlign: "left" }}
      className={`${profileClasses.whiteBorderedBox} ${profileClasses.fullWidth}`}
    >
      <Grid container alignItems="center" style={{ padding: 15 }}>
        <Grid item xs={3} sm={4}>
          <div className={profileClasses.pageVisibilityByWidth}>
            <Avatar
              style={{
                border: "1px solid rgba(255, 255, 255, 0.8)",
                backgroundColor: "rgba(255, 255, 255, 0.25)",
                width: 55,
                height: 55,
              }}
            >
              <AvatarIcon className={profileClasses.pageVisibilityByWidth} />
            </Avatar>
          </div>
          <div className={profileClasses.smallScreen}>
            <Avatar
              style={{
                backgroundColor: "white",
                width: 60,
                height: 60,
              }}
            >
              <BlueAvatarIcon className={profileClasses.smallScreen} />
            </Avatar>
          </div>
        </Grid>
        <Grid item xs={9} sm={8} style={{ position: "relative" }}>
          <Typography
            style={{
              fontWeight: "bold",
              fontFamily: "RedHatDisplay",
              fontSize: 18,
            }}
          >
            {profile.userName}
          </Typography>
          <Typography
            style={{ fontFamily: "RedHatDisplay", fontSize: 16, marginTop: 5 }}
          >
            {profile.year}
          </Typography>
        </Grid>
      </Grid>
      <div className={profileClasses.enrolledAt}>
        <div
          className={profileClasses.gradientLine}
          style={{ bottom: 0 }}
        ></div>
        <div
          style={{ padding: 15 }}
          className={profileClasses.largeScreenWithBlock}
        >
          <Typography
            style={{
              fontFamily: "RedHatDisplay",
              fontWeight: "bold",
              fontSize: 16,
              textAlign: "left",
              overflowWrap: "break-word",
            }}
          >
            {profile.shortSchoolName}
          </Typography>
        </div>
        <div
          className={profileClasses.smallScreenWithFlex}
          style={{ alignItems: "center", padding: 15 }}
        >
          <SchoolIcon
            style={{ fontSize: 20, marginRight: 10 }}
            className={profileClasses.pageVisibilityByWidth}
          />
          <BlueSchoolIcon
            style={{ fontSize: 20, marginRight: 10 }}
            className={profileClasses.smallScreen}
          />
          <Typography
            className={profileClasses.fontColorByScreenSize}
            style={{
              fontFamily: "RedHatDisplay-Medium",
              fontSize: 16,
              marginRight: 15,
              flexBasis: 90,
            }}
          >
            Enrolled at
          </Typography>
          <Typography
            style={{
              fontFamily: "RedHatDisplay",
              fontWeight: "bold",
              fontSize: 16,
              textAlign: "right",
              overflowWrap: "break-word",
            }}
          >
            {profile.shortSchoolName}
          </Typography>
        </div>
        <div className={profileClasses.gradientLine} style={{ top: 0 }}></div>
      </div>
      <div
        style={{
          fontFamily: "RedHatDisplay-Medium",
          fontSize: 14,
          padding: 15,
        }}
      >
        {profile.introduction}
      </div>
    </div>
  );
}

export type LabelProps = {
  name: string;
  value: string;
};

export function Label(props: LabelProps) {
  const profileClasses = profileStyles();
  return (
    <div
      className={`${profileClasses.profileLabel} ${profileClasses.fullWidth}`}
    >
      <Typography
        style={{
          fontFamily: "RedHatDisplay",
          fontWeight: "bold",
          fontSize: 20,
          marginTop: 15,
        }}
      >
        {props.value}
      </Typography>
      <Typography
        style={{
          fontFamily: "RedHatDisplay-SemiBold",
          fontSize: 16,
          marginBottom: 10,
        }}
      >
        {props.name}
      </Typography>
    </div>
  );
}

export type RowLabelProps = {
  name: string;
  value: string;
  shouldShowBottonLine: boolean;
  icon: React.ReactNode;
};

export function RowLabel(props: RowLabelProps) {
  const profileClasses = profileStyles();
  return (
    <div
      style={{ position: "relative", padding: "15px 15px" }}
      className={`${profileClasses.fullWidth}`}
    >
      {props.shouldShowBottonLine && (
        <div className={profileClasses.gradientLine} style={{ bottom: 0 }} />
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: "0 0 24px", marginRight: "10px" }}>
          {props.icon}
        </div>
        <Typography
          className={profileClasses.rowLabelTitle}
          style={{
            fontFamily: "RedHatDisplay-Medium",
            flexGrow: 0,
            flexShrink: 0,
            fontSize: 16,
          }}
        >
          {props.name}
        </Typography>
        <Typography
          style={{
            fontFamily: "RedHatDisplay-SemiBold",
            fontSize: 16,
            flexBasis: "52%",
            textAlign: "right",
            flexGrow: 1,
            marginRight: 5,
          }}
        >
          {props.value}
        </Typography>
      </div>
    </div>
  );
}

export default LeftPanel;
