import React, { useState, useEffect } from "react";
import { PDFDocumentProxy } from "pdfjs-dist/webpack";
import * as PDFJS from "pdfjs-dist/webpack";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PageTitle from "../general/PageTitle";
import { RectWithId } from "../upload/ImageEditor";
import { FileWithPages } from "../general/Stepper";
import { Redirect } from "react-router-dom";
import * as Sentry from "@sentry/browser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: "0px 15px",
    },
    pageContainer: {
      margin: "10px",
      height: "calc(100% - 20px)",
    },
    page: {
      margin: "5%",
      width: "90%",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    clickButton: {
      position: "absolute",
      marginTop: "5px",
      marginLeft: "5px",
      color: theme.palette.primary.main,
    },
    imageHeader: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: "300px",
      },
    },
  })
);

export interface Page {
  purePage?: string;
  renderablePage?: string;
  selected: boolean;
  rects: Array<RectWithId>;
  types: Array<string>;
}

interface Props {
  disableBack: boolean;
  handleBack: () => void;
  handleNext: () => void;
  nextButtonText: string;
  files: Array<FileWithPages>;
  shouldProceedToNext?: boolean;
  setShouldProceedToNext?: (value: boolean) => void;
  shouldProceedToPrevious?: boolean;
  setShouldProceedToPrevious?: (value: boolean) => void;
}

function SelectPagesPage(props: Props) {
  const classes = useStyles();
  //const [pages, setPages] = useState<Array<Page>>(concatedPages);
  const [filesWithPages, setFilesWithPages] = useState<Array<FileWithPages>>(
    props.files
  );
  const [redirect, setRedirect] = useState<JSX.Element | null>(null);
  var concatedPages: Array<Page> = [];
  filesWithPages.forEach((file) => {
    concatedPages = concatedPages.concat(
      file.pages.filter((page) => page.purePage)
    );
  });

  const onPageLoadSuccess = (newFiles: Array<FileWithPages>) => {
    setFilesWithPages(newFiles);
  };

  useEffect(() => {
    if (props.files === null || props.files.length === 0) {
      return;
    }
    const unrendered_files = props.files.filter(
      (file) => file.pages.length === 0
    );
    if (unrendered_files.length !== 0) {
      getImageFromPdf(props.files, onPageLoadSuccess, setRedirect);
    } else {
      var shouldRender =
        props.files.filter(
          (file) => file.pages.filter((page) => !page.purePage).length !== 0
        ).length !== 0;
      if (shouldRender) {
        getImageFromPdf(props.files, onPageLoadSuccess, setRedirect);
      }
    }
  }, [props.files, concatedPages.length]);

  useEffect(() => {
    if (
      props.shouldProceedToPrevious === true &&
      props.setShouldProceedToPrevious
    ) {
      props.handleBack();
      props.setShouldProceedToPrevious(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToPrevious]);

  useEffect(() => {
    if (props.shouldProceedToNext === true && props.setShouldProceedToNext) {
      props.handleNext();
      props.setShouldProceedToNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToNext]);

  if (redirect) {
    return redirect;
  }

  let data: React.ReactNode = null;

  if (concatedPages !== null && concatedPages.length !== 0) {
    let grids = concatedPages.map((dataUrl, index) => {
      return (
        <Grid key={index} item xs={12} md={12} lg={6} xl={6}>
          <div className={classes.pageContainer}>
            <img
              key={index}
              src={dataUrl.purePage}
              className={classes.page}
              alt=""
            ></img>
          </div>
        </Grid>
      );
    });
    data = (
      <Grid container spacing={0}>
        {grids}
      </Grid>
    );
  }

  return (
    <div>
      <PageTitle title="Review Proof of Acceptance" />
      <div className={classes.contentContainer}>
        {data}
        <div style={{ padding: "20px 0px" }}>
          <Button
            disabled={props.disableBack}
            onClick={props.handleBack}
            className={classes.backButton}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.handleNext()}
          >
            {props.nextButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
}

function getImageFromPdf(
  files: Array<FileWithPages>,
  onLoadSuccess: (newFiles: Array<FileWithPages>) => void,
  setRedirect: (redirect: JSX.Element) => void
) {
  let loadImagePromises: Array<Promise<Array<Page>> | null> = [];
  let parsedFiles: Array<FileWithPages> = Array.from(files);
  for (let i = 0; i < files.length; i++) {
    const unrendered_pages = files[i].pages.filter((page) => !page.purePage);
    if (
      files[i].file &&
      files[i].pages.length !== 0 &&
      unrendered_pages.length === 0
    ) {
      loadImagePromises.push(null);
    } else {
      const loadImagePromise = loadImage(files[i]);
      loadImagePromises.push(loadImagePromise);
    }
  }
  Promise.all(loadImagePromises)
    .then((singlePdfPages) => {
      for (let i = 0; i < singlePdfPages.length; i++) {
        const filePages = singlePdfPages[i];
        if (filePages !== null) {
          parsedFiles[i].pages = filePages;
        }
      }
      onLoadSuccess(parsedFiles);
    })
    .catch((error) => {
      Sentry.captureException(error, {
        extra: {
          //'files': files
        },
      });
      setRedirect(<Redirect to="/503" />);
    });
}

function loadImage(file: FileWithPages): Promise<Array<Page>> {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    reader.onerror = (error) => {
      reject("Failed to load images from the file.");
    };
    if (file.file.type === "image/jpeg" || file.file.type === "image/png") {
      reader.onloadend = () => {
        resolve([
          {
            purePage: reader.result as string,
            selected: file.pages.length > 0 ? file.pages[0].selected : true,
            rects: file.pages.length > 0 ? file.pages[0].rects : [],
            types: file.pages.length > 0 ? file.pages[0].types : [],
          },
        ]);
      };
      reader.readAsDataURL(file.file);
      return;
    }
    reader.onloadend = () => {
      PDFJS.getDocument(reader.result as string).promise.then(
        (pdf) => {
          const pages = [];
          for (let i = 0; i < pdf.numPages; i++) {
            pages.push(
              renderPage(
                i + 1,
                pdf,
                file.pages.length === pdf.numPages ? file.pages[i] : null
              )
            );
          }
          Promise.all(pages)
            .then((resolvedPages) => {
              resolve(resolvedPages);
            })
            .catch((error) => {
              Sentry.captureException(error, {
                extra: {
                  //'file': file
                },
              });
              reject(error);
            });
        },
        function (reason) {
          // PDF loading error
          Sentry.captureException(new Error(reason));
          reject(reason);
        }
      );
    };
    reader.readAsDataURL(file.file);
  });
}

function renderPage(
  num: number,
  pdf: PDFDocumentProxy,
  existing_page: Page | null
): Promise<Page> {
  return new Promise((resolve, reject) => {
    pdf.getPage(num).then(
      (page) => {
        const scale = 5.0;
        const viewport = page.getViewport({ scale });
        const canvas = document.createElement("canvas");
        const canvasContext =
          canvas.getContext("2d") || new CanvasRenderingContext2D();
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        canvas.style.height = "100%";
        canvas.style.width = "100%";
        page
          .render({
            canvasContext,
            viewport,
          })
          .promise.then(
            () => {
              const result = {
                purePage: canvas.toDataURL("image/jpeg"),
                selected: existing_page ? existing_page.selected : true,
                rects: existing_page ? existing_page.rects : [],
                types: existing_page ? existing_page.types : [],
              };
              resolve(result);
            },
            function (reason) {
              Sentry.captureException(new Error(reason));
              reject(reason);
            }
          );
      },
      function (reason) {
        Sentry.captureException(new Error(reason));
        reject(reason);
      }
    );
  });
}

export default SelectPagesPage;
