import React, { useEffect, useState } from "react";
import PageTitle from "../general/PageTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import SearchableSelector, { Option } from "../general/SearchableSelector";
import {
  FilterLineItem,
  GeneralApplicationFilter,
} from "../../types/serverSideTypes";
import {
  GeneralApplicationInfo,
  Sat,
  Act,
  Honor,
  ExtracurricularActivity,
  Essay,
  ALevelTest,
  CollegeLevelTest,
  SatSubjectTest,
} from "../../types/uploadTypes";
import { FilterList, OriginalFilterItem } from "../../types/types";
import InputAdornment from "@material-ui/core/InputAdornment";
import Spacing from "../general/Spacing";
import RadioOptions from "../general/RadioOptions";
import Button from "@material-ui/core/Button";
import SnackBar from "../general/SnackBar";
import axios from "axios";
import InputTextField from "./profile/InputTextField";
import * as Sentry from "@sentry/browser";
import ActivitiesTable from "../general/ExtracurricularActivityTable";
import SatSubjectTestTable from "../general/SatSubjectTestTable";
import CollegeLevelTestTable from "../general/CollegeLevelTable";
import ALevelTestTable from "../general/ALevelTable";
import HonorsTable from "../general/HonorsTable";
import Grid from "@material-ui/core/Grid";
import EssaysTable from "../general/EssaysTable";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light,
      //marginBottom: '5%',
    },
    question: {
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      padding: "0px 5px",
    },
    questionBlock: {
      textAlign: "left",
      [theme.breakpoints.up("sm")]: {
        padding: "20px 40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px 30px",
      },
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    buttonText: {
      fontFamily: "SuperaGothic",
    },
  })
);

export interface SimpleCaseInfo {
  school_name: string;
  year: string;
  id: number;
  status: string;
}

interface Props {
  caseInfo?: GeneralApplicationInfo;
  disableBack: boolean;
  handleNext: (caseInfo: GeneralApplicationInfo) => void;
  //handleEnterInfo: (caseInfo: GeneralApplicationInfo) => void,
  nextButtonText: string;
  shouldProceedToNext: boolean;
  setShouldProceedToNext: (value: boolean) => void;
  //loadFilesByIdAndStatus: (caseId: number, status: string, callback: (caseId: number, status: string) => void, failureCallback: () => void) => void
}

function convertFilterFormat(filter: Array<FilterList>, title: string) {
  return filter.find((item) => item.title === title)?.listStatus || [];
}

export interface CaseIdentification {
  caseId: number;
  status: string;
}

function validateSat(sat: Sat): string | undefined {
  if (!sat.math) {
    return "Please enter your SAT Math score";
  }
  if (!sat.verbal) {
    return "Please enter your SAT Verbal score";
  }
  if (sat.math < 200 || sat.math > 800) {
    return "SAT Math score must be between 200 and 800";
  }
  if (sat.verbal < 200 || sat.verbal > 800) {
    return "SAT Verbal score must be between 200 and 800";
  }
  if (sat.writing && (sat.writing < 200 || sat.writing > 800)) {
    return "SAT Writing score must be between 200 and 800";
  }
  return undefined;
}

function validateAct(act: Act): string | undefined {
  if (!act.math) {
    return "Please enter your ACT Math score";
  }
  if (!act.english) {
    return "Please enter your ACT English score";
  }
  if (!act.science) {
    return "Please enter your ACT Science score";
  }
  if (!act.reading) {
    return "Please enter your ACT Reading score";
  }
  if (act.math < 1 || act.math > 36) {
    return "ACT Math score must be between 1 and 36";
  }
  if (act.english < 1 || act.english > 36) {
    return "ACT English score must be between 1 and 36";
  }
  if (act.science < 1 || act.science > 36) {
    return "ACT Science score must be between 1 and 36";
  }
  if (act.reading < 1 || act.reading > 36) {
    return "ACT Reading score must be between 1 and 36";
  }
  return undefined;
}

function validateGPA(unweightedGpa: string, weightedGpa: string | undefined) {
  if (Number(unweightedGpa) === undefined) {
    return "Unweighted GPA must be between 0 and 4.0";
  }
  if (Number(unweightedGpa) < 0 || Number(unweightedGpa) > 4.0) {
    return "Unweighted GPA must be between 0 and 4.0";
  }
  if (weightedGpa) {
    if (Number(weightedGpa) === undefined) {
      return "Weighted GPA must be between 0 and 5.0";
    }
    if (Number(unweightedGpa) < 0 || Number(unweightedGpa) > 5.0) {
      return "Weighted GPA must be between 0 and 5.0";
    }
  }
  return undefined;
}

function validateClassRank(classRank: number | undefined) {
  if (!classRank) {
    return undefined;
  }
  if (Number(classRank) === undefined) {
    return "Class rank must be between 1 and 100";
  }
  if (Number(classRank) < 1 || Number(classRank) > 100) {
    return "Class rank must be between 1 and 100";
  }
}

function validateEssays(essays: Array<Essay>) {
  for (let i = 0; i < essays.length; i++) {
    const test = essays[i];
    if (!test.essayTopicId && !test.title) {
      return "Please pick an essay topic or manually type in your essay's topic";
    }
    if (!test.value) {
      return "Please make sure you have pasted your essays";
    }
  }
}

function validateActivities(activities: Array<ExtracurricularActivity>) {
  for (let i = 0; i < activities.length; i++) {
    const test = activities[i];
    if (!test.name || !test.yearsInvolved) {
      return "Please make sure you have indicated names and years involved for all your extracurriculr activities";
    }
  }
}

function validateHonors(honors: Array<Honor>) {
  for (let i = 0; i < honors.length; i++) {
    const test = honors[i];
    if (!test.honorLevelId || !test.description) {
      return "Please make sure you have indicated levels and descriptions for all your honors";
    }
  }
}

function validateALevelTests(aLevelTests: Array<ALevelTest>) {
  for (let i = 0; i < aLevelTests.length; i++) {
    const test = aLevelTests[i];
    if (!test.aLevelTestNameId || !test.aLevelTestScoreId) {
      return "Please make sure you have selected subjects and entered scores for A Level tests";
    }
  }
  return undefined;
}

function validateCollegeLevelTests(
  collegeLevelTests: Array<CollegeLevelTest>,
  collegeLevelTestNames: Array<FilterLineItem>
) {
  for (let i = 0; i < collegeLevelTests.length; i++) {
    const test = collegeLevelTests[i];
    if (!test.collegeLevelTestNameId || !test.collegeLevelTestScoreId) {
      return "Please make sure you have selected subjects and entered scores for AP and IB tests";
    }

    const test_type = collegeLevelTestNames.find(
      (name) => name.id === test.collegeLevelTestNameId
    )?.type;
    if (test_type === "AP") {
      if (!["1", "2", "3", "4", "5"].includes(test.collegeLevelTestScoreId)) {
        return "AP test scores must be betwen 1 and 5";
      }
    }
  }
  return undefined;
}

function validateSatSubjectTests(satSubjectTests: Array<SatSubjectTest>) {
  for (let i = 0; i < satSubjectTests.length; i++) {
    const test = satSubjectTests[i];
    if (!test.satSubjectTestNameId || !test.score) {
      return "Please make sure you have selected subjects and entered scores for SAT subject tests";
    }
    if (test.score > 800 || test.score < 200) {
      return "SAT Subject test scores must be between 200 and 800";
    }
  }
  return undefined;
}

function EnterInformationPage(props: Props) {
  const classes = useStyles();

  const [schoolId, setSchoolId] = useState<string | undefined>(
    props.caseInfo?.schoolId
  );
  const [yearId, setYearId] = useState<string | undefined>(
    props.caseInfo?.yearId
  );
  const [majorId, setMajorId] = useState<string | undefined>(
    props.caseInfo?.majorId
  );
  const [ethnicityId, setEthnicityId] = useState<string | undefined>(
    props.caseInfo?.ethnicityId
  );
  const [usStateId, setUsStateId] = useState<string | undefined>(
    props.caseInfo?.usStateId
  );
  const [genderId, setGenderId] = useState<string | undefined>(
    props.caseInfo?.genderId
  );
  const [countryId, setCountryId] = useState<string | undefined>(
    props.caseInfo?.countryId
  );
  const [title, setTitle] = useState<string | undefined>(props.caseInfo?.title);
  const [city, setCity] = useState<string | undefined>(props.caseInfo?.city);
  const [unweightedGpa, setUnweightedGpa] = useState<string | undefined>(
    props.caseInfo?.unweightedGpa
  );
  const [shouldCollectStructureData, setShouldCollectStructureData] = useState<
    boolean | null
  >(null);
  const [weightedGpa, setWeightedGpa] = useState<string | undefined>(
    props.caseInfo?.weightedGpa
  );
  const [classRank, setClassRank] = useState<number | undefined>(
    props.caseInfo?.classRank
  );
  //const [additionalInformation, setAdditionalInformation] = useState<string | undefined>(props.caseInfo?.additionalInformation);
  const [sat, setSat] = useState<Sat | undefined>(props.caseInfo?.sat);
  const [act, setAct] = useState<Act | undefined>(props.caseInfo?.act);

  const [aLevelTests, setALevelTests] = useState<Array<ALevelTest>>(
    props.caseInfo?.aLevelTests || []
  );
  const [collegeLevelTests, setCollegeLevelTests] = useState<
    Array<CollegeLevelTest>
  >(props.caseInfo?.collegeLevelTests || []);
  const [satSubjectTests, setSatSubjectTests] = useState<Array<SatSubjectTest>>(
    props.caseInfo?.satSubjectTests || []
  );

  const [alternativeFilter, setAlternativeFilter] =
    useState<GeneralApplicationFilter | null>(null);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [satTaken, setSatTaken] = useState<boolean>(
    props.caseInfo?.sat !== undefined
  );
  const [actTaken, setActTaken] = useState<boolean>(
    props.caseInfo?.act !== undefined
  );

  const [essays, setEssays] = useState<Array<Essay>>(
    props.caseInfo?.essays || []
  );
  const [activities, setActivities] = useState<Array<ExtracurricularActivity>>(
    props.caseInfo?.activities || []
  );
  const [honors, setHonors] = useState<Array<Honor>>(
    props.caseInfo?.honors || []
  );

  useEffect(() => {
    axios("api/v1/get_general_profile_filters", {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => response.data)
      .then((data) => {
        var downloadedFilter = data.data as Array<FilterList>;
        if (downloadedFilter) {
          downloadedFilter = downloadedFilter.map((item) => {
            if (item.title === "Ethnicities" || item.title === "Genders") {
              return {
                title: item.title,
                listStatus: addOtherOption(item.listStatus),
              };
            }
            return item;
          });
          const transformedFilter = {
            schools: convertFilterFormat(downloadedFilter, "Schools"),
            majors: convertFilterFormat(downloadedFilter, "Majors"),
            years: convertFilterFormat(downloadedFilter, "Years"),
            ethnicities: convertFilterFormat(downloadedFilter, "Ethnicities"),
            genders: convertFilterFormat(downloadedFilter, "Genders"),
            countries: convertFilterFormat(downloadedFilter, "Countries"),
            essay_topics: convertFilterFormat(downloadedFilter, "Essay Topics"),
            honor_levels: convertFilterFormat(downloadedFilter, "Honor Levels"),
            sat_subject_test_names: convertFilterFormat(
              downloadedFilter,
              "SAT Subject Test Names"
            ),
            us_states: convertFilterFormat(downloadedFilter, "US States"),
            a_level_test_names: convertFilterFormat(
              downloadedFilter,
              "A Level Test Names"
            ),
            a_level_test_scores: convertFilterFormat(
              downloadedFilter,
              "A Level Test Scores"
            ),
            college_level_test_names: convertFilterFormat(
              downloadedFilter,
              "College Level Test Names"
            ),
            college_level_test_scores: convertFilterFormat(
              downloadedFilter,
              "College Level Test Scores"
            ),
          };
          setAlternativeFilter(transformedFilter);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        // TODO: add error logger
        // TODO: add banner
      });

    axios("api/v1/get_combined_experiment_group", {
      method: "POST",
      withCredentials: true,
      data: {
        experiment_name: "frontend_collect_structured_data",
      },
    })
      .then((response) => response.data)
      .then((data) => {
        var groupName = data.data;
        if (groupName) {
          setShouldCollectStructureData(groupName === "control" ? false : true);
        } else {
          setShouldCollectStructureData(false);
        }
      })
      .catch((error) => {
        setShouldCollectStructureData(false);
        Sentry.captureException(error);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const radioOptions = [
    {
      key: "yes",
      value: "Yes",
    },
    {
      key: "no",
      value: "No",
    },
  ];

  const handleSelectSchool = (key: string | null) => {
    setSchoolId(key || undefined);
  };

  const handleSelectYear = (key: string | null) => {
    setYearId(key || undefined);
  };

  const handleSelectEthnicity = (key: string | null) => {
    setEthnicityId(key || undefined);
  };

  const handleSelectGender = (key: string | null) => {
    setGenderId(key || undefined);
  };

  const onCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
  };

  const onUnweightedGpaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUnweightedGpa(event.target.value);
  };

  const onClassRankChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && !Number(event.target.value)) {
      return;
    }
    let val: number | undefined = parseInt(event.target.value);
    if (val > 100) {
      val = 100;
    }
    if (val < 1) {
      val = 1;
    }
    if (event.target.value === "") {
      val = undefined;
    }
    setClassRank(val);
  };

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const onWeightedGpaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeightedGpa(event.target.value);
  };

  const shouldShowUsStates =
    alternativeFilter?.countries.find(
      (country) => country.name === "United States of America"
    )?.id === countryId;

  const handleNext = () => {
    if (schoolId === undefined) {
      setMessage("Please specify which school you are enrolled in.");
      return;
    }
    if (!yearId) {
      setMessage("Please specify your class.");
      return;
    }
    if (!countryId) {
      setMessage("Please specify your country.");
      return;
    }
    if (shouldShowUsStates && !usStateId) {
      setMessage("Please specify the state you are located in.");
      return;
    }
    if (!unweightedGpa) {
      setMessage("Please specify the unweighted GPA.");
      return;
    }

    let notification = validateGPA(unweightedGpa, weightedGpa);
    if (notification) {
      setMessage(notification);
      return;
    }

    notification = validateClassRank(classRank);
    if (notification) {
      setMessage(notification);
      return;
    }

    if (!title) {
      setMessage("Please specify a title for your profile.");
      return;
    }
    if (majorId === undefined) {
      setMessage("Please specify your prospective major.");
      return;
    }

    if (sat) {
      notification = validateSat(sat);
      if (notification) {
        setMessage(notification);
        return;
      }
    }

    if (act) {
      notification = validateAct(act);
      if (notification) {
        setMessage(notification);
        return;
      }
    }

    notification = validateSatSubjectTests(satSubjectTests);
    if (notification) {
      setMessage(notification);
      return;
    }

    notification = validateCollegeLevelTests(
      collegeLevelTests,
      alternativeFilter?.college_level_test_names || []
    );
    if (notification) {
      setMessage(notification);
      return;
    }

    notification = validateALevelTests(aLevelTests);
    if (notification) {
      setMessage(notification);
      return;
    }

    notification = validateActivities(activities);
    if (notification) {
      setMessage(notification);
      return;
    }

    notification = validateHonors(honors);
    if (notification) {
      setMessage(notification);
      return;
    }

    notification = validateEssays(essays);
    if (notification) {
      setMessage(notification);
      return;
    }

    props.handleNext({
      schoolId,
      yearId,
      majorId,
      countryId,
      ethnicityId: ethnicityId === "-1" ? undefined : ethnicityId,
      usStateId,
      genderId: genderId === "-1" ? undefined : genderId,
      title,
      city,
      weightedGpa,
      unweightedGpa,
      additionalInformation: undefined,
      sat,
      act,
      aLevelTests,
      collegeLevelTests,
      satSubjectTests,
      essays,
      activities,
      honors,
      classRank,
    });
  };

  useEffect(() => {
    if (props.shouldProceedToNext) {
      handleNext();
      props.setShouldProceedToNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldProceedToNext]);

  const callBack = () => {
    setMessage(undefined);
  };

  /*const loadCaseById = (caseId: number, status: string, callback: (caseId: number, status: string) => void) => {
        props.loadFilesByIdAndStatus(caseId, status, callback, () => {});
    }*/

  const handleSatTaken = (value: string) => {
    setSatTaken(value === "Yes" ? true : false);
    if (value === "Yes") {
      setSat({});
    } else {
      setSat(undefined);
    }
  };

  const handleAtTaken = (value: string) => {
    setActTaken(value === "Yes" ? true : false);
    if (value === "Yes") {
      setAct({});
    } else {
      setAct(undefined);
    }
  };

  const handleSatMath = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && !Number(event.target.value)) {
      return;
    }
    let val: Number | undefined = Number(event.target.value);
    if (val > 800) {
      val = 800;
    }
    if (val >= 100 && val < 200) {
      val = 200;
    }
    if (event.target.value === "") {
      val = undefined;
    }
    const new_value = Object.assign({}, sat, {
      math: val,
    });
    setSat(new_value);
  };

  const handleSatVerbal = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && !Number(event.target.value)) {
      return;
    }
    let val: Number | undefined = Number(event.target.value);
    if (val > 800) {
      val = 800;
    }
    if (val >= 100 && val < 200) {
      val = 200;
    }
    if (event.target.value === "") {
      val = undefined;
    }
    const new_value = Object.assign({}, sat, {
      verbal: val,
    });
    setSat(new_value);
  };

  const handleSatWriting = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && !Number(event.target.value)) {
      return;
    }
    let val: Number | undefined = Number(event.target.value);
    if (val > 800) {
      val = 800;
    }
    if (val >= 100 && val < 200) {
      val = 200;
    }
    if (event.target.value === "") {
      val = undefined;
    }
    const new_value = Object.assign({}, sat, {
      writing: val,
    });
    setSat(new_value);
  };

  const handleActMath = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && !Number(event.target.value)) {
      return;
    }
    const new_value = Object.assign({}, act, {
      math: Number(event.target.value),
    });
    setAct(new_value);
  };

  const handleActEnglish = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && !Number(event.target.value)) {
      return;
    }
    const new_value = Object.assign({}, act, {
      english: Number(event.target.value),
    });
    setAct(new_value);
  };

  const handleActReading = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && !Number(event.target.value)) {
      return;
    }
    const new_value = Object.assign({}, act, {
      reading: Number(event.target.value),
    });
    setAct(new_value);
  };

  const handleActScience = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && !Number(event.target.value)) {
      return;
    }
    const new_value = Object.assign({}, act, {
      science: Number(event.target.value),
    });
    setAct(new_value);
  };

  const handleCountryChange = (value: string | undefined) => {
    setCountryId(value);
    if (!value) {
      setUsStateId(undefined);
    }
  };

  const radioQuestion = (
    <RadioOptions
      options={radioOptions}
      handleSelect={handleSatTaken}
      value={satTaken ? "Yes" : "No"}
    />
  );
  const actRadioQuestion = (
    <RadioOptions
      options={radioOptions}
      handleSelect={handleAtTaken}
      value={actTaken ? "Yes" : "No"}
    />
  );

  if (shouldCollectStructureData === undefined) {
    return (
      <div style={{ height: "100%" }} className={classes.root}>
        <CircularProgress color="primary" style={{ margin: "auto" }} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <PageTitle title="Tell Us About Yourself" />
      <div className={classes.questionBlock}>
        {GetQuestionTitle(
          "Let’s get started with some basic information about you and your college application."
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle(
          "*1. Which college are you enrolled in or plan to enroll in?"
        )}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(
            alternativeFilter !== null ? alternativeFilter?.schools : []
          ),
          handleSelectSchool,
          schoolId || null,
          "Stanford University"
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle(
          "*2. Which class are you? (Class of 2019 means that you graducated from college in 2019.)"
        )}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(alternativeFilter !== null ? alternativeFilter?.years : []),
          handleSelectYear,
          yearId || null,
          "Class of 2019"
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("3. What is your gender?")}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(
            alternativeFilter !== null ? alternativeFilter?.genders : []
          ),
          handleSelectGender,
          genderId || null,
          "Male"
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("4. What is your ethnicity?")}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(
            alternativeFilter !== null ? alternativeFilter?.ethnicities : []
          ),
          handleSelectEthnicity,
          ethnicityId || null,
          "Asian"
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle(
          "*5. Which country were you located in when the application was submitted?"
        )}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(
            alternativeFilter !== null ? alternativeFilter?.countries : []
          ),
          (key: string | null) => handleCountryChange(key || undefined),
          countryId || null,
          "United States"
        )}
        {shouldShowUsStates && (
          <div>
            <Spacing spacing={20} />
            {GetQuestionTitle("*Which state were you located in?")}
            <Spacing spacing={8} />
            {GetDropdownList(
              getFilter(
                alternativeFilter !== null ? alternativeFilter?.us_states : []
              ),
              (key: string | null) => setUsStateId(key || undefined),
              usStateId || null,
              "California"
            )}
          </div>
        )}
        <Spacing spacing={20} />
        {GetQuestionTitle("Which city were you residing in?")}
        <Spacing spacing={8} />
        <InputTextField
          value={city || ""}
          onChange={onCityChange}
          placeholder="San Francisco"
          style={{ maxWidth: "320px", minWidth: "240px", width: "30%" }}
        />
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("*6. What is your unweighted GPA from high school?")}
        <Spacing spacing={8} />
        <InputTextField
          value={unweightedGpa || ""}
          onChange={onUnweightedGpaChange}
          placeholder="3.6"
          style={{ maxWidth: "320px", minWidth: "240px", width: "30%" }}
        />
        <Spacing spacing={20} />
        {GetQuestionTitle(
          "What is your weighted GPA from high school (if applicable)?"
        )}
        <Spacing spacing={8} />
        <InputTextField
          value={weightedGpa || ""}
          onChange={onWeightedGpaChange}
          placeholder="3.9"
          style={{ maxWidth: "320px", minWidth: "240px", width: "30%" }}
        />
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle(
          "*7. In one sentence, introduce yourself. This will be included in your public CollegeSharing profile."
        )}
        <Spacing spacing={8} />
        <InputTextField
          value={title || ""}
          onChange={onTitleChange}
          placeholder="Asian girl interested in creative writing + STEM"
          multiline
          minRows={3}
          style={{ maxWidth: "320px", minWidth: "240px", width: "30%" }}
        />
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle(
          "*8. What is your prospective major when applying to colleges? Please select the primary one if there are multiple."
        )}
        <Spacing spacing={8} />
        {GetDropdownList(
          getFilter(
            alternativeFilter !== null ? alternativeFilter?.majors : []
          ),
          (key: string | null) => setMajorId(key || undefined),
          majorId || null,
          "Computer Science"
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("9. What is your class rank in percentage?")}
        <Spacing spacing={8} />
        <InputTextField
          value={classRank ? String(classRank) : ""}
          onChange={onClassRankChange}
          placeholder="20"
          iconAtEnd={true}
          icon={
            <InputAdornment position="start" className={classes.buttonText}>
              %
            </InputAdornment>
          }
          style={{ maxWidth: "320px", minWidth: "240px", width: "30%" }}
        />
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("*10. Did you report your SAT score?")}
        <Spacing spacing={8} />
        {radioQuestion}
        {satTaken && (
          <div>
            <Spacing spacing={16} />
            <Grid container>
              <Grid
                item
                xs={3}
                sm={2}
                md={1}
                lg={1}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  minWidth: 100,
                }}
              >
                {GetQuestionTitle("Math")}
              </Grid>
              <Grid item xs={9} sm={4} md={4} lg={2}>
                <InputTextField
                  value={sat?.math ? String(sat?.math) : ""}
                  onChange={handleSatMath}
                  style={{ maxWidth: "240px" }}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {satTaken && (
          <div>
            <Spacing spacing={16} />
            <Grid container>
              <Grid
                item
                xs={3}
                sm={2}
                md={1}
                lg={1}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  minWidth: 100,
                }}
              >
                {GetQuestionTitle("Verbal")}
              </Grid>
              <Grid item xs={9} sm={4} md={4} lg={2}>
                <InputTextField
                  value={sat?.verbal ? String(sat?.verbal) : ""}
                  onChange={handleSatVerbal}
                  style={{ maxWidth: "240px" }}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {satTaken && (
          <div>
            <Spacing spacing={16} />
            <Grid container>
              <Grid
                item
                xs={3}
                sm={2}
                md={1}
                lg={1}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  minWidth: 100,
                }}
              >
                {GetQuestionTitle("Writing")}
              </Grid>
              <Grid item xs={9} sm={4} md={4} lg={2}>
                <InputTextField
                  value={sat?.writing ? String(sat?.writing) : ""}
                  placeholder="Optional"
                  onChange={handleSatWriting}
                  style={{ maxWidth: "240px" }}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("*11. Did you report your ACT score?")}
        <Spacing spacing={8} />
        {actRadioQuestion}
        {actTaken && (
          <div>
            <Spacing spacing={16} />
            <Grid container>
              <Grid
                item
                xs={3}
                sm={2}
                md={1}
                lg={1}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  minWidth: 100,
                }}
              >
                {GetQuestionTitle("Math")}
              </Grid>
              <Grid item xs={9} sm={4} md={4} lg={2}>
                <InputTextField
                  value={act?.math ? String(act?.math) : ""}
                  onChange={handleActMath}
                  style={{ maxWidth: "240px" }}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {actTaken && (
          <div>
            <Spacing spacing={16} />
            <Grid container>
              <Grid
                item
                xs={3}
                sm={2}
                md={1}
                lg={1}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  minWidth: 100,
                }}
              >
                {GetQuestionTitle("English")}
              </Grid>
              <Grid item xs={9} sm={4} md={4} lg={2}>
                <InputTextField
                  value={act?.english ? String(act?.english) : ""}
                  onChange={handleActEnglish}
                  style={{ maxWidth: "240px" }}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {actTaken && (
          <div>
            <Spacing spacing={16} />
            <Grid container>
              <Grid
                item
                xs={3}
                sm={2}
                md={1}
                lg={1}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  minWidth: 100,
                }}
              >
                {GetQuestionTitle("Reading")}
              </Grid>
              <Grid item xs={9} sm={4} md={4} lg={2}>
                <InputTextField
                  value={act?.reading ? String(act?.reading) : ""}
                  onChange={handleActReading}
                  style={{ maxWidth: "240px" }}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {actTaken && (
          <div>
            <Spacing spacing={16} />
            <Grid container>
              <Grid
                item
                xs={3}
                sm={2}
                md={1}
                lg={1}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  minWidth: 100,
                }}
              >
                {GetQuestionTitle("Science")}
              </Grid>
              <Grid item xs={9} sm={4} md={4} lg={2}>
                <InputTextField
                  value={act?.science ? String(act?.science) : ""}
                  onChange={handleActScience}
                  style={{ maxWidth: "240px" }}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle(
          "12. Please list the SAT subject tests you have taken."
        )}
        <Spacing spacing={8} />
        <SatSubjectTestTable
          tests={satSubjectTests}
          setTests={setSatSubjectTests}
          satSubjectNames={alternativeFilter?.sat_subject_test_names || []}
        />
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("13. What AP tests and IB tests have you taken?")}
        <Spacing spacing={8} />
        <CollegeLevelTestTable
          tests={collegeLevelTests}
          setTests={setCollegeLevelTests}
          satSubjectNames={alternativeFilter?.college_level_test_names || []}
          satSubjectScores={alternativeFilter?.college_level_test_scores || []}
        />
      </div>
      <div className={classes.questionBlock}>
        {GetQuestionTitle("14. Please list any A Level tests you have taken.")}
        <Spacing spacing={8} />
        <ALevelTestTable
          tests={aLevelTests}
          setTests={setALevelTests}
          testNames={alternativeFilter?.a_level_test_names || []}
          testScores={alternativeFilter?.a_level_test_scores || []}
        />
      </div>
      {shouldCollectStructureData && (
        <div className={classes.questionBlock}>
          {GetQuestionTitle(
            "15. What extracurricular activities have you done?"
          )}
          <Spacing spacing={8} />
          <ActivitiesTable
            activities={activities}
            setActivities={setActivities}
          />
        </div>
      )}
      {shouldCollectStructureData && (
        <div className={classes.questionBlock}>
          {GetQuestionTitle(
            "16. Did you earn any honors/rewards in high school? If so, please list them."
          )}
          <Spacing spacing={8} />
          <HonorsTable
            honors={honors}
            setHonors={setHonors}
            honorLevels={alternativeFilter?.honor_levels || []}
          />
        </div>
      )}
      <div className={classes.questionBlock}>
        {shouldCollectStructureData &&
          GetQuestionTitle(
            "17. Please add your personal statements. (You will be asked to add school-specific supplements later.)"
          )}
        {!shouldCollectStructureData &&
          GetQuestionTitle(
            "15. Please add your personal statements. (You will be asked to add school-specific supplements later.)"
          )}
        <Spacing spacing={8} />
        <EssaysTable
          shouldFilterBySchoolId={false}
          mode={"personal_statement"}
          activities={essays}
          setActivities={setEssays}
          essayTopics={alternativeFilter?.essay_topics || []}
        />
      </div>

      <SnackBar message={message} callBack={callBack} />
      <div style={{ padding: "20px 0px" }}>
        <Button variant="contained" color="primary" onClick={handleNext}>
          {props.nextButtonText}
        </Button>
      </div>
    </div>
  );
}

function getFilter(items: Array<FilterLineItem>): Array<Option> {
  const options = items.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });
  return options;
}

function addOtherOption(
  options: Array<OriginalFilterItem>
): Array<OriginalFilterItem> {
  if (!options) {
    return options;
  }
  options.push({ id: "-1", name: "Prefer Not to Say" });
  return options;
}

function GetDropdownList(
  options: Array<Option>,
  handleSelect: (key: string | null) => void,
  value: string | null,
  placeholder?: string
) {
  return (
    <div style={{ minWidth: "240px", maxWidth: "320px", width: "30%" }}>
      <SearchableSelector
        options={options}
        handleSelect={handleSelect}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
}

function GetQuestionTitle(question: string) {
  //const classes = useStyles();
  return (
    <Typography
      variant="body1"
      style={{
        fontFamily: "SuperaGothic",
        fontWeight: "bold",
        padding: "0px 5px",
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      {question}
    </Typography>
  );
}

export default EnterInformationPage;
