import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    generalButton: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.body1.fontSize,
      fontFamily: "SuperaGothic",
      //backgroundColor: theme.palette.primary.main,
      textTransform: "none",
      "&:hover": {
        backgroundColor: "white",
        color: "#0A5961",
      },
      alignItems: "center",
      //padding: '0.3rem 1.5rem',
    },
    zeroPadding: {
      padding: "6px 0px",
    },
  })
);

function CustomizedButton(props: ButtonProps) {
  const classes = useStyles();
  return (
    <Button
      className={classes.generalButton}
      {...props}
      classes={{ text: classes.zeroPadding }}
    >
      {props.children}
    </Button>
  );
}

export default CustomizedButton;
