import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ProfileCard from "../general/ProfileCard";
import PrimaryButton from "../general/PrimaryButton";
import Typography from "@material-ui/core/Typography";
import { SchoolCardContent } from "../../types/types";
import { GetPaginatedSchoolsResponse } from "../../types/serverSideTypes";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "rgba(14,127,138,0.2)",
      [theme.breakpoints.up("sm")]: {
        borderRadius: "100px 0px 0px 0px",
      },
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0px",
      },
    },
    outer: {
      overflow: "auto",
      width: "100%",
      //backgroundColor: '#F5F5F5',
    },
    previewSection: {
      //backgroundColor: '#F5F5F5',
      display: "flex",
      padding: "30px",
      width: "fit-content",
      margin: "0 auto",
    },
    profileCardWrap: {
      padding: "10px",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        width: "300px",
      },
      [theme.breakpoints.up("md")]: {
        width: "350px",
      },
    },
    buttonWrap: {
      paddingBottom: "40px",
      //backgroundColor: '#F5F5F5',
    },
    titleSection: {
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
    spacing: {
      "@media (min-width:800px)": {
        marginTop: "25px",
        marginBottom: "15px",
      },
      "@media (max-width:800px)": {
        marginTop: "5vw",
        marginBottom: "0px",
      },
    },
    leftAlign: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
      },
      [theme.breakpoints.up("sm")]: {
        textAlign: "center",
      },
    },
  })
);

interface Props {
  shouldExcludeButton?: boolean;
}

function PreviewSection(props: Props) {
  const styles = useStyles();
  const [cards, setCards] = useState<Array<SchoolCardContent>>([]);
  useEffect(() => {
    axios("api/v1/get_preview_cases", {
      method: "POST",
      withCredentials: true,
      data: {
        requester_id: 1,
      },
    })
      .then((response) => {
        const data = response.data.data as GetPaginatedSchoolsResponse;
        setCards(data.cases);
      })
      .catch((error) => {
        Sentry.captureException(error);
        // log error if needed
      });
  }, []);
  if (cards.length === 0) {
    return <div></div>;
  }
  const profileCards = cards.map((card) => {
    return (
      <Box key={card.id}>
        <div className={styles.profileCardWrap}>
          <ProfileCard visibleByDefault profile={card} shouldShowAction />
        </div>
      </Box>
    );
  });

  const buttonStyle = {
    //backgroundColor: "rgba(14,127,138,0.00)",
    //color: '#0A5961',
    //border: 'solid 1px #0A5961'
    //marginBottom: '10vw'
  };
  return (
    <div className={styles.root}>
      <Typography
        variant="h3"
        className={styles.titleSection}
        style={{
          fontWeight: "bold",
          color: "black",
          paddingTop: "8%",
          marginLeft: "10%",
          marginRight: "10%",
        }}
      >
        Featured Applications
      </Typography>
      <Typography
        variant="body1"
        className={`${styles.leftAlign} ${styles.spacing}`}
        style={{ color: "black", marginLeft: "10%", marginRight: "10%" }}
      >
        Browse some of the featured applications below to get an idea on how you
        can contribute.
      </Typography>
      <div className={styles.outer}>
        <div className={styles.previewSection}>
          <Box flexDirection="row" display="flex">
            {profileCards}
          </Box>
        </div>
      </div>
      {!props.shouldExcludeButton && (
        <div className={styles.buttonWrap}>
          <PrimaryButton
            style={buttonStyle}
            link="/search"
            label="preview_section.browse_more"
            buttonStyle="primary"
          >
            <Typography variant="h5">Browse More</Typography>
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}

export default PreviewSection;
