import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import step1Image from "../../resources/step1.svg";
import step2Image from "../../resources/step2.svg";
import step3Image from "../../resources/step3.svg";
import InstructionCard from "./InstructionCard";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import lineBackground from "../../resources/line.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      [theme.breakpoints.down("md")]: {
        display: "inline-bloxk",
      },
    },
    contentContainer: {
      margin: "0px 15px",
      backgroundColor: "#F0F7F8",
    },
    paper: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    card: {
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        maxWidth: "800px",
      },
      [theme.breakpoints.down("md")]: {
        margin: "10%",
        padding: "10%",
        width: "auto",
      },
    },
    cardWidth: {
      [theme.breakpoints.up("md")]: {
        width: "35%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    image: {
      margin: "auto",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        width: "70%",
        marginLeft: "80px",
        display: "inline-block",
      },
    },
    innerImage: {
      maxHeight: "600px",
    },
    grid: {
      border: `2px rgba(14, 127, 138, 0.15) solid`,
      display: "inline-block",
      borderRadius: "10px",
      padding: "7%",
      backgroundColor: "rgba(14, 127, 138, 0.02)",
    },
    showDownMd: {
      [theme.breakpoints.down("md")]: {
        marginBottom: 20,
        //margin:"3% 0%",
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    border: {
      //padding:"3%",
      //border: `2px rgba(57, 125, 136, 0.2) solid`,
    },
    showUpMd: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    list: {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        width: "35%",
        minWidth: 250,
      },
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },
    spacing: {
      [theme.breakpoints.up("md")]: {
        marginTop: "25px",
        marginBottom: "0px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "5vw",
        marginBottom: "5vw",
        textAlign: "left",
      },
    },
    container: {
      [theme.breakpoints.down("sm")]: {
        margin: "3%",
      },
      [theme.breakpoints.up("md")]: {
        margin: "80px 10%",
      },
    },
    conditionalMargin: {
      [theme.breakpoints.down("sm")]: {
        margin: "7%",
      },
      [theme.breakpoints.up("md")]: {
        margin: "0%",
      },
    },
    titleSection: {
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "5vw",
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
  })
);

function UnconditionalInstructionSection() {
  const classes = useStyles();

  const cards = instructions.map((instruction) => {
    let image;
    if (instruction.index === 2) {
      image = step2Image;
    } else if (instruction.index === 3) {
      image = step3Image;
    } else {
      image = step1Image;
    }
    return (
      <div className={classes.showDownMd} key={instruction.index}>
        <div className={classes.border}>
          <Grid className={classes.grid} item lg={12} md={12} sm={12} xs={12}>
            <InstructionCard
              {...instruction}
              updateImage={(index?: number) => {}}
              transparent={true}
              shouldElevate={false}
              clickable={false}
            />
            <div className={classes.image}>
              <img
                className={classes.innerImage}
                style={{ padding: "30px 0px" }}
                src={image}
                alt=""
              />
            </div>
            <div
              style={{
                backgroundColor: "#5DAEB7",
                borderRadius: "10px",
                padding: "8px",
                color: "white",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontFamily: "SuperaGothic-ExtraBold" }}
              >
                {instruction.instruction}
              </Typography>
            </div>
          </Grid>
        </div>
      </div>
    );
  });

  return (
    <div className={classes.root}>
      <Grid container alignItems={"stretch"} className={classes.list}>
        {cards}
      </Grid>
    </div>
  );
}

function InstructionSection() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.conditionalMargin}>
        <Typography
          variant="h3"
          style={{ fontWeight: "bold", color: "black" }}
          className={classes.titleSection}
        >
          Getting Started Is Easy
        </Typography>
        <Typography variant="body1" className={classes.spacing}>
          Follow 3 simple steps and you’re done!
        </Typography>
      </div>
      <div style={{ marginTop: "5%", marginBottom: "10%" }}>
        <HorizontalSection />
        <UnconditionalInstructionSection />
      </div>
    </div>
  );
}

function HorizontalSection() {
  const classes = useStyles();
  const [image, setImage] = useState<string>(step1Image);
  const [index, setIndex] = useState<number>(1);

  const updateImage = (index?: number) => {
    setIndex(index || 1);
    if (index === 2) {
      setImage(step2Image);
    } else if (index === 3) {
      setImage(step3Image);
    } else {
      setImage(step1Image);
    }
  };

  const cards = instructions.map((instruction) => {
    return (
      <Grid item lg={12} md={12} sm={12} xs={12} key={instruction.index}>
        <InstructionCard
          {...instruction}
          updateImage={updateImage}
          shouldElevate={index === instruction.index}
          clickable={index !== instruction.index}
        />
      </Grid>
    );
  });

  return (
    <div className={classes.showUpMd}>
      <Grid
        container
        alignItems={"stretch"}
        spacing={4}
        className={classes.list}
        style={{ backgroundImage: `url(${lineBackground})` }}
      >
        {cards}
      </Grid>
      <div className={classes.image}>
        <img
          alt=""
          className={classes.innerImage}
          style={{ margin: "auto" }}
          src={image}
        />
        <div
          style={{
            backgroundColor: "#5DAEB7",
            borderRadius: "10px",
            marginTop: 20,
            padding: "10px",
            color: "white",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            style={{ fontFamily: "SuperaGothic-ExtraBold" }}
          >
            {instructions.find((ins) => ins.index === index)?.instruction}
          </Typography>
        </div>
      </div>
    </div>
  );
}

const instructions = [
  {
    index: 1,
    title: "Download",
    text: "Download your application from commonapp.org",
    icon: "../../resources/step1.svg",
    url: "https://www.commonapp.org",
    instruction: "1. Save application as PDF",
  },
  {
    index: 2,
    title: "Upload",
    text: "Upload to CollegeSharing and black out sensitive info",
    icon: "../../resources/step2.svg",
    url: "https://www.collegesharing.com/upload",
    instruction: "2. Black-off sensitive info",
  },
  {
    index: 3,
    title: "Earn",
    text: "Check your email and accept payout with venmo or paypal",
    icon: "../../resources/step3.svg",
    url: "https://mail.google.com",
    instruction: "3. Commit to upload",
  },
];

export default InstructionSection;
