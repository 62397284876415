import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
//import Cookies from 'js-cookie';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      width: "100%",
      height: "120px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerText: {
      fontFamily: "Righteous",
      fontSize: "32px",
      color: "white",
      position: "relative",
      bottom: "90px",
    },
    overflow: {
      overflow: "hidden",
    },
  })
);

function CardHeader({ title }: { title: string }) {
  const classes = useStyles();
  return (
    <div>
      <LazyLoadImage
        src={require("../../../resources/header-background.jpg")}
        height={120}
        effect="blur"
        visibleByDefault={true}
        placeholderSrc={""}
        wrapperClassName={classes.overflow}
      />
      <Typography className={classes.headerText}>{title}</Typography>
    </div>
  );
  /*return (
        <div className={classes.header} style={{backgroundImage: `url(${require('../../../resources/header-background.jpg')}`}}>
            <Typography className={classes.headerText}>{title}</Typography>
        </div>
    );*/
}

export default CardHeader;
