import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ExtracurricularActivity } from "../../types/uploadTypes";
import RoundedButton from "./PrimaryButton";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

interface Props {
  activities: Array<ExtracurricularActivity>;
  setActivities: (activities: Array<ExtracurricularActivity>) => void;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
    },
    body: {
      fontSize: 16,
      fontFamily: "SuperaGothic",
      fontWeight: "normal",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-child(4n)": {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.action.hover,
      },
      "&:nth-child(4n-1)": {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.action.hover,
      },
      "& > *": {
        borderBottom: "unset",
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  resize: {
    fontSize: 16,
    lineHeight: "19px",
  },
});

interface RowProps {
  activity: ExtracurricularActivity;
  delete: () => void;
  setActivity: (activity: ExtracurricularActivity) => void;
}

function Row(props: RowProps) {
  const styles = useStyles();
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Object.assign({}, props.activity, {
      name: event.target.value,
    });
    props.setActivity(newValue);
    //setName(event.target.value);
  };
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = Object.assign({}, props.activity, {
      description: event.target.value,
    });
    props.setActivity(newValue);
    //setDescription(event.target.value);
  };
  const handlePositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Object.assign({}, props.activity, {
      position: event.target.value,
    });
    props.setActivity(newValue);
    //setPosition(event.target.value);
  };
  const handleYearsInvolvedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value && !Number(event.target.value)) {
      return;
    }
    const newValue = Object.assign({}, props.activity, {
      yearsInvolved: Number(event.target.value),
    });
    props.setActivity(newValue);
    //setYearsInvolved(Number(event.target.value));
  };

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell style={{ verticalAlign: "bottom", paddingBottom: 6 }}>
          <TextField
            multiline
            rowsMax={2}
            InputProps={{ classes: { input: styles.resize } }}
            style={{ width: "100%" }}
            placeholder="Title (Math Olympiad, etc.)"
            value={props.activity.name || ""}
            onChange={handleNameChange}
          />
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ verticalAlign: "bottom", paddingBottom: 6 }}
        >
          <TextField
            multiline
            rowsMax={2}
            InputProps={{ classes: { input: styles.resize } }}
            style={{ width: "100%" }}
            placeholder="Years Involved (2, 3, 4, etc.)"
            value={props.activity.yearsInvolved || ""}
            onChange={handleYearsInvolvedChange}
          />
        </StyledTableCell>
        <StyledTableCell style={{ verticalAlign: "bottom", paddingBottom: 6 }}>
          <TextField
            multiline
            rowsMax={2}
            InputProps={{ classes: { input: styles.resize } }}
            style={{ width: "100%" }}
            placeholder="Position"
            value={props.activity.position || ""}
            onChange={handlePositionChange}
          />
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell colSpan={2}>
          <TextField
            multiline
            rowsMax={3}
            InputProps={{ classes: { input: styles.resize } }}
            placeholder="Description (2013’ USAMO Qualifier, etc.)"
            style={{ width: "100%" }}
            value={props.activity.description || ""}
            onChange={handleDescriptionChange}
          />
        </StyledTableCell>
        <StyledTableCell>
          <RoundedButton
            variant="outlined"
            style={{ borderRadius: "30px", color: "white", border: "none" }}
            buttonStyle="secondary"
            onClick={props.delete}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: "16px",
                fontFamily: "SuperaGothic",
                lineHeight: "normal",
              }}
            >
              Delete
            </Typography>
          </RoundedButton>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function CustomizedTables(props: Props) {
  const classes = useStyles();

  const updateActivities = (
    index: number,
    activity: ExtracurricularActivity
  ) => {
    const newArray = props.activities.map((a) => Object.assign({}, a));
    newArray[index] = activity;
    props.setActivities(newArray);
  };

  const deleteActivity = (index: number) => {
    const newArray = props.activities.map((a) => Object.assign({}, a));
    newArray.splice(index, 1);
    props.setActivities(newArray);
  };

  const addActivity = () => {
    const newArray = props.activities.map((a) => Object.assign({}, a));
    newArray.push({
      name: undefined,
      position: undefined,
      yearsInvolved: undefined,
      description: undefined,
    });
    props.setActivities(newArray);
  };
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell size="small">Years Involved</StyledTableCell>
            <StyledTableCell>Position</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.activities.map((row, index) => (
            <Row
              activity={row}
              delete={() => deleteActivity(index)}
              setActivity={(newActivity: ExtracurricularActivity) =>
                updateActivities(index, newActivity)
              }
              key={index}
            />
          ))}
          <StyledTableRow>
            <StyledTableCell colSpan={3}>
              <RoundedButton
                variant="outlined"
                style={{
                  borderRadius: "30px",
                  color: "white",
                  border: "none",
                  margin: "auto",
                }}
                buttonStyle="primary"
                onClick={addActivity}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "16px",
                    fontFamily: "SuperaGothic",
                    lineHeight: "normal",
                  }}
                >
                  Add
                </Typography>
              </RoundedButton>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
