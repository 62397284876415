import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Spacing from "../general/Spacing";
import { Paper, Typography } from "@material-ui/core";
import Alert from "../pages/account/PanelAlert";
import {
  isValidEmail,
  emailAlertMessage,
} from "../../helpers/AccountInfoChecker";
import ColoredTextField from "../pages/account/ColoredTextField";
import LoadingContent from "../pages/account/LoadingContent";
import axios from "axios";
import { AlertProps } from "@material-ui/lab/Alert";
import * as Sentry from "@sentry/browser";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
//import Cookies from 'js-cookie';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: "0px 15px",
    },
    paper: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    card: {
      alignItems: "center",
      minWidth: "320px",
      maxWidth: "560px",
      width: "50%",
      overflow: "hidden",
      margin: "0px auto",
    },
    header: {
      width: "100%",
      height: "120px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerText: {
      fontFamily: "Righteous",
      fontSize: "32px",
      color: "white",
    },
    buttonText: {
      fontFamily: "SuperaGothic",
      fontSize: "18px",
    },
    root: {
      alignItems: "center",
      //backgroundColor: theme.palette.primary.main,
      //paddingBottom: '80px',
      //paddingTop: '80px',
    },
    labelRoot: {
      fontFamily: "SuperaGothic",
    },
    inputRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleText: {
      fontSize: "16px",
      fontFamily: "SuperaGothic",
      fontWeight: "bold",
      width: "120px",
      textAlign: "left",
    },
    titleSection: {
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },

    textField: {
      width: "90%",
      maxWidth: "280px",
    },
    textFieldContainer: {
      width: "90%",
      margin: "auto",
    },
    rowWrapper: {
      //marginBottom: '12px',
    },
    cardContent: {
      margin: "30px",
    },
  })
);

interface Props {
  caseId: number;
  close: () => void;
}

function SendEmailCard(props: Props) {
  const classes = useStyles();

  const [email, setEmail] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertProps>({});

  const onSubmitEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onSendEmail = () => {
    if (!isValidEmail(email)) {
      setAlert({
        severity: "error",
        title: "Error",
        children: emailAlertMessage,
      });
      return;
    }
    setIsLoading(true);
    axios("api/v1/send_referral_email", {
      method: "POST",
      withCredentials: true,
      data: {
        email: email,
        id: props.caseId,
      },
    })
      .then((response) => {
        setAlert({
          severity: "success",
          title: "Success",
          children: "Email successfully sent.",
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setAlert({
          severity: "error",
          title: "Error",
          children: "Failed to send email. Please try again.",
        });
        Sentry.captureException(error, { extra: { email: email } });
      });
  };

  const onClose = () => {
    props.close();
    setAlert({});
    setEmail(undefined);
    setIsLoading(false);
  };
  var content;

  if (!isLoading) {
    content = (
      <div className="card-wrapper" style={{ opacity: 1 }}>
        {alert.children && (
          <div>
            <Alert severity={alert.severity} title={alert.title}>
              {alert.children}
            </Alert>
            <Spacing spacing={24} />
          </div>
        )}
        <div className="row-container">
          <div className={classes.rowWrapper}>
            <Typography
              style={{ fontSize: "16px", fontFamily: "SuperaGothic" }}
            >
              Who do you want to share this application with? Please enter the
              email address below.
            </Typography>
          </div>
          <Spacing spacing={16} />
          <div className={classes.rowWrapper}>
            <ColoredTextField
              title="Friend's Email"
              type="text"
              onChange={onSubmitEmail}
              placeholder="anna@gmail.com"
            />
          </div>
        </div>
      </div>
    );
  } else {
    content = <LoadingContent title="Sign Up" excludeHeader />;
  }

  return (
    <Paper className={classes.card} elevation={8}>
      <DialogTitle id="confirmation-dialog-title">
        <Typography
          style={{ fontWeight: "bold" }}
          className={classes.titleSection}
        >
          Share with your friend
        </Typography>
      </DialogTitle>

      <DialogContent dividers style={{ padding: 0 }}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onClose}
          color="primary"
          disabled={isLoading}
        >
          <Typography
            variant="body1"
            style={{
              fontFamily: "SuperaGothic",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Cancel
          </Typography>
        </Button>
        <Button onClick={onSendEmail} color="primary" disabled={isLoading}>
          <Typography
            variant="body1"
            style={{
              fontFamily: "SuperaGothic",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            {isLoading ? "Sending" : "Send"}
          </Typography>
        </Button>
      </DialogActions>
    </Paper>
  );
}

export default SendEmailCard;
