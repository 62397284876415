import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../general/PrimaryButton";
import triangle from "../../resources/Triangle.svg";
import triangleTextBackground from "../../resources/TriangleTextBackground.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: "SuperaGothic",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        display: "inline-block",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    text: {
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: "10%",
        paddingBottom: "8%",
        width: "auto",
        textAlign: "center",
      },
      [theme.breakpoints.up("md")]: {
        padding: "80px 10%",
        width: "50%",
        paddingRight: "50px",
        paddingLeft: "5%",
        marginLeft: "5%",
        textAlign: "left",
      },
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },
    image: {
      background: "white",
      margin: "auto",
      //width: '800px',
    },
    leftAlign: {
      [theme.breakpoints.down("md")]: {
        textAlign: "left",
      },
      [theme.breakpoints.up("md")]: {
        textAlign: "left",
      },
    },
    padding: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        backgroundImage: "none",
      },
      [theme.breakpoints.only("sm")]: {
        width: "80%",
        backgroundImage: "none",
      },
      [theme.breakpoints.up("md")]: {
        //display:'flex',
        padding: "2%",
        paddingBottom: "0%",
        paddingLeft: "0%",
        width: "60%",
        maxWidth: "900px",
        //backgroundImage: `url(${triangleImageBackground})`
      },
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      margin: "auto",
    },
    spacing: {
      "@media (min-width:800px)": {
        marginTop: "25px",
        marginBottom: "15px",
      },
      "@media (max-width:800px)": {
        marginTop: "5vw",
        marginBottom: "0px",
      },
    },
    fitBackground: {
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },
    wrapperClassName: {
      display: "flex",
    },
    titleSection: {
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
      [theme.breakpoints.up("sm")]: {
        fontFamily: "SuperaGothic-ExtraBold",
      },
    },
  })
);

function WhatToUploadSection() {
  const styles = useStyles();
  return (
    <div className={`${styles.root}`}>
      <div
        className={styles.text}
        style={{ backgroundImage: `url(${triangleTextBackground})` }}
      >
        <Typography
          variant="h3"
          className={styles.titleSection}
          style={{ fontWeight: "bold" }}
        >
          What Should I Upload?
        </Typography>
        <Typography
          variant="body1"
          className={`roboto-condensed ${styles.spacing} ${styles.leftAlign}`}
        >
          We recommend uploading{" "}
          <span style={{ fontWeight: "bold" }}>
            essays, test scores, and extracurriculars
          </span>
          . But it’s entirely up to you.
        </Typography>
        <Typography
          variant="body1"
          className={`roboto-condensed ${styles.spacing} ${styles.leftAlign}`}
        >
          Keep in mind: we will pay more for more info.
        </Typography>
        <div style={{ margin: "auto", textAlign: "center", marginTop: "60px" }}>
          <PrimaryButton
            link="/upload_status"
            label="what_to_upload_section.upload_now"
          >
            <Typography variant="h5">Upload Now</Typography>
          </PrimaryButton>
        </div>
      </div>

      <div className={styles.padding}>
        <img
          className={styles.image}
          style={{ margin: "auto" }}
          src={triangle}
          alt=""
        />
      </div>
    </div>
  );
}

export default WhatToUploadSection;
