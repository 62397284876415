import ReactGa, { EventArgs } from "react-ga";
import axios from "axios";

export function logEvent(args: EventArgs) {
  args.label = FormattedLabel(args.label);
  ReactGa.event(args);
  axios("api/v1/log_user_event", {
    method: "POST",
    withCredentials: true,
    data: {
      category: args.category,
      action: args.action,
      label: args.label,
      value: args.value,
    },
  });
}

export function logModalView(modalName: string) {
  const name = FormattedLabel(modalName);
  ReactGa.modalview(name);
}

export function convertStringToLoggingFormat(name: string) {
  return name.toLocaleLowerCase().split(" ").join("_");
}

function FormattedLabel(label?: string) {
  var pathname = window.location.pathname.replace(/^\/+/, "");
  const index = pathname.indexOf("/");
  if (index > 0) {
    pathname = pathname.substring(0, index);
  }
  const formattedLabel = label
    ? pathname +
      (pathname.length > 0 ? "." : "home.") +
      convertStringToLoggingFormat(label)
    : pathname;
  return formattedLabel;
}
