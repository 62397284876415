import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelText: {
      fontFamily: "SuperaGothic",
      ...theme.typography.body1,
    },
    radio: {
      paddingRight: "3px",
      paddingTop: "3px",
      paddingBottom: "3px",
    },
  })
);

interface Option {
  key: string;
  value: string;
}

interface Props {
  options: Array<Option>;
  handleSelect: (value: string) => void;
  value: string | null;
}

export default function RadioButtonsGroup(props: Props) {
  const classes = useStyles();
  //const [value, setValue] = React.useState(props.options[1].value);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    props.handleSelect(value);
  };

  const options = props.options.map((option) => {
    const labelText = (
      <Typography className={classes.labelText} variant="body2">
        {option.value}
      </Typography>
    );
    return (
      <FormControlLabel
        value={option.value}
        control={<Radio color="primary" className={classes.radio} />}
        label={labelText}
        key={option.key}
      />
    );
  });

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="gender"
        name="gender1"
        onChange={onChange}
        value={props.value}
      >
        {options}
      </RadioGroup>
    </FormControl>
  );
}
