import React from "react";
import Banner from "../general/Banner";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import UploadStatusPage from "./UploadStatusPage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "flex-start",
      overflow: "hidden",
    },
    paperEdge: {
      width: "10px",
      backgroundColor: theme.palette.primary.main,
      //marginRight: '20px',
    },
    titleFlex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      //borderBottom: '1px solid #e5e5e5',
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: "2rem",
      marginRight: "10px",
    },
    container: {
      margin: "40px 20px",
      //marginTop: '40px',
    },
    buttonStyle: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      marginTop: "40px",
      marginBottom: "10vw",
    },
  })
);

interface Props {
  title?: string;
  subtitle?: string;
}

function UploadSuccessPage(props: Props) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container}>
        <Banner
          title={props.title || "Profile Successfully Saved"}
          subtitle={
            props.subtitle || "Your profile has been successfully saved!"
          }
        />
      </div>
      <UploadStatusPage />
    </div>
  );
}

export default UploadSuccessPage;
