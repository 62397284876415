import React from "react";
import { FileDrop } from "react-file-drop";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import "./DragAndDropZone.css";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

const acceptedFiles = ["application/pdf", "image/jpeg", "image/png"];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    target: {
      backgroundColor: "#87C5CC",
    },
    button: {
      marginTop: "15px",
      backgroundColor: theme.palette.secondary.light,
      padding: "0px 20px",
      fontFamily: "RobotoCondensed",
      textTransform: "none",
      ...theme.typography.h6,
      "&:hover": {
        backgroundColor: "#E5E5E5",
      },
    },
    text: {
      color: "white",
      marginTop: "5px",
      marginBottom: "20px",
    },
    close: {
      padding: theme.spacing(0.5),
    },
  })
);

interface Props {
  handleNext: (uploadedFiles: Array<File>) => void;
  sendAlert: () => void;
  uploadedFiles: Array<File>;
  setUploadedFiles: (files: Array<File>) => void;
}

export default function DragAndDrop(props: Props) {
  const classes = useStyles();

  const styles = {
    backgroundColor: "#87C5CC",
    padding: 15,
  };

  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files !== null && files.length !== 0) {
      props.setUploadedFiles(props.uploadedFiles.concat(Array.from(files)));
      //props.handleNext(uploadedFiles.concat(pdfs));
    }
  };

  const fileDroppedHandler = (
    files: FileList | null,
    event: React.DragEvent<HTMLDivElement>
  ) => {
    if (files !== null && files.length !== 0) {
      const pdfs = Array.from(files).filter((file) =>
        acceptedFiles.includes(file.type)
      );
      if (pdfs.length !== Array.from(files).length) {
        props.sendAlert();
      }
      props.setUploadedFiles(props.uploadedFiles.concat(pdfs));
    }
  };

  return (
    <div style={styles}>
      <FileDrop onDrop={fileDroppedHandler}>
        <PictureAsPdfIcon
          style={{ color: "white", marginTop: 20, fontSize: "40px" }}
        />
        <input
          type="file"
          style={{ display: "none" }}
          accept="application/pdf, image/png, image/jpeg"
          id="contained-button-file"
          multiple
          onChange={fileSelectedHandler}
        />
        <label htmlFor="contained-button-file">
          <Button
            classes={{
              root: classes.button,
            }}
            color="secondary"
            variant="contained"
            disableElevation
            component="span"
          >
            Select Files
          </Button>
        </label>
        <Typography variant="h6" className={classes.text}>
          Or drag files here
        </Typography>
      </FileDrop>
    </div>
  );
}
