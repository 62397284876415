import { GeneralProfile, School } from "../../../types/admitProfileTypes";
import React from "react";
import TestSection from "./TestSection";
import HonorSection from "./HonorSection";
import ActivitySection from "./ActivitySection";
import EssaySection from "./EssaySection";
import SchoolSection from "./SchoolSection";
import { useStyles } from "./ProfileStyles";

export type MainPanelProps = {
  generalProfile: GeneralProfile;
  schools: School[];
};

function MainPanel(props: MainPanelProps) {
  const classes = useStyles();
  const profile = props.generalProfile;

  return (
    <div>
      <TestSection generalProfile={profile} />
      <div
        className={classes.conditionalSplit}
        style={{ justifyContent: "space-around", flexDirection: "row-reverse" }}
      >
        <div className={classes.conditionalSplitItem}>
          <HonorSection honors={profile.honors} />
          <ActivitySection activities={profile.activities} />
          <EssaySection essays={profile.essays} />
        </div>
        <div className={classes.conditionalSplitItem}>
          <SchoolSection schools={props.schools} />
        </div>
      </div>
    </div>
  );
}

export default MainPanel;
