import {
  UpdateFilterAction,
  UPDATE_FILTER,
  UPDATE_FILTERS,
  ClearAllFiltersAction,
  CLEAR_ALL_FILTERS,
  UpdatePageNumberAction,
  UPDATE_PAGE_NUMBER,
  LOAD_INITIAL_FILTER,
  LoadInitialFilterAction,
  UPDATE_ORDER_BY,
  UpdateOrderByAction,
  LoadFilterFromUrlAction,
  LOAD_FILTER_FROM_URL,
  UpdateFiltersAction,
} from "../types/actions";
import {
  SearchPageFilter,
  CheckBoxItem,
  CategoryStatus,
  FilterItem,
} from "../types/types";

const updateFilter = (
  state: SearchPageFilter,
  action:
    | UpdateFilterAction
    | ClearAllFiltersAction
    | UpdatePageNumberAction
    | LoadInitialFilterAction
    | UpdateOrderByAction
    | LoadFilterFromUrlAction
    | UpdateFiltersAction
) => {
  let newState = null;
  switch (action.type) {
    case UPDATE_PAGE_NUMBER:
      newState = Object.assign({}, state, {
        pageNumber: action.pageNumber,
      });
      action.callBack(newState);
      return newState;
    case UPDATE_FILTER:
      newState = Object.assign({}, state, {
        categoryStatuses: state.categoryStatuses.map((categoryStatus) => {
          if (categoryStatus.title === action.name) {
            return Object.assign({}, categoryStatus, {
              listStatus: reorder(action.newFilter),
            });
          } else {
            return categoryStatus;
          }
        }),
      });
      action.callBack(newState);
      return newState;
    case UPDATE_FILTERS:
      newState = Object.assign({}, state, {
        categoryStatuses: state.categoryStatuses.map((categoryStatus) => {
          if (action.updatedFilter.has(categoryStatus.title)) {
            return Object.assign({}, categoryStatus, {
              listStatus: reorder(
                action.updatedFilter.get(categoryStatus.title)
              ),
            });
          } else {
            return categoryStatus;
          }
        }),
      });
      action.callBack(newState);
      return newState;
    case LOAD_FILTER_FROM_URL:
      const newCategoryStatuses = state.categoryStatuses.map(
        (categoryStatus) => {
          const selectedItems: Array<string> =
            action.urlFilter.selected.find(
              (item) =>
                item.name.toLocaleLowerCase() ===
                categoryStatus.title.toLocaleLowerCase()
            )?.value || [];
          return Object.assign({}, categoryStatus, {
            listStatus: categoryStatus.listStatus.map((checkboxItem) => {
              const isSelected = selectedItems.includes(
                checkboxItem.filterItem.id
              );
              return Object.assign({}, checkboxItem, {
                selected: isSelected,
              });
            }),
          });
        }
      );
      return {
        pageNumber: action.urlFilter.pageNumber,
        categoryStatuses: newCategoryStatuses,
        orderBy: action.urlFilter.orderBy,
      };
    case LOAD_INITIAL_FILTER:
      let categoryStatuses: Array<CategoryStatus> = action.initialFilter.map(
        (categoryStatus) => {
          return {
            title: categoryStatus.title,
            listStatus: categoryStatus.listStatus.map(
              (filterItem: FilterItem) => {
                return {
                  filterItem,
                  selected: false,
                };
              }
            ),
          };
        }
      );
      categoryStatuses = categoryStatuses.map((categoryStatus) => {
        const selectedItems: Array<string> =
          action.urlFilter.selected.find(
            (item) =>
              item.name.toLocaleLowerCase() ===
              categoryStatus.title.toLocaleLowerCase()
          )?.value || [];
        return Object.assign({}, categoryStatus, {
          listStatus: categoryStatus.listStatus.map((checkboxItem) => {
            const isSelected = selectedItems.includes(
              checkboxItem.filterItem.id
            );
            return Object.assign({}, checkboxItem, {
              selected: isSelected,
            });
          }),
        });
      });
      return {
        pageNumber: action.urlFilter.pageNumber,
        categoryStatuses,
        orderBy: action.urlFilter.orderBy,
      };
    case CLEAR_ALL_FILTERS:
      newState = Object.assign({}, state, {
        categoryStatuses: state.categoryStatuses.map((categoryStatus) => {
          return Object.assign({}, categoryStatus, {
            listStatus: categoryStatus.listStatus.map((checkbox) => {
              return Object.assign({}, checkbox, {
                selected: false,
              });
            }),
          });
        }),
      });
      newState = Object.assign({}, newState, {
        pageNumber: 1,
      });
      action.callBack(newState);
      return newState;
    case UPDATE_ORDER_BY:
      newState = Object.assign({}, state, {
        orderBy: action.orderBy,
        pageNumber: 1,
      });
      action.callBack(newState);
      return newState;
    default:
      return state;
  }
};

function reorder(list: Array<CheckBoxItem>): Array<CheckBoxItem> {
  return list.sort(sortFuntion);
}

function sortFuntion(
  filterItem1: CheckBoxItem,
  filterItem2: CheckBoxItem
): number {
  const lowerCaseName1 = filterItem1.filterItem.name.toLocaleLowerCase();
  const lowerCaseName2 = filterItem2.filterItem.name.toLocaleLowerCase();
  if (lowerCaseName1 < lowerCaseName2) {
    return -1;
  }
  if (lowerCaseName1 > lowerCaseName2) {
    return 1;
  }
  return 0;
}

export default updateFilter;
